import React, { useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap"
import Switch from 'react-switch'
import Breadcrumbs from "../../components/Common/Breadcrumb"
//import images
import adobephotoshop from "../../assets/images/companies/adobe-photoshop.svg"
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import Swal from "sweetalert2"
import ChatWidget from "components/Common/ChatWidget"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const convertDateFormat = inputDate => {
  const parts = inputDate.split("/")
  const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`
  return formattedDate
}
const ProjectDetail = () => {
  const { state } = useLocation()
  const [DataToBeSetTasks, setDataToBeSetTasks] = useState([])
  const [counter, setCounter] = useState(1)
  const [assignTo, setassignTo] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [isAttachmentSwitch, setisAttachmentSwitch] = useState(false)
  const [createTaskShow,setCreateTaskShown] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [editloadingSpinner, setEditLoadingSpinner] = useState(false);
  const [clientType, setClientType] = useState('')
  const [editProjectDetail, setEditProjectDetail] = useState(false)
  const [startDate, setStartDate] = useState('')
  

  document.title = `Project Overview ${state.project.projectName} | ${process.env.REACT_APP_NAME}`
  
  const getAllTasks = () => {
    var formdata = new FormData();
    formdata.append('project_id', state.project.id);
    formdata.append('method', 'fetch-tasks');
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
    };
    fetch(process.env.REACT_APP_LEAVE_API_URL+'/tasks.php', requestOptions)
      .then(response => response.json())
      .then(result => {
        setDataToBeSetTasks(result)
      })
      .catch(error => console.log('error', error));
  };
  useEffect(() => {
    getAllTasks()
    fetchEmployeeList()
    fetchSingleProjectDetails(state.project.id,'no')
  }, [counter])

  const calculateDaysDifference = deadlineDate => {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const currentDate = new Date()
    const deadline = new Date(deadlineDate)

    // Calculate the difference in days
    const differenceInDays = Math.round((deadline - currentDate) / oneDay)

    // Determine status message based on the difference
    let statusMessage
    if (differenceInDays === 1) {
      statusMessage = "Tomorrow"
    } else if (differenceInDays === 0) {
      statusMessage = "Today"
    } else if (differenceInDays < 0) {
      statusMessage = `${Math.abs(differenceInDays)} days overdue`
    } else {
      statusMessage = `${differenceInDays} days remaining`
    }

    return statusMessage
  }
  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setmockData2(result)
      })
      .catch(er => {
        console.log(er)
      })
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const formatDateTime = (inputDate) => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate);
    
    // Extract the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    
    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const [singleProject,setSingleProject] = useState({
    client_id:'',
    client_name:'',
    client_email:'',
    client_phone:'',
    client_address:'',
    project_id : '',
    project_name:'',
    project_description:'',
    project_status:'',
    project_created:'',
    attachment:'',
    project_status : '',
    deadline_date: '',
    created_by: null,
  })
  const fetchSingleProjectDetails = (passing,other) =>{
    other == 'yes' ? setEditProjectDetail(true): null
    let form = new FormData();
    form.append('method', 'edit_single_project');
    form.append('id',passing);
    axios.post(process.env.REACT_APP_LEAVE_API_URL  + '/projects.php',form).then((res)=>{
      setSingleProject(res.data)
      if(res.data.deadline_date){
        setStartDate(convertDateFormat(res.data.deadline_date))
      }
    })

  }
  function convertDateFormat(inputDate) {
    const parts = inputDate.split("/")
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`
    return formattedDate
  }
  const onClickDelete = (id)=>{
   
    Swal.fire({
      title: "Delete Task",
      html: "<p>Are you sure to delete this Project</p> <p><strong style='color:red'>Note:</strong> This will remove the tasks linked to that project</p>",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    }).then(result => {
      if (result.isConfirmed) {
        // Handle action for Button 1
        let form = new FormData()
        form.append("method", "delete-project")
        form.append("delete_id", id)
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/projects.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              Swal.fire("Deleted", "Project is successfully deleted")
              window.history.back()
            } else {
              Swal.fire(
                "Server Error",
                "Error while deleting the Project. Try again"
              )
            }
          })
      } else if (result.dismiss === "cancel") {
        Swal.close()
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs titleLink="/projects" title="Projects" breadcrumbItem="Project Overview" />
          {/* Render Breadcrumbs */}
          <Row>
            <Col md="12" className="text-end mb-4"  style={{textAlign:'end'}}>
               <div className="offset-8 col-md-4 hstack gap-2">
                    <button className="btn btn-primary w-100" onClick={()=>fetchSingleProjectDetails(state.project.id,'yes')}>
                      Edit Project
                    </button>
                    <button className="btn btn-warning w-100" onClick={()=>setCreateTaskShown(true)}>
                      Create Task
                    </button>
                    <button className="btn btn-danger w-100" onClick={()=>onClickDelete(state.project.id)}>
                      Delete Project
                    </button>
                 
                  </div>
            </Col>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h5 className="fw-semibold">Overview</h5>
                
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th scope="col">Project Title</th>

                          <td scope="col">
                            {singleProject?.project_name}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Client Name:</th>
                          <td>{singleProject.client_name}</td>
                        </tr>
                        <tr>
                          <th scope="row">Client Email</th>
                          <td>{singleProject?.client_phone}</td>
                        </tr>
                        <tr>
                          <th scope="row">Client Phone</th>
                          <td>{singleProject?.client_email}</td>
                        </tr>

                        <tr>
                          <th scope="row">Status</th>
                          <td>
                            <span className="badge badge-soft-info">
                              {singleProject?.project_status}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">Posted Date</th>

                          <td>{singleProject.project_created}</td>
                        </tr>
                        <tr>
                          <th scope="row">Deadline Date</th>

                          <td>{singleProject.deadline_date}</td>
                        </tr>
                        <tr>
                          <th scope="row">Created By</th>

                          <td>{singleProject.created_by?.first_name + ' ' + singleProject.created_by?.last_name}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                
                </CardBody>
              </Card>
            </Col>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <h5 className="fw-semibold">Description</h5>

                 <div dangerouslySetInnerHTML={{__html: singleProject.project_description}}></div>
                  {singleProject.attachment !== "" ? (
                    <a
                      rel="noreferrer"
                      href={singleProject.attachment}
                      target="_blank"
                    >
                      Doc Link
                    </a>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-2">
                      <h5 className="card-title mb-4">
                        Tasks {state ? state.project.projectName : null}
                      </h5>
                    </div>
                  </div>

                  <div className="table-responsive">
                    <table className="table table-nowrap align-middle mb-0">
                      <thead>
                        <tr>
                          <th>Task Title</th>
                          <th>Assigned Team</th>
                          <th>Deadline Status</th>
                          <th>Status</th>
                        
                        </tr>
                      </thead>
                      <tbody>
                        {DataToBeSetTasks.map((e, index) => (
                          <tr key={index}>
                            <td>
                              <h5 className="text-truncate font-size-14 m-0">
                              <Link 
                                   to={'/task-remarks'}
                                  state={{id: e.task.task_id , task_data : e}}>
                                  {e.task.task_title}
                                </Link>
                              </h5>
                            </td>
                            <td>
                              <div className="avatar-group">
                                {e.assigned_to.map((eimg, index) => (
                                  <div
                                    key={index}
                                    className="avatar-group-item"
                                  >
                                    <Link
                                      to="#"
                                      className="d-inline-block"
                                      title={
                                        eimg.first_name + "(" + eimg.role + ")"
                                      }
                                    >
                                      <img
                                        src={eimg.profile_image}
                                        alt=""
                                        className="rounded-circle avatar-xs"
                                      />
                                    </Link>
                                  </div>
                                ))}
                              </div>
                            </td>
                            <td>
                              <p>
                                {calculateDaysDifference(
                                  convertDateFormat(e.task.deadline_date)
                                )}
                              </p>
                            </td>
                            <td>
                              <div>
                                {e.task.status == "active" ? (
                                  <span className="badge rounded-pill badge-soft-success font-size-11">
                                    {e.task.status}
                                  </span>
                                ) : (
                                  <span className="badge rounded-pill badge-soft-secondary font-size-11">
                                    {e.task.status}
                                  </span>
                                )}
                              </div>
                            </td>
                           
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <Offcanvas
          isOpen={editProjectDetail}
          direction="bottom"
          style={{height:700}}
          toggle={()=>setEditProjectDetail(false)}
        >
          <OffcanvasHeader
          toggle={()=>setEditProjectDetail(false)}
          >
            Edit Project
          </OffcanvasHeader>
          <OffcanvasBody>
          <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                // if(clientType == ""){
                //     toastr.error('Select the client type');
                //     return false;
                // }else if(e.target.elements.project_name.value == ""){
                //     toastr.error('Project name is required');
                //     return false
                // }else if(e.target.elements.project_description.value == ""){
                //     toastr.error('Project name is required');
                //     return false
                // }else{
                //     let local = JSON.parse(localStorage.getItem('authUser'));
                //     let org = local.map((e)=>e.org_id)
                //     setLoadingSpinner(true)
                //     let form = new FormData();
                //     const fileInput = document.querySelector('input[type="file"]')
                //     const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                //     form.append('method', 'create-project');
                //     form.append('org_id', org);
                //     form.append('client_type' , clientType);
                //     if(clientType == 'new client'){
                //         form.append('client_name',e.target.elements.client_name.value)
                //         form.append('client_email',e.target.elements.client_email.value)
                //         form.append('client_phone',e.target.elements.client_phone.value)
                //         form.append('client_address',e.target.elements.client_address.value)
                //     }else{
                //         form.append('client_id',clientType == 'existing client' ? e.target.elements.client_id.value : null)
                //     }
                //     form.append('project_name',e.target.elements.project_name.value)
                //     form.append('project_description',e.target.elements.project_description.value)
                //     if (isAttachmentSwitch == true) {
                //       form.append('file_upload', file);
                //     }
                   
                //     axios.post(
                //       process.env.REACT_APP_LEAVE_API_URL+'/projects.php',form,{headers:{
                //           "Content-Type": "multipart/form-data",
                //         }}
                //        ).then(result => {
                //           setLoadingSpinner(false)
                //           if(result.data === 'success'){
                //               toastr.success('You successfully added the project');
                              
                //               setCreateTaskShown(false);
                //           }else{
                //               toastr.error('Error while adding the project');
                //           }
                //       })
                //       .catch(e => console.log(e));
                // }  
                Swal.fire({
                  title:'Confirmation',
                  icon:'warning',
                  text:'Are you sure to want to update the changes',
                  showCancelButton:true,
                  cancelButtonText:'Discard Changes',
                  cancelButtonColor:'red',
                  confirmButtonColor:'green',
                  allowOutsideClick:false,
                  confirmButtonText:'Yes, I want',
                  
                  
                }).then((is)=>{
                  if(is.isConfirmed){
                    let form = new FormData();
                    form.append('method','update_project');
                    form.append('project_id',singleProject.project_id)
                    form.append('client_id',singleProject.client_id)
                    form.append('project_name',singleProject.project_name)
                    form.append('project_status',singleProject.project_status)
                    form.append('project_description',singleProject.project_description)
                    form.append('client_name',singleProject.client_name)
                    form.append('client_phone',singleProject.client_phone)
                    form.append('client_email',singleProject.client_email)
                    form.append('client_address',singleProject.client_address)
                    const [year, month, day] = startDate.split('-');
                    const dateSending = `${month}/${day}/${year}`;
                    form.append('deadline_date',dateSending)
                    axios.post(process.env.REACT_APP_LEAVE_API_URL + '/projects.php', form).then((res)=>{
                      if(res.data.message == 'success'){
                        toastr.success('You successfully updated the project changes');
                        fetchSingleProjectDetails(state.project.id, 'no')
                        setEditProjectDetail(false)
                      }else{
                        toastr.error('Error comes while save changes. Please try again');
                      }
                    })
                  }else if(is.isDismissed || is.isDenied){
                        fetchSingleProjectDetails(state.project.id, 'no')
                  }
                })
            }}
            >
            
              <Row>
               
                
                
                   <Col md={12}>
                    <Label>Client Information</Label>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Input
                            type="text"
                            className="form-control"
                            name="client_name"
                            placeholder="Client Name"
                            value={singleProject.client_name}
                            onChange={(e)=>setSingleProject((prev)=>({
                              ...prev,
                              client_name:e.target.value
                            }))}
                            />
                        </Col>
                        <Col md={4} className="mb-3">
                            <Input
                            type="tel"
                            className="form-control"
                            name="client_email"
                            value={singleProject.client_email}
                            onChange={(e)=>setSingleProject((prev)=>({
                              ...prev,
                              client_email:e.target.value
                            }))}
                            placeholder="Client Email"

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Input
                            type="email"
                            className="form-control"
                            name="client_phone"
                            value={singleProject.client_phone}
                            onChange={(e)=>setSingleProject((prev)=>({
                              ...prev,
                              client_phone:e.target.value
                            }))}
                            placeholder="Client Phone"
                            
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <Input
                            type="textarea"
                            className="form-control"
                            name="client_address"
                            value={singleProject.client_address}
                            onChange={(e)=>setSingleProject((prev)=>({
                              ...prev,
                              client_address:e.target.value
                            }))}
                            placeholder="Client Address"

                            />
                        </Col>
                    </Row>
                </Col>
                
                <Col md={6} className="mb-3">
                  <Label>Project Name</Label>
                 <Input
                    name="project_name"
                    type="text"
                    className="form-control"
                    placeholder="..."
                    value={singleProject.project_name}
                    onChange={(e)=>setSingleProject((prev)=>({
                      ...prev,
                      project_name:e.target.value
                    }))}
                 />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Deadline Date</Label>
                  <input
                      type="date"
                      value={startDate}
                      onChange={e => setStartDate(e.target.value)}
                      className="form-control"
                      
                      name="deadline_date"
                      placeholder="Select date"
                    />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Project Status</Label>
                  <select className="form-control" value={singleProject.project_status} 
                  onChange={(e)=>setSingleProject((prev)=>({
                    ...prev,
                    project_status: e.target.value
                  }))}>
                    <option value={'new'}>New</option>
                    <option  value={'active'}>Active</option>
                    <option  value={'completed'}>Completed</option>
                  </select>
               
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Project Description</Label>
                  <CKEditor
                      editor={ClassicEditor}
                      data={singleProject.project_description}
                      
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setSingleProject((prev)=>({...prev, project_description : data}))

                      }}
                      
                    />
                </Col>
               
                <Col md={12}>
                {/* <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div> */}
                </Col>
                {/* {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                } */}
                <Col md={12} style={{textAlign:'start'}}>
                    <span type="button"  onClick={()=>setEditProjectDetail(false)} className="btn btn-danger">Cancel</span>
                    &nbsp;&nbsp;
                    {
                        editloadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Edit Project</button>
                        :<Spinner color="primary" size={'md'} style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
            </OffcanvasBody>
            </Offcanvas>
        <Offcanvas
          isOpen={createTaskShow}
          direction="end"
          toggle={()=>setCreateTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateTaskShown(false)}
          >
            Create Task
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(e.target.elements.task_title.value == ""){
                    toastr.error('Task title is required');
                    return false;
                }else if(assignTo.length == 0){
                    toastr.error('Please select the assigned member');
                    return false;
                }else if(e.target.elements.task_description.value == ""){
                    toastr.error('Task description is required');
                    return false;
                }else if(e.target.elements.deadline_date.value == ""){
                    toastr.error('Task Deadline date is required');
                    return false;
                }else{
                    setLoadingSpinner(true)
                    let dataParse = JSON.parse(localStorage.getItem('authUser'));
                    let orgId = dataParse.map((e)=>e.org_id)
                    let form = new FormData();
                    const fileInput = document.querySelector('input[type="file"]')
                    const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                    form.append('method', 'create-task');
                    form.append('org_id', orgId);
                    form.append('task_title', e.target.elements.task_title.value);
                    form.append('project_id', state.project.id);
                    form.append('task_description', e.target.elements.task_title.value);
                    form.append('assignedTo', assignTo.toString());
                    form.append(
                      'deadline_date',
                     formatDateTime(e.target.elements.deadline_date.value),
                    );
                    if (isAttachmentSwitch == true) {
                      form.append('file_upload', file);
                    }
                   
                    axios.post(
                        process.env.REACT_APP_LEAVE_API_URL+'/tasks.php',form,{headers:{
                          "Content-Type": "multipart/form-data",
                        }}
                       ).then(result => {
                          setLoadingSpinner(false)
                          if(result.data === 'success'){
                              toastr.success('You successfully added the task');
                              getAllTasks();
                              setCreateTaskShown(false);
                          }else{
                              toastr.error('Error while adding the task');
                          }
                      })
                      .catch(e => console.log(e));
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Title</Label>
                  <Input
                    name="task_title"
                    type="text"
                    className="form-control"
                    placeholder="..."
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Assign Member</label>
                    <Select
                      isMulti={true}
                      onChange={item => {
                        setassignTo(item.map(e => e.value))
                      }}
                      options={mockData2}
                      className="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Description</Label>
                  <textarea
                    name="task_description"
                    type="text"
                    className="form-control"
                    placeholder="Type description here...."
                  ></textarea>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Deadline Date</Label>
                  <input
                    name="deadline_date"
                    type="date"
                    className="form-control"
                 />
                </Col>
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'start'}}>
                    <button type="button"  onClick={()=>setCreateTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Task</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <ChatWidget/>
    </React.Fragment>
  )
}

export default ProjectDetail
