import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Label,
  Progress,
  Form,
  Input,
  UncontrolledTooltip,
  Spinner,
  Modal,
} from "reactstrap"

//import images
import PropTypes from "prop-types"
import avatar from "../../assets/images/users/avatar-6.jpg"
import AboutUs from "../JobPages/CandidateOverview/AboutUs"
import Sidebar from "../JobPages/CandidateOverview/Sidebar"
import { useParams } from "react-router-dom"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import classnames from "classnames"
import Select from "react-select"
import Skeleton from "react-loading-skeleton"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import { Value, ValueinUSD } from "./MembersData"
import TableContainer from "components/Common/TableContainer"
import SwipeableViews from "react-swipeable-views"
import axios from "axios"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { AppBar, Box, Tab, Tabs, Typography, useTheme } from "@mui/material"
import {
  AccessAlarm,
  AccessTimeOutlined,
  AddSharp,
  ArrowDropDownCircleOutlined,
  ArticleSharp,
  BadgeSharp,
  CheckSharp,
  Delete,
  DeleteSharp,
  EditOutlined,
  EditSharp,
  EventNoteSharp,
  InfoOutlined,
  InfoSharp,
  LocationOnOutlined,
  LocationOnSharp,
  LockSharp,
  LogoutSharp,
  MailOutlineSharp,
  ModeEdit,
  PendingActionsOutlined,
  PermIdentityOutlined,
  PermIdentitySharp,
  PersonSharp,
  PhoneMissedSharp,
  PhoneSharp,
  PointOfSaleSharp,
  SafetyCheckSharp,
  ShieldSharp,
  WorkHistoryOutlined,
  WorkHistorySharp,
  WorkOutline,
  WorkSharp,
} from "@mui/icons-material"
import Breadcrumb from "components/Common/Breadcrumb"
import ChatWidget from "components/Common/ChatWidget"
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 7 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  }
}

const MembersDetails = () => {
  document.title = `Employee Detail | ${process.env.REACT_APP_NAME}`
  const { id } = useParams()
  const [data, setData] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [editInformation, setEditInformation] = useState(false)
  const [documentLoader, setDocumentLoader] = useState(false)
  const [mockData, setMockData] = useState([])
  const [mockData2, setMockData2] = useState([])
  const [currentRole, setCurrentRole] = useState("")
  const [selectLocation, setSelectedLocation] = useState({
    label: "",
    value: "",
  })
  const [updateSpinnerForSchedule, setSpinnerForSchedule] = useState(false)
  const [selectDept, setSelectedDept] = useState({ label: "", value: "" })
  const [selectSupervisor, setSelectedSupervisor] = useState({
    label: "",
    value: "",
  })
  const [rotationalDay,setRotationalDays] = useState([])
  const [shiftTimes, setShiftTimes] = useState(Array(rotationalDay.length).fill({ start: '', end: '' }));
  const [supervisorArray, setsupervisorArray] = useState([])
  const [incrementalInfoArray, setIncrementalInfoArray] = useState([])
  const [leaveData, setLeaveData] = useState([])
  const [payrollType, setpayrollType] = useState("")
  const [overTimePayType, setOvertimePayType] = useState("")
  const [payrollRate, setpayrollRate] = useState(null)
  const [payrollCurrency, setpayrollCurrency] = useState("USD")
  const [overTimeRate, setoverTimeRate] = useState(null)
  const [cycleDate, setCycleDate] = useState("")
  const [presentProgress, setPresentProgress] = useState(null)
  const [absentProgress, setAbsentProgress] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(null)
  const [shiftStart, setShiftStart] = useState("")
  const [shiftEnd, setShiftEnd] = useState("")
  const [openLoader, setopenLoader] = useState(true)
  const [editJobView, setEditJobView] = useState(false)
  const [addEmployeeStatus, setaddEmployeeStatus] = useState(false)
  const [markedAttendance, setmarkedAttendance] = useState(false)
  const [reviewLeaves, setreviewLeaves] = useState(false)
  const [ByManual, setByManual] = useState(false)
  const [trackStatus, settrackStatus] = useState(false)
  const [ByFaceRecong, setByFaceRecong] = useState(false)
  const [editAnnualLeaves, setEditAnnualLeaves] = useState(false)
  const [imagePreview, setImagePreview] = useState(
    "https://via.placeholder.com/200"
  )
  const [imagePreviewOther, setImagePreviewOther] = useState(null)
  const [privileges, setPrivilege] = useState({})

  const [scheduleData, setScheduleData] = useState({
    breaks_mints: "",
    early_out_margin: "",
    end_time: "",
    id: "",
    no_of_breaks: "",
    no_of_members: "",
    overtime_margin: "",
    schedule_name: "",
    selected_Ids: "",
    start_time: "",
    status: "",
    trady_margin: "",
    type: "",
    update_at: "",
    work_days: "",
    shift_type:''
  })
  const [scheduleDataRotational, setScheduleDataRotational] = useState({
    breaks_mints: "",
    early_out_margin: "",
    end_time: "",
    id: "",
    no_of_breaks: "",
    overtime_margin: "",
    work_days: "",
    shift_type:''
  })
  const [infoData, setInfoData] = useState({
    first_name: "",
    last_name: "",
    user_name: "",
    email_address: "",
    phone_code: "",
    phone_number: "",
    job_location: { label: "", value: "" },
    department: { label: "", value: "" },
    hiring_date: "",
    super_id: { label: "", value: "" },
    terminate_date: "",
    terminated_type: "",
    terminate_reason:"",
    terminate_reason_message:"",
    terminated_comments: "",
    date_for_clearance: "",
    assigned_Leaves:"",
    employeement_status : {label: '' ,value: ''},
    work_modal: ''
  })

  useEffect(() => {
    fetchEmployee()

    fetchCompaines()
    getAllActivities()
    graphAttendanceValuesFromDB()
    fetchLeaves()
    fetchDocumentSingleEmployee()
  }, [])
  const getAllActivities = async () => {
    // const value = await JSON.parse(AsyncStorage.getItem('@loginSession')).map((e)=>e.org_id);
    // alert(value)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        result.map(e => {
          let val = {
            value: e.name,
            label: e.name,
          }
          mockData.push(val)
        })
      })
      .catch(error => console.log("error", error))
  }
  const fetchCompaines = async () => {
    mockData.push([])
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/company.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length !== 0) {
          result.map(e => {
            let val = {
              value: e.location,
              label: e.location,
            }
            mockData2.push(val)
          })
        } else {
          alert("Please add a location. Before add employee")
        }
      })
      .catch(error => console.log("error", error))
  }
  const graphAttendanceValuesFromDB = async () => {
    // this.setState({setCurrentMonth : passingMonth })

    var formdata = new FormData()
    formdata.append("method", "fetch-attendance-for-mobile-graph")
    formdata.append("org_id", id)
    formdata.append("month", new Date().getMonth() + 1)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/attendance.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setAbsentProgress(result.absent_in_precentage)
        setPresentProgress(result.present_in_precentage)

        // this.setState({presentProgress : result.present_in_precentage})
        // this.setState({absentProgress : result.absent_in_precentage})
        // this.setState({markedDate : result.dataList})
      })
      .catch(error => console.log("error", error))
  }
  const [editnoOfEmployees, setEditnoOfEmployees] = useState([])
  const fetchEmployee = async () => {
    setopenLoader(true)
    setIncrementalInfoArray([])
    let value = JSON.parse(localStorage.getItem("authUser"))
    let role = value.map(e => e.role)
    setCurrentRole(role)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees_by_id")
    formdata.append("post_id", id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setData(result)
        if (result.length !== 0) {
          result.map(e => {
            setImagePreview(e.profile_image)
            setaddEmployeeStatus(
              e.privilege.employee_right == "true" ? true : false
            )
            settrackStatus(e.privilege.tracking_status == "true" ? true : false)
            setByFaceRecong(e.privilege.byfaceright == "true" ? true : false)
            setByManual(e.privilege.byManualright == "true" ? true : false)
            setreviewLeaves(e.privilege.leave_right == "true" ? true : false)
            setmarkedAttendance(e.privilege.mark_right == "true" ? true : false)
          
            if (e.job_info.length != 0) {
              e.job_info.map(einner => {
                if(e.super_id != "null"){
                  
                  fetchSupervisorFor(einner.location, e.super_id)
                }

                // fetchSupervisorFor(einner.location)
                // setSelectedLocation({
                //   label: einner.location,
                //   value: einner.location,
                // })
                // setSelectedDept({
                //   label: einner.job_category,
                //   value: einner.job_category,
                // })
                let phn_code = null
                let phn_number = null
                if (e.phone_number != null) {
                  let array = e.phone_number.split("|")
                  phn_code = array[0]
                  phn_number = array[1]
                }
                
              setItemName(einner.annual_leaves_type ? einner.annual_leaves_type.split(',') : [])
              setItemQuantities(einner.annual_leaves ? einner.annual_leaves.split(',') : [])
                setInfoData({
                  first_name: e.first_name,
                  phone_code: phn_code,
                  phone_number: phn_number,
                  email_address: e.email_address,
                  last_name: e.last_name,
                  user_name: e.username,
                  work_modal : einner.select_work_modal,
                  department: {
                    label: einner.job_category,
                    value: einner.job_category,
                  },
                  job_location: {
                    label: einner.location,
                    value: einner.location,
                  },
                  employeement_status  : {
                    label: einner.employement_status,
                    value: einner.employement_status
                  },
                  assigned_Leaves : '' ,
                  hiring_date: einner.joing_date,
                  super_id: { label: e.super_id, value: e.super_id },
                  terminate_date: einner.terminate_date,
                  terminated_comments: einner.terminate_message,
                  terminated_type: einner.terminate_status,
                  date_for_clearance: einner.clearance_date,
                  terminate_reason: einner.terminate_reason,
                  terminate_reason_message : einner.t_reason_message
                })
              })
            }
            if (e.salary_info.length !== 0) {
              e.salary_info.map(e => {
                setpayrollType(e.pay_frequency)
                setpayrollCurrency(e.currency_type)
                setOvertimePayType(e.overtime_frequency)
                setpayrollRate(e.salary_amount_parent)
                setoverTimeRate(e.overtime_rate)
                setCycleDate(e.pay_grade)
              
              })
            }
            if (e.schedule_info.length != 0) {
              e.schedule_info.map(es => {
                
                  setbreakMintsArray(es.breaks_mints.split(","))
                  e.schedule_type == 'simple' ? setEditnoOfEmployees(es.work_days.split(",")) : setRotationalDays(es.work_days.split(','))
                  if(e.schedule_type == 'rotational'){
                    let start =  es.start_time.split(',');
                     let end =  es.end_time.split(',');
                     let properArray = [];
                     start.map((e)=>{
                      end.map((iner)=>{
                        properArray.push({
                          start: e,
                          end: iner
                        })
                      })
                     })
                    setShiftTimes(properArray)
                  }
                  setScheduleData({
                    shift_type: e.schedule_type ? e.schedule_type :'simple',
                    breaks_mints: es.breaks_mints,
                    early_out_margin: es.early_out_margin,
                    end_time: es.end_time,
                    id: es.id,
                    no_of_breaks: es.no_of_breaks,
                    overtime_margin: es.overtime_margin,
                    schedule_name: es.schedule_name,
                    start_time: es.start_time,
                    trady_margin: es.trady_margin,
                    work_days: es.work_days,
                  })
                
              
                //end
              })
            }
            if (e.incremental_info.length != 0) {
              setIncrementalInfoArray(e.incremental_info)
            }
          })
        }
        setopenLoader(false)
        
      })
      .catch(error => console.log("error", error))
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  function formatAmount(amount, currency) {
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 2,
    })
  }
  function convertDateFormat(inputDate) {
    const parts = inputDate.split("/")
    const formattedDate = `${parts[2]}-${parts[0].padStart(
      2,
      "0"
    )}-${parts[1].padStart(2, "0")}`
    return formattedDate
  }
  const onChangeLocation = val => {
    setSelectedLocation(val)
    setInfoData(prevState => ({
      ...prevState,
      job_location: val,
    }))
    fetchSupervisorFor(val.value, infoData.super_id)
  }
  const fetchSupervisorFor = async (val, pass) => {
    const value = localStorage.getItem("authUser")
    let jsonFormat = JSON.parse(value)
    let org = jsonFormat.map(e => e.org_id)
    var formdata = new FormData()

    formdata.append("method", "select-for-supervisor")
    formdata.append("org_id", org)
    formdata.append("location_for", val)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    console.error(formdata)
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        result.map(e => {
          if (e.value == pass) {
            setInfoData(prevState => ({
              ...prevState,
              super_id: { label: e.label, value: e.value },
            }))
            setSelectedSupervisor({
              label: e.label,
              value: e.value,
            })
          }
        })
        setsupervisorArray(result)
      })
      .catch(error => console.log("error", error))
  }
  const fetchLeaves = () => {
    let local = localStorage.getItem("authUser")
    let mapings = JSON.parse(local)
    let org = mapings.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-leaves-single-member")
    form.append("emp_id", id)
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/leave.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setLeaveData(result)
        // setFilteredData(result);
        // setTimeout(() => {
        //   if (filteredData.length == 0) {
        //     setPlaceholderShown(false);
        //   }
        // }, 1000);
      })
      .catch(e => {
        console.log(e)
      })
  }

  const MySwal = withReactContent(Swal)
  const OnPressNext = (id, val) => {
    MySwal.fire({
      title: "Mark Leave as",
      html: "Change attendance status",
      showCancelButton: true,
      confirmButtonText: "Sick Leave",
      cancelButtonText: "Casual Leave",
      cancelButtonColor: "green",
      confirmButtonColor: "red",
    }).then(result => {
      if (result.isConfirmed) {
        // Handle action for Button 1
        let form = new FormData()
        form.append("method", "update_leave_status")
        form.append("leave_id", id)
        form.append("leave_status", val)
        form.append("leave_other", "sick-leave")
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/leave.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              fetchLeaves()
            } else {
              alert("Error while updating the status. Try again")
            }
          })
      } else if (result.dismiss === "cancel") {
        let form = new FormData()
        form.append("method", "update_leave_status")
        form.append("leave_id", id)
        form.append("leave_status", val)
        form.append("leave_other", "casual-leave")
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/leave.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              fetchLeaves()
            } else {
              alert("Error while updating the status. Try again")
            }
          })
      }
    })
  }
  const OnPressNextDecline = (id, val) => {
    let form = new FormData()
    form.append("method", "update_leave_status")
    form.append("leave_id", id)
    form.append("leave_status", val)
    form.append("leave_other", "un-paid")
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/leave.php", requestOptions)
      .then(res => res.text())
      .then(result => {
        if (result == "success") {
          fetchLeaves()
        } else {
          alert("Error while updating the status. Try again")
        }
      })
  }
  function formatDates(inputDate) {
    // Split the input date string into day, month, and year
    const [day, month, year] = inputDate.split("-")

    // Create a Date object with the provided year, month (subtracting 1 as months are zero-indexed), and day
    const date = new Date(year, month - 1, day)

    // Get the month name abbreviation using an array of month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    const monthAbbreviation = monthNames[date.getMonth()]

    // Format the date string in "DD Mon YYYY" format
    const formattedDate = `${day} ${monthAbbreviation} ${year}`

    return formattedDate
  }
  const columns = useMemo(
    () => [
      {
        Header: "Employee",
        accessor: "emp_id",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <p>
                {cellProps.cell.value}-{cellProps.cell.row.original.fullname}
              </p>
            </div>
          )
        },
      },
      {
        Header: "Apply Date",
        accessor: "apply_date",
        filterable: true,
        Cell: cellProps => {
          return <div>{formatDates(cellProps.cell.value)}</div>
        },
      },
      {
        Header: "Duration",
        accessor: "no_days",
        filterable: true,
        Cell: cellProps => {
          return <span>{cellProps.cell.value + " days"}</span>
        },
      },
      {
        Header: "Time Off Policy",
        accessor: "leave_type",
        filterable: true,
        Cell: cellProps => {
          return <Value {...cellProps} />
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <ValueinUSD {...cellProps} />
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          if (cellProps.row.original.status == "requested") {
            return (
              <div>
                <button
                  style={{ fontSize: 10, padding: 7 }}
                  className="btn btn-success"
                  onClick={() => OnPressNext(cellProps.cell.value, "approved")}
                >
                  {"Approve"}
                </button>
                <button
                  className="btn btn-danger"
                  style={{ fontSize: 10, padding: 7 }}
                  onClick={() =>
                    OnPressNextDecline(cellProps.cell.value, "rejected")
                  }
                >
                  {"Unpaid"}
                </button>
              </div>
            )
          } else {
            return (
              <div>
                <button
                  disabled
                  style={{ fontSize: 10, padding: 7 }}
                  className="btn btn-success"
                >
                  {"Approve"}
                </button>
                <button
                  disabled
                  style={{ fontSize: 10, padding: 7 }}
                  className="btn btn-danger"
                >
                  {"Unpaid"}
                </button>
              </div>
            )
          }
        },
      },
    ],
    []
  )
  const OnSubmit = () => {
    MySwal.fire({
      title: "Save Changes",
      html: "Are you sure to want save changes of current employee information",
      confirmButtonColor: "green",
      icon: "warning",
      confirmButtonText: "Yes, Save Changes",
      cancelButtonColor: "red",
      cancelButtonText: "No, Discard Changes",
      showCancelButton: true,
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "update_single_member_data_by_id")
        form.append("emp_id", id)
        form.append("first_name", infoData.first_name)
        form.append("last_name", infoData.last_name)
        form.append("user_name", infoData.user_name)
        form.append("location", infoData.job_location.value)
        form.append("department", infoData.department.value)
        form.append("super_id", infoData.super_id.value)
        form.append("hiring_date", infoData.hiring_date)
        form.append("email_address", infoData.email_address)
        form.append("employement_status", infoData.employeement_status.value)
        form.append("work_modal", infoData.work_modal)
        form.append(
          "phone_number",
          infoData.phone_code != null
            ? infoData.phone_code + "|" + infoData.phone_number
            : null
        )
        let requestOptions = {
          method: "POST",

          body: form,
          redirect: "follow",
        }

        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/login.php",
          requestOptions
        )
          .then(response => response.json())
          .then(result => {
            if (result.message == "success") {
              setEditInformation(false)
              setEditJobView(false)
              Swal.fire(
                "Success",
                "You successfully update the information of the employee "
              )
              fetchEmployee()
            } else {
              Swal.fire(
                "Server Error",
                "Please check your input fields and Try again"
              )
            }
          })
          .catch(error => console.error(error))
      } else if (is.isDenied) {
        MySwal.close()
      }
    })
  }

  const convertTo24Hour = time12h => {
    const [time, modifier] = time12h.split(" ")
    let [hours, minutes] = time.split(":")

    if (hours === "12") {
      hours = "00"
    }
    if (hours < 10) {
      hours = "0" + hours
    }

    if (modifier === "pm") {
      hours = parseInt(hours, 10) + 12
    }

    return hours + ":" + minutes
  }
  const updateScheduleForIndividual = () => {
    Swal.fire({
      title: "Confirm Alert",
      icon: "warning",
      text: "Are you want to update the current employee work schedule",
      confirmButtonText: "Yes, Save Changes",
      cancelButtonText: "Discard Changes",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
      showCancelButton: true,
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        let data = JSON.parse(localStorage.getItem("authUser"))
        let org = data.map(e => e.org_id)

        form.append("method", "update_current_emp_schedule")
        form.append("emp_id", id)
        form.append("org_id", org)
      
        form.append("noOfBreaks", scheduleData.no_of_breaks)
        form.append("breakMintsArray", breakMintsArray.toString())
        form.append("overtime", scheduleData.overtime_margin)
        form.append("early_out", scheduleData.early_out_margin)
        form.append("late_in", scheduleData.trady_margin)
        form.append("type", scheduleData.shift_type)
        if(scheduleData.shift_type == 'rotational'){
          
          let start =[];
          let end = [];
          shiftTimes.map((e)=>{
              start.push(e.start)
              end.push(e.end)
            
          });
          form.append("working_days", rotationalDay.toString())
          form.append("start_time", start.toString())
          form.append("start_end", end.toString())
        }else{
          form.append("working_days", editnoOfEmployees.toString())
          form.append("start_time", scheduleData.start_time)
          form.append("start_end", scheduleData.end_time)
        }
        let requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/login.php",
          requestOptions
        )
          .then(res => res.text())
          .then(re => {
            if (re == "success") {
              toastr.success(
                "You successfully update the schedule for this employ"
              )
              fetchEmployee()
              setEditModeForSchedule(false)
            } else {
              toastr.error(
                "Please check your input fields and try again or reload the page"
              )
            }
          })
          .catch(err => console.log(err))
      } else {
        Swal.close()
      }
    })
  }
  const [modal_center, setmodal_center] = useState(false)
  const [modal_centerother, setmodal_centerother] = useState(false)
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  function tog_center() {
    setmodal_center(!modal_center)
    removeBodyCss()
  }
  function tog_centerother() {
    setmodal_centerother(!modal_centerother)
    removeBodyCss()
  }
  const [incrementType, setIncrementType] = useState("")
  function getCurrentDate() {
    // Get current date
    const currentDate = new Date()

    // Extract year, month, and day
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, "0") // Months are zero-based, so add 1
    const day = String(currentDate.getDate()).padStart(2, "0")

    // Format the date as 'YYYY-MM-DD'
    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }
  const DeleteIncrementFrom = pass => {
    MySwal.fire({
      title: "Confirm Alert",
      icon: "warning",
      text: "Are you sure to want to delete this increment",
      confirmButtonText: "Yes, Please",
      showCancelButton: true,
      cancelButtonText: "No, I don't want",
      confirmButtonColor: "green",
      cancelButtonColor: "red",
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "delete-increment")
        form.append("del_id", pass)
        axios
          .post(process.env.REACT_APP_LEAVE_API_URL + "/increment.php", form)
          .then(res => {
            if (res.data == "success") {
              toastr.success("Incremental Values are succesfully delete")
              fetchEmployee()
              return false
            } else {
              toastr.error(
                "Error comes while deleting the increment, Please reload the page."
              )
              return false
            }
          })
      } else {
        MySwal.close()
      }
    })
  }
  const [editModeIncrement, setEditModeIncrement] = useState(false)
  const [documentArray, setDocumentArray] = useState([])
  
  const [editModeForSchedule, setEditModeForSchedule] = useState(false)
  const [singleIncrementData, setSingleIncrementData] = useState({
    increment_id: "",
    increment_type: "",
    increment_amount: "",
    apply_date: "",
  })
  const fetchSingleIncrement = pass => {
    setmodal_center(true)
    setEditModeIncrement(true)
    let form = new FormData()
    form.append("method", "fetch-single-increment")
    form.append("id_for", pass)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/increment.php", form)
      .then(res => {
        if (res.data.lenght !== 0) {
          document
            .querySelectorAll('input[name="edit_increment_type"]')
            .forEach(radio => {
              if (radio.value === res.data.type_for) {
                radio.checked = true
              } else {
                radio.checked = false
              }
            })
          setSingleIncrementData({
            increment_id: res.data.id,
            increment_type: res.data.type_for,
            increment_amount: res.data.amount,
            apply_date: res.data.apply_date,
          })
        }
      })
  }

  const UpdatePayrollCurrentEmployee = () => {
    MySwal.fire({
      title: "Save Payroll Information",
      icon: "warning",
      text: "Are you sure to want to save the changes in payroll of the current employee",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "No, I'm not",
      confirmButtonColor: "green",
      confirmButtonText: "Yes, Please",
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "update-salary-single-by-emp-id")
        form.append("emp_id", id)
        form.append("type", payrollType)
        form.append("currency", payrollCurrency)
        form.append("amount", payrollRate)
        form.append("overtime_frequency", overTimePayType)
        form.append("overtime_rate", overTimeRate)
        form.append("cycle_date", cycleDate)
        axios
          .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", form)
          .then(res => {
            if (res.data == "success") {
              toastr.success("You successfully updated the payroll information")
              fetchEmployee()
            } else {
              toastr.error(
                "Error while updating the payroll information please try again or reload the page"
              )
            }
          })
          .catch(e => {
            console.log(e)
          })
      }
    })
  }

  let arrayForMe = []
  for (let i = 1; i <= 31; i++) {
    arrayForMe.push({
      label: i,
      value: i,
    })
  }
  const [breakMintsArray, setbreakMintsArray] = useState([])
  const RenderItemIwant = () => {
    return (
      <React.Fragment>
        {/* Render additional Text components based on noofBreaks */}
        {Array.from({ length: scheduleData.no_of_breaks }, (_, index) => (
          <div key={index} style={{ paddingTop: 10, paddingBottom: 0 }}>
            <Input
              placeholder={`Break ${index + 1} in mints`}
              onChange={e => {
                const updatedBreakMintsArray = [...breakMintsArray]
                updatedBreakMintsArray[index] = e.target.value
                setbreakMintsArray(updatedBreakMintsArray)
              }}
              value={breakMintsArray[index]}
              className="form-control"
              type="number"
              min={0}
            />
          </div>
        ))}
      </React.Fragment>
    )
  }

  const radioData = [
    { label: "M", value: "Mon" },
    { label: "T", value: "Tue" },
    { label: "W", value: "Wed" },
    { label: "T", value: "Thu" },
    { label: "F", value: "Fri" },
    { label: "S", value: "Sat" },
    { label: "S", value: "Sun" },
  ]
  const handleEmployeeSelectionEdit = value => {
    // Create a copy of the current state array
    const updatedEmployees = [...editnoOfEmployees]

    // Check if the value is already in the array
    const index = updatedEmployees.indexOf(value)

    // If the value is already in the array, remove it
    if (index !== -1) {
      updatedEmployees.splice(index, 1)
    } else {
      // If the value is not in the array, add it
      updatedEmployees.push(value)
    }

    // Update the state with the new array
    setEditnoOfEmployees(updatedEmployees)
    
  }
  const handleRotationalSelectionEdit = value => {
    // Create a copy of the current state array
    const updatedEmployees = [...rotationalDay]

    // Check if the value is already in the array
    const index = updatedEmployees.indexOf(value)

    // If the value is already in the array, remove it
    if (index !== -1) {
      updatedEmployees.splice(index, 1)
    } else {
      // If the value is not in the array, add it
      updatedEmployees.push(value)
    }

    // Update the state with the new array
    setRotationalDays(updatedEmployees)
    // setShiftTimes(rot.fill({ start: '', end: '' }))
  }
  const theme = useTheme()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = index => {
    setValue(index)
  }

  const fetchDocumentSingleEmployee = () => {
    let form = new FormData()
    form.append("method", "fetch-qualification-attachments")
    form.append("emp_id", id)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", form)
      .then(res => {
        if (res.data) {
          setDocumentArray(res.data)
        }
      })
  }
  const DeleteDocumentFromDB = pass => {
    Swal.fire({
      title: "Confirm Alert",
      icon: "warning",
      text: "Are you sure you want to delete the document",
      showCancelButton: true,
      cancelButtonColor: "red",
      cancelButtonText: "No, I don't",
      confirmButtonColor: "green",
      confirmButtonText: "Yes, Please",
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "delete-qualification-attachments")
        form.append("component_id", pass)
        form.append("emp_id", id)
        axios
          .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", form)
          .then(res => {
            if (res.data == "success") {
              toastr.success("You successfully delete the document")
              fetchDocumentSingleEmployee()
            } else {
              toastr.error(
                "Error while deleting the document. Please try again"
              )
              return false
            }
          })
          .catch(er => console.log(er))
      } else {
        Swal.close()
      }
    })
  }
  const [spinnerForPrivilages, setSpinnerForPrivilages] = useState(false)
  const updateCurrentEmployeeRights = () => {
    Swal.fire({
      title: "Save Changes",
      html: "Are you sure to want save changes of current employee privilages",
      confirmButtonColor: "green",
      icon: "warning",
      confirmButtonText: "Yes, Save Changes",
      cancelButtonColor: "red",
      cancelButtonText: "No, Discard Changes",
      showCancelButton: true,
    })
      .then(is => {
        if (is.isConfirmed) {
          let form = new FormData()
          setSpinnerForPrivilages(true)
          form.append("method", "current_employee_privilage")
          form.append("emp_id", id)
          form.append("leave_right", reviewLeaves)
          form.append("tracking_status", trackStatus)
          form.append("addEmployeeRight", addEmployeeStatus)
          form.append("markAttendanceRight", markedAttendance)
          form.append("byManual", markedAttendance == true ? ByManual : false)
          form.append(
            "byFaceRecong",
            markedAttendance == true ? ByFaceRecong : false
          )
          axios
            .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", form)
            .then(res => {
              if (res.data == "success") {
                setSpinnerForPrivilages(false)
                toastr.success(
                  "You successfully updated the employee privilages"
                )
                return false
              } else {
                setSpinnerForPrivilages(false)
                toastr.error("Error comes while updating employee privilages")
                return false
              }
            })
        } else {
          Swal.close()
        }
      })
      .catch(er => console.log(er))
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onload = function (e) {
        setImagePreview(e.target.result)
        setImagePreviewOther(e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }
 const  ChangeNumberOfLeaves = () =>{
  let form = new FormData();
  form.append('method','update_annual_leaves');
  form.append('emp_id', id);
  form.append('leaves', itemQuantities.toString())
  form.append('leaves_type', itemName.toString())
  axios.post(process.env.REACT_APP_LEAVE_API_URL + '/leave.php',form).then((res)=>{
    if(res.data.message== 'success'){
      toastr.success('You successfully change the employee leave quota')
      setInfoData((prev)=>({
        ...prev,
        assigned_Leaves : res.data.counter
      }))
      setEditAnnualLeaves(false)
    }else{
      toastr.error('Error while updating the annual quota. Try again');
    }
  }).catch((er)=>console.log(er))
 }
  const changeCurrentEmployeeImage = () => {
    setopenLoader(true)
    let form = new FormData()
    const fileInput = document.getElementById("profile_change")
    const file = fileInput.files[0]
    form.append("method", "update_profile_image")
    form.append("emp_id", id)
    form.append("profileimage", file)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(res => {
        setopenLoader(false)

        if (res.data == "success") {
          toastr.success("You successfully update the profile")
          fetchEmployee()
          setImagePreviewOther(null)
        } else if (res.data == "error") {
          toastr.error("Error", `Error while uploading image please try again`)
        } else {
          toastr.error("Error", `${res.data}`)
        }
      })
      .catch(er => {
        setopenLoader(false)
        setImagePreview(data.map(e => e.profile_image))
        setImagePreviewOther(null)
        console.log(er)
      })
  }
  const deleteSelectedImage = event => {
    event.preventDefault()
    setImagePreviewOther(null)
    setImagePreview(data.map(e => e.profile_image))
  }
  const handleTimeChange = (index, field, value) => {
    const updatedShiftTimes = [...shiftTimes];
    updatedShiftTimes[index] = { ...updatedShiftTimes[index], [field]: value };
    setShiftTimes(updatedShiftTimes);
  };

  const calculateTotalHours = (startTime, endTime) => {
    const start = new Date(`01/01/2000 ${startTime}`);
    const end = new Date(`01/01/2000 ${endTime}`);

    const diffMs = end - start;
    const diffHours = Math.floor(diffMs / 3600000);
    const diffMinutes = Math.floor((diffMs % 3600000) / 60000);

    return `${diffHours}h:${diffMinutes}m`;
  };
  const [itemName, setItemName] = useState([])
  const [itemQuantities, setItemQuantities] = useState([])
  const [itemPrices, setItemPrices] = useState([])
  const [itemDescriptions, setItemDescriptions] = useState([])

  const addDynamicLayout = () => {
    setItemName(prevState => [...prevState, ""])
    setItemQuantities(prevState => [...prevState, ""])
  }
  const removeDynamicLayout = index => {
    setItemName(prevState => prevState.filter((_, i) => i !== index))
    setItemQuantities(prevState => prevState.filter((_, i) => i !== index))  
  }

  const updateItemValue = (type, index, value) => {
    switch (type) {
      case "itemName":
        setItemName(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      case "itemQuantities":
        setItemQuantities(prevState =>
          prevState.map((item, i) => (i === index ? value : item))
        )
        break
      default:
        break
    }
    
    
  }
  const mockDataForLeaves = [
  
    {label: 'Matternity', value: 'Matternity'},
    {label: 'Personal', value: 'Personal'},
    {label: 'Casual Leave', value: 'Casual Leave'},
    {label: 'Medical Leave', value: 'Medical Leave'},
    {label: 'Sick Leave', value: 'Sick Leave'},
    {label: 'Half Leave', value: 'Half Leave'},
    {label: 'Emergency Leave', value: 'Emergency Leave'}
  ];
  return (
    <React.Fragment>
      <div className="page-content">
      <Breadcrumb titleLink="/people/members" title='Employees'  breadcrumbItem={`${infoData.first_name + ' ' + infoData.last_name} Details`}/>
        <Container fluid>
          <Backdrop
              sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
              open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Row>
            <Col lg={12}>
              {data.length == 0
                ? null
                : data.map((e, index) => {
                    return (
                      <Card className="overflow-hidden" key={index}>
                        <div className="bg-soft bg-secondary">
                          <Row>
                            <Col xs="7">
                              <div
                                className="text-primary"
                                style={{ padding: "5rem" }}
                              ></div>
                            </Col>
                          </Row>
                        </div>
                        <CardBody className="pt-0">
                          <Row className=" profile-user-wid">
                            <Col sm="2">
                              <div className="avatar-xl ">
                                <label
                                  htmlFor="profile_change"
                                  className="profile-label"
                                >
                                  <img
                                    src={
                                      imagePreview ||
                                      "https://via.placeholder.com/120"
                                    }
                                    style={{
                                      width: 150,
                                      height: 120,
                                      objectFit: "cover",
                                    }}
                                    alt=""
                                    className="img-thumbnail avatar-md rounded-circle"
                                  />
                                  <input
                                    type="file"
                                    hidden
                                    accept=".png,.jpeg,.jpg"
                                    id="profile_change"
                                    onChange={handleFileChange}
                                  />
                                  <span className="change-profile-text">
                                    {imagePreviewOther == null ? (
                                      "Select the image"
                                    ) : (
                                      <div style={{ display: "flex" }}>
                                        <button
                                          className="btn"
                                          onClick={() =>
                                            changeCurrentEmployeeImage()
                                          }
                                        >
                                          <CheckSharp fontSize="18px" />
                                        </button>
                                        <button
                                          style={{ marginLeft: 10 }}
                                          onClick={deleteSelectedImage}
                                          className="btn"
                                        >
                                          <Delete fontSize="18px" />
                                        </button>
                                      </div>
                                    )}
                                  </span>
                                </label>
                                {imagePreviewOther !== null && (
                                  <div className="image-overlay"></div>
                                )}
                              </div>
                            </Col>
                            <Col
                              sm="8"
                              className="mt-4"
                              style={{ marginLeft: -16 }}
                            >
                              <h5 className="font-size-15 text-truncate ">
                                Full Name:{" "}
                                <span style={{ fontWeight: "bold" }}>
                                  {e.first_name + " " + e.last_name}
                                </span>
                              </h5>

                              <span>
                                {e.job_info.map((einer, index) => (
                                  <span key={index}>
                                    Job Title:{" "}
                                    <span style={{ fontWeight: "bold" }}>
                                      {einer.job_title
                                        ? einer.job_title
                                        : "N/a"}
                                    </span>
                                  </span>
                                ))}
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )
                  })}
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <Box sx={{ bgcolor: "background.paper", width: "100%" }}>
                <AppBar position="static">
                  <Tabs
                    value={value}
                    className="tab_class"
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="white"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label=""
                  >
                    <Tab
                      className="tab_class"
                      icon={<PersonSharp />}
                      iconPosition="start"
                      label="Personal"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="tab_class"
                      disabled={currentRole == "user" ? true : false}
                      icon={<WorkSharp />}
                      iconPosition="start"
                      label="Job"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="tab_class"
                      icon={<ArticleSharp />}
                      iconPosition="start"
                      label="Documents"
                      {...a11yProps(2)}
                    />
                    {/* <Tab className="tab_class" icon={<EventNoteSharp />} iconPosition="start" label="Attendance" {...a11yProps(2)} /> */}
                    <Tab
                      className="tab_class"
                      icon={<WorkHistorySharp />}
                      iconPosition="start"
                      label="Schedule"
                      {...a11yProps(3)}
                    />
                    <Tab
                      className="tab_class"
                      disabled={currentRole == "user" ? true : false}
                      icon={<PointOfSaleSharp />}
                      iconPosition="start"
                      label="Payroll"
                      {...a11yProps(4)}
                    />
                    <Tab
                      className="tab_class"
                      icon={<SafetyCheckSharp />}
                      iconPosition="start"
                      label="Approvals"
                      {...a11yProps(5)}
                    />
                    <Tab
                      className="tab_class"
                      icon={<LockSharp />}
                      iconPosition="start"
                      label="Password"
                      {...a11yProps(6)}
                    />
                    <Tab
                      className="tab_class"
                      disabled={currentRole == "user" ? true : false}
                      icon={<LogoutSharp />}
                      iconPosition="start"
                      label="Separation"
                      {...a11yProps(7)}
                    />
                    <Tab
                      className="tab_class"
                      disabled={currentRole == "user" ? true : false}
                      icon={<ShieldSharp />}
                      iconPosition="start"
                      label="Privilege "
                      {...a11yProps(8)}
                    />
                  </Tabs>
                </AppBar>
                 <SwipeableViews
                    axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                    index={value}
                    onChangeIndex={handleChangeIndex}
                  >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <Row>
                      <Col md={5}>
                        <h5>Personal Information</h5>
                      </Col>
                      <Col md={7} style={{ textAlign: "end" }}>
                        <button
                          id="tooltip_edit_view"
                          className={`btn ${
                            editInformation ? "btn-success" : "btn-primary"
                          }`}
                          onClick={() => setEditInformation(!editInformation)}
                        >
                          {editInformation ? <EditSharp /> : <ModeEdit />}
                        </button>
                        <UncontrolledTooltip
                          target={`tooltip_edit_view`}
                          placement="bottom"
                          // isOpen={tooltipOpen}
                          // toggle={() => setTooltipOpen(!tooltipOpen)}
                        >
                          {editInformation ? "View" : "Edit"}
                        </UncontrolledTooltip>
                        &nbsp;&nbsp;
                        {editInformation ? (
                          <button
                            id="tooltip_edit_submit"
                            className="btn btn-primary"
                            onClick={() => OnSubmit()}
                          >
                            <CheckSharp />
                            <UncontrolledTooltip
                              target={`tooltip_edit_submit`}
                              placement="top"
                            >
                              Submit
                            </UncontrolledTooltip>
                          </button>
                        ) : null}
                      </Col>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          border: "0.5px solid #f3f3f3",
                        }}
                      ></div>
                      <Col md={8}>
                        {editInformation ? (
                          <Row className="mt-4">
                            <Col md={6} className="mb-3">
                              <Label>
                                <PermIdentitySharp /> First Name
                              </Label>
                              <input
                                className="form-control"
                                name="firstName"
                                type="text"
                                value={infoData.first_name}
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    first_name: e.target.value,
                                  }))
                                }
                                placeholder="firstName..."
                              />
                            </Col>
                            <Col md={6} className="mb-3">
                              <Label>
                                <PermIdentitySharp /> Last Name
                              </Label>
                              <input
                                className="form-control"
                                name="lastName"
                                type="text"
                                value={infoData.last_name}
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    last_name: e.target.value,
                                  }))
                                }
                                placeholder="lastName..."
                              />
                            </Col>
                            <Col md={6} className="mb-3">
                              <Label>
                                <MailOutlineSharp /> Email Address
                              </Label>
                              <input
                                className="form-control"
                                name="emailAddress"
                                type="email"
                                value={infoData.email_address}
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    email_address: e.target.value,
                                  }))
                                }
                                placeholder="Enter email..."
                              />
                            </Col>
                            <Col md={6} className="mb-3">
                              <Label>
                                <BadgeSharp /> Username
                              </Label>
                              <input
                                className="form-control"
                                name="username"
                                type="text"
                                value={infoData.user_name}
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    user_name: e.target.value,
                                  }))
                                }
                                placeholder="Username..."
                              />
                            </Col>
                            <Col md={6} className="mb-3">
                              <Label>
                                <PhoneSharp /> Phone Number
                              </Label>
                              <Row>
                                <Col
                                  md={4}
                                  style={{ padding: "0px 0px 0px 10px" }}
                                >
                                  <input
                                    className="form-control"
                                    name="phone_code"
                                    type="text"
                                    value={infoData.phone_code}
                                    onChange={e =>
                                      setInfoData(prevState => ({
                                        ...prevState,
                                        phone_code: e.target.value,
                                      }))
                                    }
                                    placeholder="Country Code..."
                                  />
                                </Col>
                                <Col md={8} style={{ padding: 0 }}>
                                  <input
                                    className="form-control"
                                    name="phone_number"
                                    type="text"
                                    value={infoData.phone_number}
                                    onChange={e =>
                                      setInfoData(prevState => ({
                                        ...prevState,
                                        phone_number: e.target.value,
                                      }))
                                    }
                                    placeholder="Enter Phone Number..."
                                  />
                                </Col>
                              </Row>
                            </Col>
                            {/* <Col md={6} className="mb-3">
                                  <Label>Phone Number</Label>
                                  
                                </Col> */}
                          </Row>
                        ) : (
                          <Row className="mt-4">
                            <Col md={6} className="marginClass">
                              <Label>
                                <PermIdentitySharp /> First Name
                              </Label>
                              <p>{data.map(e => e.first_name)}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                              <Label>
                                <PermIdentitySharp /> Last Name
                              </Label>
                              <p>{data.map(e => e.last_name)}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                              <Label>
                                <MailOutlineSharp /> Email Address
                              </Label>
                              <p>
                                {data.map(e =>
                                  e.email_address ? e.email_address : "N/a"
                                )}
                              </p>
                            </Col>
                            <Col md={6} className="marginClass">
                              <Label>
                                <PhoneSharp /> Phone Number
                              </Label>
                              <p>
                                {" "}
                                {infoData.phone_code == null
                                  ? "N/a"
                                  : infoData.phone_code +
                                    " " +
                                    infoData.phone_number}
                              </p>
                            </Col>
                            <Col md={6} className="marginClass">
                              <Label>
                                <BadgeSharp /> Username
                              </Label>
                              <p>{data.map(e => e.username)}</p>
                            </Col>
                            {/* <Col md={6}>
                                  <Label>Location</Label>
                                  <p>
                                    {data.map(e =>
                                      e.job_info.map(einner => einner.location)
                                    )}
                                  </p>
                                </Col>

                                <Col md={6}>
                                  <Label>Department</Label>
                                  <p>
                                    {data.map(e =>
                                      e.job_info.map(
                                        einner => einner.job_category
                                      )
                                    )}
                                  </p>
                                </Col>
                                <Col md={6}>
                                  <Label>Hiring Date</Label>
                                  <p>
                                    {data.map(e =>
                                      e.job_info.map(einner =>
                                        convertDateFormatOther(
                                          einner.joing_date
                                        )
                                      )
                                    )}
                                  </p>
                                </Col> */}
                          </Row>
                        )}
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <Row>
                      <Col md={5}>
                        <h5>Job Information</h5>
                      </Col>
                      <Col md={7} style={{ textAlign: "end" }}>
                      <button
                          id="tooltip_edit_view"
                          className={`btn ${
                            editJobView ? "btn-success" : "btn-primary"
                          }`}
                          onClick={() => setEditJobView(!editJobView)}
                        >
                          {editJobView ? <EditSharp /> : <ModeEdit />}
                        </button>
                        <UncontrolledTooltip
                          target={`tooltip_edit_view`}
                          placement="bottom"
                          // isOpen={tooltipOpen}
                          // toggle={() => setTooltipOpen(!tooltipOpen)}
                        >
                          {editJobView ? "View" : "Edit"}
                        </UncontrolledTooltip>
                        &nbsp;&nbsp;
                        {editJobView ? (
                          <button
                            id="tooltip_edit_submit"
                            className="btn btn-primary"
                            onClick={() => OnSubmit()}
                          >
                            <CheckSharp />
                            <UncontrolledTooltip
                              target={`tooltip_edit_submit`}
                              placement="top"
                            >
                              Submit
                            </UncontrolledTooltip>
                          </button>
                        ) : null}
                      </Col>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          border: "0.5px solid #f3f3f3",
                        }}
                      ></div>

                      {editJobView == true ? (
                        <Col md={8}>
                          <Row>
                            <Col md={6} style={{ paddingTop: 10 }}>
                              <Label>
                                <LocationOnOutlined /> Locations
                              </Label>
                              <Select
                                options={mockData2}
                                value={infoData.job_location}
                                onChange={e => onChangeLocation(e)}
                                className="select2-selection"
                                name="location"
                              />
                            </Col>
                            <Col md={6} style={{ paddingTop: 10 }}>
                              <Label>
                                <WorkOutline /> Departments
                              </Label>
                              <Select
                                options={mockData}
                                value={infoData.department}
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    department: e,
                                  }))
                                }
                                className="select2-selection"
                                name="department"
                              />
                            </Col>
                            {data.map((e, index) =>
                              e.role == "user" ? (
                                <Col
                                  md={6}
                                  key={index}
                                  style={{ paddingTop: 10 }}
                                >
                                  <Label>
                                    <PermIdentityOutlined /> Supervisor Name
                                  </Label>
                                  <Select
                                    options={supervisorArray}
                                    value={infoData.super_id}
                                    className="select2-selection"
                                    onChange={e => {
                                      setInfoData(prev => ({
                                        ...prev,
                                        super_id: e,
                                      }))
                                    }}
                                    name="supervisor_id"
                                    // value={}
                                  />
                                </Col>
                              ) : null
                            )}
                            <Col md={6} style={{ paddingTop: 10 }}>
                              <Label>
                                <WorkHistoryOutlined /> Hiring Date{}
                              </Label>
                              <input
                                className="form-control"
                                name="hiring_date"
                                type="date"
                                value={
                                  infoData.hiring_date != ""
                                    ? convertDateFormat(infoData.hiring_date)
                                    : infoData.hiring_date
                                }
                                onChange={e =>
                                  setInfoData(prevState => ({
                                    ...prevState,
                                    hiring_date: e.target.value,
                                  }))
                                }
                                placeholder="..."
                              />
                            </Col>
                            <Col md={6} style={{ paddingTop: 10 }}>
                              <Label><PendingActionsOutlined/> Employement Status</Label>
                              <Select
                              value={infoData.employeement_status}
                              onChange={(e)=>setInfoData((prev)=>({
                                ...prev,
                                employeement_status : e
                              }))}
                                options={[
                                  {
                                    label: "Internship",
                                    value: "Internship",
                                  },
                                  {
                                    label: "Permanent",
                                    value: "Permanent",
                                  },
                                  {
                                    label: "Project Based",
                                    value: "Project Based",
                                  },
                                  {
                                    label: "Freelancer",
                                    value: "Freelancer",
                                  },
                                  {
                                    label: "Probation",
                                    value: "Probation",
                                  },
                                  {
                                    label: "Contract Based",
                                    value: "Contract Based",
                                  },
                                ]}
                                className="select2-selection"
                                name="employement_status"
                              />
                            </Col>
                            <Col md={12} style={{ paddingTop: 10 }}>
                    <Label>Work Modal</Label>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor11"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setInfoData((prev)=>({
                              ...prev,
                              work_modal:e.target.value
                            }))}
                            value={"remote"}
                            defaultChecked = {infoData.work_modal == 'remote' ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor11"
                          >
                            Remote
                          </label>
                        </div>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor112"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setInfoData((prev)=>({
                              ...prev,
                              work_modal:e.target.value
                            }))}
                            value={'on_site'}
                            defaultChecked = {infoData.work_modal == 'on_site' ? true : false}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor112"
                          >
                            On-Site
                          </label>
                        </div>
                  </Col>

                          </Row>
                        </Col>
                      ) : (
                        <Col md={8}>
                          <Row>
                            <Col
                              md={6}
                              style={{ paddingTop: 10 }}
                              className="marginClass"
                            >
                              <Label>
                                <LocationOnOutlined /> Locations
                              </Label>
                              <p>{infoData.job_location.label}</p>
                            </Col>
                            <Col
                              md={6}
                              style={{ paddingTop: 10 }}
                              className="marginClass"
                            >
                              <Label>
                                <WorkOutline /> Departments
                              </Label>
                              <p>{infoData.department.label}</p>
                            </Col>
                            {data.map((e, index) =>
                              e.role == "user" ? (
                                <Col
                                  md={6}
                                  className="marginClass"
                                  key={index}
                                  style={{ paddingTop: 10 }}
                                >
                                  <Label>
                                    <PermIdentityOutlined /> Supervisor Name
                                  </Label>
                                  <p>{infoData.super_id.label}</p>
                                </Col>
                              ) : null
                            )}
                            <Col
                              md={6}
                              style={{ paddingTop: 10 }}
                              className="marginClass"
                            >
                              <Label>
                                <WorkHistoryOutlined /> Hiring Date{}
                              </Label>
                              <p>{infoData.hiring_date}</p>
                            </Col>
                            <Col
                              md={6}
                              style={{ paddingTop: 10 }}
                              className="marginClass"
                            >
                              <Label>
                                <PermIdentityOutlined /> Employement Status{}
                              </Label>
                              <p>{infoData.employeement_status.value}</p>
                            </Col>
                            <Col
                              md={6}
                              style={{ paddingTop: 10 }}
                              className="marginClass"
                            >
                              <Label>
                                <PermIdentityOutlined /> Work Modal{}
                              </Label>
                              <p>{infoData.work_modal}</p>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    
                    </Row>
                  </TabPanel>
                  <TabPanel value={value} index={2} dir={theme.direction}>
                    <Row>
                      <Col md={6}>
                        <h4>Documents </h4>
                      </Col>
                      <Col md={6} className="text-end">
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            tog_centerother()
                          }}
                        >
                          Add New
                        </button>
                      </Col>

                      {documentArray.length !== 0 ? (
                        <Col md={12} className="mt-4">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Type</th>
                                <th>URL</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {documentArray.map((e, index) => {
                                return (
                                  <tr key={index}>
                                    <td style={{ textTransform: "uppercase" }}>
                                      {e.type}
                                    </td>
                                    <td>
                                      <a
                                        href={e.url}
                                        rel="noreferrer"
                                        target="_blank"
                                      >
                                        Preview
                                      </a>
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          DeleteDocumentFromDB(e.id)
                                        }
                                        className="btn btn-outline"
                                      >
                                        <DeleteSharp className="text-danger" />
                                      </button>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </Col>
                      ) : null}
                    </Row>
                    <Modal
                      isOpen={modal_centerother}
                      toggle={() => {
                        tog_centerother()
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Add Documents</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_centerother(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            if (e.target.elements.select_type.value == "") {
                              toastr.error("Please select the document type")
                              return false
                            } else if (
                              e.target.elements.file.files[0] == undefined
                            ) {
                              toastr.error("Please select the file to upload")
                              return false
                            } else {
                              setDocumentLoader(true)
                              let form = new FormData()
                              form.append(
                                "method",
                                "add-qualification-attachment"
                              )
                              form.append("emp_id", id)
                              form.append(
                                "document_type",
                                e.target.elements.select_type.value
                              )
                              form.append(
                                "document_file",
                                e.target.elements.file.files[0]
                              )
                              axios
                                .post(
                                  process.env.REACT_APP_LEAVE_API_URL +
                                    "/login.php",
                                  form,
                                  {
                                    headers: {
                                      "Content-Type": "multipart/form-data",
                                    },
                                  }
                                )
                                .then(res => {
                                  setDocumentLoader(false)
                                  if (res.data == "success") {
                                    fetchDocumentSingleEmployee()
                                    toastr.success(
                                      "You successfully added the document"
                                    )
                                    setmodal_centerother(false)
                                    return false
                                  } else {
                                    toastr.error(
                                      "Error while uploading document please try again"
                                    )
                                  }
                                })
                            }
                          }}
                        >
                          <Row>
                            <Col md={12}>
                              <Label>Select Document Type</Label>
                              <Select
                                options={[
                                  {
                                    label: "CNIC",
                                    value: "cnic",
                                  },
                                  {
                                    label: "CV",
                                    value: "cv",
                                  },
                                  {
                                    label: "Educational",
                                    value: "educational",
                                  },
                                  {
                                    label: "Experience Letter",
                                    value: "experience letter",
                                  },
                                  {
                                    label: "Other",
                                    value: "other",
                                  },
                                ]}
                                className="select2-selection"
                                name="select_type"
                              />
                            </Col>
                            <Col md={12} className="mt-2">
                              <Label>Attach file</Label>
                              <Input
                                type="file"
                                accept=".pdf,.docx,.doc,.png,.jpeg,.jpg"
                                name="file"
                                className="form-control"
                              />
                            </Col>
                            <Col md={12} className="mt-4 mb-4">
                              {documentLoader == true ? (
                                <Spinner color="info" size={"md"} />
                              ) : (
                                <button type="form" className="btn btn-primary">
                                  Upload Document
                                </button>
                              )}
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Modal>
                  </TabPanel>
                  <TabPanel value={value} index={3} dir={theme.direction}>
                    <Row>
                      <Col md={5}>
                        <h5>Schedule Information</h5>
                      </Col>
                      {currentRole == "admin" || currentRole == "supervisor" ? (
                        <Col md={7} style={{ textAlign: "end" }}>
                          <button
                            id="tooltip_edit_view"
                            className={`btn ${
                              editModeForSchedule
                                ? "btn-success"
                                : "btn-primary"
                            }`}
                            onClick={() =>
                              setEditModeForSchedule(!editModeForSchedule)
                            }
                          >
                            {editModeForSchedule ? <EditSharp /> : <ModeEdit />}
                          </button>
                          <UncontrolledTooltip
                            target={`tooltip_edit_view`}
                            placement="bottom"
                            // isOpen={tooltipOpen}
                            // toggle={() => setTooltipOpen(!tooltipOpen)}
                          >
                            {editModeForSchedule ? "View" : "Edit"}
                          </UncontrolledTooltip>
                          &nbsp;&nbsp;
                          {editModeForSchedule ? (
                            <button
                              id="tooltip_edit_submit"
                              className="btn btn-primary"
                              onClick={() => updateScheduleForIndividual()}
                            >
                              <CheckSharp />
                              <UncontrolledTooltip
                                target={`tooltip_edit_submit`}
                                placement="top"
                              >
                                Submit
                              </UncontrolledTooltip>
                            </button>
                          ) : null}
                        </Col>
                      ) : null}
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          border: "0.5px solid #f3f3f3",
                        }}
                      ></div>

                      <Col md={8} className="mt-4">
                        <Row>
                          {editModeForSchedule == false ? (
                            <Col md={8} className="mb-3">
                              <Row>
                                <Col md={12} className="marginClass">
                                  <Label>
                                    <PendingActionsOutlined /> Working Days <span>({scheduleData.shift_type})</span>
                                  </Label>
                                  <p>
                                    {scheduleData.work_days} <br></br>{" "}
                                    {scheduleData.work_days.split(",").length +
                                      " Days"}
                                  </p>
                                </Col>
                                {scheduleData.shift_type == 'simple' ? 
                                <Row>

                                <Col md={6} className="marginClass">
                                  <Label>
                                    <AccessTimeOutlined /> Shift Start
                                  </Label>
                                  <p>{scheduleData.start_time}</p>
                                </Col>
                                <Col md={6} className="marginClass">
                                  <Label>
                                    <AccessTimeOutlined /> Shift End
                                  </Label>
                                  <p>{scheduleData.end_time}</p>
                                </Col>
                                </Row>:(
                                  rotationalDay.map((e,index)=>(  
                                    <Row key={index}>
                                      <Col md={12}>
                                        <p>{e}</p>
                                      </Col>
                                    <Col md={6} className="marginClass">
                                      <Label>
                                        <AccessTimeOutlined /> Shift Start
                                      </Label>
                                      <p>{shiftTimes[index]?.start}</p>
                                    </Col>
                                    <Col md={6} className="marginClass">
                                      <Label>
                                        <AccessTimeOutlined /> Shift End
                                      </Label>
                                      <p>{shiftTimes[index]?.end}</p>
                                    </Col>
                                    </Row>
                                  ))
                                )}
                                <Col md={6} className="marginClass">
                                  <Label>
                                    <AccessTimeOutlined /> Early In Margin
                                  </Label>
                                  <p>{scheduleData.trady_margin} Mints</p>
                                </Col>
                                <Col md={6} className="marginClass">
                                  <Label>
                                    <AccessTimeOutlined /> Overtime Allowed
                                  </Label>
                                  <p>{scheduleData.overtime_margin} Mints</p>
                                </Col>
                                <Col md={6} className="marginClass">
                                  <Label>
                                    <AccessTimeOutlined /> Early Out Margin
                                  </Label>
                                  <p>{scheduleData.early_out_margin} Mints </p>
                                </Col>
                                <Col md={12}>
                                  <Row>
                                    <Col md={12}>
                                      <h4>Breaks</h4>
                                    </Col>
                                    <Col md={4}>
                                      <Label>No of Breaks</Label>
                                      <p>{scheduleData.no_of_breaks}</p>
                                    </Col>
                                    <Col md={4} className="marginClass">
                                      <Label>No of Mints/Break</Label>
                                      <table className="table table-sm">
                                        <tbody>
                                          <tr>
                                            {scheduleData.breaks_mints
                                              .split(",")
                                              .map((e, index) => {
                                                return (
                                                  <td key={index}>
                                                    {e + "Mints"}
                                                  </td>
                                                )
                                              })}
                                          </tr>
                                        </tbody>
                                      </table>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          ) : (
                            
                            <Col md={10} className="mb-3">
                              <Row>
                                <Col md={12} className="mb-2">
                                  <Label>Shift Type </Label>
                                  <br></br>
                                  <Input 
                                  type="radio"
                                  value={'simple'}
                                  id="label1"
                                 onChange={(e)=>setScheduleData((prev)=>({
                                  ...prev,
                                  shift_type: e.target.value
                                }))}
                                  name="shift_type"
                                  defaultChecked={scheduleData.shift_type == 'simple' ? true : false}
                                  /> <Label htmlFor="label1" aria-label="label1">Simple</Label>
                                  <Input 
                                  style={{marginLeft:20}}
                                  type="radio"
                                  id="label2"
                                  value={'rotational'}
                                  defaultChecked={scheduleData.shift_type == 'rotational' ? true : false}
                                  name="shift_type"
                                  onChange={(e)=>setScheduleData((prev)=>({
                                    ...prev,
                                    shift_type: e.target.value
                                  }))}
                                  /> <label htmlFor="label2">Rotational</label>
                                </Col>
                                <Col md={12}>
                                
                                
                                  <Row>
                                    {
                                      scheduleData.shift_type == 'simple' ? 

                                    <div>
                                  <Col md={12}>
                                  <Label>Working Days</Label>
                                  <Row
                                    style={{ paddingLeft: 15 }}
                                    className="mb-3"
                                  >
                                    {radioData.map((item, index) => (
                                      <span
                                        className="col-md-1"
                                        key={index}
                                        onClick={() =>
                                          handleEmployeeSelectionEdit(
                                            item.value
                                          )
                                        }
                                        style={{
                                          background:
                                            editnoOfEmployees.includes(
                                              item.value
                                            )
                                              ? "#8fe1ff"
                                              : "white",

                                          padding: 10, // Add padding for better appearance
                                          borderWidth: 1,
                                          borderStyle: "solid",
                                          textAlign: "center",
                                          cursor: "pointer",
                                          borderColor: "#8fe1ff",
                                        }}
                                      >
                                        {item.label}
                                      </span>
                                    ))}
                                  </Row>
                                   </Col>
                                
                                    
                                <Col md={6}>
                                  <Label>Shift Start</Label>
                                  <Input
                                    type="time"
                                    value={convertTo24Hour(
                                      scheduleData.start_time
                                    )}
                                    onChange={e =>
                                      setScheduleData(prev => ({
                                        ...prev,
                                        start_time: e.target.value,
                                      }))
                                    }
                                    name="schedule_start_time"
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6}>
                                  <Label>Shift End</Label>
                                  <Input
                                    type="time"
                                    value={convertTo24Hour(
                                      scheduleData.end_time
                                    )}
                                    onChange={e =>
                                      setScheduleData(prev => ({
                                        ...prev,
                                        end_time: e.target.value,
                                      }))
                                    }
                                    name="schedule_end_time"
                                    className="form-control"
                                  />
                                </Col>
                                </div>
                                :
                                <Row>
                                    
                                <Col md={12}>
                            <Label>Working Days</Label>
                            <Row
                              style={{ paddingLeft: 15 }}
                              className="mb-3"
                            >
                              {radioData.map((item, index) => (
                                <span
                                  className="col-md-1"
                                  key={index}
                                  onClick={() =>
                                    handleRotationalSelectionEdit(
                                      item.value
                                    )
                                  }
                                  style={{
                                    background:
                                      rotationalDay.includes(
                                        item.value
                                      )
                                        ? "#8fe1ff"
                                        : "white",

                                    padding: 10, // Add padding for better appearance
                                    borderWidth: 1,
                                    borderStyle: "solid",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    borderColor: "#8fe1ff",
                                  }}
                                >
                                  {item.label}
                                </span>
                              ))}
                            </Row>
                            
                          </Col>
                          {
                            rotationalDay.length !== 0 ? 
                            (
                              rotationalDay.map((e,index)=>(
                                <Row key={index} className="mb-2">
                                <Col md={12}>
                                  <p>{e}</p> 
                                </Col>
                                <Col md={4}>
                                  <Label>Shift Start</Label>
                                  <Input
                                    type="time"
                                    name="schedule_start_time"
                                    className="form-control"
                                    value={shiftTimes[index]?.start}
                                    onChange={(e) => handleTimeChange(index, 'start', e.target.value)}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Label>Shift End</Label>
                                  <Input
                                    type="time"
                                    name="schedule_end_time"
                                    className="form-control"
                                    value={shiftTimes[index]?.end}
                                    onChange={(e) => handleTimeChange(index, 'end', e.target.value)}
                                  />
                                </Col>
                                <Col md={4}>
                                  <Label>Total</Label>
                                  <p>{shiftTimes[index]?.start !='' && shiftTimes[index]?.end != ''  ? calculateTotalHours(shiftTimes[index]?.start, shiftTimes[index]?.end) : '0h:0m'}</p>
                                </Col>
                              </Row>
                              ))
                            )
                             :null 
                          }
                            </Row>
                                    }
                                <Col md={6} className="mt-2">
                                  <Label>Early In Margin</Label>
                                  <Input
                                    type="number"
                                    value={scheduleData.trady_margin}
                                    onChange={e =>
                                      setScheduleData(prev => ({
                                        ...prev,
                                        trady_margin: e.target.value,
                                      }))
                                    }
                                    name="schedule_start_time"
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6} className="mt-2">
                                  <Label>Overtime Allowed</Label>
                                  <Input
                                    type="number"
                                    value={scheduleData.overtime_margin}
                                    onChange={e =>
                                      setScheduleData(prev => ({
                                        ...prev,
                                        overtime_margin: e.target.value,
                                      }))
                                    }
                                    name="schedule_start_time"
                                    className="form-control"
                                  />
                                </Col>
                                <Col md={6} className="mt-2">
                                  <Label>Early Out Margin</Label>
                                  <Input
                                    type="number"
                                    value={scheduleData.early_out_margin}
                                    onChange={e =>
                                      setScheduleData(prev => ({
                                        ...prev,
                                        early_out_margin: e.target.value,
                                      }))
                                    }
                                    name="schedule_start_time"
                                    className="form-control"
                                  />
                                </Col>

                                <Col md={12}>
                                  <Row>
                                    <Col md={12} className="mt-2">
                                      <h4>Breaks</h4>
                                    </Col>
                                    <Col md={4}>
                                      <Label>No of Breaks</Label>
                                      <Input
                                        type="number"
                                        value={scheduleData.no_of_breaks}
                                        onChange={e =>
                                          setScheduleData(prev => ({
                                            ...prev,
                                            no_of_breaks: e.target.value,
                                          }))
                                        }
                                        name="schedule_start_time"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col md={12} className="mt-3">
                                      <Label>Break Per Mints</Label>
                                      <RenderItemIwant />
                                    </Col>
                                  </Row>
                                </Col>
                                  </Row> 
                                
                                
                                </Col>
                              </Row>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel value={value} index={4} dir={theme.direction}>
                    <Row>
                      <Col md={12}>
                        <Row>
                          <Col md={5}>
                            <h5>Payroll Information</h5>
                          </Col>
                          <Col md={7} style={{ textAlign: "end" }}>
                            <button
                              style={{ marginRight: 5 }}
                              onClick={() => UpdatePayrollCurrentEmployee()}
                              className="btn btn-primary"
                            >
                              <CheckSharp />
                            </button>
                            <button
                              className="btn btn-info"
                              onClick={() => {
                                tog_center()
                              }}
                            >
                              <AddSharp />
                            </button>
                          </Col>
                          <div
                            style={{
                              marginTop: 5,
                              marginBottom: 5,
                              border: "0.5px solid #f3f3f3",
                            }}
                          ></div>
                        </Row>
                      </Col>
                      <Col md={6} className="mt-3">
                        <Row>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor2"
                                name="emp_payroll_type"
                                // checked={payrollType == "hourly"}
                                defaultChecked={
                                  payrollType == "hourly" ? true : false
                                }
                                className="form-check-input"
                                onChange={e => setpayrollType(e.target.value)}
                                value={"hourly"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor2"
                              >
                                Hourly
                              </label>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor1"
                                name="emp_payroll_type"
                                className="form-check-input"
                                defaultChecked={
                                  payrollType == "daily" ? true : false
                                }
                                onChange={e => setpayrollType(e.target.value)}
                                value={"daily"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor1"
                              >
                                Daily
                              </label>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor3"
                                name="emp_payroll_type"
                                className="form-check-input"
                                defaultChecked={
                                  payrollType == "monthly" ? true : false
                                }
                                onChange={e => setpayrollType(e.target.value)}
                                value={"monthly"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor3"
                              >
                                Monthly
                              </label>
                            </div>
                          </Col>
                          <Col md={6}>
                            <Label>Select Currency</Label>
                            <input
                              type="text"
                              placeholder="Currency"
                              className="form-control"
                              value={payrollCurrency}
                              onChange={e => setpayrollCurrency(e.target.value)}
                            />
                          </Col>
                          <Col md={6}>
                            <Label style={{ textTransform: "capitalize" }}>
                              {payrollType} Rate
                            </Label>
                            <input
                              type="text"
                              placeholder="Rate"
                              className="form-control"
                              value={payrollRate}
                              onChange={e => setpayrollRate(e.target.value)}
                            />
                          </Col>
                          <Col md={12} className="mt-3">
                            <p>Overtime Pay Frequency</p>
                          </Col>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor23"
                                name="emp_overtime_type"
                                // checked={payrollType == "hourly"}
                                defaultChecked={
                                  overTimePayType == "hourly" ? true : false
                                }
                                className="form-check-input"
                                onChange={e => setOvertimePayType(e.target.value)}
                                value={"hourly"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor23"
                              >
                                Hourly
                              </label>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor22"
                                name="emp_overtime_type"
                                className="form-check-input"
                                defaultChecked={
                                  overTimePayType == "daily" ? true : false
                                }
                                onChange={e => setOvertimePayType(e.target.value)}
                                value={"daily"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor22"
                              >
                                Daily
                              </label>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="form-check form-radio-primary mb-3">
                              <input
                                type="radio"
                                id="customRadiocolor223"
                                name="emp_overtime_type"
                                className="form-check-input"
                                defaultChecked={
                                  overTimePayType == "monthly" ? true : false
                                }
                                onChange={e => setOvertimePayType(e.target.value)}
                                value={"monthly"}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customRadiocolor223"
                              >
                                Monthly
                              </label>
                            </div>
                          </Col>
                          <Col md={6} className="mt-2">
                            <Label>Overtime Rate</Label>
                            <input
                              type="text"
                              placeholder="Rate"
                              className="form-control"
                              value={overTimeRate == "null" ? "" : overTimeRate}
                              onChange={e => setoverTimeRate(e.target.value)}
                            />
                          </Col>
                          <Col md={6} className="mt-2">
                            <Label>Cycle Date</Label>
                            <Select
                              options={arrayForMe}
                              value={{ label: cycleDate, value: cycleDate }}
                              onChange={e => setCycleDate(e.label)}
                              className="select2-selection"
                              name="cycle_date"
                            />
                          </Col>
                        </Row>
                      </Col>
                      {incrementalInfoArray.length == 0 ? null : (
                        <Col md={8}>
                          <h4 className="mt-3">Increment History</h4>
                          <table className="table">
                            <thead>
                              <tr>
                                <td>Amount</td>
                                <td>Type</td>
                                <td>Apply Date</td>
                                <td>Actions</td>
                              </tr>
                            </thead>
                            <tbody>
                              {incrementalInfoArray.map((e, index) => (
                                <tr key={index}>
                                  <td>
                                    {e.amount}
                                    {e.type_for == "in_precentage" ? "%" : null}
                                  </td>
                                  <td>{e.type_for}</td>
                                  <td>
                                    <p>{e.apply_date}</p>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() => fetchSingleIncrement(e.id)}
                                      className="btn btn-sm btn-info"
                                    >
                                      <i className="bx bx-edit-alt"></i>
                                    </button>
                                    <button
                                      onClick={() => DeleteIncrementFrom(e.id)}
                                      style={{ marginLeft: 5 }}
                                      className="btn btn-sm btn-danger"
                                    >
                                      <i className="bx bx-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                      )}

                     <Col md={12} className="mt-3">
                      <Row >
                        <Col md={6}>
                         <h5> Annual Leaves</h5>
                        </Col>
                        <Col md={6} className="text-end">
                        <button
                          id="tooltip_edit_view"
                          className={`btn ${
                            editAnnualLeaves ? "btn-success" : "btn-primary"
                          }`}
                          onClick={() => setEditAnnualLeaves(!editAnnualLeaves)}
                        >
                          {editAnnualLeaves ? <EditSharp /> : <ModeEdit />}
                        </button>
                        <UncontrolledTooltip
                          target={`tooltip_edit_view`}
                          placement="bottom"
                          // isOpen={tooltipOpen}
                          // toggle={() => setTooltipOpen(!tooltipOpen)}
                        >
                          {editAnnualLeaves ? "View" : "Edit"}
                        </UncontrolledTooltip>
                        &nbsp;&nbsp;
                        {editAnnualLeaves ? (
                          <button
                            id="tooltip_edit_submit"
                            className="btn btn-primary"
                            onClick={() => ChangeNumberOfLeaves()}
                          >
                            <CheckSharp />
                            <UncontrolledTooltip
                              target={`tooltip_edit_submit`}
                              placement="top"
                            >
                              Submit
                            </UncontrolledTooltip>
                          </button>
                        ) : null}
                        </Col>
                        <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          border: "0.5px solid #f3f3f3",
                        }}
                      ></div>
                    {
                      editAnnualLeaves == true ?
                      <Row className="mt-3">
                         <Col className="col-md-6 text-start">
                                  <p className="btn btn-info"  onClick={() => addDynamicLayout()} style={{fontWeight:'600',cursor:'pointer'}}>Assign Quote</p>
                            </Col>
                         <Col md={12}>
                          {itemName.map((itemName, index) => (
                            <div className="row" key={index}>
                              <div className="col-md-12 mt-2 mb-2">
                                <span>Leave Quote {index + 1}</span>
                              </div>
                              <div className="col-md-3">
                                <Select
                                 className="select2-selection"
                                 name="separation_type"
                                   options = {mockDataForLeaves}
                                  onChange={value =>
                                    updateItemValue(
                                      "itemName",
                                      index,
                                      value.value
                                    )
                                  }
                                  value={{label: itemName, value: itemName}}
                                  placeholder="Item Name"
                                />
                              </div>

                              <div className="col-md-2">
                                <Input
                                  onChange={value =>
                                    updateItemValue(
                                      "itemQuantities",
                                      index,
                                      value.target.value
                                    )
                                  }
                                  value={itemQuantities[index]}
                                  placeholder="Quantity"
                                />
                              </div>
                              
                              <div className="col-md-2">
                              <button
                                  className="btn btn-danger"
                                  onClick={() => removeDynamicLayout(index)}
                                >
                                  <DeleteSharp/>
                                </button>
                              </div>
                            
                            </div>
                          ))}
                        </Col>
                      </Row>
                      :
                      <Col md={6} className="mt-3">
                        
                        {itemName.map((itemName, index) => (
                            <div className="row" key={index}>
                              
                              <div className="col-md-6">
                                <p><AccessAlarm/> {itemName}</p>
                              </div>
                              <div className="col-md-6">
                                <p>{itemQuantities[index]}</p>
                              </div>
                            </div>
                          ))}
                  
                      </Col>
                    }
                           
                      </Row>
                      </Col>
                    </Row>

                    <Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center()
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">
                          {editModeIncrement == true
                            ? "Edit Increment"
                            : "Add Increment"}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false)
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        {editModeIncrement == false ? (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                              if (
                                e.target.elements.increment_type.value == ""
                              ) {
                                toastr.error("Please select the Increment type")
                                return false
                              } else {
                                if (
                                  e.target.elements.increment_value.value == ""
                                ) {
                                  toastr.error("Enter the increment value")
                                  return false
                                } else if (
                                  e.target.elements.date_for_increment.value ==
                                  ""
                                ) {
                                  toastr.error("Enter the date to be applied")
                                  return false
                                } else {
                                  let form = new FormData()
                                  form.append("method", "add-increment")
                                  form.append("emp_id", id)
                                  form.append(
                                    "type_for",
                                    e.target.elements.increment_type.value
                                  )
                                  form.append(
                                    "amount",
                                    e.target.elements.increment_value.value
                                  )
                                  form.append(
                                    "apply_date",
                                    e.target.elements.date_for_increment.value
                                  )
                                  axios
                                    .post(
                                      process.env.REACT_APP_LEAVE_API_URL +
                                        "/increment.php",
                                      form
                                    )
                                    .then(res => {
                                      if (res.data == "success") {
                                        Swal.fire(
                                          "Success",
                                          "You successfully add the increment for the current Employee",
                                          "success"
                                        )
                                        fetchEmployee()
                                        ;(e.target.elements.increment_value.value =
                                          ""),
                                          (e.target.elements.date_for_increment.value =
                                            ""),
                                          setIncrementType("")

                                        setmodal_center(false)
                                      } else {
                                        Swal.fire(
                                          "Error",
                                          "Please check your input fields and try again",
                                          "error"
                                        )
                                      }
                                    })
                                    .catch(er => {
                                      console.log(er)
                                    })
                                }
                              }
                            }}
                          >
                            <Row>
                              <Col md={12}>
                                <Label>Enter Increment</Label>
                                <br></br>
                                <Input
                                  type="radio"
                                  name="increment_type"
                                  onChange={e =>
                                    setIncrementType(e.target.value)
                                  }
                                  value={"in_precentage"}
                                  id="in_percentage"
                                />{" "}
                                <label htmlFor="in_percentage">
                                  In Precentage
                                </label>
                                <Input
                                  style={{ marginLeft: 10 }}
                                  onChange={e =>
                                    setIncrementType(e.target.value)
                                  }
                                  type="radio"
                                  name="increment_type"
                                  value={"in_amount"}
                                  id="in_amount"
                                />{" "}
                                <label htmlFor="in_amount">In Amount</label>
                              </Col>
                              <Col md={12}>
                                {incrementType.length != 0 ? (
                                  <Row>
                                    <Col md={12} className="mt-2">
                                      <Label>Increment Value</Label>
                                      <Input
                                        className="form-control"
                                        placeholder={`Enter value in ${
                                          incrementType == "in_precentage"
                                            ? "%"
                                            : "amount"
                                        }`}
                                        name="increment_value"
                                      />
                                    </Col>
                                    <Col md={12} className="mt-2">
                                      <Label>Apply Date</Label>
                                      <Input
                                        className="form-control"
                                        min={getCurrentDate()}
                                        type="date"
                                        name="date_for_increment"
                                      />
                                    </Col>
                                  </Row>
                                ) : null}
                              </Col>
                              <Col md={12} className="mt-3">
                                <button className="btn btn-primary">
                                  Add Increment
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        ) : (
                          <Form
                            onSubmit={e => {
                              e.preventDefault()

                              MySwal.fire({
                                title: "Save Changes",
                                icon: "warning",
                                text: "Are you sure you want to save changes?",
                                showCancelButton: true,
                                cancelButtonColor: "red",
                                cancelButtonText: "No, I don't want",
                                confirmButtonText: "Yes, I agree",
                                confirmButtonColor: "green",
                              }).then(is => {
                                if (is.isConfirmed) {
                                  let form = new FormData()
                                  form.append("method", "update-increment")
                                  form.append(
                                    "edit_id",
                                    singleIncrementData.increment_id
                                  )
                                  form.append(
                                    "type_for",
                                    singleIncrementData.increment_type
                                  )
                                  form.append(
                                    "amount",
                                    singleIncrementData.increment_amount
                                  )
                                  form.append(
                                    "apply_date",
                                    singleIncrementData.apply_date
                                  )
                                  axios
                                    .post(
                                      process.env.REACT_APP_LEAVE_API_URL +
                                        "/increment.php",
                                      form
                                    )
                                    .then(res => {
                                      if (res.data == "success") {
                                        Swal.fire(
                                          "Success",
                                          "You successfully update the increment for the current Employee",
                                          "success"
                                        )
                                        fetchEmployee()
                                        setmodal_center(false)
                                      } else {
                                        Swal.fire(
                                          "Error",
                                          "Please check your input fields and try again",
                                          "error"
                                        )
                                      }
                                    })
                                    .catch(er => {
                                      console.log(er)
                                    })
                                } else {
                                  MySwal.close()
                                }
                              })
                            }}
                          >
                            <Row>
                              <Col md={12}>
                                <Label>Enter Increment</Label>
                                <br></br>
                                <Input
                                  type="radio"
                                  name="edit_increment_type"
                                  onChange={e =>
                                    setSingleIncrementData(prev => ({
                                      ...prev,
                                      increment_type: e.target.value,
                                    }))
                                  }
                                  value={"in_precentage"}
                                  id="in_percentage"
                                />{" "}
                                <label htmlFor="in_percentage">
                                  In Precentage
                                </label>
                                <Input
                                  style={{ marginLeft: 10 }}
                                  onChange={e =>
                                    setSingleIncrementData(prev => ({
                                      ...prev,
                                      increment_type: e.target.value,
                                    }))
                                  }
                                  type="radio"
                                  name="edit_increment_type"
                                  value={"in_amount"}
                                  id="in_amount"
                                />{" "}
                                <label htmlFor="in_amount">In Amount</label>
                              </Col>
                              <Col md={12}>
                                <Row>
                                  <Col md={12} className="mt-2">
                                    <Label>Increment Value</Label>
                                    <Input
                                      className="form-control"
                                      value={
                                        singleIncrementData.increment_amount
                                      }
                                      onChange={e =>
                                        setSingleIncrementData(prev => ({
                                          ...prev,
                                          increment_amount: e.target.value,
                                        }))
                                      }
                                      placeholder={`Enter value in ${
                                        singleIncrementData.increment_type ==
                                        "in_precentage"
                                          ? "%"
                                          : "amount"
                                      }`}
                                      name="increment_value"
                                    />
                                  </Col>
                                  <Col md={12} className="mt-2">
                                    <Label>Apply Date</Label>
                                    <Input
                                      className="form-control"
                                      onChange={e =>
                                        setSingleIncrementData(prev => ({
                                          ...prev,
                                          apply_date: e.target.value,
                                        }))
                                      }
                                      value={singleIncrementData.apply_date}
                                      min={singleIncrementData.apply_date}
                                      type="date"
                                      name="date_for_increment"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} className="mt-3">
                                <button className="btn btn-primary">
                                  Update Increment
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        )}
                      </div>
                    </Modal>
                  </TabPanel>
                  <TabPanel value={value} index={5} dir={theme.direction}>
                    {/* <Row>
                      <Col md={12}>
                        <select>
                          <option>
                            --Select--
                          </option>
                          <option>
                            Personal
                          </option>
                          <option>
                            Annual
                          </option>
                        </select>
                      </Col>
                    </Row> */}
                    <TableContainer
                      columns={columns}
                      data={leaveData}
                      isGlobalFilter={true}
                      isAddOptions={false}
                      customPageSize={10}
                    />
                  </TabPanel>
                  <TabPanel value={value} index={6} dir={theme.direction}>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownCircleOutlined />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>
                          Change Password{" "}
                          <i id="tooltip_edit_submit_">
                            <InfoOutlined />
                          </i>
                          <UncontrolledTooltip
                            target={`tooltip_edit_submit_`}
                            placement="top"
                          >
                            Password that is used for login purpose
                          </UncontrolledTooltip>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Row>
                          <Col md={12}>
                            <h4 style={{ fontFamily: "Nunito Sans" }}>
                              Change Password
                            </h4>
                          </Col>
                          <Col md={7}>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                if (
                                  e.target.elements.currentPassword.value == ""
                                ) {
                                  toastr.error("Current Password is required")
                                  return false
                                } else if (
                                  e.target.elements.confirmPassword.value == ""
                                ) {
                                  toastr.error("Confirm Password is required")
                                  return false
                                } else {
                                  // let local =  localStorage.getItem('authUser');
                                  // let jsonFormat = JSON.parse(local);
                                  // const uid = jsonFormat.map((e)=>e.user_id);
                                  let form = new FormData()
                                  form.append(
                                    "currentPassword",
                                    e.target.elements.currentPassword.value
                                  )
                                  form.append(
                                    "confirmPassword",
                                    e.target.elements.confirmPassword.value
                                  )
                                  form.append("currentID", id)
                                  form.append("method", "update-password")
                                  var requestOptions = {
                                    method: "POST",
                                    body: form,
                                    redirect: "follow",
                                  }
                                  fetch(
                                    process.env.REACT_APP_LEAVE_API_URL +
                                      "/login.php",
                                    requestOptions
                                  )
                                    .then(response => response.text())
                                    .then(result => {
                                      if (result == "success") {
                                        Swal.fire(
                                          "Password Changed",
                                          `Employee ${
                                            infoData.first_name +
                                            " " +
                                            infoData.last_name
                                          } password is successfully updated`,
                                          "success"
                                        )
                                        e.target.elements.currentPassword.value =
                                          ""
                                        e.target.elements.confirmPassword.value =
                                          ""
                                      } else {
                                        Swal.fire(`Error`, `${result}`, "error")
                                      }
                                    })
                                    .catch(error => console.log("error", error))
                                }
                              }}
                            >
                              <Row>
                                <Col md={7}>
                                  <Label>Current Password</Label>
                                  <input
                                    className="form-control"
                                    name="currentPassword"
                                    placeholder="Current Password"
                                    type="password"
                                  />
                                </Col>
                                <Col md={7} style={{ paddingTop: 10 }}>
                                  <Label>Confirm Password</Label>
                                  <input
                                    className="form-control"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    type="password"
                                  />
                                </Col>
                                <Col md={12} style={{ paddingTop: 20 }}>
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Change Password
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownCircleOutlined />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>
                          Change PIN{" "}
                          <i id="tooltip_edit_submit">
                            <InfoOutlined />
                          </i>
                          <UncontrolledTooltip
                            target={`tooltip_edit_submit`}
                            placement="top"
                          >
                            PIN which is used for attendance purpose
                          </UncontrolledTooltip>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Row>
                          <Col md={7}>
                            <Form
                              onSubmit={e => {
                                e.preventDefault()
                                if (e.target.elements.current_Pin.value == "") {
                                  toastr.error("Current PIN is required")
                                  return false
                                } else if (
                                  e.target.elements.new_Pin.value == ""
                                ) {
                                  toastr.error("New PIN is required")
                                  return false
                                }
                                if (
                                  e.target.elements.new_Pin.value.length < 4
                                ) {
                                  toastr.error("New Pin should be 4 digits")
                                  return false
                                } else if (
                                  e.target.elements.current_Pin.value ==
                                  e.target.elements.new_Pin.value
                                ) {
                                  toastr.error(
                                    "You are already using this password required"
                                  )
                                  return false
                                } else {
                                  // let local =  localStorage.getItem('authUser');
                                  // let jsonFormat = JSON.parse(local);
                                  // const uid = jsonFormat.map((e)=>e.user_id);
                                  let form = new FormData()
                                  form.append(
                                    "current_pin",
                                    e.target.elements.current_Pin.value
                                  )
                                  form.append(
                                    "new_pin",
                                    e.target.elements.new_Pin.value
                                  )
                                  form.append("emp_id", id)
                                  form.append("method", "update-pin")
                                  var requestOptions = {
                                    method: "POST",
                                    body: form,
                                    redirect: "follow",
                                  }
                                  fetch(
                                    process.env.REACT_APP_LEAVE_API_URL +
                                      "/login.php",
                                    requestOptions
                                  )
                                    .then(response => response.text())
                                    .then(result => {
                                      if (result == "success") {
                                        Swal.fire(
                                          "PIN Changed",
                                          `Employee ${
                                            infoData.first_name +
                                            " " +
                                            infoData.last_name
                                          } password is successfully updated`,
                                          "success"
                                        )
                                        e.target.elements.currentPassword.value =
                                          ""
                                        e.target.elements.confirmPassword.value =
                                          ""
                                      } else {
                                        Swal.fire(`Error`, `${result}`, "error")
                                      }
                                    })
                                    .catch(error => console.log("error", error))
                                }
                              }}
                            >
                              <Row>
                                <Col md={7}>
                                  <Label>
                                    Current PIN <small>4 digits</small>
                                  </Label>
                                  <input
                                    className="form-control"
                                    name="current_Pin"
                                    maxLength={4}
                                    placeholder="****"
                                    type="password"
                                  />
                                </Col>
                                <Col md={7} style={{ paddingTop: 10 }}>
                                  <Label>
                                    New PIN <small>4 digits</small>
                                  </Label>
                                  <input
                                    className="form-control"
                                    name="new_Pin"
                                    maxLength={4}
                                    placeholder="****"
                                    type="password"
                                  />
                                </Col>
                                <Col md={12} style={{ paddingTop: 20 }}>
                                  <button
                                    className="btn btn-primary"
                                    type="submit"
                                  >
                                    Change PIN
                                  </button>
                                </Col>
                              </Row>
                            </Form>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>
                  </TabPanel>
                  <TabPanel value={value} index={7} dir={theme.direction}>
                    <Row>
                      <Col md={12}>
                        <h4 style={{ fontFamily: "Nunito Sans" }}>
                          Mark Separation
                        </h4>
                      </Col>
                      <Col md={7}>
                        <Form
                          onSubmit={e => {
                            e.preventDefault()
                            if (
                              e.target.elements.separation_type.value == "null"
                            ) {
                              toastr.error("Select the separation type")
                              return false
                            } else if (
                              e.target.elements.speration_date.value == ""
                            ) {
                              toastr.error("Separation date is required")
                              return false
                            } else {
                              // let local =  localStorage.getItem('authUser');
                              // let jsonFormat = JSON.parse(local);
                              // const uid = jsonFormat.map((e)=>e.user_id);
                              let form = new FormData()
                              form.append(
                                "type",
                                e.target.elements.separation_type.value
                              )
                              form.append(
                                "reason_type",
                                e.target.elements.reason_type.value
                              )
                              form.append(
                                "reason_message",
                                e.target.elements.reason_comments.value
                              )
                              form.append(
                                "separation_date",
                                e.target.elements.speration_date.value
                              )
                              form.append(
                                "clearance_date",
                                e.target.elements.clearance_date.value
                              )
                              form.append(
                                "comments",
                                e.target.elements.comments.value
                              )
                              form.append("user_for", id)
                              form.append("method", "mark-separation")
                              var requestOptions = {
                                method: "POST",
                                body: form,
                                redirect: "follow",
                              }
                              fetch(
                                process.env.REACT_APP_LEAVE_API_URL +
                                  "/login.php",
                                requestOptions
                              )
                                .then(response => response.text())
                                .then(result => {
                                  if (result == "success") {
                                    Swal.fire(
                                      "Success",
                                      "You successfully mark the employee separation"
                                    )
                                  } else {
                                    Swal.fire(
                                      `Server Error`,
                                      `Check your input fields`
                                    )
                                  }
                                })
                                .catch(error => console.log("error", error))
                            }
                          }}
                        >
                          <Row>
                            <Col md={7} className="mb-3">
                              <Label>Select Separation Type</Label>

                              <Select
                                className="select2-selection"
                                name="separation_type"
                                value={{
                                  label: infoData.terminated_type,
                                  value: infoData.terminated_type,
                                }}
                                onChange={e =>
                                  setInfoData(prev => ({
                                    ...prev,
                                    terminated_type: e.label,
                                  }))
                                }
                                options={[
                                  {
                                    label: "Terminate",
                                    value: "Terminate",
                                  },
                                  { label: "Laid off", value: "Laid off" },
                                  { label: "Resigned", value: "Resigned" },
                                ]}
                              />
                            </Col>
                            <Col md={7} className="mb-3">
                              <Label>Select Reason</Label>

                              <Select
                                className="select2-selection"
                                name="reason_type"
                                value={{
                                  label: infoData.terminate_reason,
                                  value: infoData.terminate_reason,
                                }}
                                onChange={e =>
                                  setInfoData(prev => ({
                                    ...prev,
                                    terminate_reason: e.label,
                                  }))
                                }
                                options={[
                                  {
                                    label: "Personal",
                                    value: "Personal",
                                  },
                                  { label: "Marriage", value: "Marriage" },
                                  { label: "Better Opportunity", value: "Better Opportunity" },
                                ]}
                              />
                            </Col>
                            <Col md={7} className="mb-3">
                              <Label>Reason Comments</Label>

                              <Input
                              className="form-control"
                              type="textarea"
                              placeholder="Comments...."
                              name="reason_comments"
                              value={infoData.terminate_reason_message}
                              onChange={(e)=>setInfoData((prev)=>({
                                ...prev,
                                terminate_reason_message : e.target.value
                              }))}
                              />
                            </Col>
                            <Col md={7} className="mb-3">
                              <Label>Select Date</Label>
                              <input
                                className="form-control"
                                min={getCurrentDate()}
                                name="speration_date"
                                value={infoData.terminate_date}
                                onChange={e =>
                                  setInfoData(prev => ({
                                    ...prev,
                                    terminate_date: e.target.value,
                                  }))
                                }
                                placeholder="Select Date"
                                type="date"
                              />
                            </Col>
                            <Col md={7} className="mb-3">
                              <Label>Comments</Label>
                              <Input
                                className="form-control"
                                name="comments"
                                value={infoData.terminated_comments}
                                onChange={e =>
                                  setInfoData(prev => ({
                                    ...prev,
                                    terminated_comments: e.target.value,
                                  }))
                                }
                                placeholder="Write some comments"
                                type="textarea"
                              />
                            </Col>
                            <Col md={7} className="mb-3">
                              <Label>Select Clearance Date</Label>
                              <input
                                className="form-control"
                                name="clearance_date"
                                placeholder="Select Date"
                                min={getCurrentDate()}
                                value={infoData.date_for_clearance}
                                onChange={e =>
                                  setInfoData(prev => ({
                                    ...prev,
                                    date_for_clearance: e.target.value,
                                  }))
                                }
                                type="date"
                              />
                            </Col>

                            <Col md={12} style={{ paddingTop: 20 }}>
                              <button className="btn btn-primary" type="submit">
                                Save Changes
                              </button>
                            </Col>
                          </Row>
                        </Form>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel value={value} index={8} dir={theme.direction}>
                    <Row>
                      <Col md={5}>
                        <h5>Employee Privileges</h5>
                      </Col>
                      <Col md={7} style={{ textAlign: "end" }}>
                        {spinnerForPrivilages == true ? (
                          <Spinner color="info" />
                        ) : (
                          <button
                            id="tooltip_edit_submit"
                            className="btn btn-primary"
                            onClick={() => updateCurrentEmployeeRights()}
                          >
                            <CheckSharp />
                            <UncontrolledTooltip
                              target={`tooltip_edit_submit`}
                              placement="top"
                            >
                              Submit
                            </UncontrolledTooltip>
                          </button>
                        )}
                      </Col>
                      <div
                        style={{
                          marginTop: 5,
                          marginBottom: 5,
                          border: "0.5px solid #f3f3f3",
                        }}
                      ></div>
                      <Col md={7} className="mt-4 mb-4">
                        <div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor1"
                              name="rules"
                              defaultChecked={addEmployeeStatus}
                              onChange={e =>
                                setaddEmployeeStatus(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor1"
                            >
                              &nbsp;&nbsp;Add Employee
                            </label>
                          </div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor2"
                              defaultChecked={markedAttendance}
                              onChange={e => {
                                setmarkedAttendance(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor2"
                            >
                              &nbsp;&nbsp;Mark Attendance
                            </label>
                          </div>
                          {markedAttendance == true ? (
                            <div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  defaultChecked={ByManual}
                                  id="customCheck-outlinecolor33"
                                  onChange={e => {
                                    setByManual(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor33"
                                >
                                  &nbsp;&nbsp;By Manual
                                </label>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  defaultChecked={ByFaceRecong}
                                  id="customCheck-outlinecolor35"
                                  onChange={e => {
                                    setByFaceRecong(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor35"
                                >
                                  &nbsp;&nbsp;By Face
                                </label>
                              </div>
                            </div>
                          ) : null}
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="rules"
                              id="customCheck-outlinecolor3"
                              defaultChecked={reviewLeaves}
                              // checked={reviewLeaves}
                              onChange={e => {
                                setreviewLeaves(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor3"
                            >
                              &nbsp;&nbsp;Review Leaves
                            </label>
                          </div>
                          <div className=" mb-3">
                            <Input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor4"
                              defaultChecked={trackStatus}
                              // checked={trackStatus}
                              onChange={e => {
                                settrackStatus(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor4"
                            >
                              &nbsp;&nbsp;Enable GPS
                            </label>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </TabPanel>
                </SwipeableViews>
              </Box>
            </Col>
          </Row>
          <ChatWidget/>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MembersDetails
