import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Circle,
  Polyline,
  
} from "google-maps-react"
import { connect } from "react-redux"
// import LightData from "./LightData"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Container,
  Input,
  Label,
  Modal,
  Badge,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material"
import CustomMarkerIcon from "./CustomIconMarker"
import { Link } from "react-router-dom"
import ChatWidget from "components/Common/ChatWidget"


const LoadingContainer = () => <div>Loading...</div>
const SkeletonRow = () => (
  <Row>
    <Col md={12}>
      <Row>
        <Col md={4}>
          <Skeleton height={20} width={280} style={{ marginBottom: 10 }} />
          <Row>
            <Col md={12} style={{display:'flex'}}>
            {/* <Col md={4} style={{ textAlign: "center" }}> */}
              <Skeleton  variant="rectangular" height={20} width={90} style={{ marginBottom: 10 }} />
              <Skeleton variant="rectangular" height={20} width={90} style={{ marginBottom: 10 ,marginLeft: 10 }} />
            {/* </Col> */}
            </Col>
            <Col md={12} style={{display:'flex',paddingTop:10}}>
            {/* <Col md={4} style={{ textAlign: "center" }}> */}
              <Skeleton variant="circle" height={80} width={80} style={{ marginBottom: 10 ,borderRadius: 100}} />
              <div style={{paddingLeft:10,paddingTop:5}}>
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              </div>
            
            {/* </Col> */}
            </Col>
            <Col md={12} style={{display:'flex',paddingTop:10}}>
            {/* <Col md={4} style={{ textAlign: "center" }}> */}
              <Skeleton variant="circle" height={80} width={80} style={{ marginBottom: 10 ,borderRadius: 100}} />
              <div style={{paddingLeft:10,paddingTop:5}}>
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              </div>
            
            {/* </Col> */}
            </Col>
            <Col md={12} style={{display:'flex',paddingTop:10}}>
            {/* <Col md={4} style={{ textAlign: "center" }}> */}
              <Skeleton variant="circle" height={80} width={80} style={{ marginBottom: 10 ,borderRadius: 100}} />
              <div style={{paddingLeft:10,paddingTop:5}}>
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              <Skeleton height={20} width={120} style={{ marginBottom: 5 }}   />
              </div>
            
            {/* </Col> */}
            </Col>
          
          </Row>
        </Col>
        <Col md={8}>
        <Skeleton variant="rectangular" height={300} />

        </Col>
      </Row>
      
    </Col>
   
  </Row>
)
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={10} sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
} 
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LiveLocation = ({props}) => {
  //meta title
  document.title = `Track Location | ${process.env.REACT_APP_NAME}`
  const [alllocation, setAlllocation] = useState(false)
  const [mockData2, setMockData] = useState([])
  const [attendanceData, setAttendanceData] = useState([])
  const [absentData, setAbsentData] = useState([])
  const [filterPresent, setFilterPresent] = useState([])
  const [filterAbsent, setFilterAbsent] = useState([])
  const [showPlaceHolder, setShowPlaceHolder] = useState(true)
  const [total, setTotal] = useState(0)
  const [absent, setAbsent] = useState(0)
  const [present, setPresent] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [companyLat, setCompanyLat] = useState(null)
  const [companyLong, setCompanyLong] = useState(null)
  const [otherModeON, setotherModeON] = useState(false)
  const [modal_large, setmodal_large] = useState(false)
  const [coordinates, setCoordinate]= useState([])
  const [distanceCovered, setdistanceCovered]= useState(0)
  const [company_Name, setCompanyName] = useState("")
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedMarkerData, setSelectedMarkerData] = useState({})
  const [infoWindowVisible, setInfoWindowVisible] = useState(false)
  const [activeMarker, setActiveMarker] = useState({})
  const [circleGeo, setCircleGeo] = useState(null)
  const [googleKey, setGoogleKey] = useState('')
  function getCurrentDate() {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const day = currentDate.getDate().toString().padStart(2, '0');
      const formattedDate = year + '-' + month + '-' + day;
  
      return formattedDate;
  }
  function tog_large() {
    setmodal_large(!modal_large);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const fetchCompaines = async () => {
    setShowPlaceHolder(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)

    axios
      .post(process.env.REACT_APP_LEAVE_API_URL+"/company.php", formdata)
      .then(result => {
        if (result.data.length !== 0) {
          let array = []
          result.data.map(e => {
            let val = {
              value: e.id,
              label: e.location,
            }
            array.push(val)
          })
          setSelectedLocation(array[0].label)
          fetchEmployee(array[0].value)
          setMockData(array)
        } else {
          setMockData([])
        }
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    
    fetchCompaines()
  }, [])
  const fetchEmployee = async id => {
    setShowPlaceHolder(true)
    var formdata = new FormData()
    formdata.append("method", "fetch_company_by_id")
    formdata.append("id", id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/company.php", requestOptions)
      .then(response => response.json())
      .then(e => {
        setShowPlaceHolder(false)
        setAttendanceData(e.present_data)
        setFilterPresent(e.present_data)
        setAbsentData(e.absent_data)
        setFilterAbsent(e.absent_data)
        setPresent(e.present)
        setAbsent(e.absent)
        setCompanyLat(e.c_lat)
        setCompanyLong(e.c_long)
        setCompanyName(e.company_name)
        setTotal(e.total)
        setCircleGeo(e.geofence)
      })
      .catch(error => {
        // this.setState({isLoading:false});
        console.log("error", error)
      })
  }

  const handleDropdownItemClick = (val, id) => {
    setSelectedLocation(val)
    fetchEmployee(id)
    setAlllocation(false)
  }
  const onMouseoverMarker = (props, marker, e) => {
    console.log(props)
    console.log(marker);
    console.log(e)
    setSelectedMarkerData(props)
    setInfoWindowVisible(true)
    setActiveMarker(marker)
    console.log(e)
  }
  const onCloseClick = props => {
    if (infoWindowVisible) {
      setInfoWindowVisible(false)
      setActiveMarker({})
    }
  }
  const convertTime = inputTime => {
    if (inputTime !== 0) {
      const [hours, minutes, seconds] = inputTime.split(":")
      const dateObj = new Date()
      dateObj.setHours(hours)
      dateObj.setMinutes(minutes)
      dateObj.setSeconds(seconds)
      const formattedTime = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })

      return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
    } else {
      return "N/a"
    }
  }

const currentDate = getCurrentDate();
function formatTimeString(dateTimeString) {
    // Convert dateTimeString to a Date object
    const date = new Date(dateTimeString);

    // Obtain the time string in 12-hour format with minutes padded with leading zero
    const timeString = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

    // Extract hour and minute parts
    const [hourMinute, amPm] = timeString.split(' ');
    const [hour, minute] = hourMinute.split(':');

    // Return the formatted time string
    return `${hour}:${minute} ${amPm.toLowerCase()}`;
}
const [singleItem, setSingleItem ] = useState({
  id:'',
  first_name:'',
  last_name:'',
  profile_image:'',
  role:'',
  check_in_location:'',
  check_out_location: '',
  check_out_time: '',
  check_in_time:'',
})
const ShowMapsLocationForCurrentEmployee = (item) =>{
  // setotherModeON(true);

  console.log(item)
}
const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setActiveTab(newValue);
};
const callFilterFunction = (text)=>{
  if(activeTab == 0){
    let arrayNew = []
    arrayNew = attendanceData;
    if (text.length !== 0) {
      const newData = filterPresent.filter(item => {
        return item.first_name.toLowerCase().includes(text.toLowerCase()) || 
        item.last_name.toLowerCase().includes(text.toLowerCase())  || item.department.toLowerCase().includes(text.toLowerCase()) || item.job_title.toLowerCase().includes(text.toLowerCase());
    });
      setAttendanceData(newData)
    }else{
      setAttendanceData(filterPresent)
  }
}else{
    if (text.length !== 0) {
      const newData = filterAbsent.filter(item => {
        return item.first_name.toLowerCase().includes(text.toLowerCase()) || 
        item.last_name.toLowerCase().includes(text.toLowerCase())  || item.department.toLowerCase().includes(text.toLowerCase()) || item.job_title.toLowerCase().includes(text.toLowerCase());
    });
      setAbsentData(newData)
    }else{
     setAbsentData(filterAbsent)
   }


  }
}

function getCurrentDate() {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1 and pad with '0' if necessary
  const day = String(today.getDate()).padStart(2, '0'); // Pad day with '0' if necessary
  return `${year}-${month}-${day}`;
}
const [employeeLat, setEmployeeLat] = useState({
  initialLat:0,
  initialLong:0,
  

})

const fetchDirections = async (passingId) => {
  
 
  const date = getCurrentDate()
  var formdata = new FormData();
  formdata.append('method', 'get-user-tracking');
  formdata.append('emp_id', passingId);
  formdata.append('date_by', date);
  console.log(date)
  var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow',
  };
  await fetch('https://api.frontforcecrm.com/tracking.php', requestOptions)
    .then(response => response.json())
    .then(async result => {
      setmodal_large(true);
      const coordinates = result.map(item => ({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
      }));
      const origin = `${coordinates[0].lat},${coordinates[0].lng}`;
      const destination = `${coordinates[coordinates.length - 1].lat},${
        coordinates[coordinates.length - 1].lng
      }`;
  
      // this.setState({latitude: coordinates[0].latitude, markerLat: coordinates[coordinates.length - 1].latitude,markerLong: coordinates[coordinates.length - 1].longitude});
      // this.setState({longitude: coordinates[0].longitude});
     console.log(coordinates)
      setCoordinate(coordinates)
      let distanceCovered = 0;
      for (let i = 1; i < coordinates.length; i++) {
        distanceCovered += calculateDistance(
          coordinates[i - 1],
          coordinates[i],
        );
      }
      setdistanceCovered(distanceCovered)
     
      // const apiKey = 'AIzaSyAIiEgVMMUA5gil8H1mRK3y7WrMcghm8jY'; // Replace with your Google Maps API key
      // try {
      //   const response = await axios.get(
      //     `https://maps.googleapis.com/maps/api/directions/json?origin=${origin}&destination=${destination}&key=${apiKey}`,{
      //       headers: {
      //         'Access-Control-Allow-Origin': '*', // Allow requests from any origin
      //           'Content-Type': 'application/json'
      //       }
      //     }
      //   );
      //   const points = response.data.routes[0].overview_polyline.points;
      //   const decodedPoints = decode(points);
      //   const routeCoordinates = decodedPoints.map(point => ({
      //     latitude: point[0],
      //     longitude: point[1],
      //   }));
      //   // setCoordinate(routeCoordinates)
      //   console.log(routeCoordinates);

       
      // } catch (error) {
      //   console.error('Error fetching directions:', error);
      // }
    })
    .catch(error => console.log('error', error));
};
const deg2rad = deg => {
  return deg * (Math.PI / 180);
};
const calculateDistance = (coord1, coord2) => {
  const R = 6371; // Earth radius in kilometers
  const dLat = deg2rad(coord2.lat - coord1.lat);
  const dLon = deg2rad(coord2.lng - coord1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coord1.lat)) *
      Math.cos(deg2rad(coord2.lat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c * 1000; // Convert to meters
    return distance;
};
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Track Location" />

          {showPlaceHolder == true ? <SkeletonRow /> : null}
          {showPlaceHolder == false ? (
            <div>
              <Row>
                <Col md={12} style={{ marginBottom: 20 }}>
                  <Dropdown
                    isOpen={alllocation}
                    toggle={() => setAlllocation(!alllocation)}
                  >
                    <DropdownToggle
                      tag="button"
                      className="btn-sm btn btn-light"
                    >
                      {selectedLocation ? selectedLocation : "All location"}

                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {mockData2.map((e, index) => {
                        return (
                          <DropdownItem
                            onClick={() =>
                              handleDropdownItemClick(e.label, e.value)
                            }
                            key={index}
                          >
                            {e.label}
                          </DropdownItem>
                        )
                      })}

                      {/* <DropdownItem>This Week</DropdownItem> */}
                    </DropdownMenu>
                  </Dropdown>
                </Col>
              </Row>

              <Row>
                <Col md={4}>

                    <Row>
                      <Col md="12">
                        <Label>
                          Search 
                        </Label>
                        <Input
                        type="text"
                        // className="form"
                        placeholder="By Name or Department"
                        onChange={(e)=>callFilterFunction(e.target.value)}
                        
                        />
                      </Col>
                    <Col md={12} >
                     <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={activeTab} onChange={handleChange} aria-label="frontpintabs">
                      <Tab label={`Present (${attendanceData.length})`} {...a11yProps(0)} />
                      <Tab label={`Absent (${absentData.length})`} {...a11yProps(1)} />
                    </Tabs>
                      </Box>
                     
                      <div style={{ height: '500px', overflowY: 'scroll',scrollbarWidth: 'none' }}>
                      <CustomTabPanel value={activeTab} index={0}>
                            {
                              attendanceData.map((e,index)=>{
                                return (
      
                            <Card key={index} onClick={()=>ShowMapsLocationForCurrentEmployee(e)}>
                              <CardBody >
                                <Row style={{columnGap:10}}>
                                  <Col md={3}>
                                    <img src={e.profile_image} className="avatar-md rounded-circle"/>
                                  </Col>
                                  <Col md={8}>
                                    <p style={{margin:0}}>{e.first_name} {e.last_name}</p>
                                    <p >{e.department}</p>
                                  {
                                    e.tracking_status == "true" ?

                                    <Badge style={{cursor:'pointer'}} onClick={()=>fetchDirections(e.id)} color="primary">Track</Badge>
                                    :null
                                  }
                                    <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Clock IN</span> {e.check_in_time ? formatTimeString(e.check_in_time) : 'N/a'}</p>
                                    <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Clock OUT</span> {e.check_out_time ? formatTimeString(e.check_out_time) : 'N/a'}</p>
                                  </Col>
                                </Row>
                                
                              </CardBody>

                            </Card>
                                );
                              })
                            }
                      </CustomTabPanel>
                      <CustomTabPanel value={activeTab} index={1}>
                            {
                              absentData.map((e,index)=>{
                                return (
      
                            <Card key={index} onClick={()=>ShowMapsLocationForCurrentEmployee(e)}>
                              <CardBody >
                                <Row style={{columnGap:10}}>
                                    <Col md={3}>
                                      <img src={e.profile_image} style={{objectFit:'cover'}} className="avatar-md rounded-circle"/>
                                    </Col>
                                  <Col md={8}>
                                    <p style={{margin:0}}>{e.first_name} {e.last_name}</p>
                                    <p >{e.department}</p>
                                    <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Clock IN</span> {e.check_in_time ? formatTimeString(e.check_in_time) : 'N/a'}</p>
                                    <p style={{margin:0}}><span style={{fontWeight:'bold'}}>Clock OUT</span> {e.check_out_time ? formatTimeString(e.check_out_time) : 'N/a'}</p>
                                  </Col>
                                </Row>
                                
                              </CardBody>

                            </Card>
                                );
                              })
                            }
                      </CustomTabPanel>
                      </div>
                    </Col>
                  </Row>
                 
                </Col>
           
                <Col lg={8}>
                  {companyLat !== null && companyLong !== null ?  (
                    // <Card  style={{height: '500px'}}>
                    //   <CardBody>
                        <div
                          id="gmaps-markers"
                          className="gmaps"
                          style={{ position: "relative" }}
                        >
                         
                         <Map
                            
                            onClick={onCloseClick}
                            initialCenter={{
                              lat: parseFloat(companyLat),
                              lng: parseFloat(companyLong),
                            }}
                          
                            google={google}
                            style={{ width: "100%", height: "550px" }}
                            zoom={13}
                          >
                            <Marker
                              title={`${company_Name}`}
                              position={{
                                lat: parseFloat(companyLat),
                                lng: parseFloat(companyLong),
                              }}
                            />
                            <Circle
                              center={{
                                lat: parseFloat(companyLat),
                                lng: parseFloat(companyLong),
                              }} // Set center of the circle
                              radius={parseFloat(circleGeo)} // Set radius of the circle in meters
                              strokeColor="red" // Set stroke color
                              strokeOpacity={0.8} // Set stroke opacity
                              strokeWeight={2} // Set stroke weight
                              fillColor="red" // Set fill color
                              fillOpacity={0.35} // Set fill opacity
                            />
                            {attendanceData.map((e, index) => {
                              let arrayDefined = e.check_in_location.split(",")
                              console.log(e.profile_image)
                              let value = []
                              if (e.check_in_time !== null) {
                                value = e.check_in_time.split(" ")
                              }
                              let valuez = []
                              if (e.check_out_time !== null) {
                                valuez = e.check_out_time.split(" ")
                              }
                              let firstIn = convertTime(
                                value.length !== 0 ? value[1] : 0
                              )
                              let lastOut = convertTime(
                                valuez.length !== 0 ? valuez[1] : 0
                              )
                              return (
                                <Marker
                                  key={index}
                                  profile={e.profile_image}
                                  lat = {parseFloat(arrayDefined[0])}
                                  long = {parseFloat(arrayDefined[1])}
                                  onClick={onMouseoverMarker}
                                  title={e.first_name}
                                  checkIn={firstIn}
                                  checkOut={lastOut}
                                  role={e.role}
                                  position={{
                                    lat: parseFloat(arrayDefined[0]),
                                    lng: parseFloat(arrayDefined[1]),
                                  }}
                                />
                              )
                            })}

                            <InfoWindow
                              visible={infoWindowVisible}
                              marker={activeMarker}
                              position={{
                                lat: parseFloat(selectedMarkerData.lat),
                                lng: parseFloat(selectedMarkerData.long),
                              }}
                            >
                              <div style={{ overflowX: "hidden" }}>
                                <Row>
                                  <Col md={2}>
                                    <img
                                      style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 100,
                                      }}
                                      src={selectedMarkerData.profile}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <span
                                      style={{
                                        fontSize: 18,
                                        fontWeight: "500",
                                      }}
                                    >
                                      {selectedMarkerData.title}
                                    </span>
                                    <br></br>
                                    <span style={{ fontSize: 14 }}>
                                      {selectedMarkerData.role}
                                    </span>
                                  </Col>
                                  <Col md={5}>
                                    <Row>
                                      <span>
                                        Check In : {selectedMarkerData.checkIn}
                                      </span>
                                      <span>
                                        Check Out :{" "}
                                        {selectedMarkerData.checkOut}
                                      </span>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </InfoWindow>
                          </Map>
                        </div>
                  // </CardBody>
                   //</Row> </Card> 
                  ) : null}
                </Col>
               
              </Row>
            </div>
          ) : null}
        </div>

      </div>
      
                   <Modal
                      size="xl"
                      isOpen={modal_large}
                      toggle={() => {
                        tog_large();
                      }}
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                          Distance covered {Math.round(distanceCovered)  <= 1000 ? Math.round(distanceCovered) + ' meters'  : Math.round(distanceCovered/1000) + ' KM'}
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_large(false);
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body" style={{ height: '550px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', maxWidth: '100%', position: 'relative', height: '100%', maxHeight: '100%' }}>
                      
                         {
                          coordinates.length == 0 ? 
                          null: 
                          <Map
                            initialCenter={{
                              lat: parseFloat(coordinates[0].lat),
                              lng: parseFloat(coordinates[0].lng),
                            }}
                            google={google}
                            style={{ width: '100%', height: '100%' }}
                            zoom={15}
                          >
                            <Marker
                            // label={}
                            title="Check In"

                            lat ={parseFloat(coordinates[0].lat)}
                            lng={parseFloat(coordinates[0].lng)}
                            
                            />
                             <Polyline
                              path={coordinates}
                              strokeColor="#0000FF" // Color of the line
                              strokeOpacity={0.8} // Opacity of the line
                              strokeWeight={3} // Width of the line
                            />
                          </Map>
                         }
                        </div>
                      </div>
                    </Modal>
                    <ChatWidget/>
    </React.Fragment>
  )
}

LiveLocation.propTypes = {
  google: PropTypes.object,
}


export default connect(
  null,
  {}
)(GoogleApiWrapper({
  apiKey: "AIzaSyBcmiuSTdNiLIfKu4gfuJTh9GmZacB37eU", // Use the dynamically fetched API key
  LoadingContainer: LoadingContainer,
})(LiveLocation))

