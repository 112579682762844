import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Table,
  Form,
  CardTitle,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap"
import classnames from "classnames"
import Select from "react-select"

import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"
import Switch from 'react-switch';

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import img3 from "../../assets/images/empty-state.3838ce81.svg"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import ChatWidget from "components/Common/ChatWidget"
const WorkSchedules = props => {
  //meta title
  document.title = "Work Schedule | FrontPin"

  const [activeTab, setActiveTab] = useState("1")
  const [addNewSection, setaddNewSection] = useState(false)
  const [editSectionView, setEditSectionView] = useState(false)
  const [scheduleData, setscheduleData] = useState([])
  const [noOfEmployees, setnoOfEmployees] = useState([])
  const [BreaksNo, setNoofBreaks] = useState(0)
  const [breakMintsArray, setbreakMintsArray] = useState([])
  const [currentActiveTab, setcurrentActiveTab] = useState("Location")
  const [filterFetch, setfilterFetch] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [itemData, setItemData] = useState([])
  const [PlaceholderShown, setPlaceholderShown] = useState(true)

  //Edit ScreenVariables
  const [nameSchedule, setNameSchedule] = useState("")
  const [editnoOfEmployees, setEditnoOfEmployees] = useState([])
  const [EditBreaksNo, setEditNoofBreaks] = useState(0)
  const [breakMintsArrayEdit, setbreakMintsArrayEdit] = useState([])
  const [editcurrentActiveTab, setEditcurrentActiveTab] = useState("")
  const [editStartTime, setEditStartTime] = useState("")
  const [editEndTime, setEditEndTime] = useState("")
  const [EditlateIn, setEditLateIn] = useState("")
  const [EditEarlyOut, setEditEarlyOut] = useState("")
  const [editOverTime, setEditOvertime] = useState("")
  const [editselectedIds, setSelectedIdsEdit] = useState([])
  const [editselectedId, setSelectedIdEdit] = useState(null)
  const [showActive , setShowActive] = useState(null)
  const [editStatus , showEditStatus] = useState(false)
  const [DeleteSpinner , setDeleteSpinner] = useState(false)

  const getDataFromDatabase = async () => {
    let local = localStorage.getItem("authUser")
    let localmapingVal = JSON.parse(local)
    let org_id = localmapingVal.map(e => e.org_id)

    var formdata = new FormData()
    formdata.append("method", "schedule-list")
    formdata.append("org_id", org_id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/schedule.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setPlaceholderShown(false);
        setscheduleData(result)
        setTimeout(() => {
          if (scheduleData.length == 0) {
            setPlaceholderShown(false);
          }
        }, 1000);
    
      })
      .catch(error => console.log("error", error))
  }

  useEffect(() => {
    getDataFromDatabase()
    fetchLocations()
  }, [])

  const handleEmployeeSelection = value => {
    // Create a copy of the current state array
    const updatedEmployees = [...noOfEmployees]

    // Check if the value is already in the array
    const index = updatedEmployees.indexOf(value)

    // If the value is already in the array, remove it
    if (index !== -1) {
      updatedEmployees.splice(index, 1)
    } else {
      // If the value is not in the array, add it
      updatedEmployees.push(value)
    }

    // Update the state with the new array
    setnoOfEmployees(updatedEmployees)
  }
  const handleEmployeeSelectionEdit = value => {
    // Create a copy of the current state array
    const updatedEmployees = [...editnoOfEmployees]

    // Check if the value is already in the array
    const index = updatedEmployees.indexOf(value)

    // If the value is already in the array, remove it
    if (index !== -1) {
      updatedEmployees.splice(index, 1)
    } else {
      // If the value is not in the array, add it
      updatedEmployees.push(value)
    }

    // Update the state with the new array
    setEditnoOfEmployees(updatedEmployees)
  }
  const radioData = [
    { label: "M", value: "Mon" },
    { label: "T", value: "Tue" },
    { label: "W", value: "Wed" },
    { label: "T", value: "Thu" },
    { label: "F", value: "Fri" },
    { label: "S", value: "Sat" },
    { label: "S", value: "Sun" },
  ]
  const NoofBreaks = [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "6", value: "6" },
  ]
const RenderItemIwant = () => {
  return (
    <React.Fragment>
      {/* Render additional Text components based on noofBreaks */}
      {Array.from({ length: BreaksNo }, (_, index) => (
        <div key={index} style={{ paddingTop: 10, paddingBottom: 0 }}>
          <Input
            placeholder={`Break ${index + 1} in mints`}
            onChange={e => {
              const updatedBreakMintsArray = [...breakMintsArray]
              updatedBreakMintsArray[index] = e.target.value
              setbreakMintsArray(updatedBreakMintsArray)
            }}
            value={breakMintsArray[index]}
            className="form-control"
            type="number"
            min={0}
          />
        </div>
      ))}
    </React.Fragment>
  )
}
  const EditRenderItemIwant = () => {
    return (
      <React.Fragment>
        {/* Render additional Text components based on noofBreaks */}
        {Array.from({ length: EditBreaksNo }, (_, index) => (
          <div key={index} style={{ paddingTop: 10, paddingBottom: 0 }}>
            <Input
              placeholder={`Break ${index + 1} in mints`}
              onChange={e => {
                const updatedBreakMintsArray = [...breakMintsArrayEdit]
                updatedBreakMintsArray[index] = e.target.value
                setbreakMintsArrayEdit(updatedBreakMintsArray)
              }}
              value={breakMintsArrayEdit[index]}
              className="form-control"
              type="number"
              min={0}
            />
          </div>
        ))}
      </React.Fragment>
    )
  }
  const fetchEmployee = async () => {
    setfilterFetch([])
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "fetch_employees-desktop")

    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setfilterFetch(result)
        // setisLoading(false)
        // setfilterFetch(prevData => prevData.concat(result))
        //    setData(prevDatas => prevDatas.concat(result))
        // result.map((e)=>{
        //   Alert.alert(JSON.stringify(e))
        //   filteredData.push(e);
        // })
      })
      .catch(error => console.log("error", error))
  }
  const getAllActivities = async () => {
    setfilterFetch([])
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let org = localArray.map(e => e.org_id)

    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/departments.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setfilterFetch(result)
        // setisLoading(false)
      })
      .catch(error => console.log("error", error))
  }
  const fetchLocations = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let val = localArray.map(e => e.user_id)
    let org_id = localArray.map(e => e.org_id)
    let id = localArray.map(e => e.role)

    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", id)
    formdata.append("emp_id", val)
    formdata.append("org_id", org_id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/company.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setfilterFetch(result)
      })
      .catch(error => console.log("error", error))
  }
  const handleCheckboxPress = itemId => {
    if (selectedIds.includes(itemId)) {
      setSelectedIds(selectedIds.filter(id => id !== itemId))
    } else {
      setSelectedIds([...selectedIds, itemId])
    }
  }
  const handleCheckboxPressEdit = itemId => {
    if (editselectedIds.includes(itemId)) {
      setSelectedIds(editselectedIds.filter(id => id !== itemId))
    } else {
      setSelectedIdsEdit([...editselectedIds, itemId])
    }
  }
  const clearAll = () => {
    setSelectedIds([])
  }
  const convertTo12HourFormat = time => {
    // Parse the time string
    const [hours, minutes] = time.split(":").map(Number)

    // Determine the period (AM or PM)
    const period = hours < 12 ? "am" : "pm"

    // Convert hours to 12-hour format
    const hours12 = hours % 12 || 12

    // Format the time in 12-hour format
    return `${hours12}:${minutes.toString().padStart(2, "0")} ${period}`
  }
  const HandleSaveScheduleIntoDB = async event => {
    event.preventDefault()

    if (event.target.elements.schedule_name.value == "") {
      toastr.error("Schedule name is required")
      return false
    } else if (noOfEmployees.length == 0) {
      toastr.error("Select the working days")
      return false
    } else if (event.target.elements.start_time.value == "") {
      toastr.error("Schedule start time is required")
      return false
    } else if (event.target.elements.end_time.value == "") {
      toastr.error("Schedule end time is required")
      return false
    } else if (event.target.elements.late_in_mints.value == "") {
      toastr.error("Late in mints is required")
      return false
    } else if (event.target.elements.early_mints.value == "") {
      toastr.error("Early out mints is required")
      return false
    } else if (event.target.elements.overtime_mints.value == "") {
      toastr.error("Overtime mints is required")
      return false
    } else if (BreaksNo === 0 || BreaksNo == "") {
      toastr.error("Select the number of break")
      return false
    } else if (breakMintsArray.length == 0) {
      toastr.error("Add break mints")
      return false
    } else if (selectedIds.length === 0) {
      toastr.error(
        "Please select the department, location or individual member to apply this schedule"
      )
      return false
    } else {
      setIsLoading(true)
      let local = localStorage.getItem("authUser")
      let localmapingVal = JSON.parse(local)
      let org_id = localmapingVal.map(e => e.org_id)
      let form = new FormData()

      form.append("method", "create-schedule")
      form.append(
        "type",
        currentActiveTab == "Location" ? "Locations" : currentActiveTab
      )
      form.append("org_id", org_id)
      form.append("selectedIds", selectedIds.toString())
      form.append("schedule_name", event.target.elements.schedule_name.value)
      form.append("work_days", noOfEmployees.toString())
      form.append(
        "startTime",
        convertTo12HourFormat(event.target.elements.start_time.value)
      )
      form.append(
        "endTime",
        convertTo12HourFormat(event.target.elements.end_time.value)
      )
      form.append("noOfBreaks", BreaksNo)
      form.append("breakMints", breakMintsArray.toString())
      form.append("lateInMargin", event.target.elements.late_in_mints.value)
      form.append("earlyOutMargin", event.target.elements.early_mints.value)
      form.append("overTimeMargin", event.target.elements.overtime_mints.value)
      form.append("status", "active")
      var requestOptions = {
        method: "POST",
        body: form,
        redirect: "follow",
      }
      await fetch(process.env.REACT_APP_LEAVE_API_URL+"/schedule.php", requestOptions)
        .then(response => response.text())
        .then(result => {
          setIsLoading(false)
          if (result === "success") {
            toastr.success("You successfully created a schedule")
            location.reload()
          } else {
            toastr.error(`${result}`)
            return false
          }
        })
        .catch(er => console.log(er))
    }
  }
  function convertTo24Hour(time12h) {
    // Extract hours, minutes, and am/pm from the time string
    const [time, modifier] = time12h.split(' ');
    const [hours, minutes] = time.split(':');
  
    // Convert hours to 24-hour format
    let hours24 = parseInt(hours, 10);
    if (hours24 === 12 && modifier.toUpperCase() === 'AM') {
      hours24 -= 12;
    } else if (hours24 !== 12 && modifier.toUpperCase() === 'PM') {
      hours24 += 12;
    }
  
    // Pad single-digit hours and minutes with leading zeros
    const paddedHours = hours24.toString().padStart(2, '0');
    const paddedMinutes = minutes.padStart(2, '0');
  
    // Return the time in 24-hour format
    return `${paddedHours}:${paddedMinutes}`;
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  const  onDeleteClick = ()=>{
    setDeleteSpinner(true)
    let localVar =  localStorage.getItem('authUser');
    let ParsingData = JSON.parse(localVar);
    let org_Id =  ParsingData.map((e)=>e.org_id)

    let form = new FormData();
    form.append('method' ,'delete-schedule');
    form.append('id_for',editselectedId);
    form.append('org_id', org_Id)
    var requestOptions = {
        method: 'POST',
        body: form,
        redirect: 'follow'
        };
        fetch(process.env.REACT_APP_LEAVE_API_URL+"/schedule.php", requestOptions)
        .then(response => response.text()).then((result)=>{
          setDeleteSpinner(false)
            if(result === 'success'){
               toastr.success('You successfully delete the schedule')
                getDataFromDatabase();
                setEditSectionView(false)
             
            }
        }).catch((er)=>console.log(er))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          {
            PlaceholderShown === true ? 
          <Row>
          <Col md={3}>
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                  <Skeleton height={40} width={180} style={{ marginBottom: 10 }} />
                  </Col>
                  <Col md={3}>
                    
                    <Skeleton height={20} width={100} style={{ marginBottom: 5 }}/>
                    <Skeleton height={20} width={100} />
                  </Col>
                 
                </Row>
                <Row style={{paddingTop:10}}>
                  <Col md={3}>
                    <Skeleton height={20} width={100} style={{ marginBottom: 5 }}/>
                    <Skeleton height={20} width={100} />
                  </Col>
                 
                </Row>
                <Row style={{paddingTop:10}}>
                  <Col md={3}>
                    <Skeleton height={20} width={100} style={{ marginBottom: 5 }}/>
                    <Skeleton height={20} width={100} />
                  </Col>
                 
                </Row>
              </CardBody>
            </Card>
          </Col>
          </Row>
           :
          null
          }
          {
            scheduleData.length === 0 && PlaceholderShown === false  ? 
            <Row className="pt-3">
            <Col xl="12">
              <div className="product-img position-relative">
                {/* <img
                  src={img3}
                  alt=""
                  className="img-fluid mx-auto d-block"
                /> */}
              </div>
              <div className="mt-4 text-center">
                <h5 className="mb-3 text-truncate">
                  Take attendance to the next level
                </h5>
              </div>
              <div className="text-muted mb-3 text-center">
                Boost timesheets with fixed or flexible work hours, overtime,
                breaks, and deductions for your team's compliance.
              </div>
              <div className="text-center">
                <Button
                  type="submit"
                  onClick={() => setaddNewSection(true)}
                  className="btn btn-warning"
                >
                  Add Your Work Schedule
                </Button>
              </div>
            </Col>
          </Row>:null
          }
          {scheduleData.length == 0  && PlaceholderShown == true ?  null : (
            <Row className="pt-3">
              <Col lg="12">
                <Row>
                  {
                    scheduleData.length == 0 ? null :
                  <Col xl="3">
                    <Card>
                      <CardBody>
                        <div className="text-center mb-3">
                          <Button
                            onClick={() => setaddNewSection(true)}
                            type="submit"
                            className="btn-sm btn-warning"
                          >
                            Add New Schedule
                          </Button>
                        </div>
                        {scheduleData.length == 0 ? (
                          <div>
                            <p>No schedule is added yet</p>
                          </div>
                        ) : null}
                        {scheduleData.map((e, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                padding: 10,
                                borderBottomWidth: 1,
                                borderColor: showActive == index ? '#8fe1ff' : "#f3f3f3",
                                borderStyle: "solid",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowActive(index)
                                setEditSectionView(true)
                                setItemData(e)
                                setNameSchedule(e.schedule_name)
                                setEditnoOfEmployees(e.work_days.split(","))
                                setEditStartTime(convertTo24Hour(e.start_time))
                                setEditEndTime(convertTo24Hour(e.end_time))
                                setEditLateIn(e.trady_margin)
                                setEditEarlyOut(e.early_out_margin)
                                setEditOvertime(e.overtime_margin)
                                setEditNoofBreaks(e.no_of_breaks)
                                setEditcurrentActiveTab(
                                  e.type === "Locations" ? "Location" : e.type
                                )
                                if(e.type == 'Locations'){
                                  fetchLocations();
                                }else if(e.type == 'Departments'){
                                  getAllActivities()
                                }else{
                                  fetchEmployee()
                                }
                                setSelectedIdsEdit(e.selected_Ids.split(","))
                                setbreakMintsArrayEdit(e.breaks_mints.split(","))
                                setSelectedIdEdit(e.id)
                                showEditStatus(e.status == 'active' ? true : false)
                              }}
                            >
                              <div>{e.schedule_name}</div>
                              <div>
                                {e.status === "active" ? "DEFAULT" : null}
                              </div>
                            </div>
                          )
                        })}
                      </CardBody>
                    </Card>
                  </Col>
                  }
                  {editSectionView == true ? (
                    <Col md={7}>
                      <Card>
                        <CardBody>
                         <Row>
                          <Col md={12}>
                              <Row>
                                <Col md={6}>

                              <h4>Schedule Setting</h4>
                              <p style={{fontSize:18}}>{nameSchedule}</p>
                                </Col>
                                <Col md={6} style={{textAlign:'end'}}>
                                  {
                                    DeleteSpinner == true ? <Spinner/>:
                                    <button onClick={()=>onDeleteClick()} className="btn btn-danger">Delete Schedule</button>
                                 } 
                                </Col>
                              </Row>
                          </Col>
                          <Col md={6}>
                            <p>Change Status</p>
                          </Col>
                          <Col md={6}>
                              <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-1 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              onChange={() => {
                             
                               
                                  let local =  localStorage.getItem('authUser');
                                let localmapingVal = JSON.parse(local);
                                let org_id = localmapingVal.map((e)=>e.org_id)
                                
                                  var formdata = new FormData();
                                  formdata.append("method", "change-default-status");
                                  formdata.append("org_id", org_id);
                                  formdata.append("id", editselectedId);
                                 
                                      formdata.append('status', editStatus == true ? 'in-active' : 'active');
                                  
                                  var requestOptions = {
                                  method: 'POST',
                                  body: formdata,
                                  redirect: 'follow'
                                  };
                                  fetch(process.env.REACT_APP_LEAVE_API_URL+"/schedule.php", requestOptions)
                                  .then(response => response.text())
                                  .then(result => {
                                    if(result == 'success'){
                                      toastr.success('You successfully update the status');
                                      showEditStatus(!editStatus)
                                      getDataFromDatabase();
                                    }else{
                                      toastr.error('Error comes while updating the status');
                                    }
                                  })
                                  .catch(error => console.log('error', error));
                                
                              }}
                              checked={editStatus}
                            />
                          </Col>
                         </Row>
                          <Form
                          onSubmit={(e)=>{
                            e.preventDefault();
                            toastr.warning('Under Development')
                          }}
                          >
                            <Row>
                              <Col md={12}>
                                <div className="md-3">
                                  <Label>Name</Label>
                                  <Input
                                    name="schedule_name"
                                    className="form-control"
                                    type="text"
                                    value={nameSchedule}
                                    onChange={e =>
                                      setNameSchedule(e.target.value)
                                    }
                                    style={{ zIndex: 2000 }}
                                  />
                                </div>
                              </Col>
                              <Col
                                md={12}
                                style={{ paddingTop: 20, paddingLeft: 15 }}
                              >
                                <Label>Select the working days</Label>
                                <Row style={{ paddingLeft: 15 }}>
                                  {radioData.map((item, index) => (
                                    <span
                                      className="col-md-1"
                                      key={index}
                                      onClick={() =>
                                        handleEmployeeSelectionEdit(item.value)
                                      }
                                      style={{
                                        background: editnoOfEmployees.includes(
                                          item.value
                                        )
                                          ? "#8fe1ff"
                                          : "white",

                                        padding: 10, // Add padding for better appearance
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        borderColor: "#8fe1ff",
                                      }}
                                    >
                                      {item.label}
                                    </span>
                                  ))}
                                </Row>
                              </Col>

                              <Col md={12} style={{ paddingTop: 10 }}>
                                <Row>
                                  <Col md={6}>
                                    <Label>Start Time</Label>
                                    <Input
                                      type="time"
                                      name="start_time"
                                      value={editStartTime}
                                      onChange={e =>
                                        setEditStartTime(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <Label>End Time</Label>
                                    <Input
                                      type="time"
                                      name="end_time"
                                      value={editEndTime}
                                      onChange={e =>
                                        setEditEndTime(e.target.value)
                                      }
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={12} style={{ paddingTop: 15 }}>
                                <Label>Schedule Timing</Label>
                              </Col>
                              <Col md={12}>
                                <Label>Late In</Label>
                                <Input
                                  type="number"
                                  min={1}
                                  placeholder="No in mints"
                                  name="late_in_mints"
                                  value={EditlateIn}
                                  onChange={e => setEditLateIn(e.target.value)}
                                  className="form-control"
                                />
                              </Col>
                              <Col md={12}>
                                <Label>Early Out</Label>
                                <Input
                                  type="number"
                                  min={1}
                                  placeholder="No in mints"
                                  name="early_mints"
                                  value={EditEarlyOut}
                                  onChange={e =>
                                    setEditEarlyOut(e.target.value)
                                  }
                                  className="form-control"
                                />
                              </Col>
                              <Col md={12}>
                                <Label>Overtime</Label>
                                <Input
                                  type="number"
                                  min={1}
                                  placeholder="No in mints"
                                  name="overtime_mints"
                                  value={editOverTime}
                                  onChange={e =>
                                    setEditOvertime(e.target.value)
                                  }
                                  className="form-control"
                                />
                              </Col>

                              <Col
                                md={12}
                                style={{ paddingTop: 20, paddingLeft: 15 }}
                              >
                                <Label>Select no of breaks</Label>
                                <Row style={{ paddingLeft: 15 }}>
                                  {NoofBreaks.map((item, index) => (
                                    <span
                                      className="col-md-1"
                                      key={index}
                                      onClick={() =>
                                        setEditNoofBreaks(item.value)
                                      }
                                      style={{
                                        background:
                                          EditBreaksNo == item.value
                                            ? "#8fe1ff"
                                            : "white",

                                        padding: 10, // Add padding for better appearance
                                        borderWidth: 1,
                                        borderStyle: "solid",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        borderColor: "#8fe1ff",
                                      }}
                                    >
                                      {item.label}
                                    </span>
                                  ))}
                                </Row>
                              </Col>
                              <Col md={12}>
                                <EditRenderItemIwant/>
                              </Col>
                              <Col md={12} style={{ paddingTop: 10 }}>
                                <Label>Select Implementation</Label>
                                <br></br>
                                <input
                                  type="radio"
                                  id="html1"
                                  className="form-check-input"
                                  value={"Location"}
                                  checked={
                                    editcurrentActiveTab === "Location"
                                      ? true
                                      : false
                                  }
                                  onChange={e => {
                                    setEditcurrentActiveTab(e.target.value)
                                    fetchLocations()
                                  }}
                                  name="assigned_type"
                                />
                                &nbsp;
                                <label htmlFor="html1">Location</label>
                                &nbsp;&nbsp;
                                <input
                                  type="radio"
                                  id="html2"
                                  className="form-check-input"
                                  value={"Departments"}
                                  checked={
                                    editcurrentActiveTab === "Departments"
                                      ? true
                                      : false
                                  }
                                  onChange={e => {
                                    setEditcurrentActiveTab(e.target.value)
                                    getAllActivities()
                                  }}
                                  name="assigned_type"
                                />
                                &nbsp;
                                <label htmlFor="html2">Departments</label>
                                &nbsp;&nbsp;
                                <input
                                  id="html3"
                                  type="radio"
                                  className="form-check-input"
                                  value={"Individual"}
                                  checked={
                                    editcurrentActiveTab === "Individual"
                                      ? true
                                      : false
                                  }
                                  onChange={e => {
                                    setEditcurrentActiveTab(e.target.value)
                                    fetchEmployee()
                                  }}
                                  name="assigned_type"
                                />
                                &nbsp;
                                <label htmlFor="html3">Individual</label>
                              </Col>
                              <Col md={12} style={{ paddingTop: 10 }}>
                                {filterFetch.map((item, index) => {
                                  if (editcurrentActiveTab === "Departments") {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() =>
                                          handleCheckboxPressEdit(item.id)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={
                                            editselectedIds.includes(item.id)
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleCheckboxPressEdit(item.id)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        <span>{item.name}</span>
                                      </div>
                                    )
                                  } else if (
                                    editcurrentActiveTab === "Location"
                                  ) {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() =>
                                          handleCheckboxPressEdit(item.id)
                                        }
                                      >
                                        <input
                                          type="checkbox"
                                          className="form-check-input"
                                          checked={
                                            editselectedIds.includes(item.id)
                                              ? true
                                              : false
                                          }
                                          onChange={() =>
                                            handleCheckboxPressEdit(item.id)
                                          }
                                        />
                                        &nbsp;&nbsp;
                                        <span>{item.location}</span>
                                      </div>
                                    )
                                  } else {
                                    return (
                                      <div
                                        key={index}
                                        onClick={() =>
                                          handleCheckboxPressEdit(item.id)
                                        }
                                      >
                                        <div
                                          style={{
                                            paddingHorizontal: 10,
                                            backgroundColor: "white",
                                            paddingVertical: 10,
                                          }}
                                        >
                                          <div
                                            style={{
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div
                                              style={{ flexDirection: "row" }}
                                            >
                                              <div
                                                style={{
                                                  justifyContent: "center",
                                                  paddingLeft: 10,
                                                  flexDirection: "row",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    flexDirection: "row",
                                                  }}
                                                >
                                                  <div
                                                    style={{
                                                      borderWidth: 0.5,
                                                      width: 35,
                                                      height: 35,
                                                      justifyContent: "center",
                                                      marginTop: 5,
                                                      marginRight: 10,
                                                    }}
                                                  >
                                                    <Input
                                                      type="checkbox"
                                                      className="form-check-input"
                                                      checked={
                                                        editselectedIds.includes(
                                                          item.id
                                                        )
                                                          ? true
                                                          : false
                                                      }
                                                      onChange={() =>
                                                        handleCheckboxPressEdit(
                                                          item.id
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                  <img
                                                    style={{
                                                      width: 50,
                                                      height: 50,
                                                      borderRadius: 100,
                                                    }}
                                                    src={item.profile_image}
                                                  />
                                                </div>
                                                <div
                                                  style={{
                                                    justifyContent: "center",
                                                    paddingLeft: 10,
                                                  }}
                                                >
                                                  <p>
                                                    {item.first_name}{" "}
                                                    {item.last_name}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div style={{justifyContent: 'center'}}>
                                                      <MaterialCommunityIcons name="chevron-right" size={25} />
                                                    </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </Col>
                              <Col
                                md={12}
                                style={{ paddingTop: 10, textAlign: "end" }}
                              >
                                {isLoading == false ? (
                                  <button
                                    type="submit"
                                    className="btn btn-primary"
                                  >
                                    Save Changes
                                  </button>
                                ) : (
                                  <Spinner />
                                )}
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  ) : null}
                </Row>
              </Col>
            </Row>
          )}
        </Container>
        <Offcanvas
          isOpen={addNewSection}
          direction="end"
          style={{ width: 500 }}
          toggle={() => setaddNewSection(!addNewSection)}
        >
          <OffcanvasHeader toggle={() => setaddNewSection(!addNewSection)}>
            Add Schedule
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form onSubmit={HandleSaveScheduleIntoDB}>
              <Row>
                <Col md={12}>
                  <div className="md-3">
                    <Label>Name</Label>
                    <Input
                      name="schedule_name"
                      className="form-control"
                      type="text"
                    />
                  </div>
                </Col>
                <Col md={12} style={{ paddingTop: 20, paddingLeft: 15 }}>
                  <Label>Select the working days</Label>
                  <Row style={{ paddingLeft: 15 }}>
                    {radioData.map((item, index) => (
                      <span
                        className="col-md-1"
                        key={index}
                        onClick={() => handleEmployeeSelection(item.value)}
                        style={{
                          background: noOfEmployees.includes(item.value)
                            ? "#8fe1ff"
                            : "white",

                          padding: 10, // Add padding for better appearance
                          borderWidth: 1,
                          borderStyle: "solid",
                          textAlign: "center",
                          cursor: "pointer",
                          borderColor: "#8fe1ff",
                        }}
                      >
                        {item.label}
                      </span>
                    ))}
                  </Row>
                </Col>

                <Col md={12} style={{ paddingTop: 10 }}>
                  <Row>
                    <Col md={6}>
                      <Label>Start Time</Label>
                      <Input
                        type="time"
                        name="start_time"
                        className="form-control"
                      />
                    </Col>
                    <Col md={6}>
                      <Label>End Time</Label>
                      <Input
                        type="time"
                        name="end_time"
                        className="form-control"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12} style={{ paddingTop: 15 }}>
                  <Label>Schedule Timing</Label>
                </Col>
                <Col className="mb-3" md={12}>
                  <Label>Late In</Label>
                  <Input
                    type="number"
                    min={1}
                    placeholder="No in mints"
                    name="late_in_mints"
                    className="form-control"
                  />
                </Col>
                <Col className="mb-3" md={12}>
                  <Label>Early Out</Label>
                  <Input
                    type="number"
                    min={1}
                    placeholder="No in mints"
                    name="early_mints"
                    className="form-control"
                  />
                </Col>
                <Col className="mb-3" md={12}>
                  <Label>Overtime</Label>
                  <Input
                    type="number"
                    min={1}
                    placeholder="No in mints"
                    name="overtime_mints"
                    className="form-control"
                  />
                </Col>

                <Col md={12} style={{ paddingTop: 20, paddingLeft: 15 }}>
                  <Label>Select no of breaks</Label>

                  <Row style={{ paddingLeft: 15 }}>
                    {NoofBreaks.map((item, index) => (
                      <span
                        className="col-md-1"
                        key={index}
                        onClick={() => setNoofBreaks(item.value)}
                        style={{
                          background:
                            BreaksNo == item.value ? "#8fe1ff" : "white",

                          padding: 10, // Add padding for better appearance
                          borderWidth: 1,
                          borderStyle: "solid",
                          textAlign: "center",
                          cursor: "pointer",
                          borderColor: "#8fe1ff",
                        }}
                      >
                        {item.label}
                      </span>
                    ))}
                  </Row>
                </Col>
                <Col md={12}>
                  <RenderItemIwant />
                </Col>
                <Col md={12} style={{ paddingTop: 10 }}>
                  <Label>Select Implementation</Label>
                  <br></br>
                  <input
                    type="radio"
                    id="html1"
                    className="form-check-input"
                    value={"Location"}
                    checked={currentActiveTab === "Location" ? true : false}
                    onChange={e => {
                      setcurrentActiveTab(e.target.value)
                      fetchLocations()
                    }}
                    name="assigned_type"
                  />
                  &nbsp;
                  <label htmlFor="html1">Location</label>&nbsp;&nbsp;
                  <input
                    type="radio"
                    id="html2"
                    className="form-check-input"
                    value={"Departments"}
                    checked={currentActiveTab === "Departments" ? true : false}
                    onChange={e => {
                      setcurrentActiveTab(e.target.value)
                      getAllActivities()
                    }}
                    name="assigned_type"
                  />
                  &nbsp;
                  <label htmlFor="html2">Departments</label>
                  &nbsp;&nbsp;
                  <input
                    id="html3"
                    type="radio"
                    className="form-check-input"
                    value={"Individual"}
                    checked={currentActiveTab === "Individual" ? true : false}
                    onChange={e => {
                      setcurrentActiveTab(e.target.value)
                      fetchEmployee()
                    }}
                    name="assigned_type"
                  />
                  &nbsp;
                  <label htmlFor="html3">Individual</label>
                </Col>
                <Col md={12} style={{ paddingTop: 10 }}>
                  {filterFetch.map((item, index) => {
                    if (currentActiveTab === "Departments") {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCheckboxPress(item.id)}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={
                              selectedIds.includes(item.id) ? true : false
                            }
                            onChange={() => handleCheckboxPress(item.id)}
                          />
                          &nbsp;&nbsp;
                          <span>{item.name}</span>
                        </div>
                      )
                    } else if (currentActiveTab === "Location") {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCheckboxPress(item.id)}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={
                              selectedIds.includes(item.id) ? true : false
                            }
                            onChange={() => handleCheckboxPress(item.id)}
                          />
                          &nbsp;&nbsp;
                          <span>{item.location}</span>
                        </div>
                      )
                    } else {
                      return (
                        <div
                          key={index}
                          onClick={() => handleCheckboxPress(item.id)}
                        >
                          <div
                            style={{
                              paddingHorizontal: 10,
                              backgroundColor: "white",
                              paddingVertical: 10,
                            }}
                          >
                            <div
                              style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ flexDirection: "row" }}>
                                <div
                                  style={{
                                    justifyContent: "center",
                                    paddingLeft: 10,
                                    flexDirection: "row",
                                  }}
                                >
                                  <div style={{ flexDirection: "row" }}>
                                    <div
                                      style={{
                                        borderWidth: 0.5,
                                        width: 35,
                                        height: 35,
                                        justifyContent: "center",
                                        marginTop: 5,
                                        marginRight: 10,
                                      }}
                                    >
                                      <Input
                                        type="checkbox"
                                        className="form-check-input"
                                        checked={
                                          selectedIds.includes(item.id)
                                            ? true
                                            : false
                                        }
                                        onChange={() =>
                                          handleCheckboxPress(item.id)
                                        }
                                      />
                                    </div>
                                    <img
                                      style={{
                                        width: 50,
                                        height: 50,
                                        borderRadius: 100,
                                      }}
                                      src={item.profile_image}
                                    />
                                  </div>
                                  <div
                                    style={{
                                      justifyContent: "center",
                                      paddingLeft: 10,
                                    }}
                                  >
                                    <p>
                                      {item.first_name} {item.last_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {/* <div style={{justifyContent: 'center'}}>
                                            <MaterialCommunityIcons name="chevron-right" size={25} />
                                          </div> */}
                            </div>
                          </div>
                        </div>
                      )
                    }
                  })}
                </Col>
                <Col md={12} style={{ paddingTop: 10, textAlign: "end" }}>
                  {isLoading == false ? (
                    <button type="submit" className="btn btn-primary">
                      Save Schedule
                    </button>
                  ) : (
                    <Spinner />
                  )}
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <ChatWidget/>
    </React.Fragment>
  )
}

export default WorkSchedules
