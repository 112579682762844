import React, { useEffect, useState, useMemo } from "react"
import PropTypes from "prop-types"
import * as XLSX from 'xlsx';

import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  CardTitle,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import profile from "../../assets/images/users/avatar-1.jpg"
import Select from "react-select"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
} from "../TimeOff/Timeoffdata"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import TableContainer from "../../components/Common/TableContainer"

import img2 from "../../assets/images/no-time-off.59f54e64.svg"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"
import { Link } from "react-router-dom";
import axios from "axios";
import { DataGrid, GridRowsProp, GridToolbar,GridColDef } from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Backdrop, Box, CircularProgress } from "@mui/material"
import Swal from "sweetalert2";
import ChatWidget from "components/Common/ChatWidget";

const Payroll = props => {
  //meta title
  document.title = `Payroll | ${process.env.REACT_APP_NAME}`
  function getCurrentDate() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") // January is 0!
    const yyyy = today.getFullYear()

    return `${yyyy}-${mm}-${dd}`
  }
  const currentDate = getCurrentDate()
  const [orders, onGetOrders] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [startDate, setStartDate] = useState(new Date(currentDate))
  const [selectCoin, setselectCoin] = useState(null)
  const [selectType, setselectType] = useState("Buy")
  const [selectStatus, setselectStatus] = useState("Completed")
  const [productData, setSetProductData] = useState([orders])
  const [timeoffmodal, setTimeoffmodal] = useState(false)
  const [taxModal, setTaxModal] = useState(false)
  const [editTaxModal, setEditTaxModal] = useState(false)
  const [spinnerForTax, setSpinnerForTax] = useState(false)
  const [timeoffmodalOther, setTimeoffmodalOther] = useState(false)
  const [leaveStartDate, setLeaveStartDate] = useState(new Date(currentDate))
  const [leaveEndDate, setLeaveEndDate] = useState(new Date(currentDate))
  const [selectedUser, setSelectedUser] = useState([])
  const [selectedMulti2, setselectedMulti2] = useState(null)
  const [selectedMulti23, setselectedMulti23] = useState(null)
  const [selectedMulti2Other, setselectedMulti2Other] = useState(null)
  const [selectedMulti23Other, setselectedMulti23Other] = useState(null)
  const [tableLoader, setTableLoader] = useState(true)
  const [activePayRollAmountPKR, setActivePayrollAmountPKR] = useState(0)
  const [lastPayRollAmountPKR, setLastPayrollAmountPKR] = useState(0)
  const [activePayRollAmountUSD, setActivePayrollAmountUSD] = useState(0)
  const [lastPayRollAmountUSD, setLastPayrollAmountUSD] = useState(0)
  const [minVal, setMinVal] = useState('')
  const [maxVal, setMaxVal] = useState('')
  const [open,setOpen]= useState(false)
  const [monthName, setMonthName ] = useState('');
  const [payRollStep, setPayRollStep] = useState({
    step1:true,
    step2:false,
    step3:false
  })
  const [payRollStepOther, setPayRollStepOther] = useState({
    step1:true,
    step2:false,
  })
  const addtimeModal = () => {
    setTimeoffmodal(!timeoffmodal)
  }
  const addtimeModalOther = () => {
    setTimeoffmodalOther(!timeoffmodalOther)
  }
 
  useEffect(() => {
    if(activeTab == "1"){
      fetchEmployee();
    }else{
      fetchPayrollSalarySlab()
    }
  }, [activeTab])

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  
 const [salarySlabData, setSalarySlabData] = useState([])
const fetchPayrollSalarySlab = ()=>{
  setSalarySlabData([])
  let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
  let form = new FormData();
  form.append('method' , 'fetch-tax')
  form.append('org_id' , org[0])
  axios.post(process.env.REACT_APP_LEAVE_API_URL + '/tax.php', form).then((res)=>{
    if(res.data.length !== 0 ){
      setSalarySlabData(res.data);
    }
    if(res.data.length == 0){
      setTableLoader(false)
    }

  })
}
const [singleTaxData,setSingleTaxData] = useState({
  id:'',
  range_1:'',
  range_2:'',
  currency:'',
  status:'',
  tax_value:''
})
const fetchSingleTax = (id)=>{
  let form = new FormData();
  form.append('method','fetch_single_by_id');
  form.append('fetch_id', id);
  axios.post(process.env.REACT_APP_LEAVE_API_URL + '/tax.php', form).then((res)=>{
    setSingleTaxData(res.data)
    setEditTaxModal(true)
  }).catch((error)=>console.log(error))
}
  // Table Data

  function getCurrentMonthAndYear() {
    
      const currentDate = new Date();
      // Get the month and year of the previous month
      let previousMonth = currentDate.getMonth(); // Zero-based index
      let previousYear = currentDate.getFullYear();
      if (previousMonth === 0) { // If the current month is January, adjust to December of the previous year
          previousMonth = 11; // December is represented as 11
          previousYear--;
      } else {
          previousMonth--; // Decrement the month by 1 to get the previous month
      }
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ]
      const monthAbbreviation = monthNames[previousMonth]
      // Convert the month to '01' to '12' format
      const formattedMonth = (previousMonth + 1).toString().padStart(2, '0'); // Add 1 as getMonth() returns zero-based index
      const formattedYear = previousYear.toString();
      return { month: formattedMonth, year: formattedYear,monthName : monthAbbreviation };
  
}
  const fetchEmployee = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    const {month,year,monthName} = getCurrentMonthAndYear()
    setMonthName(monthName)
    fetchEmployeeLastMonth(month,year,org)
    var formdata = new FormData()
    formdata.append("method", "fetch_payroll_list")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result)
        let salary_pkr = 0;
        let salary_usd = 0;
        result.map((e)=>{
              if(e.salary_currency == 'PKR'){
                  salary_pkr =  salary_pkr + e.basic_salary ;
                }else if(e.salary_currency  == 'USD'){
                  salary_usd =  salary_usd + e.basic_salary ;
              }
          })
          setActivePayrollAmountPKR(salary_pkr)
          setActivePayrollAmountUSD(salary_usd)
          
        if(result.length == 0){
          setTableLoader(false)
        }
        onGetOrders(result)
      })
      .catch(error => console.log("error", error))
  }
  function formatAmount(amount,currency) {
    return amount.toLocaleString('en-US', { style: 'currency', currency: currency, minimumFractionDigits: 2 });
}
function formatDateHiring(inputDate) {
    // Split the input date string into month, day, and year parts
    var parts = inputDate.split('/');
    var month = parseInt(parts[0], 10);
    var day = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Create a Date object using the parsed values
    var date = new Date(year, month - 1, day); // Subtracting 1 from month since it's zero-indexed

    // Get the day and month names
    var dayName = date.getDate();
    var monthName = date.toLocaleString('default', { month: 'short' });

    // Format the date as 'day MonthName year'
    var formattedDate = dayName + ' ' + monthName + ' ' + year;
    
    return formattedDate;
}
const fetchEmployeeLastMonth= (month,year,org)=>{
          function convertDateFormatMin(dateString) {
              // Split the date string into year and month parts
              const [year, month] = dateString.split('-');

              // Convert month to a two-digit format with leading zero if needed
              const formattedMonth = month.length === 1 ? '0' + month : month;

              // Create a new Date object using the year and month parts
              const date = new Date(year, formattedMonth - 1); // Month is 0-indexed in JavaScript

              // Get the month name from the Date object
              const monthName = date.toLocaleString('default', { month: 'short' });

              // Construct the new date format
              const newDateFormat = `${monthName} ${year}`;

              return newDateFormat;
          }
      const formdata = new FormData();
      formdata.append("method", "fetch_counter_last_payroll_list");
      formdata.append("month", month);
      formdata.append("year", year);
      formdata.append("org_id", org);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow"
      };

fetch(process.env.REACT_APP_LEAVE_API_URL+"/salary.php", requestOptions)
  .then((response) => response.json())
  .then((result) => {
  
    console.log(result);
    let amountPKR = 0;
    let amountUSD = 0;
    setMinVal(convertDateFormatMin(result.min))
    setMaxVal(convertDateFormatMin(result.max))
   
    result.response.map((einner)=>{
      einner.response.map((e)=>{
        if(e.schedule_off_status == 'Yes'){
          if(e.salary_currency == 'PKR' ){
              amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
            }else if(e.salary_currency  == 'USD'){
              amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
          }
        }else{
            if(e.salary_currency == 'PKR' ){
              amountPKR = amountPKR + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month)  - parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
            }else if(e.salary_currency  == 'USD'){
              amountUSD = amountUSD + parseFloat(e.basic_salary) - parseFloat(e.salary_for_this_month) -  parseFloat(e.unpaid) + parseFloat(e.amount_per_month) + parseFloat(e.amount_per_once) - parseFloat(e.loan_amount) - parseFloat(e.month_tax) + parseFloat(e.month_overtime) + parseFloat(e.month_incentive) + parseFloat(e.month_bonus) + parseFloat(e.month_commission) - parseFloat(e.advance_salary);
          }
        }
      })
      
  })
  setLastPayrollAmountPKR(amountPKR)
  setLastPayrollAmountUSD(amountUSD)
  })
  .catch((error) => console.error(error));
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}
const columnsFor: GridColDef[] = [
{ field: 'profile_image', headerName: 'Image', width: 100, renderCell:((param)=>(<img src={param.value} className="avatar-xs rounded-circle"/>))},
{ field: 'emp_id', headerName: 'Emp ID', width: 80,renderCell:((param)=>(<p>{param.value}</p>)) },
{ field: 'first_name', headerName: 'Full Name', width: 150 ,renderCell:((param)=>(<p>{param.value} {param.row.last_name}</p>))},
{ field: 'job_title', headerName: 'Designation', width: 150 },
{ field: 'job_category', headerName: 'Department', width: 150 },
{ field: 'super_name', headerName: 'Supervisor Name', width: 130 },
{ field: 'job_date', headerName: 'Hiring Date', width: 100,renderCell:((param)=>(<p>{formatDateHiring(param.value)}</p>)) },
{ field: 'basic_salary', headerName: 'Basic Salary', width: 130,renderCell:((param)=>
  (<div >{formatAmount(parseFloat(param.value) , param.row.salary_currency)}</div> )
) },
{ field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
  
  
  
  
  return (
    <ul className="list-unstyled hstack gap-1 mb-0">
      <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
     
      <Link to={`/payroll/viewslip/${param.row.emp_id}`} 
           className="btn btn-sm btn-soft-success">
            
          <i className="mdi mdi-file-outline" id="viewtooltip"></i>
        </Link>
      </li>
      <UncontrolledTooltip placement="top" target="viewtooltip">
        View Payroll
      </UncontrolledTooltip>
      
    </ul>
  
  )
}) }
];
const columnsForTax: GridColDef[] = [

{ field: 'range_1', headerName: 'Amount Range', width: 280,renderCell:((param)=>(<p>{formatAmount(parseFloat(param.value), param.row.currency)} -- {formatAmount(parseFloat(param.row.range_2), param.row.currency)}</p>)) },
{ field: 'currency', headerName: 'Currency', width: 280,renderCell:((param)=>(<p>{param.value}</p>)) },
{ field: 'tax_value', headerName: '% of Tax', width: 150 ,renderCell:((param)=>(<p>{param.value} {param.row.last_name}</p>))},
{ field: 'status', headerName: 'Status', width: 150 },

{ field: '', headerName: 'Actions', width: 150,renderCell:((param)=>{
  
  
  
  
  return (
    <ul className="list-unstyled hstack gap-1 mb-0">
      <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
     
      <button 
            onClick={()=>fetchSingleTax(param.row.id)}
           className="btn btn-sm btn-soft-primary">
            
          <i className="mdi mdi-pencil" id="viewtooltip"></i>
        </button>
      <UncontrolledTooltip placement="top" target="viewtooltip">
        Edit
      </UncontrolledTooltip>
      </li>
      <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
     
      <button 
          onClick={()=>deleteTaxSlab(param.row.id)}
           className="btn btn-sm btn-soft-danger">
            
          <i className="bx bx-trash " id="viewtooltips"></i>
        </button>
      <UncontrolledTooltip placement="top" target="viewtooltips">
        Delete
      </UncontrolledTooltip>
      </li>
      
    </ul>
  
  )
}) }
];

  const privilegeEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let leave = localArray.map(e => e.leave_right)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", leave)
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL+"/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        console.log(result)
        setSelectedUser(result)
      })
      .catch(er => {
        console.log(er)
      })
  }
  function handleMulti2(selectedMulti2) {
    setselectedMulti2(selectedMulti2)
  }
  function handleMulti23(selectedMulti23) {
    setselectedMulti23(selectedMulti23)
  }
  function handleMulti2Other(selectedMulti2) {
    setselectedMulti2Other(selectedMulti2)
  }
  function handleMulti23Other(selectedMulti23) {
    setselectedMulti23Other(selectedMulti23)
  }
  const goBackToStep1  =()=>{
    setPayRollStep({
        step1 : true,
        step2:false,
        step3:false
    })
  }
  const goBackToStep2  =()=>{
    setPayRollStep({
        step1 : false,
        step2:true,
        step3:false
    })
  }
  const goNextToStep1  =()=>{
    setPayRollStep({
        step1 : false,
        step2:true,
        step3:false
    })
  }
  const goNextToStep2  =()=>{
    setPayRollStep({
        step1 : false,
        step2:false,
        step3:true
    })
  }
  const handleFileUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      const requestData = {
        month: selectedMulti2.value,
        year: selectedMulti23.value
      };
      console.log(requestData);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const raw = {
        data: JSON.stringify(jsonData),
        requestData:JSON.stringify(requestData)
      };
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow"
      };
      
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/insert-salary-addons.php", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
      // Here you can send the JSON data to your API
      // Example:

      // fetch('your-api-endpoint', {

      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(jsonData),
      // })
      // .then(response => response.json())
      // .then(data => {
      //   console.log('Success:', data);
      // })
      // .catch((error) => {
      //   console.error('Error:', error);
      // });
    };

    reader.readAsArrayBuffer(file);
  };
  function getNext20YearsArray() {
    const currentYear = new Date().getFullYear();
    const yearsArray = [];

    for (let i = 0; i < 21; i++) { // Change loop condition to < 21 to include current year and next 20 years
        const year = currentYear + i;
        yearsArray.push({
            label: year.toString(),
            value: year.toString()
        });
    }

    return yearsArray;
}

const next20Years = getNext20YearsArray();
const [tableShown , setTableShown] = useState(false)

const GenerateReport =() =>{
  if(selectedMulti2Other == null || selectedMulti23Other == null){
    toastr.error('Please select the month & year');
    return false;
  }else if(selectedMulti23Other.value > new Date().getFullYear()){
    toastr.error('Selected year is greater than current');
    return false;
  }
  else{
    setOpen(true)
    let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
    const formdata = new FormData();
    formdata.append("method", "genrate_data");
    formdata.append("month", selectedMulti2Other.value);
    formdata.append("year", selectedMulti23Other.value);
    formdata.append("org_id", org[0]);
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow"
    };
   fetch(process.env.REACT_APP_LEAVE_API_URL + "/converted-excel.php", requestOptions)
  .then((response) => response.json())
  .then((result) => {
    setOpen(false)
    alert(result.url)
    if(result.message == "success"){
      const link = document.createElement('a');
      link.href = result.url;
      link.setAttribute('download', `salary-report-for-${selectedMulti2Other.value}-${selectedMulti23Other.value}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setTimeoffmodalOther(false)
    }else{
      Swal.fire({
        title:'Error while generating',
        text:'There is server error for create excel file. Please try again',
        icon:'error',
        confirmButtonColor: 'red',
      })
    }
  })
  .catch((error) => console.error(error));
  }





}
const deleteTaxSlab = (passing) =>{
  Swal.fire({
    title:'Confirmed',
    text:'Are you sure that you want to delete this tax. This process cannot undo.',
    confirmButtonText:'Yes, Please',
    confirmButtonColor:'green',
    showCancelButton:true,
    cancelButtonColor:'red',
    cancelButtonText:"No, I don't",
  }).then((is)=>{
    if(is.isConfirmed){
      let form = new FormData();
      form.append('method', 'delete_tax_value');
      form.append('fetch_id', passing);
      axios.post(process.env.REACT_APP_LEAVE_API_URL + '/tax.php', form).then((res)=>{
        if(res.data == 'success'){
          toastr.success('You successfully delete the tax value.','Success');
          fetchPayrollSalarySlab()
        }else{
          toastr.success('Error','Please try again');
          return false;
        }
      })
    }else{
      Swal.close();
    }
  })
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xl="6">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Overview
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    Payroll Tax
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col xl="6">
              <div className="float-end">
                <Button
                  type="button"
                  color="primary"
                  className="btn-sm w-md"
                  onClick={() => addtimeModalOther()}
                >
                  Generate Report
                </Button>
              </div>
              &nbsp;
              <div className="float-end">
                <Button
                  style={{ marginRight: 10 }}
                  type="button"
                  color="primary"
                  className="btn-sm w-md"
                  onClick={() => addtimeModal()}
                >
                  Import
                </Button>
              </div>
            </Col>
          </Row>
         
          <Row className="pt-3">
            <Col lg="12">
              <Card>
                <CardBody>
                    
                  <TabContent activeTab={activeTab} className="p-3">
                    <TabPane tabId="1">
                    <Row className="mt-4">
                         <Col lg={3}>
                              <Link to={"/payroll/active"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">Active Payroll</p>
                                        <h5 className="mb-2">
                                          {formatAmount(activePayRollAmountUSD , 'USD')}
                                        </h5>
                                        <h5 className="mb-0">
                                            {formatAmount(activePayRollAmountPKR , 'PKR')}
                                        </h5>
                                      
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bx-dollar"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                          <Col lg={3}>
                              <Link to={`/payroll/salarypaid`}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">Salaries Paid</p>
                                        
                                        <span className="mb-2">
                                           {minVal} - {maxVal}
                                        </span>
                                        <h5 className="mb-2 mt-1">
                                          {formatAmount(lastPayRollAmountUSD,'USD')}
                                        </h5>
                                        <h5 className="mb-0">
                                        {formatAmount(lastPayRollAmountPKR,'PKR')}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bx-dollar"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col> 
                            
          </Row>
                      <Row>
                        <Col md={12}>
                          
                          <Box sx={{ width: '100%' }}>
                          
                            <DataGrid
                                          
                            autoHeight
                            sx={{ '--DataGrid-overlayHeight': '300px' }}
                            slots={{
                            toolbar:  GridToolbar ,
                            noRowsOverlay: CustomNoRowsOverlay 
                            
                          }}
                          rows={orders} 
                          loading = { orders.length == 0 && tableLoader == true ? true : false}
                          columns={columnsFor}
                          getRowId={(row)=>row.emp_id}
                          />
                       
                         </Box>
                           </Col>
                        
                      </Row>
                   
                    </TabPane>
                    <TabPane tabId="2">
                      <Row className="justify-content-center ">
                        <Col md={12} className="mt-3 mb-3">
                          <button className="btn btn-primary" onClick={()=>setTaxModal(true)}>Add New Tax</button>
                        </Col>
                        <Col xl="12" >
                        <Box sx={{ width: '100%' }}>
                          
                          <DataGrid
                                              
                                autoHeight
                                sx={{ '--DataGrid-overlayHeight': '300px' }}
                                slots={{
                                toolbar:  GridToolbar ,
                                noRowsOverlay: CustomNoRowsOverlay 
                              }}
                              rows={salarySlabData} 
                              loading = { salarySlabData.length == 0 && tableLoader == true ? true : false}
                              columns={columnsForTax}
                              getRowId={(row)=>row.id}
                              />
                          
                            </Box>
                        </Col>
                      </Row>
                      <Modal
                    isOpen={taxModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                      setTaxModal(false)
                                  }}
                    >
                      <ModalHeader
                      toggle={()=>{
                          setTaxModal(false)
                        }}
                      />
                      <ModalBody>
                        <Form
                        onSubmit={(e)=>{
                          e.preventDefault();
                          if(e.target.elements.range_1.value == ''){
                            toastr.error('Range 1 is required');
                            return false;
                          }
                         else if(e.target.elements.range_2.value == ''){
                            toastr.error('Range 2 is required');
                            return false;
                          }
                          else if(e.target.elements.currency.value == 'null'){
                            toastr.error('Select the currency');
                            return false;
                          }
                          else if(e.target.elements.status.value == 'null'){
                            toastr.error('Select the status');
                            return false;
                          }
                          else if(e.target.elements.tax_value.value == ''){
                            toastr.error('Tax value is required');
                            return false;
                          }else{
                            let form = new FormData();
                            setSpinnerForTax(true)
                            let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
                            form.append('method', 'add-tax');
                            form.append('org_id', org[0]);
                            form.append('range_1', e.target.elements.range_1.value);
                            form.append('range_2', e.target.elements.range_2.value);
                            form.append('currency', e.target.elements.currency.value);
                            form.append('status', e.target.elements.status.value);
                            form.append('tax_value', e.target.elements.tax_value.value);
                            axios.post(process.env.REACT_APP_LEAVE_API_URL + '/tax.php', form).then((res)=>{
                              setSpinnerForTax(false);
                              if(res.data == 'success'){
                                toastr.success('Success','Your tax value is added');

                                e.target.elements.range_1.value = ''
                                e.target.elements.range_2.value = ''
                                e.target.elements.currency.value = ''
                                e.target.elements.status.value = ''
                                e.target.elements.tax_value.value = ''
                                setTaxModal(false)
                                fetchPayrollSalarySlab()
                              }else{
                                toastr.error('Error', 'Please check your input fields and Try again.');
                                return false;
                              }
                            })
                          }
                        }}
                        >

                        <Row>
                          <Col md="6" className="mb-2"> 
                          <Label>Range 1</Label>
                          <Input
                          type="text"
                          name="range_1"
                          placeholder=""

                          />
                          </Col>
                          <Col md="6" className="mb-2"> 
                          <Label>Range 2</Label>
                          <Input
                            type="text"
                            name="range_2"
                            placeholder=""
                          />
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Select Currency</Label>
                          <select className="form-control" name="currency">
                          <option value={'null'}>--SELECT CURRENCY--</option>
                          <option value={'PKR'}>PKR</option>
                          <option value={'USD'}>USD</option>
                         </select>
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Status</Label>
                         <select className="form-control" name="status">
                          <option value={'null'}>--SELECT STATUS--</option>
                          <option value={'active'}>Active</option>
                          <option value={'in_active'}>In-Active</option>
                         </select>
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Tax in % <small> (Do not include the % sign)</small></Label>
                          <Input
                           type="number"
                           name="tax_value"
                           min={1}
                          />
                          </Col>
                          <Col md="12" className="mb-2 mt-2"> 
                          {
                            spinnerForTax  == true ? 
                            <Spinner color="info"/>:
                           <button className="btn btn-primary" type="submit">
                              Submit
                           </button>
                          }
                           <span onClick={()=>setTaxModal(false)} className="btn btn-outline-danger" >
                              Close
                           </span>
                          </Col>
                        </Row>
                        </Form>
                      </ModalBody>
                    
                    </Modal>
                      <Modal
                    isOpen={editTaxModal}
                    role="dialog"
                    autoFocus={true}
                    centered={true}
                    className="exampleModal"
                    tabIndex="-1"
                    toggle={() => {
                      setEditTaxModal(false)
                          }}
                    >
                      <ModalHeader
                      title="Edit Payroll Tax"
                      toggle={()=>{
                        setEditTaxModal(false)
                        }}
                      />
                      <ModalBody>
                        <Form
                        onSubmit={(e)=>{
                          e.preventDefault();
                        
                            let form = new FormData();
                            setSpinnerForTax(true)
                            let org = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id);
                            form.append('method', 'update_single_by_id');
                            form.append('fetch_id',singleTaxData.id);
                            form.append('range_1', singleTaxData.range_1);
                            form.append('range_2', singleTaxData.range_2);
                            form.append('currency', singleTaxData.currency);
                            form.append('status', singleTaxData.status);
                            form.append('tax_value', singleTaxData.tax_value);
                            axios.post(process.env.REACT_APP_LEAVE_API_URL + '/tax.php', form).then((res)=>{
                               setSpinnerForTax(false);
                              if(res.data == 'success'){
                                toastr.success('Success','Your tax value is updated');
                                setEditTaxModal(false)
                                fetchPayrollSalarySlab()
                              }else{
                                toastr.error('Error', 'Please check your input fields and Try again.');
                              }
                            })
                          
                        }}
                        >

                        <Row>
                          <Col md="6" className="mb-2"> 
                          <Label>Range 1</Label>
                          <Input
                          type="text"
                          name="range_1"
                          placeholder=""
                          value={singleTaxData.range_1}
                          onChange={e =>
                            setSingleTaxData(prevState => ({
                              ...prevState,
                              range_1: e.target.value,
                            }))
                          }
                          />
                          </Col>
                          <Col md="6" className="mb-2"> 
                          <Label>Range 2</Label>
                          <Input
                            type="text"
                            name="range_2"
                            placeholder=""
                            value={singleTaxData.range_2}
                            onChange={e =>
                              setSingleTaxData(prevState => ({
                                ...prevState,
                                range_2: e.target.value,
                              }))
                            }
                          />
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Select Currency</Label>
                          <select className="form-control"
                           value={singleTaxData.currency}
                           onChange={e =>
                             setSingleTaxData(prevState => ({
                               ...prevState,
                               currency: e.target.value,
                             }))
                           }
                          name="currency">
                          <option value={'null'}>--SELECT CURRENCY--</option>
                          <option value={'PKR'}>PKR</option>
                          <option value={'USD'}>USD</option>
                         </select>
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Status</Label>
                         <select className="form-control" name="status"
                          value={singleTaxData.status}
                          onChange={e =>
                            setSingleTaxData(prevState => ({
                              ...prevState,
                              status: e.target.value,
                            }))
                          }
                         >
                          <option value={'null'}>--SELECT STATUS--</option>
                          <option value={'active'}>Active</option>
                          <option value={'in_active'}>In-Active</option>
                         </select>
                          </Col>
                          <Col md="12" className="mb-2"> 
                          <Label>Tax in % <small> (Do not include the % sign)</small></Label>
                          <Input
                           type="number"
                           name="tax_value"
                           min={1}
                           value={singleTaxData.tax_value}
                           onChange={e =>
                             setSingleTaxData(prevState => ({
                               ...prevState,
                               tax_value: e.target.value,
                             }))
                           }
                          />
                          </Col>
                          <Col md="12" className="mb-2 mt-2"> 
                          {
                            spinnerForTax  == true ? 
                            <Spinner color="info"/>:
                           <button className="btn btn-primary" type="submit">
                              Save Changes
                           </button>
                          }
                           <span style={{marginLeft:10}} onClick={()=>setEditTaxModal(false)} className="btn btn-outline-danger" >
                              Close
                           </span>
                          </Col>
                        </Row>
                        </Form>
                      </ModalBody>
                    
                    </Modal>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={timeoffmodal}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setTimeoffmodal(!timeoffmodal)
        }}
      >
         {
            payRollStep.step1 == true ? 
            <div>
        
            <ModalHeader
              toggle={() => {
                setTimeoffmodal(!timeoffmodal)
              }}
            >
              Generate Payroll
            </ModalHeader>
            <ModalBody>
              <Row>

                <Col className="col-6">
                  <div className="mb-3 ">
                    <Label>Select Month</Label>
                    <Select
                      name="month_for"
                      onChange={e => handleMulti2(e)}
                      options={[
                        { label: "Jan", value: "01" },
                        { label: "Feb", value: "02" },
                        { label: "Mar", value: "03" },
                        { label: "Apr", value: "04" },
                        { label: "May", value: "05" },
                        { label: "Jun", value: "06" },
                        { label: "Jul", value: "07" },
                        { label: "Aug", value: "08" },
                        { label: "Sep", value: "09" },
                        { label: "Oct", value: "10" },
                        { label: "Nov", value: "11" },
                        { label: "Dec", value: "12" }
                      ]}
                      value={selectedMulti2}
                      className="select2-selection"
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">

                    <Label>Select Year</Label>
                    <Select
                      name="year_for"
                      onChange={e => handleMulti23(e)}
                      options={next20Years}
                      value={selectedMulti23}
                      className="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setTimeoffmodal(!timeoffmodal)
                }}
              >
                Close
              </Button>
              <Button type="submit" onClick={()=>goNextToStep1()} color="primary">
                Next
              </Button>
            </ModalFooter>
          
        </div>:null
         }
         {
            payRollStep.step2 == true ? 
            <div>
        
            <ModalHeader
              toggle={() => {
                setTimeoffmodal(!timeoffmodal)
              }}
            >
              Select File
              <br></br>
              <small>Salary Addons</small>
              <br></br>
              <small style={{color: tableShown ? 'red' : 'green',cursor : 'pointer'}} onClick={()=>setTableShown(!tableShown)}>{tableShown == true ? 'Hide' : 'Show'} Format</small>
            </ModalHeader>
            <ModalBody>
              <Row>
              {
                    tableShown ==true ? 

                <Col className="col-12">
                  <div className="mb-3 ">
                    <table className="table">
                        <thead>
                            <th>
                                Employee IDs
                            </th>
                            <th>
                                Bouns
                            </th>
                            <th>
                                Commission
                            </th>
                            <th>
                                Incentive
                            </th>
                            <th>
                                Overtime
                            </th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1011
                                </td>
                                <td>
                                    100
                                </td>
                                <td>
                                    100
                                </td>
                                <td>
                                    100
                                </td>
                                <td>
                                    100
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </Col> : null
                }
                <Col className="col-12">
                  <div className="mb-3 ">
                    <Label>Select File xlsx foramt <a href="#">Download Template File</a></Label>
                    <br/>
                    <input type="file" onChange={handleFileUpload} className="form-control" />
                  </div>
                </Col>
            
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                   goBackToStep1();
                }}
              >
                Back
              </Button>
              <Button type="submit" onClick={()=>{
                goNextToStep2();
              }} color="primary">
                Next
              </Button>
            </ModalFooter>
          
        </div>:null
         }
         {
            payRollStep.step3 == true ? 
            <div>
        
            <ModalHeader
              toggle={() => {
                setTimeoffmodal(!timeoffmodal)
              }}
            >
              Select File
              <br></br>
              <small>Salary Deducations</small>
              <br></br>
              <small style={{color: tableShown ? 'red' : 'green',cursor : 'pointer'}} onClick={()=>setTableShown(!tableShown)}>{tableShown == true ? 'Hide' : 'Show'} format</small>
            </ModalHeader>
            <ModalBody>
              <Row>
              {
                    tableShown == true ? 

                <Col className="col-12">
                  <div className="mb-3 ">
                    <table className="table">
                        <thead>
                            <th>
                                Employee IDs
                            </th>
                          
                            <th>
                                Fine
                            </th>
                            <th>
                                Tax
                            </th>
                            <th>
                                Advance 
                            </th>
                            <th>
                                Loan
                            </th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    1011
                                </td>
                               
                                <td>
                                    1000
                                </td>
                                <td>
                                    100
                                </td>
                                <td>
                                    0
                                </td>
                                <td>
                                    0
                                </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </Col> : null
                }
                <Col className="col-12">
                  <div className="mb-3 ">
                    <Label>Select File xlsx foramt <a href="#">Download Template File</a></Label>
                    <br/>
                    <input type="file" onChange={handleFileUpload} className="form-control" />
                  </div>
                </Col>
            
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                   goBackToStep2();
                }}
              >
                Back
              </Button>
              <Button type="submit" color="primary">
                Submit
              </Button>
            </ModalFooter>
          
        </div>:null
         }
       
      </Modal>
      <Modal
        isOpen={timeoffmodalOther}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setTimeoffmodalOther(!timeoffmodalOther)
        }}
      >

            <ModalHeader
              toggle={() => {
                setTimeoffmodalOther(!timeoffmodalOther)  
              }}
            >
              Generate Payroll
            </ModalHeader>
            <ModalBody>
              <Row>

                <Col className="col-6">
                  <div className="mb-3 ">
                    <Label>Select Month</Label>
                    <Select
                      name="month_for"
                      onChange={e => handleMulti2Other(e)}
                      options={[
                        { label: "Jan", value: "01" },
                        { label: "Feb", value: "02" },
                        { label: "Mar", value: "03" },
                        { label: "Apr", value: "04" },
                        { label: "May", value: "05" },
                        { label: "Jun", value: "06" },
                        { label: "Jul", value: "07" },
                        { label: "Aug", value: "08" },
                        { label: "Sep", value: "09" },
                        { label: "Oct", value: "10" },
                        { label: "Nov", value: "11" },
                        { label: "Dec", value: "12" }
                      ]}
                      value={selectedMulti2Other}
                      className="select2-selection"
                    />
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="mb-3">

                    <Label>Select Year</Label>
                    <Select
                      name="year_for"
                      onChange={e => handleMulti23Other(e)}
                      options={next20Years}
                      value={selectedMulti23Other}
                      className="select2-selection"
                    />
                  </div>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setTimeoffmodalOther(!timeoffmodalOther)
                }}
              >
                Close
              </Button>
               <Button type="submit" onClick={()=>GenerateReport()} color="primary">
                Generate
              </Button>
            </ModalFooter>
      
        
       
      </Modal>
      <ChatWidget/>
    </React.Fragment>
  )
}

Payroll.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Payroll))
