import Activity from "pages/Dashboard-Blog/Activity"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Spinner,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Form,
  Input,
  Label,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Switch from "react-switch"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Select from "react-select"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import {} from "reactstrap"
import axios from "axios"
import ChatWidget from "components/Common/ChatWidget"

function convertDateToISO(dateString) {
  // Split the input date by '/'
  const [day, month, year] = dateString.split("/")

  // Return the date in ISO format (YYYY-MM-DD)
  return `${year}-${month}-${day}`
}
export default function TaskRemarks() {
  document.title = `Task Remarks | ${process.env.REACT_APP_NAME}`
  const [currentUserID, setCurrentUserID] = useState(null)
  const [currentUserRole, setCurrentUserRole] = useState(null)
  const [remarksList, setremarksList] = useState([])
  const [statusForFinishButton, setstatusForFinishButton] = useState(false)
  const [isAttachmentSwitch, setisAttachmentSwitch] = useState(false)
  const [iseditAttachmentSwitch, setiseditAttachmentSwitch] = useState(false)
  const [loadingSpinner, setloadingSpinner] = useState(false)
  const [assignTo, setassignTo] = useState([])
  const [editloadingSpinner, setEditloadingSpinner] = useState(false)
  const [editTaskLoader, setEditTaskLoader] = useState(false)
  const [createTaskShown, setCreateTaskShown] = useState(false)
  const [createEditTaskShown, setCreateEditTaskShown] = useState(false)
  const [editTaskModal, setEditTaskModal] = useState(false)
  const [editDescription, setEditDescription] = useState("")
  const [editDescriptionID, setEditDescriptionID] = useState("")
  const [files, setFiles] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [editTaskData, setEditTaskData] = useState({
    id: "",
    title: "",
    description: "",
    assignTo: [],
    deadlined_date: "",
    any_attachment: false,
    filesArray: [],
  })

  const { state } = useLocation()

  // console.log(state)
  // return false;
  useEffect(() => {
    fetchRemarksfromDB(1)
    FirstCheckFinishTask()
    fetchEmployeeList()
  }, [1])
  const fetchRemarksfromDB = pram => {
    let localValue = localStorage.getItem("authUser")
    let paringStatus = JSON.parse(localValue)
    let emp_id = paringStatus.map(e => e.user_id)
    let role = paringStatus.map(e => e.role)
    setCurrentUserID(emp_id)
    setCurrentUserRole(role)
    let form = new FormData()

    form.append("task_id", state.task_data.task.id)
    form.append("project_id", state.task_data.task.project_id)
    form.append("method", "fetch-task-remarks")
    var requestOptions = {
      method: "POST",
      body: form,
    }
    console.warn(form)
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setremarksList(result)
      })
      .catch(er => console.log(er))
  }
  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setmockData2(result)
      })
      .catch(er => {
        console.log(er)
      })
  }
  function formatDate(inputDate) {
    const date = new Date(inputDate)
    const formattedDate = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    })
    return formattedDate
  }

  const calculateDaysToDueDate = deadlineDate => {
    const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
    const currentDate = new Date()
    const deadline = new Date(deadlineDate)

    // Calculate the difference in days
    const differenceInDays = Math.round((deadline - currentDate) / oneDay)

    // Determine status message based on the difference
    let statusMessage
    if (differenceInDays === 1) {
      statusMessage = "Tomorrow"
    } else if (differenceInDays === 0) {
      statusMessage = "Today"
    } else if (differenceInDays < 0) {
      statusMessage = `${Math.abs(differenceInDays)} days overdue`
    } else {
      statusMessage = `${differenceInDays} days remaining`
    }

    return statusMessage
  }
  const FirstCheckFinishTask = () => {
    let local = localStorage.getItem("authUser")
    let mapingVal = JSON.parse(local)
    let emp_id = mapingVal.map(e => e.user_id)
    var formdata = new FormData()
    formdata.append("method", "check-task-done-by-employee")
    formdata.append("emp_id", emp_id)
    formdata.append("task_id", state.task_data.task.id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        //  this.setState({loaderTaskFinish: false})

        if (result == "done") {
          setstatusForFinishButton(true)
        } else {
          setstatusForFinishButton(false)
        }
      })
      .catch(error => console.log("error", error))
  }
  const onClickfinishTask = () => {
    let local = localStorage.getItem("authUser")
    let mapingVal = JSON.parse(local)
    let emp_id = mapingVal.map(e => e.user_id)
    var formdata = new FormData()
    formdata.append("method", "task-done-by-employee")
    formdata.append("emp_id", emp_id)
    formdata.append("task_id", state.task_data.task.id)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.text())
      .then(result => {
        console.log(result)
        if (result == "success") {
          Swal.fire("Task Done", "You successfully done this task")
          FirstCheckFinishTask()
        } else if (result == "already added") {
          Swal.fire("Already Finish", "You already finish this task")
          setstatusForFinishButton(true)
        } else {
          Swal.fire(
            "Server Error",
            "Please check your input fields are try again"
          )
        }
      })
      .catch(error => console.log("error", error))
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }
  const formatDateTime = inputDate => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate)

    // Extract the year, month, and day components
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, "0") // Add leading zero if needed

    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`

    return formattedDate
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }
  const handleFileSelection = e => {
    setFiles([...e.target.files])
  }
  const functionThatIwant = (desc, id) => {
    setEditDescription(desc)
    setEditDescriptionID(id)
    setCreateEditTaskShown(true)
  }
  const DeleteTaskFromDB = passingId => {
    Swal.fire({
      title: "Delete Remarks",
      html: "Are you sure to want delete remarks",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    }).then(result => {
      if (result.isConfirmed) {
        // Handle action for Button 1
        let form = new FormData()
        form.append("method", "delete-task-remarks")
        form.append("project_id", state.task_data.task.project_id)
        form.append("remarks_id", passingId)
        form.append("task_id", state.task_data.task.id)
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/tasks.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              Swal.fire("Deleted", "Remarks is successfully delete")
              fetchRemarksfromDB()
            } else {
              Swal.fire(
                "Server Error",
                "Error while updating the status. Try again"
              )
            }
          })
      } else if (result.dismiss === "cancel") {
        Swal.close()
      }
    })
  }
  const DeleteTaskInfoFromDB = () => {
    Swal.fire({
      title: "Delete Task",
      html: "Are you sure to delete this task",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    }).then(result => {
      if (result.isConfirmed) {
        // Handle action for Button 1
        let form = new FormData()
        form.append("method", "delete-task")
        form.append("project_id", state.task_data.task.project_id)
        form.append("delete_id", state.task_data.task.id)
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/tasks.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              Swal.fire("Deleted", "Tasks is successfully delete")
              window.history.back()
            } else {
              Swal.fire(
                "Server Error",
                "Error while deleting the task. Try again"
              )
            }
          })
      } else if (result.dismiss === "cancel") {
        Swal.close()
      }
    })
  }

  const fetchSingleTaskDetail = id => {
    const formdata = new FormData()
    formdata.append("method", "fetch-tasks_by_id")
    formdata.append("task_id", 1)
    var requestOptions = {
      method: "POST",
      body: formdata,
    }
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result?.task === null) {
          setEditTaskData({
            id: "",
            title: "",
            description: "",
            assignTo: [],
            deadlined_date: "",
            any_attachment: false,
            filesArray: [],
          })
        } else {
          const { task, assigned_to } = result
          console.log(task)
          const mapedArray = assigned_to.map(e => ({
            value: e.emp_id,
            label: e.emp_id + "-" + e.first_name + " " + e.last_name,
          }))
          setEditTaskData({
            id: task.id,
            title: task.task_title,
            description: task.task_description,
            assignTo: mapedArray,
            deadlined_date: convertDateToISO(task.deadline_date),
            any_attachment: task.task_attachment === "" ? false : true,
            filesArray: [],
          })
          setEditTaskModal(true)
        }
      })
      .catch(error => console.log("error", error))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="ms-auto">
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <label>Project Name</label>
                        <p>{state.task_data.project}</p>
                      </Col>
                      <Col md={3}>
                        <label>Deadline Date</label>
                        <p style={{ fontWeight: "normal" }}>
                          {calculateDaysToDueDate(
                            state.task_data?.task?.deadline_date
                          )}
                        </p>
                      </Col>
                      <Col md={5}>
                        <button
                          style={{ marginLeft: 5 }}
                          className="btn btn-info"
                          onClick={() => setCreateTaskShown(true)}
                          disabled={
                            statusForFinishButton == true ? true : false
                          }
                        >
                          Add Remarks
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          className="btn btn-warning"
                          onClick={() =>
                            fetchSingleTaskDetail(state.task_data.task.id)
                          }
                          disabled={
                            statusForFinishButton == true ? true : false
                          }
                        >
                          Edit Task
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          className="btn btn-success"
                          onClick={() => onClickfinishTask()}
                          disabled={
                            statusForFinishButton == true ? true : false
                          }
                        >
                          Mark as done
                        </button>
                        <button
                          style={{ marginLeft: 5 }}
                          className="btn btn-danger"
                          onClick={() => DeleteTaskInfoFromDB()}
                          disabled={
                            statusForFinishButton == true ? true : false
                          }
                        >
                          Delete Task
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <label>Task </label>
                        <br />
                        <p>{state.task_data.task.task_title}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <label>{"Description"}</label>
                        <br />
                        <p>{state.task_data.task.task_description} </p>
                      </Col>
                      <Col md={12}>
                        <label>Assigned To</label>
                        <br />
                        <div className="row">
                          {state.task_data?.assigned_to?.map((e, index) => {
                            return (
                              <div key={index} className="col-md-1">
                                <img
                                  src={e.profile_image}
                                  alt=""
                                  title={e.first_name + "-" + e.role}
                                  className="rounded-circle avatar-xs"
                                />
                                <br />
                                <p
                                  className={`badge ${
                                    e.status == "active"
                                      ? "bg-warning"
                                      : "bg-success"
                                  }`}
                                >
                                  {e.status}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-start">
                      <div className="me-2">
                        <h5 className="card-title mb-4">Tasks Remarks</h5>
                      </div>
                    </div>
                    <Col md={12}>
                      {remarksList.map((e, index) => {
                        return (
                          <SimpleBar
                            key={index}
                            className="mt-2"
                            style={{ maxHeight: "280px" }}
                          >
                            <ul className="verti-timeline list-unstyled">
                              {/* <img
                      key={index}
                      src={e.employee_detail.profile_image}
                      alt=""
                      title={e.employee_detail.first_name + '-' + e.employee_detail.role}
                      className="rounded-circle avatar-xs"
                    /> */}

                              <li className="event-list">
                                <div className="event-timeline-dot">
                                  <i className="bx bxs-right-arrow-circle font-size-18 bx-fade-right"></i>
                                </div>
                                <div className="d-flex">
                                  <div className="flex-shrink-0 me-3">
                                    <h5
                                      className="font-size-14"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      {e.employee_detail.first_name +
                                        "-" +
                                        e.employee_detail.role}
                                      <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2"></i>
                                    </h5>
                                    <h5 className="font-size-14">
                                      {formatDate(e.created_at)}
                                    </h5>
                                    {currentUserID == e.employee_detail.id ? (
                                      <h5 className="font-size-14">
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            functionThatIwant(
                                              e.project_remarks,
                                              e.remarks_id
                                            )
                                          }
                                        >
                                          Edit{" "}
                                        </span>
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            DeleteTaskFromDB(e.remarks_id)
                                          }
                                        >
                                          Delete
                                        </span>
                                      </h5>
                                    ) : null}
                                  </div>
                                  <div className="flex-grow-1">
                                    <div>
                                      {e.project_remarks}{" "}
                                      <span className="fw-semibold"></span>
                                    </div>
                                    {e?.files_Attach.length === 0 ? null : (
                                      <div className="">
                                        {/* <div><strong>Attachments</strong></div> */}
                                        <div>
                                          <ul style={{ padding: 0 }}>
                                            {e?.files_Attach.map((e, index) =>
                                              e === "" ? null : (
                                                <li key={index}>
                                                  <a
                                                    href={e}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                  >
                                                    Attachment {index + 1}
                                                  </a>
                                                </li>
                                              )
                                            )}
                                          </ul>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </SimpleBar>
                        )
                      })}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Offcanvas
        isOpen={createTaskShown}
        direction="bottom"
        style={{ height: 400 }}
        toggle={() => setCreateTaskShown(false)}
      >
        <OffcanvasHeader toggle={() => setCreateTaskShown(false)}>
          Add Remarks
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (e.target.elements.task_title.value === "") {
                toastr.error("Please add some remarks description")
                return false
              } else {
                // this.setState({submitDisabled: true});
                let localValue = localStorage.getItem("authUser")
                let paringStatus = JSON.parse(localValue)
                let emp_id = paringStatus.map(e => e.user_id)
                let form = new FormData()
                form.append("method", "create-remarks-for-tasks")
                form.append("project_id", state.task_data.task.project_id)
                form.append("emp_id", emp_id)

                form.append("task_id", state.task_data.task.id)
                form.append(
                  "project_description",
                  e.target.elements.task_title.value
                )

                if (isAttachmentSwitch) {
                  for (let i = 0; i < files.length; i++) {
                    form.append(`file_upload_${i}`, files[i])
                  }
                }

                axios
                  .post(
                    process.env.REACT_APP_LEAVE_API_URL + "/tasks.php",
                    form,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then(result => {
                    setCreateTaskShown(false)
                    e.target.elements.task_title.value = ""
                    setloadingSpinner(false)
                    if (result.data == "success") {
                      Swal.fire(
                        "Remark Added",
                        "Your remarks are added to project"
                      )
                      fetchRemarksfromDB()
                    } else {
                      Swal.fire(
                        "Server Error",
                        "Please check you input fields and try again"
                      )
                      return false
                    }
                  })
                  .catch(er => {
                    console.log(er)
                    setloadingSpinner(false)
                  })
              }
            }}
          >
            <Row>
              <Col md={12} className="mb-3">
                <Label>Description</Label>
                <Input
                  name="task_title"
                  type="textarea"
                  className="form-control"
                  placeholder="..."
                />
              </Col>

              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 10,
                  }}
                >
                  <div style={{ justifyContent: "center" }}>
                    <p style={{ fontSize: 18 }}>Any Attachments</p>
                  </div>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                    checked={isAttachmentSwitch}
                    onChange={() => {
                      setisAttachmentSwitch(!isAttachmentSwitch)
                    }}
                  />
                </div>
              </Col>
              {isAttachmentSwitch == true ? (
                <Col md={12}>
                  <div style={{ paddingBottom: 10 }}>
                    Note File Size is less than 5 MB
                  </div>
                  <div className="mb-3">
                    <input
                      name="input_file"
                      type="file"
                      className="form-control"
                      onChange={handleFileSelection}
                      multiple
                    />
                  </div>
                </Col>
              ) : null}
              <Col md={12} style={{ textAlign: "end" }}>
                <button
                  type="button"
                  onClick={() => setCreateTaskShown(false)}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                &nbsp;&nbsp;
                {
                  // loadingSpinner == false ?
                  <button type="submit" className="btn btn-primary">
                    Add Remarks
                  </button>
                  // :<Spinner style={{paddingxTop:8}}/>
                }
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        isOpen={createEditTaskShown}
        direction="bottom"
        style={{ height: 400 }}
        toggle={() => setCreateEditTaskShown(false)}
      >
        <OffcanvasHeader toggle={() => setCreateEditTaskShown(false)}>
          Edit Remarks
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (e.target.elements.edit_task_Description.value === "") {
                toastr.error("Please add some remarks description")
                return false
              } else {
                setEditloadingSpinner(true)
                let form = new FormData()
                form.append("method", "edit-remarks-for-task")
                form.append("remarks_id", e.target.elements.edit_id.value)

                form.append("task_id", state.task_data.task.id)
                form.append(
                  "project_description",
                  e.target.elements.edit_task_Description.value
                )

                axios
                  .post(
                    process.env.REACT_APP_LEAVE_API_URL + "/tasks.php",
                    form
                  )
                  .then(result => {
                    setEditloadingSpinner(false)
                    setCreateEditTaskShown(false)
                    if (result.data == "success") {
                      Swal.fire(
                        "Remark Updated",
                        "Your remarks are updated to project"
                      )
                      fetchRemarksfromDB()
                    } else {
                      Swal.fire(
                        "Server Error",
                        "Please check you input fields and try again"
                      )
                      return false
                    }
                  })
                  .catch(er => {
                    console.log(er)
                    setEditloadingSpinner(false)
                  })
              }
            }}
          >
            <Row>
              <Col md={12} className="mb-3">
                <Label>Edit Description</Label>
                <Input
                  name="edit_task_Description"
                  id="edit_task"
                  type="textarea"
                  className="form-control"
                  placeholder="..."
                  onChange={e => setEditDescription(e.target.value)}
                  value={editDescription}
                />
                <Input
                  name="edit_id"
                  id="edit_id"
                  type="hidden"
                  className="form-control"
                  value={editDescriptionID}
                />
              </Col>

              <Col md={12} style={{ textAlign: "end" }}>
                <button
                  type="button"
                  onClick={() => setCreateEditTaskShown(false)}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                &nbsp;&nbsp;
                {editloadingSpinner == false ? (
                  <button type="submit" className="btn btn-primary">
                    Save Changes
                  </button>
                ) : (
                  <Spinner style={{ paddingTop: 8 }} />
                )}
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        isOpen={editTaskModal}
        direction="end"
        toggle={() => setEditTaskModal(false)}
      >
        <OffcanvasHeader toggle={() => setEditTaskModal(false)}>
          Edit Task
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()

              setEditTaskLoader(true)

              let form = new FormData()
              const fileInput = document.querySelector('input[type="file"]')
              const file =
                editTaskData.any_attachment === true ? fileInput.files[0] : null
              const values = editTaskData.assignTo.map(e => e.value)
              form.append("method", "edit-task-by-id")
              form.append("edit_id", editTaskData.id)
              form.append("task_title", editTaskData.title)
              form.append("task_description", editTaskData.description)
              form.append("assignedTo", values.toString())
              form.append(
                "deadline_date",
                formatDateTime(editTaskData.deadlined_date)
              )
              if (editTaskData.any_attachment == true) {
                form.append("file_upload", file)
              }

              axios
                .post(
                  process.env.REACT_APP_LEAVE_API_URL + "/tasks.php",
                  form,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data",
                    },
                  }
                )
                .then(result => {
                  setEditloadingSpinner(false)

                  if (result.data === "success") {
                    toastr.success("You successfully updated the task")
                    setEditTaskLoader(false)
                    setEditTaskModal(false)
                    window.history.back()
                  } else {
                    setEditTaskLoader(false)
                    toastr.error("Error while updating the task")
                  }
                })
                .catch(e => console.log(e))
            }}
          >
            <Row>
              <Col md={12} className="mb-3">
                <Label>Title</Label>
                <Input
                  name="task_title"
                  type="text"
                  className="form-control"
                  placeholder="..."
                  value={editTaskData.title}
                  onChange={e =>
                    setEditTaskData(prev => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col md={12} className="mb-3">
                <div className="mb-3">
                  <label>Assign Member</label>
                  <Select
                    isMulti={true}
                    value={editTaskData.assignTo}
                    onChange={item => {
                      // setassignTo(item.map(e => e.value))
                      setEditTaskData(prev => ({ ...prev, assignTo: item }))
                    }}
                    options={mockData2}
                    className="select2-selection"
                  />
                </div>
              </Col>
              <Col md={12} className="mb-3">
                <Label>Description</Label>
                <textarea
                  name="task_description"
                  type="text"
                  className="form-control"
                  value={editTaskData.description}
                  onChange={e =>
                    setEditTaskData(prev => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                  placeholder="Type description here...."
                ></textarea>
              </Col>
              <Col md={12} className="mb-3">
                <Label>Deadline Date</Label>
                <input
                  name="deadline_date"
                  type="date"
                  value={editTaskData.deadlined_date}
                  onChange={e =>
                    setEditTaskData(prev => ({
                      ...prev,
                      deadlined_date: e.target.value,
                    }))
                  }
                  className="form-control"
                />
              </Col>
              <Col md={12}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingVertical: 10,
                  }}
                >
                  <div style={{ justifyContent: "center" }}>
                    <p style={{ fontSize: 18 }}>Any Attachments</p>
                  </div>
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    className="me-1 mb-sm-8 mb-2"
                    onColor="#626ed4"
                    checked={editTaskData.any_attachment}
                    onChange={() => {
                      setEditTaskData(prev => ({
                        ...prev,
                        any_attachment: !editTaskData.any_attachment,
                      }))
                    }}
                  />
                </div>
              </Col>
              {isAttachmentSwitch == true ? (
                <Col md={12}>
                  <div style={{ paddingBottom: 10 }}>
                    Note File Size is less than 5 MB
                  </div>
                  <div className="mb-3">
                    <input
                      name="input_file"
                      type="file"
                      className="form-control"
                    />
                  </div>
                </Col>
              ) : null}
              <Col md={12} style={{ textAlign: "end" }}>
                <button
                  type="button"
                  onClick={() => setEditTaskModal(false)}
                  className="btn btn-danger"
                >
                  Cancel
                </button>
                &nbsp;&nbsp;
                {editTaskLoader == false ? (
                  <button type="submit" className="btn btn-primary">
                    Edit Task
                  </button>
                ) : (
                  <Spinner style={{ paddingTop: 8 }} />
                )}
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <ChatWidget />
    </React.Fragment>
  )
}
