import axios from "axios"
import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Form,
  Label,
  Spinner,
  UncontrolledTooltip,
  Input,
  FormGroup,
  InputGroup,
  Button,
  Table
} from "reactstrap"
import Select from "react-select"
import Swal from "sweetalert2"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import {
  ArrowRightAltSharp,
  DeleteSharp,
  EditSharp,
  PermIdentitySharp,
  SyncAltSharp,
  SyncSharp,
} from "@mui/icons-material"
import { Link } from "react-router-dom"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Backdrop, CircularProgress, Skeleton, useTheme } from "@mui/material"
import Breadcrumb from "components/Common/Breadcrumb"
import { formatDate } from "@fullcalendar/react"

function removeBodyCss() {
  document.body.classList.add("no_padding")
}
function getValueAfterEquals(inputString) {
  // Split the string by '=' and return the second part
  const parts = inputString.split("=")
  if (parts.length > 1) {
    return parts[1]
  } else {
    return null // Return null if the '=' sign is not found
  }
}
function getFormattedDateTime(timezone) {
  const date = new Date()

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: timezone,
  }

  const formatter = new Intl.DateTimeFormat("en-GB", options)
  const formattedParts = formatter.formatToParts(date)

  const formatMap = {}
  formattedParts.forEach(({ type, value }) => {
    formatMap[type] = value
  })

  const year = formatMap.year
  const month = formatMap.month.padStart(2, "0")
  const day = formatMap.day.padStart(2, "0")
  const hour = formatMap.hour.padStart(2, "0")
  const minute = formatMap.minute.padStart(2, "0")
  const second = formatMap.second.padStart(2, "0")

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`
}


export default function PerformancePage() {
    
  document.title = `Performance | ${process.env.REACT_APP_NAME}`
  const title = 'Performance'
  const [mockData2, setmockData2] = useState([])
  const [data,setData]= useState([]);
  const theme = useTheme()
  const [assignTo, setassignTo] = useState({value:'',label:''})
  const [openLoader, setopenLoader] = useState(false)
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });
  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }
  
    fetch(process.env.REACT_APP_LEAVE_API_URL + "/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        setmockData2(result)
      })
      .catch(er => {
        console.log(er)
      })
  }
    const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
      2,
      "0"
    )}m:${String(remainingSeconds).padStart(2, "0")}`
  }
  
  useEffect(() => {
    fetchEmployeeList()
  }, [])
  const onPressFilterButton = ()=>{
    const formData = new FormData();
    formData.append('method', 'fetch_single_employee_report');
    formData.append('emp_id', assignTo.value);
    formData.append('date_start', selectedDate.start_date)
    formData.append('date_end', selectedDate.end_date)
    axios.post(`${process.env.REACT_APP_LEAVE_API_URL}/tasks.php`, formData)
    .then((res)=>{
        setData(res.data);
    })
    .catch((e)=>console.log(e.message))
  }

  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
           
          <Backdrop
            sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }}
            open={openLoader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Row>
            <Col md="12">
            <Breadcrumb title={'Dashboard'} breadcrumbItem="Performance" titleLink={'/dashboard'}/>
            </Col>
            <Col md="4">
            <FormGroup className="mb-4">
                      <Label>Date Range</Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "Y-m-d"
                          }}
                          onChange={(dates) => {
                            // Check if both start and end dates are selected
                            const [start, end] = dates;
                              setSelectedDate({
                                start_date: start ? start.toLocaleDateString("en-CA") : "", // Format YYYY-MM-DD
                                end_date: end ? end.toLocaleDateString("en-CA") : "",
                              });
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
            </Col>
            <Col md={4} className="mb-3">
            <div style={{ position: "relative", overflow: "visible" }}>
                <label>Select Member</label>
                <Select
                onChange={(item) => {
                    setassignTo(item);
                }}
                options={mockData2}
                menuPortalTarget={document.body}
                className="select2-selection"
                styles={{
                    menu: (provided) => ({
                    ...provided,
                    zIndex: 9999, // Increase z-index to ensure visibility
                    }),
             
                }}
                />
              </div>
            </Col>
            <Col md="4" style={{display:'flex', alignItems:'center'}}>
                <Button onClick={()=>onPressFilterButton()} color="primary" style={{height:45}}>Filter Result</Button>
            </Col>
          </Row>
          <Row>
            <Col md="12">
                <Table>
                    <thead>

                        <th>Project Name</th>
                        <th>Task Title</th>
                        <th>Working Hours</th>
                        <th>Actions</th>
                    </thead>
                    <tbody>
                        {
                            data.map((e,index)=>{
                                const task = {
                                       task: e.task
                                };
                                console.log(task)

                                return (
                                <tr key={index}>
                                    <td>
                                        {e?.project_title}
                                    </td>
                                    <td>
                                        {e?.task_title}
                                    </td>
                                    <td>
                                        { formatTime(Number(e?.total_time))}
                                    </td>
                                    <td>
                                        <Link className="btn btn-primary" to={`/project-overview/${e.project_id}`}>View Project</Link>
                                        <Link className="btn btn-info" to={`/task-remarks`}  state= {{id: e.task_id , task_data : task}} >Remarks</Link>
                                    </td>
                                </tr>);
                                })
                        }
                    </tbody>
                </Table>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
