import React, { useState } from 'react';
import './ChatWidget.css';
import {Link} from 'react-router-dom'
const ChatWidget = () => {

  return (
    <div className="chat-widget-container">
      
        <Link to={'/chat'} className="chat-widget-button" >
          <i className="bx bx-chat"></i>
        </Link>
      
   
    </div>
  );
};

export default ChatWidget;
