import React, { Component, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  Spinner,
  Label,
  Input,
  
} from "reactstrap"
import Switch from 'react-switch'

import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import {
  Actions,
  Coin,
  Pdate,
  Status,
  Tracked,
  Type,
} from "pages/Timesheet/SheetData"
import TableContainer from "components/Common/TableContainer"
import Select from "react-select"

import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { ArrowDropDownCircleOutlined } from "@mui/icons-material"
import ChatWidget from "components/Common/ChatWidget"
import PieChartCustom from "pages/AllCharts/apex/PieChartCustom"
import axios from "axios"

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  );
};
const formatDateTime = (inputDate) => {
  // Parse the input date string into a Date object
  const date = new Date(inputDate);
  
  // Extract the year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
  const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
  
  // Construct the formatted date string in MM/DD/YYYY format
  const formattedDate = `${month}/${day}/${year}`;
  
  return formattedDate;
}
const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  );
};
class TaskHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: String(JSON.parse(localStorage.getItem('authUser')).map((e)=>e.role == 'user' || e.role == 'supervisor' ? '2'  :  localStorage.getItem('currentActiveTabOnThisScreen') == null ? "1" : localStorage.getItem('currentActiveTabOnThisScreen'))) ,
      dataFetch: [],
      filterFetch: [],
      activityColor: null,
      assignTo: [],
      mockData2: [],
      DataToBeSet: [],
      createActivity: false,
      DataToBeSetTasks: [],
      optionforTask: [],
      activeProject: 0,
      completedProject: 0,
      newProjects: 0,
      activeTabValue: 0,
      completedTasks: [],
      activeTasks: [],
      overdueTasks: [],
      totalTasks: [],
      task_modal: false,
      task_loader:false,
      allProjectsList:[],
      allEmployeeList:[],
      anyAttachmentTask:false,
      task_info : {
        title:'',
        description:'',
        deadline_date:'',
        project_id:null,
        assigned_to : [],

      }
    }
  }
  
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      localStorage.setItem('currentActiveTabOnThisScreen', tab)
      this.setState({ activeTab: tab })
    }
  }
  
  fetchEmployeeList = () => {
    
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        // console.log(requestOptions)
        console.log(result)
        this.setState({ mockData2: result , allEmployeeList  : result })
      })
      .catch(er => {
        console.log(er)
      })
  }
  getAllActivities = async () => {
   console.log(typeof String(this.state.activeTab))
    var formdata = new FormData()
    formdata.append("method", "get-activities-all")
    formdata.append("role", "admin")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        this.setState({ dataFetch: result, filterFetch: result })
        // setTimeout(() => {
        //   if (this.state.dataFetch.length == 0) {
        //     // this.setState({isLoading :false});
        //   }
        // }, 1000);
      })
      .catch(error => console.log("error", error))
  }
  generateColumns = () => {
    return [
      {
        Header: "Activity Color",
        accessor: "activity_color",
        filterable: true,
        Cell: cellProps => {
          return (
            <span style={{ background: cellProps.value, color: "transparent" }}>
              --
            </span>
          )
        },
      },
      {
        Header: "Activity Name",
        accessor: "activity_name",
        filterable: true,
        Cell: cellProps => <Type {...cellProps} />,
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          if (cellProps.value == "active") {
            return <Badge className="bg-success font-size-10">active</Badge>
          } else {
            return <Badge className="bg-danger font-size-10">in-active</Badge>
          }
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: cellProps => <Coin {...cellProps} />,
      },
      {
        Header: "Actions",
        accessor: "emp_id",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <button className="btn btn-info">Edit</button>&nbsp;&nbsp;
              <button className="btn btn-danger">Delete</button>
            </div>
          )
        },
      },
    ]
  }

  getAllProjectFromDB = () => {
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
   
      var formdata = new FormData()
      formdata.append("org_id", orgId)
      formdata.append("method", "fetch-projects")

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_LEAVE_API_URL + "/projects.php",
        requestOptions
      )
        .then(response => response.json())

        .then(result => {
              const maped = result.map((e)=>({
                label: e.id + '-' +e.projectName ,
                value: e.id
              }))
             this.setState({
              allProjectsList : maped
             })
        })
        .catch(error => console.log("error", error))
    
  }
  getAllTasks = () => {
    // this.setState({isLoading : true})
    this.setState({ DataToBeSetTasks: [] })
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    this.setState({ CurrentUserID: user_id, currentRole: role })
    var formdata = new FormData()
    formdata.append("method", "get-tasks-assigned-all-by-status")
    formdata.append("id", user_id)
    formdata.append("role", role)
    formdata.append("status", "null")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.json())

      .then(result => {
        this.setState({ DataToBeSetTasks: result })
        // result.data.forEach(item => {

        // totalTasks = result.data.length;
        const total = result.filter(item => {
          return item
        })
        const active = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)
          if (item.task.status == "active" && deadlineDate > currentDate) {
            return item
          }
        })
        const overdue = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)

          if (deadlineDate < currentDate && item.task.status == "active") {
            return item
          }
        })
        const completed = result.filter(item => {
          if (item.task.status == "completed") {
            return item
          }
        })
        this.setState({ totalTasks: total })
        this.setState({ overdueTasks: overdue })
        this.setState({ activeTasks: active })
        this.setState({ completedTasks: completed })
        let option = {
          completed: completed.length || 0,
          active: active.length || 0,
          overdue: overdue.length || 0,
        }
        this.setState({ optionforTask: option })
        // const active = result.filter(item => {
        //   let assignedTo = item.assigned_to.find(
        //     user => user.id == user_id[0],
        //   );
        //   return assignedTo && assignedTo.status === 'Working'  ;
        // })
        // const overdue = result.filter(item => {

        // })

        // })
        // this.setState({isLoading : false})
        // if(role == 'user' || role == 'supervisor'){
        //   this.setState({DataToBeSetTasks: result })
        // }
        // if(role[0] == 'user'  || role == 'supervisor'){

        //   const val = result.filter(item => {
        //     let assignedTo = item.assigned_to.find(
        //       user => user.id == user_id[0],
        //     );

        //     return assignedTo && assignedTo.status === 'Working' ;
        //   });
        //   this.setState({DataToBeSetTasks: val })
        // }
        // else if(role[0] == 'user'  || role == 'supervisor'){

        //   const val = result.filter(item => {
        //     let assignedTo = item.assigned_to.find(
        //       user => user.id == user_id[0],
        //     );
        //     return assignedTo && assignedTo.status === 'Working';
        //   });
        //   console.error(val);
        //   this.setState({DataToBeSetTasks: val })
        // }
        // else if(role[0] == 'user'  || role == 'supervisor' ){
        //   const val = result.filter(item => {
        //     let assignedTo = item.assigned_to.find(
        //       user => user.id == user_id[0],
        //     );
        //     return assignedTo && assignedTo.status === 'completed';

        //   });

        //   this.setState({DataToBeSetTasks: val })
        // }else{
        //   let currentDate = new Date();
        //   if(this.props.route.params.screenName == 'Overdue'){
        //     const getting = result.filter(task => {
        //       // Parse the deadline date string to a Date object
        //       let deadlineDate = new Date(task.task.deadline_date);
        //       // Compare deadline date with current date
        //       return deadlineDate < currentDate;
        //     });
        //     this.setState({DataToBeSet: getting ,DataToBeSetFilter : getting})
        //   }else if(this.props.route.params.screenName == 'Completed'){

        //     const getting = result.filter(task => {
        //       // Parse the deadline date string to a Date object
        //       // let deadlineDate = new Date(task.task.deadline_date);
        //       // // Compare deadline date with current date
        //       return task.task.status == 'completed' ? task  : null;
        //     });
        //     this.setState({DataToBeSet: getting ,DataToBeSetFilter : getting})
        //   }else if(this.props.route.params.screenName == 'Active'){

        //     const getting = result.filter(task => {
        //       let deadlineDate = new Date(task.task.deadline_date);

        //       return task.task.status == 'active' && deadlineDate > currentDate ? task  : null;
        //     });
        //     this.setState({DataToBeSet: getting ,DataToBeSetFilter : getting})
        //   }
        //   else{
        //     this.setState({DataToBeSet: result ,DataToBeSetFilter : result})
        //   }
        // }
        // setTimeout(() => {
        //     if (this.state.DataToBeSet.length == 0) {
        //       this.setState({setPlaceholderShown : true});
        //     }
        //   }, 1000);
      })
      .catch(error => console.log("error", error))

    // let valueFrom = localStorage.getItem("authUser")
    // let convertedValue = JSON.parse(valueFrom)
    // let orgId = convertedValue.map(e => e.org_id)
    // let user_id = convertedValue.map(e => e.user_id)
    // let role = convertedValue.map(e => e.role)
    // var formdata = new FormData()
    // formdata.append("method", "get-tasks-assigned-all")
    // formdata.append("id", user_id)
    // formdata.append("role", role)
    // formdata.append("org_id", orgId)
    // var requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   redirect: "follow",
    // }
    // fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
    //   .then(response => response.json())
    //   .then(result => {
    //     this.setState({ DataToBeSetTasks: result })

    //   })
    //   .catch(error => console.log("error", error))
  }

  
  componentDidMount() {
    this.getAllActivities()
    this.getAllProjectFromDB()
    this.fetchEmployeeList()
    this.getAllTasks()
    // this.getTasksCounter()
  }

  render() {
    document.title = `Tasks | ${process.env.REACT_APP_NAME}`
    const {
      mockData2,
      DataToBeSet,
      newProjects,
      DataToBeSetTasks,
      activeTabValue,
      activeTasks,
      completedTasks,
      totalTasks,
      overdueTasks,
      task_info,
      task_loader,
      task_modal,
      allProjectsList,
      allEmployeeList,
      anyAttachmentTask

    } = this.state

    const columns = this.generateColumns()
    const renderRadioButton = (color, value) => (
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 8,
        }}
      >
        <div
          style={{
            backgroundColor: color,
            borderRadius: 50,
            width: 150,
            marginBottom: 8,
          }}
        >
          <input
            type="radio"
            name="radiobutton"
            className="form-check-input"
            value={this.state.activityColor}
            // checked={this.state.activityColor === value ? true : false}
            onChange={() => this.setState({ activityColor: value })}
            color={"white"}
          />
        </div>
      </div>
    )
    const convertDateFormat = inputDate => {
      const parts = inputDate.split("/")
      const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`
      return formattedDate
    }
    const formatDate = inputDate => {
      const date = new Date(inputDate)
      const options = { day: "2-digit", month: "short", year: "numeric" }
      return new Intl.DateTimeFormat("en-UK", options).format(date)
    }
    const calculateDaysDifference = deadlineDate => {
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const currentDate = new Date()
      const deadline = new Date(deadlineDate)

      // Calculate the difference in days
      const differenceInDays = Math.round((deadline - currentDate) / oneDay)

      // Determine status message based on the difference
      let statusMessage
      if (differenceInDays === 1) {
        statusMessage = "Tomorrow"
      } else if (differenceInDays === 0) {
        statusMessage = "Today"
      } else if (differenceInDays < 0) {
        statusMessage = `${Math.abs(differenceInDays)} days overdue`
      } else {
        statusMessage = `${differenceInDays} days remaining`
      }

      return statusMessage
    }

    const series = [
      {
        name: "Complete Tasks",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
      },
      {
        name: "All Tasks",
        type: "line",
        data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
      },
      {
        name: "Overdue Tasks",
        type: "line",
        data: [9, 1, 2, 3, 7, 8, 10, 12, 18, 0, 9],
      },
    ]

    const options = {
      chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
      stroke: { width: [0, 1, 2], curve: "smooth" },
      plotOptions: { bar: { columnWidth: "50%", endingShape: "rounded" } },
      colors: ["#556ee6", "#34c38f", "red"],
      fill: {
        gradient: {
          inverseColors: !1,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      markers: { size: 0 },
      yaxis: { min: 0 },
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      this.setState({ activeTabValue: newValue })
    }
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="ms-auto">
              <Row>
                <Col md={12}>
                  <Box sx={{ width: "100%" }}>
                  <Row style={{ paddingTop: 20 }}>
                            <Col className="mb-4" md={6}>
                              <h5>Task Information</h5>
                            </Col>
                            {
                              JSON.parse(localStorage.getItem('authUser')).map((e,index)=>{
                                if(e.role === 'admin' || e.role ==='supervisor'){
                                  return ( <Col key={index} className="mb-4" md={6} style={{textAlign:'end'}}>
                                  <button onClick={()=>this.setState({task_modal : true})} className="btn btn-primary">Create Task</button>
                                </Col>);
                                }
                              })
                            }
                           
                            <Col lg={3}>
                              <Link to={"../tasks/total"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">Total</p>
                                        <h5 className="mb-0">
                                          {totalTasks.length}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col lg={3}>
                              <Link to={"../tasks/active"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Active{" "}
                                        </p>
                                        <h5 className="mb-0">
                                          {activeTasks.length}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col lg={3}>
                              <Link to={"../tasks/overdue"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Over due{" "}
                                        </p>
                                        <h5 className="mb-0">
                                          {overdueTasks.length}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>

                            <Col lg={3}>
                              <Link to={"../tasks/completed"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Completed
                                        </p>
                                        {completedTasks.length}
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>

                            <Col lg={8}>
                              <Accordion defaultExpanded>
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownCircleOutlined />}
                                  aria-controls="panel1-content"
                                  id="panel1-header"
                                >
                                  <Typography>
                                    Active{" "}
                                    <Badge color="success">
                                      {activeTasks.length}
                                    </Badge>{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {/* <h4 className="card-title mb-4"></h4> */}

                                  <div className="table-responsive">
                                    <table className="table table-nowrap align-middle mb-0">
                                      <thead>
                                        <tr>
                                          <th>Task Title</th>
                                          <th>Project Name</th>
                                          <th>Assigned Team</th>
                                          <th>Deadline Date</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {activeTasks.map((e, index) => {
                                          if (index < 6) {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <h5 className="text-truncate font-size-14 m-0">
                                                    <Link
                                                      to={"/task-remarks"}
                                                      state={{
                                                        id: e.task_id,
                                                        task_data: e,
                                                      }}
                                                    >
                                                      {e.task.task_title}
                                                    </Link>
                                                  </h5>
                                                </td>
                                                <td>{e.project}</td>
                                                <td>
                                                  <div className="avatar-group">
                                                    {e.assigned_to.map(
                                                      (eimg, index) => (
                                                        <div
                                                          key={index}
                                                          className="avatar-group-item"
                                                        >
                                                          <Link
                                                            to="#"
                                                            className="d-inline-block"
                                                            title={
                                                              eimg.first_name +
                                                              " " +
                                                              eimg.last_name
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                eimg.profile_image
                                                              }
                                                              alt=""
                                                              className="rounded-circle avatar-xs"
                                                            />
                                                          </Link>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  {calculateDaysDifference(
                                                    convertDateFormat(
                                                      e.task.deadline_date
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  <div>
                                                    {e.task.status ==
                                                    "active" ? (
                                                      <span className="badge rounded-pill badge-soft-success font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : e.task.status ==
                                                      "new" ? (
                                                      <span className="badge rounded-pill badge-soft-primary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : (
                                                      <span className="badge rounded-pill badge-soft-secondary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                              <Accordion defaultExpanded>
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownCircleOutlined />}
                                  aria-controls="panel2-content"
                                  id="panel2-header"
                                >
                                  <Typography>
                                    Overdue{" "}
                                    <Badge color="warning">
                                      {overdueTasks.length}
                                    </Badge>{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {/* <h4 className="card-title mb-4"></h4> */}

                                  <div className="table-responsive">
                                    <table className="table table-nowrap align-middle mb-0">
                                      <thead>
                                        <tr>
                                          <th>Task Title</th>
                                          <th>Project Name</th>
                                          <th>Assigned Team</th>
                                          <th>Deadline Date</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {overdueTasks.map((e, index) => {
                                          if (index < 6) {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <h5 className="text-truncate font-size-14 m-0">
                                                    <Link
                                                      to={"/task-remarks"}
                                                      state={{
                                                        id: e.task_id,
                                                        task_data: e,
                                                      }}
                                                    >
                                                      {e.task.task_title}
                                                    </Link>
                                                  </h5>
                                                </td>
                                                <td>{e.project}</td>
                                                <td>
                                                  <div className="avatar-group">
                                                    {e.assigned_to.map(
                                                      (eimg, index) => (
                                                        <div
                                                          key={index}
                                                          className="avatar-group-item"
                                                        >
                                                          <Link
                                                            to="#"
                                                            className="d-inline-block"
                                                            title={
                                                              eimg.first_name +
                                                              " " +
                                                              eimg.last_name
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                eimg.profile_image
                                                              }
                                                              alt=""
                                                              className="rounded-circle avatar-xs"
                                                            />
                                                          </Link>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  {calculateDaysDifference(
                                                    convertDateFormat(
                                                      e.task.deadline_date
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  <div>
                                                    {e.task.status ==
                                                    "active" ? (
                                                      <span className="badge rounded-pill badge-soft-success font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : e.task.status ==
                                                      "new" ? (
                                                      <span className="badge rounded-pill badge-soft-primary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : (
                                                      <span className="badge rounded-pill badge-soft-secondary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ArrowDropDownCircleOutlined />}
                                  aria-controls="panel3-content"
                                  id="panel3-header"
                                >
                                  <Typography>
                                    Completed{" "}
                                    <Badge color="info">
                                      {completedTasks.length}
                                    </Badge>{" "}
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {/* <h4 className="card-title mb-4"></h4> */}

                                  <div className="table-responsive">
                                    <table className="table table-nowrap align-middle mb-0">
                                      <thead>
                                        <tr>
                                          <th>Task Title</th>
                                          <th>Project Name</th>
                                          <th>Assigned Team</th>
                                          <th>Completed Date</th>
                                          <th>Status</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {completedTasks.map((e, index) => {
                                          if (index < 6) {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <h5 className="text-truncate font-size-14 m-0">
                                                    <Link
                                                      to={"/task-remarks"}
                                                      state={{
                                                        id: e.task_id,
                                                        task_data: e,
                                                      }}
                                                    >
                                                      {e.task.task_title}
                                                    </Link>
                                                  </h5>
                                                </td>
                                                <td>{e.project}</td>
                                                <td>
                                                  <div className="avatar-group">
                                                    {e.assigned_to.map(
                                                      (eimg, index) => (
                                                        <div
                                                          key={index}
                                                          className="avatar-group-item"
                                                        >
                                                          <Link
                                                            to="#"
                                                            className="d-inline-block"
                                                            title={
                                                              eimg.first_name +
                                                              " " +
                                                              eimg.last_name
                                                            }
                                                          >
                                                            <img
                                                              src={
                                                                eimg.profile_image
                                                              }
                                                              alt=""
                                                              className="rounded-circle avatar-xs"
                                                            />
                                                          </Link>
                                                        </div>
                                                      )
                                                    )}
                                                  </div>
                                                </td>
                                                <td>
                                                  {calculateDaysDifference(
                                                    convertDateFormat(
                                                      e.task.deadline_date
                                                    )
                                                  )}
                                                </td>
                                                <td>
                                                  <div>
                                                    {e.task.status ==
                                                    "active" ? (
                                                      <span className="badge rounded-pill badge-soft-success font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : e.task.status ==
                                                      "new" ? (
                                                      <span className="badge rounded-pill badge-soft-primary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    ) : (
                                                      <span className="badge rounded-pill badge-soft-secondary font-size-11">
                                                        {e.task.status}
                                                      </span>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          }
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </Col>

                            <Col lg={4}>
                              <Card>
                                <CardBody>
                                  <CardTitle className="mb-4">
                                    Task Progress Chart
                                  </CardTitle>
                                  <PieChartCustom
                                    dataFor={this.state.optionforTask}
                                    dataColors='["--bs-success","--bs-warning","--bs-primary", "--bs-danger","--bs-info"]'
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                  </Box>
                </Col>
              </Row>
            </div>
          </Container>
          <Offcanvas
            isOpen={this.state.createActivity}
            direction="end"
            style={{ width: 500 }}
            toggle={() => this.setState({ createActivity: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ createActivity: false })}
            >
              Add New Activity
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  console.log(this.state.assignTo)

                  if (e.target.elements.activity_name.value === "") {
                    toastr.error("Activity name is required")
                    this.setState({ loading: false, disable: false })
                    return false
                  } else if (
                    e.target.elements.activity_description.value == ""
                  ) {
                    toastr.error(
                      "Please add some description related to activity"
                    )
                    this.setState({ loading: false, disable: false })
                    return false
                  } else if (e.target.elements.radiobutton.value == null) {
                    toastr.error("Select an activity color")
                    return false
                  } else {
                    var formdata = new FormData()
                    formdata.append("method", "create-activity-by-admin")
                    formdata.append(
                      "name",
                      e.target.elements.activity_name.value
                    )
                    formdata.append(
                      "desc",
                      e.target.elements.activity_description.value
                    )
                    formdata.append(
                      "color",
                      e.target.elements.radiobutton.value
                    )
                    formdata.append(
                      "assigned_to",
                      this.state.assignTo.toString()
                    )
                    var requestOptions = {
                      method: "POST",
                      body: formdata,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
                      requestOptions
                    )
                      .then(response => response.text())
                      .then(result => {
                        this.setState({ createActivity: false })
                        if (result === "success") {
                          toastr.success("Activity is added")
                          this.getAllActivities()
                          this.setState({ createActivity: false })
                        } else {
                          Alert.alert("Error while adding values")
                          this.setState({ loading: false, disable: false })
                        }
                      })
                      .catch(error => console.log("error", error))
                  }
                }}
              >
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="activity_name"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="activity_description"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Color</label>
                      {renderRadioButton("red", "red")}
                      {renderRadioButton("green", "green")}
                      {renderRadioButton("blue", "blue")}
                      {renderRadioButton("yellow", "yellow")}
                      {renderRadioButton("orange", "orange")}
                      {renderRadioButton("pink", "pink")}
                      {renderRadioButton("black", "black")}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Assign Member</label>
                      <Select
                        isMulti={true}
                        onChange={item => {
                          this.setState({ assignTo: item.map(e => e.value) })
                          console.log(item)
                          // this.setState({assignTo: item.value});
                        }}
                        options={mockData2}
                        className="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md={12} style={{ textAlign: "end" }}>
                    <button className="btn btn-primary" type="submit">
                      Add Activity
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Offcanvas
          isOpen={task_modal}
          direction="end"
          toggle={()=>this.setState({task_modal: false})}
        >
          <OffcanvasHeader
          toggle={()=>this.setState({task_modal: false})}
          >
            Create Task
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(task_info.title == ""){
                    toastr.error('Task title is required');
                    return false;
                }else if(task_info.assigned_to.length == 0){
                    toastr.error('Please select the assigned member');
                    return false;
                }else if(task_info.project_id == null){
                    toastr.error('Please select the project name');
                    return false;
                }else if(task_info.description == ""){
                    toastr.error('Task description is required');
                    return false;
                }else if(task_info.deadline_date == ""){
                    toastr.error('Task Deadline date is required');
                    return false;
                }else{
                    this.setState({task_loader : true})
                    let dataParse = JSON.parse(localStorage.getItem('authUser'));
                    let orgId = dataParse.map((e)=>e.org_id)
                    let form = new FormData();
                    const fileInput = document.querySelector('input[type="file"]')
                    const file = anyAttachmentTask === true ? fileInput.files[0] : null
                    const assignedTo = task_info.assigned_to.map((e)=>e.value);
                    form.append('method', 'create-task');
                    form.append('org_id', orgId);
                    form.append('task_title',task_info.title);
                    form.append('project_id', task_info.project_id.value);
                    form.append('task_description', task_info.description);
                    form.append('assignedTo', assignedTo.toString());
                    form.append(
                      'deadline_date',
                     formatDateTime(task_info.deadline_date),
                    );
                    if (anyAttachmentTask == true) {
                      form.append('file_upload', file);
                    }
                    axios.post(
                        process.env.REACT_APP_LEAVE_API_URL+'/tasks.php',form,{headers:{
                          "Content-Type": "multipart/form-data",
                        }}
                       ).then(result => {
                        
                          if(result.data === 'success'){
                              toastr.success('You successfully added the task');
                              this.getAllTasks()
                              this.setState({
                                task_info : {
                                  title:'',
                                  description:'',
                                  deadline_date:'',
                                  project_id:null,
                                  assigned_to : [],
                          
                                }
                              })
                              this.setState({task_loader : false, task_modal: false})
                          }else{
                              toastr.error('Error while adding the task');
                              this.setState({task_loader : false})
                          }
                      })
                      .catch(e => console.log(e));
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                  <Label>Title</Label>
                  <Input
                    name="task_title"
                    type="text"
                    className="form-control"
                    placeholder="..."

                    value={task_info.title}
                    onChange={(e)=>this.setState({task_info :{
                      ...this.state.task_info,
                      title: e.target.value 
                    }})}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Project Name</label>
                    <Select
                      onChange={item => {
                        this.setState({
                          task_info : {
                            ...task_info,
                            project_id : item
                          }
                        })
                      }}
                      options={allProjectsList}
                       value={task_info.project_id}
                      className="select2-selection"
                    />
                  </div>
                </Col>
                <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Assign Member</label>
                    <Select
                      isMulti={true}
                      onChange={item => {
                        this.setState({
                          task_info : {
                            ...task_info,
                            assigned_to : item
                          }
                        })
                      }}
                      value={task_info.assigned_to}
                      options={allEmployeeList}
                      className="select2-selection"
                    />
                  </div>
                </Col>
               
                <Col md={12} className="mb-3">
                  <Label>Description</Label>
                  <textarea
                    name="task_description"
                    type="text"
                    onChange={e=>this.setState({
                      task_info : {
                        ...task_info,
                        description : e.target.value
                      }
                    })}
                    value={task_info.description}
                    className="form-control"
                    placeholder="Type description here...."
                  ></textarea>
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Deadline Date</Label>
                  <input
                    name="deadline_date"
                    type="date"
                    onChange={(e=>this.setState({
                      task_info : {
                        ...task_info,
                        deadline_date : e.target.value
                      }
                    }))}
                    value={task_info.deadline_date}
                    className="form-control"
                 />
                </Col>
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={anyAttachmentTask}
                          onChange={() => {
                            this.setState({anyAttachmentTask: !anyAttachmentTask});
                          }}
                    />
                    </div>
                </Col>
                {
                    anyAttachmentTask == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'end'}}>
                    <button type="button"  onClick={()=>this.setState({task_modal: false})} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        task_loader  == false ? 
                        <button type="submit" className="btn btn-primary">Add Task</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
        </div>
        <ChatWidget/>
      </React.Fragment>
    )
  }
}
TaskHomePage.PropTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TaskHomePage))
