import React, { useEffect, useState } from "react"
import {
  Badge,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Modal,
  Row,
  TabContent,
  TabPane,
  Card,
  CardBody,
  Label,
  OffcanvasBody,
  OffcanvasHeader,
  Offcanvas,
  Input,
  Form,
  Spinner,
} from "reactstrap"
import * as XLSX from 'xlsx';
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import { after, map } from "lodash"
import Select from "react-select"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//Import Card invoice
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import Switch from 'react-switch';
import CardInvoice from "../Invoices/card-invoice"
import { getInvoices as onGetInvoices } from "store/actions"
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import { Avatar, Box, Paper } from "@mui/material"
import classnames from "classnames"
import axios from "axios"
import { BadgeSharp, CallSharp, DeleteSharp, HomeSharp, LinkSharp, MailOutlineSharp, ModeEditSharp, PersonOutlined, TipsAndUpdatesSharp, WorkspacePremiumSharp } from "@mui/icons-material"
import Swal from "sweetalert2"
import DatePicker from "react-flatpickr";
import ChatWidget from "components/Common/ChatWidget";
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Candidate found.</Box>
    </StyledGridOverlay>
  )
}
const Offsymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2
      }}
    >
      {" "}
      Yes
    </div>
  );
};
const PositionDetail = props => {
  //meta title
  document.title = `PositionDetails | ${process.env.REACT_APP_NAME}`
  const { state } = useLocation()
  const [invoiceData, setInvoiceData] = useState([])
  const [tableLoader, setTableLoader] = useState(false)
  const [total, setTotal] = useState(0)
  const [paid, setPaid] = useState(0)
  const [overdue, setOverdue] = useState(0)
  const [pending, setPending] = useState(0)
  const [shortlisted, setShortListed] = useState(0)
  const [hired, setHired] = useState(0)
  const [paidData, setPaidData] = useState([])
  const [pendingData, setPendingData] = useState([])
  const [overdueData, setOverdueData] = useState([])
  const [shortListedData, setShortListedData] = useState([])
  const [HiredData, setHiredData] = useState([])
  const [isRight, setIsRight] = useState(false)
  const [loading, setLoading] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [EditLoader, setEditLoader] = useState(false)
  const [modal_center, setmodal_center] = useState(false)
  const [modal_centerother, setmodal_centerother] = useState(false)
  const [shownTemplate, setShownTemplate] = useState(false)
  const [switch1, setswitch1] = useState(false)
  const [hiringFor, setHiringFor] = useState([])
  const [addMemberModal, setaddMemberModal] = useState(false)
  const [addEmployeeStatus, setaddEmployeeStatus] = useState(false)
  const [markedAttendance, setmarkedAttendance] = useState(false)
  const [reviewLeaves, setreviewLeaves] = useState(false)
  const [ByManual, setByManual] = useState(false)
  const [trackStatus, settrackStatus] = useState(false)
  const [ByFaceRecong, setByFaceRecong] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [addDepartmentModal, setaddDepartmentModal] = useState(false)
  const [payrollType, setpayrollType] = useState(null)
  const [selectWorkModal, setSelectWorkModal] = useState(null)
  const [payrollRate, setpayrollRate] = useState(null)
  const [payrollCurrency, setpayrollCurrency] = useState("USD")
  
  const [supervisorArray, setsupervisorArray] = useState([])
  const [separatedEmp, setSepratedEmp] = useState([])
  const [mockDataOther, setmockDataOther] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [mockData, setmockData] = useState([])
  const [selectedRole, setSelectedRole] = useState(null)
  const [avatarSrc, setAvatarSrc] = useState("")
  function getCurrentDate() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") // January is 0!
    const yyyy = today.getFullYear()

    return `${yyyy}-${mm}-${dd}`
  }
  let firstDateIwant = getCurrentDate()
  const [startDate, setStartDate] = useState(new Date(firstDateIwant))
  const [StatusForPayRollNew, setStatusForPayRollNew] = useState("")
  const [departmentsList, setDepartmentList] = useState([])
  let arrayForMe = []
  for (let i = 1; i <= 31; i++) {
    arrayForMe.push({
      label: i,
      value: i,
    })
  }
  const [singleCandidateData, setSingelCandidateData] = useState({
    id : '',
    fullname:'',
    email:'',
    phone:'',
    address:'',
    qualification:'',
    experience:'',
    cv_url:'',
    hiring_for:'',
    status:''
  })
  const handleFileUpload = (event) => {
    console.log(event)
    event.preventDefault();
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      console.log(jsonData)
      let json = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_id)
      const requestData = {
          hiring_for : state.obj.id,
          org_id : json[0]
      };
      console.log(requestData);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      
      const raw = {
        data: JSON.stringify(jsonData),
        requestData:JSON.stringify(requestData),

      };
      
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(raw),
        redirect: "follow"
      };
      
      fetch(process.env.REACT_APP_LEAVE_API_URL+"/insert-candidates.php", requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result.message == 'success'){
                setmodal_centerother(!modal_centerother)
                toastr.success('Candidates uploaded successgfully');
            }
        })
        .catch((error) => console.error(error));
      // Here you can send the JSON data to your API
      // Example:

      // fetch('your-api-endpoint', {

      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(jsonData),
      // })
      // .then(response => response.json())
      // .then(data => {
      //   console.log('Success:', data);
      // })
      // .catch((error) => {
      //   console.error('Error:', error);
      // });
    };

    reader.readAsArrayBuffer(file);
  };

  useEffect(() => {
    fetchPositionList()
    fetchAllCandidateList()
    fetchCompaines()
    getAllActivities()
    fetchDepartment()
    fetchCompaines()
  }, [])
  const onChangeLocation = val => {
    fetchSupervisorFor(val.value)
  }
  const fetchPositionList = () => {
    let json = JSON.parse(localStorage.getItem("authUser")).map(e => e.org_id)
    const formdata = new FormData()
    formdata.append("method", "fetch_position_list")
    formdata.append("org_id", json)
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch("https://api.frontforcecrm.com/positions.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        setHiringFor(result)
      })
      .catch(error => console.error(error))
  }
  const fetchSingleCandidate = (passing) =>{
    let form = new FormData();
    form.append('method','fetch_single_candidate');
    form.append('id',passing)
    axios.post(process.env.REACT_APP_LEAVE_API_URL+'/positions.php',form).then((res)=>{
        setSingelCandidateData(res.data)
        tog_center()
    }).catch((err)=>{
        console.log(err)
    })

  }

  const fetchAllCandidateList = () => {
    let form = new FormData()
    let json = JSON.parse(localStorage.getItem("authUser")).map(e => e.org_id)
    form.append("method", "fetch_candidates")
    form.append("org_id", json)
    form.append("hiring_for", state.obj.id)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/positions.php", form)
      .then(res => {
        if (res.data.length == 0) {
          setTableLoader(false)
        }
        setInvoiceData(res.data)
        let paidCount = 0
        let overdueCount = 0
        let pendingCount = 0
        let paidAmount = 0
        let overdueAmount = 0
        let pendingAmount = 0
        let shortlisted = 0
        let hired = 0
        let paidData = []
        let overdueData = []
        let pendingData = []
        let shortlistedData = []
        let hiredData = []
        res.data.forEach(item => {
          switch (item.status) {
            case "new":
              // paidAmount = parseFloat(item.total) + paidAmount
              paidCount++
              paidData.push(item)
              // cu = item.currency
              break
            case "rejected":
              // overdueAmount = parseFloat(item.total) + overdueAmount
              overdueCount++
              overdueData.push(item)
              break
            case "schedule":
              // pendingAmount = parseFloat(item.total) + pendingAmount
              pendingCount++
              pendingData.push(item)
              break
            case "short_listed":
              // pendingAmount = parseFloat(item.total) + pendingAmount
              shortlisted++
              shortlistedData.push(item)
              break
            case "hired":
              // pendingAmount = parseFloat(item.total) + pendingAmount
              hired++
              hiredData.push(item)
              break
            default:
              // Handle unexpected status
              break
          }
        })

        setTotal(paidCount + pendingCount + overdueCount + hired + shortlisted)

        setPending(pendingCount)

        setOverdue(overdueCount)
        setHiredData(hiredData)
        setHired(hired)
        setShortListedData(shortlistedData)
        setShortListed(shortlisted)
        setPaid(paidCount)
        setPaidData(paidData)
        setOverdueData(overdueData)
        setPendingData(pendingData)
      })
  }
  function stringToColor(string) {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = "#"

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */

    return color
  }
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.charAt(0)}`,
    }
  }
  const handleFileChange = event => {
    const file = event.target.files[0] // Get the first selected file
    if (file) {
      const reader = new FileReader()
      reader.onload = e => {
        setAvatarSrc(e.target.result) // Update the image source with the file data
      }
      reader.readAsDataURL(file) // Read the file as a data URL
    }
  }
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }

  const columnsFor: GridColDef[] = [
    {
      field: "profile_image",
      headerName: "Image",
      width: 150,
      renderCell: param => <Avatar {...stringAvatar(param.row.fullname)} />,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 150,
      renderCell: param => <p>{param.value}</p>,
    },
    { field: "email", headerName: "Email Address", width: 150 },
    { field: "phone", headerName: "Phone Number", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: props => <p>{props.value}</p>,
    },
    {
      field: "created_at",
      headerName: "Apply Date",
      width: 150,
      renderCell: props => <p>{formatDateIwant(props.value)}</p>,
    },
    {
      field: "id",
      width: 250,
      headerName: "Actions",
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <button 
              onClick={() => {
                fetchSingleCandidate(param.value);
              }}
              className="btn btn-sm btn-soft-primary">
                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>

              </button>
            </li>

            <li>
              <button
                onClick={() => DeleteCandidates(param.value)}
                className="btn btn-sm btn-soft-danger"
              >
                <i className="bx bx-trash" id="deletetooltip" />
              </button>
            </li>
          </ul>
        )
      },
    },
  ]
  const [activeTab, setActiveTab] = useState("1")

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  function formatDateIwant(dateString) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]

    // Split the date string into year, month, and day components
    const [year, monthIndex, day] = dateString.split("-")

    // Get the month abbreviation based on the month index
    const monthAbbreviation = months[parseInt(monthIndex, 10) - 1]

    // Assemble the formatted date string
    const formattedDate = `${day} ${monthAbbreviation} ${year}`

    return formattedDate
  }
  const getBadgeColor = priority => {
    switch (priority) {
      case "High":
        return "danger"
      case "Medium":
        return "warning"
      case "Low":
        return "success"
      default:
        return "secondary" // Fallback color if priority is not recognized
    }
  }
  const DeleteCandidates = pass => {
    Swal.fire({
      title: "Confirm",
      icon: "warning",
      text: "Are you sure you want to delete this candidate. This process cannot be undo",
      showCancelButton: true,
      confirmButtonText: "Yes, I want",
      cancelButtonText: "No, I don't",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "delete_candidate")
        form.append("id", pass)
        axios
          .post(process.env.REACT_APP_LEAVE_API_URL + "/positions.php", form)
          .then(res => {
            if (res.data == " success") {
              toastr.success("You successfully delete the candidate")
              fetchAllCandidateList()
            } else {
              toastr.error("Error comes while deleting. Please try again...")
              return false
            }
          })
      } else {
        Swal.close()
      }
    })
  }
  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }
  function tog_centerother() {
    setmodal_centerother(!modal_centerother);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const runthisForaLittleFunction = val => {
    setStatusForPayRollNew(val + " to " + val)
  }

  const fetchDepartment = async () => {
    let local = localStorage.getItem("authUser")
    let mapsing = JSON.parse(local)
    let org = mapsing.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setDepartmentList(result)
      })
      .catch(error => console.log("error", error))
  }
  const fetchSupervisorFor = async val => {
    const value = localStorage.getItem("authUser")
    let jsonFormat = JSON.parse(value)
    let org = jsonFormat.map(e => e.org_id)
    var formdata = new FormData()

    formdata.append("method", "select-for-supervisor")
    formdata.append("org_id", org)
    formdata.append("location_for", val)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    console.error(formdata)
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/login.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setsupervisorArray(result)
      })
      .catch(error => console.log("error", error))
  }
  const fetchCompaines = async () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/company.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        if (result.length !== 0) {
          result.map(e => {
            let val = {
              value: e.location,
              label: e.location,
            }
            mockData2.push(val)
          })
        } else {
          alert("Please add a location. Before add employee")
        }
      })
      .catch(error => console.log("error", error))
  }
  const getAllActivities = async () => {
    // const value = await JSON.parse(AsyncStorage.getItem('@loginSession')).map((e)=>e.org_id);
    // alert(value)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }
    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        result.map(e => {
          let val = {
            value: e.name,
            label: e.name,
          }
          mockData.push(val)
        })
      })
      .catch(error => console.log("error", error))
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs breadcrumbItem="Position Detail" />

          <Row>
            <Col md={12} className="mt-3 mb-3">
              <Card>
                <CardBody>
                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={6}>
                          <h5>Designation</h5>
                          <p>{state.obj.designation}</p>
                        </Col>
                        <Col md={6}>
                          <h5>Department</h5>
                          <p>{state.obj.department}</p>
                        </Col>
                        <Col md={6}>
                          <h5>No of Vacancies</h5>
                          <p>{state.obj.vacancies}</p>
                        </Col>
                        <Col md={6}>
                          <h5>Pirority</h5>

                          {state.obj.priority && (
                            <Badge color={getBadgeColor(state.obj.priority)}>
                              {state.obj.priority}
                            </Badge>
                          )}
                        </Col>
                        <Col md={6}>
                          <h5>Posted Date</h5>
                          <p>{formatDateIwant(state.obj.created_at)}</p>
                        </Col>
                        <Col md={6}>
                          <h5>Apply Date</h5>
                          <p>{formatDateIwant(state.obj.last_date)}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className="text-end">
                      <button
                        className="btn btn-primary"
                        onClick={toggleRightCanvas}
                      >
                        Add Candidate
                      </button>
                      <button
                      style={{marginLeft:10}}
                        className="btn btn-info"
                        onClick={tog_centerother}
                      >
                        Upload Candidate
                      </button>
                      {/* <input type="file" onChange={handleFileUpload} style={{display:'none'}} id="hidden_input"/> */}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col xl="12" className="mt-4">
              <Nav tabs className="nav-tabs-custom" role="tablist">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "1",
                    })}
                    onClick={() => {
                      toggleTab("1")
                    }}
                  >
                    Total <Badge color="secondary">{total}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "2",
                    })}
                    onClick={() => {
                      toggleTab("2")
                    }}
                  >
                    New <Badge color="success">{paid}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3")
                    }}
                  >
                    Schedule <Badge color="warning">{pending}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "5",
                    })}
                    onClick={() => {
                      toggleTab("5")
                    }}
                  >
                    Short listed <Badge color="success">{shortlisted}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "6",
                    })}
                    onClick={() => {
                      toggleTab("6")
                    }}
                  >
                    Hired <Badge color="warning">{hired}</Badge>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "4",
                    })}
                    onClick={() => {
                      toggleTab("4")
                    }}
                  >
                    Rejected <Badge color="danger">{overdue}</Badge>
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <TabContent activeTab={activeTab} className="p-3">
              <TabPane tabId="1">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={invoiceData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={paidData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={pendingData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={overdueData}
                        loading={
                          invoiceData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="5">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={shortListedData}
                        loading={
                          shortListedData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col md={12} className="mt-4">
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        rows={HiredData}
                        loading={
                          HiredData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                      />
                    </Box>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Row>
          <Offcanvas
            isOpen={isRight}
            direction="end"
            style={{ width: 500 }}
            toggle={toggleRightCanvas}
          >
            <OffcanvasHeader toggle={toggleRightCanvas}>
              Add Candidate
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  if (e.target.elements.fullname.value == "") {
                    toastr.error("Fullname is required")
                    return false
                  } else if (e.target.elements.email.value == "") {
                    toastr.error("Email is required")
                    return false
                  } else if (e.target.elements.phone.value == "") {
                    toastr.error("Phone is required")
                    return false
                  } else if (e.target.elements.address.value == "") {
                    toastr.error("Address is required")
                    return false
                  } else if (e.target.elements.experience.value == "") {
                    toastr.error("Experience is required")
                    return false
                  } else {
                    setLoading(true)
                    const file = e.target.elements.file.files[0]
                    let form = new FormData()
                    let json = JSON.parse(localStorage.getItem("authUser")).map(
                      e => e.org_id
                    )
                    form.append("method", "add_candidate")
                    form.append("org_id", json)
                    form.append("fullname", e.target.elements.fullname.value)
                    form.append("email", e.target.elements.email.value)
                    form.append("phone", e.target.elements.phone.value)
                    form.append("address", e.target.elements.address.value)
                    form.append(
                      "qualification",
                      e.target.elements.qualification.value
                    )
                    form.append(
                      "experience",
                      e.target.elements.experience.value
                    )
                    form.append(
                      "hiring_for",
                      state.obj.id
                    )
                    if (file !== undefined) {
                      form.append("cv_file", file)
                    }
                    axios
                      .post(
                        process.env.REACT_APP_LEAVE_API_URL + "/positions.php",
                        form,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then(res => {
                        setLoading(false)

                        if (res.data == " success") {
                          setIsRight(!isRight)
                          fetchAllCandidateList()
                          e.target.elements.fullname.value = ""
                          e.target.elements.email.value = ""
                          e.target.elements.phone.value = ""
                          e.target.elements.address.value = ""
                          e.target.elements.qualification.value = ""
                          e.target.elements.experience.value = ""
                          Swal.fire({
                            title: "Success",
                            icon: "success",
                            text: "You successfully added the candidate",
                            timer: 2000,
                          })
                        } else if (res.data == " Email is already exists") {
                          Swal.fire({
                            title: "Error",
                            icon: "error",
                            text: "Email is already exists",
                          })
                        } else {
                          Swal.fire({
                            title: res.data,
                            icon: "error",
                            text: "Error while adding please check your input fields and try again",
                          })
                        }
                      })
                  }
                }}
              >
                <Row>
                  <Col md={12} className="mb-3">
                    <Label>Full Name</Label>
                    <Input
                      type="text"
                      name="fullname"
                      className="form-control"
                      
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Email Address</Label>
                    <Input type="email" name="email" className="form-control" />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Phone Number</Label>
                    <Input type="tel" name="phone" className="form-control" />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Address</Label>
                    <Input
                      type="text"
                      name="address"
                      className="form-control"
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Qualification</Label>
                    <Input
                      type="text"
                      name="qualification"
                      className="form-control"
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>
                      Experience <small>in years</small>
                    </Label>
                    <Input
                      type="number"
                      name="experience"
                      min={1}
                      className="form-control"
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Hiring For</Label>
                    <Select
                      className="select2-selection"
                      name="hiring_for"
                      options={hiringFor}
                      isDisabled={true}
                      value={{
                        label: state.obj.designation,
                        value: state.obj.id,
                      }}
                    />
                  </Col>
                  <Col md={12} className="mb-3">
                    <Label>Upload CV</Label>
                    <Input
                      type="file"
                      accept=".pdf,.docx"
                      name="file"
                      className="form-control"
                    />
                  </Col>
                  <Col md={12} className="mt-4">
                    {loading == true ? (
                      <Spinner color="primary" size={"md"} />
                    ) : (
                      <button className="btn btn-primary">Add Candidate</button>
                    )}
                    <span
                      style={{ marginLeft: 10 }}
                      onClick={toggleRightCanvas}
                      className="btn btn-danger"
                    >
                      Cancel
                    </span>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Modal
                      isOpen={modal_center}
                      toggle={() => {
                        tog_center();
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Candidate Info</h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_center(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      {
                        editMode == false?
                        <Row>
                           <Col md={12} className="marginClass">
                                <Row>
                                    <Col md={6}>
                                    <h6><BadgeSharp/>Candidate Status</h6>
                                  <p>{singleCandidateData.status}</p>
                                    </Col>
                                    <Col md={6} className="text-end">
                                    <h6 onClick={()=>setEditMode(!editMode)} style={{cursor:'pointer'}}><ModeEditSharp /></h6>
                                  
                                    </Col>
                                    </Row>
                                
                            </Col>
                            <Col md={6} className="marginClass">
                                <h6><PersonOutlined/> Full Name</h6>
                                <p>{singleCandidateData.fullname}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                                <h6><MailOutlineSharp/> Email </h6>
                                <p>{singleCandidateData.email}</p>
                            </Col>
                            <hr/>
                            <Col md={6} className="marginClass">
                                <h6><CallSharp/> Phone</h6>
                                <p>{singleCandidateData.phone}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                                <h6><HomeSharp/> Address</h6>
                                <p>{singleCandidateData.address}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                                <h6><WorkspacePremiumSharp/> Qualification</h6>
                                <p>{singleCandidateData.qualification}</p>
                            </Col>
                            <Col md={6} className="marginClass">
                                <h6><TipsAndUpdatesSharp/> Experience</h6>
                                <p>{singleCandidateData.experience} {singleCandidateData.experience == '1' ? 'Year' : 'Years'}</p>
                            </Col>
                           {
                            singleCandidateData.cv_url == "" || singleCandidateData.cv_url == null ? null: 
                            <Col md={12} className="marginClass">
                                <h6><LinkSharp/> CV URL  </h6>
                                <p><Link to={singleCandidateData.cv_url} target="_blank">{'CV Link'}</Link></p>
                                
                            </Col>
                           }
                           
                        </Row>
                        :
                        <Form onSubmit={(e)=>{
                            setEditLoader(true)
                            e.preventDefault();
                            const file = e.target.elements.file.files[0]
                            let form = new FormData();
                            form.append('method','edit_single_candidate');
                            form.append('edit_id',singleCandidateData.id)
                            form.append('fullname',singleCandidateData.fullname)
                            form.append('email',singleCandidateData.email)
                            form.append('address',singleCandidateData.address)
                            form.append('phone',singleCandidateData.phone)
                            form.append('experience',singleCandidateData.experience)
                            form.append('qualification',singleCandidateData.qualification)
                            form.append('status',singleCandidateData.status)
                            form.append('sending_email',String(switch1))
                            if(file !==undefined){
                                form.append('cv_file',file)
                            }
                            axios.post(process.env.REACT_APP_LEAVE_API_URL+'/positions.php',form,{
                                headers: {
                                  "Content-Type": "multipart/form-data",
                                },
                              }).then((res)=>{
                                setEditLoader(false)
                                fetchAllCandidateList();
                                setEditMode(!editMode)
                                setmodal_center(!modal_center)
                                if(res.data == ' success'){
                                    Swal.fire({
                                        title:'Success',
                                        text:'Candidate data is successfully updated',
                                        icon:'success',
                                        timer:2000,
                                        showCancelButton:false,
                                        showConfirmButton:false
                                    })
                                    if(singleCandidateData.status == 'hired'){
                                      if(e.target.elements.account_create_checkbox.checked){
                                        setaddMemberModal(true)
                                      }
                                    }

                                }else{
                                    Swal.fire({
                                        title:'Error',
                                        text:'Candidate data is not updated. Please try again',
                                        icon:'error',
                                    })
                                }
                            })

                        }}>

                        <Row>
                           <Col md={12} className="mb-3">
                                <Row>
                                    <Col md={6}>
                                    <h6><BadgeSharp/>Candidate Status</h6>
                                    <select className="form-control" value={singleCandidateData.status} onChange={(e)=>setSingelCandidateData((prev)=>({
                                        ...prev,
                                        status:e.target.value
                                    }))}>
                                        <option value={'new'}>New</option>
                                        <option value={'schedule'}>Schedule</option>
                                        <option value={'short_listed'}>Short Listed</option>
                                        <option value={'hired'}>Hired</option>
                                        <option value={'rejected'}>Rejected</option>
                                    </select>
                                    </Col>
                                    
                                    </Row>
                                
                            </Col>
                            <Col md={6} className="mb-3" >
                                <h6><PersonOutlined/> Full Name</h6>
                                <input
                                value={singleCandidateData.fullname} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    fullname:e.target.value
                                }))}
                                type="text"
                                className="form-control"

                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6><MailOutlineSharp/> Email </h6>
                                <input
                                value={singleCandidateData.email} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    email:e.target.value
                                }))}
                                type="email"
                                className="form-control"
                                
                                />
                            </Col>
                            <hr/>
                            <Col md={6} className="mb-3">
                                <h6><CallSharp/> Phone</h6>
                                <input
                                value={singleCandidateData.phone} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    phone:e.target.value
                                }))}
                                type="tel"
                                className="form-control"
                                
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6><HomeSharp/> Address</h6>
                                <input
                                value={singleCandidateData.address} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    address:e.target.value
                                }))}
                                type="text"
                                className="form-control"
                                
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6><WorkspacePremiumSharp/> Qualification</h6>
                                <input
                                value={singleCandidateData.qualification} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    qualification:e.target.value
                                }))}
                                type="text"
                                className="form-control"
                                
                                />
                            </Col>
                            <Col md={6} className="mb-3">
                                <h6><TipsAndUpdatesSharp/> Experience</h6>
                                <input
                                value={singleCandidateData.experience} 
                                onChange={(e)=>setSingelCandidateData((prev)=>({
                                    ...prev,
                                    experience:e.target.value
                                }))}
                                type="number"
                                min={1}
                                className="form-control"
                                
                                />
                            </Col>
                            <Col md={12} className="mb-3">
                                <h6><LinkSharp/> Upload CV</h6>
                                <Input
                                    type="file"
                                    accept=".pdf,.docx"
                                    name="file"
                                    className="form-control"
                                    />
                            </Col>
                            {
                              singleCandidateData.status == 'short_listed'? 
                              <Col md={12}>
                                <Row>
                                  <Col md={8}>
                                  <p>
                                  Send Email to Candidate for shortlisting.?

                                </p>
                                  </Col>
                                  <Col md={4} className="text-end">

                                  <Switch
                                  
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => {
                                      setswitch1(!switch1);
                                    }}
                                    checked={switch1}
                                  />
                                  </Col>
                                </Row>
                               
                              </Col> : null
                            }
                            {
                              singleCandidateData.status == 'hired'? 
                              <Col md={12}>
                                <Row>
                                  <Col md={8}>
                                  <p>
                                  Send Email to Candidate for Hired this job.?

                                </p>
                                  </Col>
                                  <Col md={4} className="text-end">

                                  <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    className="me-1 mb-sm-8 mb-2"
                                    onColor="#626ed4"
                                    onChange={() => {
                                      setswitch1(!switch1);
                                    }}
                                    checked={switch1}
                                  />
                                  </Col>
                                  <Col md={12}>
                                    <input type="checkbox"
                                    id="account_checkbox"
                                    name="account_create_checkbox"
                                    />&nbsp;&nbsp;<label htmlFor="account_checkbox">Create an account</label>
                                  </Col>
                                </Row>
                               
                              </Col> : null
                            }
                           
                           <Col md={12} className="mt-4">
                           {
                            EditLoader == true ? 
                            <Spinner color="primary" size={'md'}/> :  <button className="btn btn-primary" type="submit" >Save Changes</button>
                           }
                            <span style={{marginLeft:10}} className="btn btn-danger" type="button" onClick={()=>setEditMode(!editMode)}>Cancel</span>
                           </Col>
                           
                        </Row>
                        </Form>
                      }
                      
                      </div>
                    </Modal>
                    <Modal
                      isOpen={modal_centerother}
                      toggle={() => {
                        tog_centerother();
                      }}
                      centered
                      style={{width:'100%'}}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0">Upload Candidates</h5>
                       
                       
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_centerother(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                      <p onClick={()=>setShownTemplate(!shownTemplate)}>{shownTemplate == true ? 'Hide' : 'Show'} Template File</p>
                      {
                            shownTemplate == true ? 
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>
                                            fullname
                                        </th>
                                        <th>
                                            email
                                        </th>
                                        <th>
                                            phone
                                        </th>
                                        <th>
                                            address
                                        </th>
                                        <th>
                                            qualification
                                        </th>
                                        <th>
                                            experience
                                        </th>
                                        <th>
                                            profilelink
                                        </th>
                                    </tr>
                                </thead>
                            </table>:null
                        }
                        <input type="file" onChange={handleFileUpload} className="form-control"/>
                        </div>
                        </Modal>
        </Container>

        <Offcanvas
          style={{
            width: 600,
          }}
          isOpen={addMemberModal}
          direction="end"
          toggle={() => setaddMemberModal(!addMemberModal)}
        >
          <OffcanvasHeader toggle={() => setaddMemberModal(!addMemberModal)}>
            {/* <h4>Add Member</h4> */}
            Add Employee
            <p style={{ fontSize: 14, fontWeight: "normal" }}>
              Provide the valid information. Before hit save button
            </p>
          </OffcanvasHeader>
          <OffcanvasBody>
            <Container>
               <Form
                onSubmit={async e => {
                  e.preventDefault()

                  const fileInput = document.querySelector('input[type="file"]')
                  const file = fileInput.files[0]

                  if (file == undefined) {
                    toastr.error("Please select the profile image")

                    return false
                  } else if (e.target.elements.first_name.value == "") {
                    toastr.error("First name is required")
                    return false
                  } else if (e.target.elements.last_name.value == "") {
                    toastr.error("Last name is required")
                    return false
                  } else if (e.target.elements.username.value == "") {
                    toastr.error("Username is required")
                    return false
                  } else if (e.target.elements.password.value == "") {
                    toastr.error("Password is required")
                    return false
                  } else if (e.target.elements.pin.value == "") {
                    toastr.error("PIN is required")
                    return false
                  } else if (e.target.elements.select_role.value == "") {
                    toastr.error("Select the role")
                    return false
                  } else if (e.target.elements.department.value == "") {
                    toastr.error("Select the department")
                    return false
                  } else if (e.target.elements.location.value == "") {
                    toastr.error("Select the Location")
                    return false
                  } else if (e.target.elements.employement_status.value == "") {
                    toastr.error("Select the Employement Status")
                    return false
                  } else if (e.target.elements.payroll_date.value == "") {
                    toastr.error("Select the Payroll date")
                    return false
                  } else if (switch1 == true) {
                    if (e.target.elements.extra_hours.value == "") {
                      toastr.error("Add the extra hours")
                      return false
                    } else {
                      setisLoading(true)
                      let form = new FormData()
                      let localVar = localStorage.getItem("authUser")
                      let localArray = JSON.parse(localVar)
                      let org = localArray.map(e => e.org_id)
                      form.append("method", "register_user")
                      form.append(
                        "first_name",
                        e.target.elements.first_name.value
                      )
                      form.append(
                        "last_name",
                        e.target.elements.last_name.value
                      )
                      form.append(
                        "department",
                        e.target.elements.department.value
                      )
                      form.append("area", e.target.elements.location.value)
                      const dd = String(startDate.getDate()).padStart(2, "0")
                      const mm = String(startDate.getMonth() + 1).padStart(
                        2,
                        "0"
                      ) // January is 0!
                      const yyyy = startDate.getFullYear()
                      let datetosend = `${mm}/${dd}/${yyyy}`
                      form.append("hire_date", datetosend)
                      form.append("other_method", "isLogin")
                      form.append("username", e.target.elements.username.value)
                      form.append("password", e.target.elements.password.value)
                      form.append("pin", e.target.elements.pin.value)
                      form.append("org_id", org)
                      form.append("role", selectedRole)
                      form.append("status", "Enabled")
                      form.append("shift_Start", "null")
                      form.append("shift_End", "null")
                      form.append("hours_status", switch1)
                      form.append(
                        "no_of_hours",
                        switch1 === true
                          ? e.target.elements.extra_hours.value
                          : null
                      )
                      form.append("leave_right", reviewLeaves)
                      form.append("tracking_status", trackStatus)
                      form.append("addEmployeeRight", addEmployeeStatus)
                      form.append("markAttendanceRight", markedAttendance)
                      form.append("byManual", ByManual)
                      form.append("byFaceRecong", ByFaceRecong)

                      form.append("profileimage", file)
                      form.append("payrollType", payrollType)
                      form.append(
                        "employement_status",
                        e.target.elements.employement_status.value
                      )

                      form.append(
                        "payroll_cycle_date",
                        e.target.elements.payroll_date.value
                      )

                      form.append(
                        "payrollRate",
                        e.target.elements.payroll_rate.value
                      )
                      form.append(
                        "payrollCurrency",
                        e.target.elements.currency_payroll.value
                      )
                      form.append(
                        "overTimeRate",
                        switch1 === true
                          ? e.target.elements.overtime_rate.value
                          : null
                      )
                      form.append(
                        "designation_for",
                        e.target.elements.designation.value
                      )
                      form.append(
                        "super_id",
                        selectedRole == "user"
                          ? e.target.elements.supervisor_id.value
                          : null
                      )
                      try {
                        await axios
                          .post(
                            process.env.REACT_APP_LEAVE_API_URL + "/login.php",
                            form,
                            {
                              headers: {
                                "Content-Type": "multipart/form-data",
                              },
                            }
                          )
                          .then(result => {
                            setisLoading(false)
                            if (result.data == "success") {
                              toastr.success("Employee is added")
                              fetchEmployee()
                              setaddMemberModal(false)
                            } else {
                              toastr.error(`${result.data}`)
                              setisLoading(false)
                              return false
                            }
                          })
                          .catch(er => {
                            console.log(er)
                            setisLoading(false)
                          })
                      } catch (error) {
                        console.error("Error:", error)
                      }
                    }
                  } else {
                    setisLoading(true)
                    let localVar = localStorage.getItem("authUser")
                    let localArray = JSON.parse(localVar)
                    let org = localArray.map(e => e.org_id)
                    let form = new FormData()
                    form.append("method", "register_user")
                    form.append(
                      "first_name",
                      e.target.elements.first_name.value
                    )
                    form.append("last_name", e.target.elements.last_name.value)
                    form.append(
                      "department",
                      e.target.elements.department.value
                    )
                    form.append("area", e.target.elements.location.value)
                    const dd = String(startDate.getDate()).padStart(2, "0")
                    const mm = String(startDate.getMonth() + 1).padStart(2, "0") // January is 0!
                    const yyyy = startDate.getFullYear()
                    let datetosend = `${mm}/${dd}/${yyyy}`
                    form.append("hire_date", datetosend)
                    form.append("other_method", "isLogin")
                    form.append("username", e.target.elements.username.value)
                    form.append("password", e.target.elements.password.value)
                    form.append("pin", e.target.elements.pin.value)
                    form.append("org_id", org)
                    form.append("role", selectedRole)
                    form.append("status", "Enabled")
                    form.append("shift_Start", "null")
                    form.append("shift_End", "null")
                    form.append("hours_status", switch1)
                    form.append(
                      "no_of_hours",
                      switch1 === true
                        ? e.target.elements.extra_hours.value
                        : null
                    )
                    form.append("leave_right", reviewLeaves)
                    form.append("tracking_status", trackStatus)
                    form.append("addEmployeeRight", addEmployeeStatus)
                    form.append("markAttendanceRight", markedAttendance)
                    form.append("byManual", ByManual)
                    form.append("byFaceRecong", ByFaceRecong)

                    form.append("profileimage", file)
                    form.append("payrollType", payrollType)
                    form.append("select_work_modal", selectWorkModal)
                    form.append(
                      "payrollRate",
                      e.target.elements.payroll_rate.value
                    )
                    form.append(
                      "payrollCurrency",
                      e.target.elements.currency_payroll.value
                    )
                    form.append(
                      "overTimeRate",
                      switch1 === true
                        ? e.target.elements.overtime_rate.value
                        : null
                    )
                    form.append(
                      "designation_for",
                      e.target.elements.designation.value
                    )
                    form.append(
                      "super_id",
                      selectedRole == "user"
                        ? e.target.elements.supervisor_id.value
                        : null
                    )
                    form.append(
                      "employement_status",
                      e.target.elements.employement_status.value
                    )

                    form.append(
                      "payroll_cycle_date",
                      e.target.elements.payroll_date.value
                    )

                    try {
                      await axios
                        .post(
                          process.env.REACT_APP_LEAVE_API_URL + "/login.php",
                          form,
                          {
                            headers: {
                              "Content-Type": "multipart/form-data",
                            },
                          }
                        )
                        .then(result => {
                          setisLoading(false)

                          if (result.data == "success") {
                            toastr.success("Employee is added")
                            fetchEmployee()
                            setaddMemberModal(false)
                          } else {
                            setisLoading(false)
                            toastr.error(`${result.data}`)
                            return false
                          }
                        })
                        .catch(er => {
                          setisLoading(false)
                          console.log(er)
                        })
                    } catch (error) {
                      console.error("Error:", error)
                    }
                  }
                }}
              >
                <Row>
                  <Row>
                    <Col md={12} style={{ marginBottom: 20 }}>
                      <img
                        src={avatarSrc || "https://placehold.co/150x150"}
                        alt=""
                        style={{ objectFit: "cover" }}
                        className="avatar-lg rounded-circle img-thumbnail"
                      />
                      <input
                        type="file"
                        accept="image/*" // Accept only image files
                        onChange={handleFileChange} // Call handleFileChange function when file is selected
                        style={{ display: "none" }} // Hide the file input element
                      />
                      <span
                        style={{
                          background: "transparent",
                          border: 0,
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          document.querySelector('input[type="file"]').click()
                        }
                      >
                        Choose Image
                      </span>
                    </Col>
                  </Row>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>First Name</Label>
                    <Input
                      name="first_name"
                      className="form-control"
                      placeholder="First Name"
                      value={singleCandidateData.fullname.split(' ')[0]}
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Last Name</Label>
                    <Input
                      name="last_name"
                      className="form-control"
                      placeholder="Last Name"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Username</Label>
                    <Input
                      name="username"
                      className="form-control"
                      placeholder="Username"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Password</Label>
                    <Input
                      name="password"
                      type="password"
                      className="form-control"
                      placeholder="Password"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>PIN</Label>
                    <Input
                      name="pin"
                      type="password"
                      maxLength={4}
                      className="form-control"
                      placeholder="PIN"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Role</Label>
                    <Select
                      options={[
                        {
                          label: "Admin",
                          value: "admin",
                        },
                        {
                          label: "Supervisor",
                          value: "supervisor",
                        },
                        {
                          label: "User",
                          value: "user",
                        },
                      ]}
                      onChange={e => setSelectedRole(e.value)}
                      className="select2-selection"
                      name="select_role"
                    />
                  </Col>
                  {selectedRole !== null ? (
                    <Col md={12}>
                      <div>
                        <p>Select Privaliages</p>
                        <div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor1"
                              name="rules"
                              onChange={e =>
                                setaddEmployeeStatus(e.target.checked)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor1"
                            >
                              &nbsp;&nbsp;Add Employee
                            </label>
                          </div>
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor2"
                              onChange={e => {
                                setmarkedAttendance(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor2"
                            >
                              &nbsp;&nbsp;Mark Attendance
                            </label>
                          </div>
                          {markedAttendance == true ? (
                            <div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  id="customCheck-outlinecolor33"
                                  // checked={reviewLeaves}
                                  onChange={e => {
                                    setByManual(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor33"
                                >
                                  &nbsp;&nbsp;By Manual
                                </label>
                              </div>
                              <div className="mb-3">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  name="rules"
                                  id="customCheck-outlinecolor35"
                                  onChange={e => {
                                    setByFaceRecong(e.target.checked)
                                  }}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customCheck-outlinecolor35"
                                >
                                  &nbsp;&nbsp;By Face
                                </label>
                              </div>
                            </div>
                          ) : null}
                          <div className="mb-3">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="rules"
                              id="customCheck-outlinecolor3"
                              // checked={reviewLeaves}
                              onChange={e => {
                                setreviewLeaves(e.target.checked)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor3"
                            >
                              &nbsp;&nbsp;Review Leaves
                            </label>
                          </div>
                          <div className=" mb-3">
                            <input
                              type="checkbox"
                              name="rules"
                              className="form-check-input"
                              id="customCheck-outlinecolor4"
                              onChange={e => settrackStatus(e.target.checked)}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor4"
                            >
                              &nbsp;&nbsp;Enable GPS
                            </label>
                          </div>

                          {/* <div className="form-check form-checkbox-outline form-check-danger">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customCheck-outlinecolor5"
                              checked={customOutlineDanger}
                              onChange={() => {
                                setcustomOutlineDanger(!customOutlineDanger)
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customCheck-outlinecolor5"
                            >
                              Checkbox Outline Danger
                            </label>
                          </div> */}
                        </div>
                      </div>
                    </Col>
                  ) : null}
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Department</Label>
                    <Select
                      options={mockData}
                      className="select2-selection"
                      name="department"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Select Location</Label>
                    <Select
                      options={mockData2}
                      onChange={e => onChangeLocation(e)}
                      className="select2-selection"
                      name="location"
                    />
                  </Col>
                  {selectedRole == "user" ? (
                    <Col md={6} style={{ marginBottom: 10 }}>
                      <Label>Supervisor Name</Label>
                      <Select
                        options={supervisorArray}
                        className="select2-selection"
                        name="supervisor_id"
                      />
                    </Col>
                  ) : null}
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Employement Status</Label>
                    <Select
                      options={[
                        {
                          label: "Internship",
                          value: "Internship",
                        },
                        {
                          label: "Permanent",
                          value: "Permanent",
                        },
                        {
                          label: "Project Based",
                          value: "Project Based",
                        },
                        {
                          label: "Freelancer",
                          value: "Freelancer",
                        },
                        {
                          label: "Probation",
                          value: "Probation",
                        },
                        {
                          label: "Contract Based",
                          value: "Contract Based",
                        },
                      ]}
                      className="select2-selection"
                      name="employement_status"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Designation Name</Label>
                    <input
                      name="designation"
                      type="text"
                      placeholder="Designation"
                      className="form-control"
                    />
                  </Col>
                  <Col md={6} style={{ marginBottom: 10 }}>
                    <Label>Hiring Date</Label>
                    <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="form-control"
                      dateFormat="d-MM-yyyy"
                      name="hire_date"
                      placeholderText="Select date"
                    />
                  </Col>
                  <Col md={12} style={{ marginBottom: 10 }}>
                    <Label>Work Modal</Label>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor11"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setSelectWorkModal(e.target.value)}
                            value={"remote"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor11"
                          >
                            Remote
                          </label>
                        </div>
                    <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor112"
                            name="customRadiocolor11"
                            className="form-check-input"
                            onChange={e => setSelectWorkModal(e.target.value)}
                            value={"on_site"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor112"
                          >
                            On-Site
                          </label>
                        </div>
                  </Col>

                  <Row>
                    <Col md={6} style={{ marginBottom: 10 }}>
                      <Label>Allow the Member for Extra Hours</Label>
                    </Col>
                    <Col md={6} style={{ textAlign: "end" }}>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        className="me-1 mb-sm-8 mb-2"
                        onColor="#626ed4"
                        onChange={() => {
                          setswitch1(!switch1)
                        }}
                        checked={switch1}
                      />
                    </Col>
                  </Row>
                  {switch1 === true ? (
                    <Col md={12}>
                      <input
                        name="extra_hours"
                        type="text"
                        placeholder="No of Hours"
                        className="form-control"
                      />
                    </Col>
                  ) : null}
                  <Col md={12} className="mt-3">
                    <h5>Payroll information</h5>
                  </Col>
                  <Col md={12}>
                    <Label>Select the payout type</Label>
                    <Row>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor1"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"daily"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor1"
                          >
                            Daily
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor2"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"hourly"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor2"
                          >
                            Hourly
                          </label>
                        </div>
                      </Col>
                      <Col md={3}>
                        <div className="form-check form-radio-primary mb-3">
                          <input
                            type="radio"
                            id="customRadiocolor3"
                            name="customRadiocolor1"
                            className="form-check-input"
                            onChange={e => setpayrollType(e.target.value)}
                            value={"monthly"}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="customRadiocolor3"
                          >
                            Monthly
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={12} className="mt-2">
                    <Label>
                      {" "}
                      <span style={{ textTransform: "capitalize" }}>
                        {payrollType}
                      </span>{" "}
                      Rate
                    </Label>
                    <input
                      type="text"
                      className="form-control"
                      name="payroll_rate"
                    />
                  </Col>
                  <Col md={12} className="mt-2">
                    <Label>Select Currency</Label>
                    <input
                      type="text"
                      className="form-control"
                      name="currency_payroll"
                    />
                    <Col md={12} className="mt-2">
                      <Label>
                        Payroll Cycle Date{" "}
                        {StatusForPayRollNew == "" ? null : StatusForPayRollNew}
                      </Label>

                      <Select
                        onChange={e => runthisForaLittleFunction(e.value)}
                        options={arrayForMe}
                        className="select2-selection"
                        name="payroll_date"
                      />
                    </Col>
                  </Col>
                  {switch1 == true ? (
                    <Col md={12} className="mt-2">
                      <Label>Overtime Rate</Label>
                      <input
                        type="text"
                        className="form-control"
                        name="overtime_rate"
                      />
                    </Col>
                  ) : null}
                  <Col md={12} style={{ paddingTop: 20,paddingBottom:40 }}>
                    {isLoading == false ? (
                      <button type="submit" className="btn btn-primary">
                        Save Member
                      </button>
                    ) : (
                      <Spinner color="info" />
                    )}
                    &nbsp;&nbsp;
                    <button
                      onClick={() => setaddMemberModal(!addMemberModal)}
                      className="btn btn-danger"
                    >
                      Cancel
                    </button>
                  </Col>
                </Row>
               </Form>
            </Container>
          </OffcanvasBody>
        </Offcanvas>
      </div>
      <ChatWidget/>
    </React.Fragment>
  )
}

PositionDetail.propTypes = {
  invoices: PropTypes.array,
  onGetInvoices: PropTypes.func,
}

export default withRouter(PositionDetail)
