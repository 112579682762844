import React, { useEffect, useMemo, useState } from "react"
import { Link, useLocation, useParams } from "react-router-dom"
import { isEmpty } from "lodash"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { jobs } from "../../common/data"
import Switch from 'react-switch'
//import images
import Select from "react-select"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  getJobList as onGetJobList,
  addNewJobList as onAddNewJobList,
  updateJobList as onUpdateJobList,
  deleteJobList as onDeleteJobList,
} from "store/actions"

import {
  JobNo,
  JobTitle,
  CompanyName,
  Location,
  Experience,
  Position,
  Type,
  PostedDate,
  LastDate,
  Status,
} from "./TaskCol"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Spinner
} from "reactstrap"
import ChatWidget from "components/Common/ChatWidget"
import Swal from "sweetalert2"

const ProjectStatus = () => {
  //meta title
  const { id } = useParams()
  const [counter, setCounter] = useState(1)
  const [assignTo, setassignTo] = useState([])
  const [mockData2, setmockData2] = useState([])
  const [isAttachmentSwitch, setisAttachmentSwitch] = useState(false)
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [startDate,setStartDate ]  = useState(new Date());
  const [projectDescription, setProjectDescription] = useState('')
  
  document.title = `${id}| ${process.env.REACT_APP_NAME}`
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [jobsList, setJobsList] = useState([])
  const [DataToBeSetTasks, setDataToBeSetTasks] = useState([])
  const [job, setJob] = useState(null)
  const [createTaskShow, setCreateTaskShown] = useState(false)
  const [clientType, setClientType] = useState('')
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      jobId: (job && job.jobId) || "",
      jobTitle: (job && job.jobTitle) || "",
      companyName: (job && job.companyName) || "",
      location: (job && job.location) || "",
      experience: (job && job.experience) || "",
      position: (job && job.position) || "",
      type: (job && job.type) || "",
      status: (job && job.status) || "",
    },
    validationSchema: Yup.object({
      jobId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Job Id")
        .required("Please Enter Your Job Id"),
      jobTitle: Yup.string().required("Please Enter Your Job Title"),
      companyName: Yup.string().required("Please Enter Your Company Name"),
      location: Yup.string().required("Please Enter Your Location"),
      experience: Yup.string().required("Please Enter Your Experience"),
      position: Yup.string().required("Please Enter Your Position"),
      type: Yup.string().required("Please Enter Your Type"),
      status: Yup.string().required("Please Enter Your Status"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateJobList = {
          id: job ? job.id : 0,
          jobId: values.jobId,
          jobTitle: values.jobTitle,
          companyName: values.companyName,
          location: values.location,
          experience: values.experience,
          position: values.position,
          type: values.type,
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values.status,
        }
        // update Job
        dispatch(onUpdateJobList(updateJobList))
        validation.resetForm()
      } else {
        const newJobList = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          jobId: values["jobId"],
          jobTitle: values["jobTitle"],
          companyName: values["companyName"],
          location: values["location"],
          experience: values["experience"],
          position: values["position"],
          type: values["type"],
          postedDate: "02 June 2021",
          lastDate: "25 June 2021",
          status: values["status"],
        }
        // save new Job
        dispatch(onAddNewJobList(newJobList))
        validation.resetForm()
      }
      toggle()
    },
  })
  const fetchEmployeeList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    
        let form  = new FormData();
        form.append('method','fetch-clients');
        
        form.append('org_id',org);
        var requestOptions = {
          method:'POST',
          body:form
        }
        fetch(process.env.REACT_APP_LEAVE_API_URL+'/clients.php',requestOptions).then((res)=>res.json()).then((result)=>{
        console.log(result);  
        setmockData2(result);
        
        }).catch((er)=>{
          console.log(er)
        })
      
  }
  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const formatDateTime = (inputDate) => {
    // Parse the input date string into a Date object
    const date = new Date(inputDate);
    
    // Extract the year, month, and day components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    
    // Construct the formatted date string in MM/DD/YYYY format
    const formattedDate = `${month}/${day}/${year}`;
    
    return formattedDate;
  }
  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Yes
      </div>
    );
  };
  
 
  useEffect(() => {
    getAllTasks();
    fetchEmployeeList()
   
  }, [])


  const toggle = () => {
    if (modal) {
      setModal(false)
      setJob(null)
    } else {
      setModal(true)
    }
  }


  const getAllTasks = () => {
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    
      var formdata = new FormData()
      formdata.append("org_id", orgId)
      formdata.append("status", id)
      formdata.append("method", "fetch-projects-by-status")

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(process.env.REACT_APP_LEAVE_API_URL+"/projects.php", requestOptions)
        .then(response => response.json())

        .then(result => setJobsList(result.reverse()))
        .catch(error => console.log("error", error))
  };
  const onClickDelete = (id)=>{
   
    Swal.fire({
      title: "Delete Task",
      html: "<p>Are you sure to delete this Project</p> <p><strong style='color:red'>Note:</strong> This will remove the tasks linked to that project</p>",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "red",
      confirmButtonColor: "green",
    }).then(result => {
      if (result.isConfirmed) {
        // Handle action for Button 1
        let form = new FormData()
        form.append("method", "delete-project")
        form.append("delete_id", id)
        var requestOptions = {
          method: "POST",
          body: form,
        }
        fetch(
          process.env.REACT_APP_LEAVE_API_URL + "/projects.php",
          requestOptions
        )
          .then(res => res.text())
          .then(result => {
            if (result == "success") {
              Swal.fire("Deleted", "Project is successfully deleted")
              getAllTasks();
            } else {
              Swal.fire(
                "Server Error",
                "Error while deleting the Project. Try again"
              )
            }
          })
      } else if (result.dismiss === "cancel") {
        Swal.close()
      }
    })
  }
  const columns = useMemo(
    () => [
      {
        Header: "Project Name",
        accessor: "projectName",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Client Name",
        accessor: "client_name",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Client Phone",
        accessor: "client_email",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value}</p>
        },
      },
      {
        Header: "Created By",
        accessor: "created_by.first_name",
        filterable: true,
        Cell: cellProps => {
          return <p>{cellProps.value} {cellProps.row.original.created_by.last_name}</p>
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        disableFilters: true,
        Cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link to={`/project-overview/${cellProps.value}`}
                 state={{
                    project:cellProps.cell.row.original
                 }} 
                   className="btn btn-sm btn-soft-success">
                    
                  <i className="mdi mdi-file-edit-outline" id="viewtooltip"></i>
                </Link>
              </li>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>
              <li>
                <Link
                  to="#"
                  
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const jobData = cellProps.row.original.id;
                  onClickDelete(jobData);
                  }}
                >
                  <i className="mdi mdi-delete-outline" id="deletetooltip" />
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              </li>
            </ul>
          
          )
        },
      },
     
    ],
    []
  )

  return (
    <React.Fragment>
      
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Projects" titleLink={'/projects'} breadcrumbItem={`${id} Projects`} />
          <Row>
            <Col lg="12">
              
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1"> </h5>
                    <div className="flex-shrink-0">
                      <button
                        className="btn btn-info"
                        onClick={()=>setCreateTaskShown(true)}
                        >
                        Add Project
                      </button>
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={jobsList}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={10}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
      
        </div>
      </div>
      <Offcanvas
          isOpen={createTaskShow}
          direction="bottom"
        style={{height:800}}
          toggle={()=>setCreateTaskShown(false)}
        >
          <OffcanvasHeader
          toggle={()=>setCreateTaskShown(false)}
          >
            Create Project
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(clientType == ""){
                    toastr.error('Select the client type');
                    return false;
                }else if(e.target.elements.project_name.value == ""){
                    toastr.error('Project name is required');
                    return false
                }else if(e.target.elements.project_description.value == ""){
                    toastr.error('Project name is required');
                    return false
                }else{
                  let local = JSON.parse(localStorage.getItem('authUser'));
                  let org = local.map((e)=>e.org_id)
                  let user_id = local.map((e)=>e.user_id)
                  setLoadingSpinner(true)
                  let form = new FormData();
                  const fileInput = document.querySelector('input[type="file"]')
                  const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                  form.append('method', 'create-project');
                  form.append('org_id', org );
                  form.append('client_type' , this.state.clientType);
                  if(this.state.clientType == 'new client'){
                      form.append('client_name',e.target.elements.client_name.value)
                      form.append('client_email',e.target.elements.client_email.value)
                      form.append('client_phone',e.target.elements.client_phone.value)
                      form.append('client_address',e.target.elements.client_address.value)
                  }else{
                      form.append('client_id',this.state.clientType == 'existing client' ? e.target.elements.client_id.value : null)
                  }
                  const dd = String(startDate.getDate()).padStart(2, "0")
                  const mm = String(startDate.getMonth() + 1).padStart(2, "0") // January is 0!
                  const yyyy = startDate.getFullYear()
                  let datetosend = `${mm}/${dd}/${yyyy}`
                  form.append('project_name',e.target.elements.project_name.value)
                  form.append('project_description',projectDescription)
                  form.append('deadline_date',datetosend)
                  form.append('created_by',user_id)
                  if (isAttachmentSwitch == true) {
                    form.append('file_upload', file);
                  }
                 
                  axios.post(
                    process.env.REACT_APP_LEAVE_API_URL+'/projects.php',form,{headers:{
                        "Content-Type": "multipart/form-data",
                      }}
                     ).then(result => {
                      setLoadingSpinner(false)
                        
                        if(result.data === 'success'){
                            toastr.success('You successfully added the project');
                            setCreateTaskShown(false)
                            getAllTasks()
                            
                        }else{
                            toastr.error('Error while adding the project');
                        }
                    })
                    .catch(e => {
                      setLoadingSpinner(false)
                      console.log(e)});
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                

                  <label>{'Select Client'}</label>
                    <div className="row">
                    <div className="col-md-2">

                        <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor3"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>setClientType(e.target.value)}
                              value={'existing client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor3"
                            >
                              Existing Client
                            </label>
                          </div>
                    </div>
                    <div className="col-md-2">
                           <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor2"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>setClientType(e.target.value)}
                              value={'new client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor2"
                            >
                              New Client
                            </label>
                          </div>
                    </div>
                    </div>
                  
                  
                </Col>
                
                {

                  clientType == "" ?  null:
                  ( clientType == 'existing client' ? 
                    <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Select Client</label>
                    <Select
                      
                      onChange={item => 
                        setassignTo(item.value)
                      }
                      options={mockData2}
                      name="client_id"
                      className="select2-selection"
                    />
                  </div>
                </Col>: <Col md={12}>
                    <Label>Client Information</Label>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Input
                            type="text"
                            className="form-control"
                            name="client_name"
                            placeholder="Client Name"
                            
                            />
                        </Col>
                        <Col md={4} className="mb-3">
                            <Input
                            type="email"
                            className="form-control"
                            name="client_email"
                            placeholder="Client Email"

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Input
                            type="tel"
                            className="form-control"
                            name="client_phone"
                            placeholder="Client Phone"
                            
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <Input
                            type="textarea"
                            className="form-control"
                            name="client_address"
                            placeholder="Client Address"

                            />
                        </Col>
                    </Row>
                </Col>)
                }
                <Col md={6} className="mb-3">
                  <Label>Project Name</Label>
                 <Input
                    name="project_name"
                    type="text"
                    className="form-control"
                    placeholder="..."
                 />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Deadline Date</Label>
                  <DatePicker
                      selected={startDate}
                      onChange={date => setStartDate(date)}
                      className="form-control"
                      dateFormat="d-MM-yyyy"
                      name="deadline_date"
                      placeholderText="Select date"
                    />
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Project Description</Label>
                  <CKEditor
                      editor={ClassicEditor}
                      data=""
                      
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setProjectDescription(data)
                        

                      }}
                      
                    />
                </Col>
               
                <Col md={12}>
                <div
                    style={{
                        display:'flex',
                        flexDirection: 'row',
                        columnGap:40,
                        // justifyContent: 'space-between',
                        paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            setisAttachmentSwitch(!isAttachmentSwitch);
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'start'}}>
                    <button type="button"  onClick={()=>setCreateTaskShown(false)} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Project</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
        <ChatWidget/>
    </React.Fragment>
  )
}

export default ProjectStatus
