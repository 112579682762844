import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Badge,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import PropTypes from 'prop-types'
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link, NavLink } from "react-router-dom"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import axios from "axios"
import Swal from "sweetalert2"
import classnames from "classnames"
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import {Avatar, Box, Paper, Tab, Tabs, Typography  } from "@mui/material"
import withRouter from "components/Common/withRouter"
import ChatWidget from "components/Common/ChatWidget"
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Position created yet.</Box>
    </StyledGridOverlay>
  )
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
} 
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const HomeHR = () => {
  document.title = `Recruitment | ${process.env.REACT_APP_NAME}`
  const [isRight, setIsRight] = useState(false)
  const [isEditRight, setIsEditRight] = useState(false)
  const [spinner, setSpinner] = useState(false)
  const [Editspinner, setEditspinner] = useState(false)
  const [positionData, setPositionData] = useState([])
  const [tableLoader, setTableLoader] = useState(true)
  const [highCount, setHighCount] = useState(0)
  const [mediumCount, setMediumCount] = useState(0)
  const [lowCount, setLowCount] = useState(0)
  const [jobVacCounter, setJobCatVal] = useState(0)
  const [activeTab, setActiveTab] = useState(0)
  const [singlePosition, setSinglePosition] = useState({
    id: '',
    designation:'',
    department:'',
    priority:'',
    last_date:'',
    vacancies:''
  })

  useEffect(() => {
    fetchAllPositions()
  }, [])
  const toggleRightCanvas = () => {
    setIsRight(!isRight)
  }
  const toggleRightEditCanvas = () => {
    setIsEditRight(!isEditRight)
  }
  const fetchSinglePosition  = (pass) =>{
    let form = new FormData();
    form.append('method', 'fetch_single_position');
    form.append('id' , pass);
    axios.post(process.env.REACT_APP_LEAVE_API_URL+'/positions.php',form).then((res)=>{
        setIsEditRight(!isEditRight)
        setSinglePosition(res.data);
    }).catch((er)=>console.log(er))

  }

  function getCurrentDate() {
    const currentDate = new Date()

    const year = currentDate.getFullYear()

    // Month needs to be zero-indexed, so we add 1 to get the correct month
    const month = String(currentDate.getMonth() + 1).padStart(2, "0")

    const day = String(currentDate.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }
  function formatDateIwant(dateString) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  
    // Split the date string into year, month, and day components
    const [year, monthIndex, day] = dateString.split('-');
    
    // Get the month abbreviation based on the month index
    const monthAbbreviation = months[parseInt(monthIndex, 10) - 1];
  
    // Assemble the formatted date string
    const formattedDate = `${day} ${monthAbbreviation} ${year}`;
  
    return formattedDate;
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const columnsFor: GridColDef[] = [
    {
      field: "designation",
      headerName: "Designation",
      width: 250,
      renderCell: param => <p>{param.value}</p>,
    },
    {
      field: "department",
      headerName: "Department",
      width: 250,
      renderCell: param => <p>{param.value}</p>,
    },
    { field: "vacancies", headerName: "No of Vacancies", width: 150 },
    { field: "canditates", headerName: "No of Candidates", width: 150,renderCell:(param)=><p>{param.value.length}</p> },
    { field: "priority", headerName: "Priority", width: 150 , renderCell:(param)=><p>{param.value}</p>},

    { field: "last_date", headerName: "Last Date", width: 150 ,renderCell: (param)=><p>{formatDateIwant(param.value)}</p>},
    {
      field: "id",
      width: 250,
      headerName: "Actions",
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
                to={`/position/${param.value}`}
            
                state={{
                  obj: param.row,
                }}
                className="btn btn-sm btn-soft-success"
              >
                <i className="mdi mdi-eye-outline" id="viewtooltip"></i>
              </Link>
            </li>

            <li>
              <button
                to={'/#'}
                onClick={()=>fetchSinglePosition(param.value)}
                
                className="btn btn-sm btn-soft-primary"
              >
                <i className="mdi mdi-book-edit-outline" id="viewTooltip" />
              </button>
            </li>
            <li>
              <button
                onClick={() => DeletePositionFromDB(param.row.id)}
                className="btn btn-sm btn-soft-danger"
              >
                <i className="bx bx-trash" id="deletetooltip" />
              </button>
            </li>
          </ul>
        )
      },
    },
  ]
  const DeletePositionFromDB = id => {
    Swal.fire({
      title: "Confirm Alert",
      text: "Are you sure that you want to delete this position.",
      icon: "warning",
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes, I want",
      cancelButtonText: "No, I don't",
    }).then(is => {
      if (is.isConfirmed) {
        let form = new FormData()
        form.append("method", "delete_position")
        form.append("id", id)
        axios
          .post(process.env.REACT_APP_LEAVE_API_URL + "/positions.php", form)
          .then(res => {
            if (res.data == " success") {
              fetchAllPositions()
              toastr.success("You successfully delete the position")
              return false
            } else {
              toastr.error("Error while deleting ")
            }
          })
      } else {
        Swal.close()
      }
    })
  }
  const fetchAllPositions = () => {
    let json = JSON.parse(localStorage.getItem("authUser")).map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch_positions")
    form.append("org_id", json)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/positions.php", form)
      .then(res => {
        if (res.data.length == 0) {
          setTableLoader(false)
        }
        setPositionData(res.data)
        console.log(res.data)
        let counter  = 0;
        res.data.map((e)=>{
          // console.log(e.candidats)
          e.canditates.forEach((item)=>{
            switch(item.status){
              case "hired":
                counter++;
            }

          })
        })
        console.log(counter)
        let highCount = 0;
        let mediumCount = 0;
        let lowCount = 0;
        let sum = 0;
         res.data.forEach(item => {
            switch (item.priority) {
              case "High":
                // paidAmount = parseFloat(item.total) + paidAmount
                 sum =  sum + parseFloat(item.vacancies)
                highCount++
                // paidData.push(item)
                // cu = item.currency
                break
              case "Medium":
                // overdueAmount = parseFloat(item.total) + overdueAmount
                mediumCount++
                // overdueData.push(item)
                sum =  sum + parseFloat(item.vacancies)
                break
              case "Low":
                // pendingAmount = parseFloat(item.total) + pendingAmount
                lowCount++
                // pendingData.push(item)
                sum =  sum + parseFloat(item.vacancies)
                break
              default:
                // Handle unexpected status
                break
            }
          })
          
          setJobCatVal(sum)
          setHighCount(highCount)
          setMediumCount(mediumCount)
          setLowCount(lowCount)
      })
  }
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs breadcrumbItem="Recruitment" /> */}
          <Row className="mt-4">
          <Col md={12}>
          <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Recruitment" {...a11yProps(0)} />
          <Tab label="CV Bank" {...a11yProps(1)} />
        </Tabs>
                </Box>
                <CustomTabPanel value={activeTab} index={0}>
                <Row className="row mt-4">
            <Col xl={6}>
              <div className="mb-4">
                {/* <h4>Browse Position By Name</h4> */}
                {/* <p className="text-muted">Post a positon to tell us about your job.</p> */}
              </div>
            </Col>
            <Col xl={6} className="text-end">
              <div className="mb-4">
                <button
                  onClick={() => setIsRight(!isRight)}
                  className="btn btn-primary"
                >
                  Add New
                </button>
                {/* <p className="text-muted">Post a positon to tell us about your job.</p> */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">{"Job Vacancies"}</p>
                      <h4 className="mb-0">{jobVacCounter}</h4>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                 <Link to={'/recruitment/high'}>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">{"High"}</p>
                      <h4 className="mb-0">{highCount}</h4>
                    </div>
                  </div>
                 </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                <Link to={'/recruitment/medium'}>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">{"Medium"}</p>
                      <h4 className="mb-0">{mediumCount}</h4>
                    </div>
                  </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
            <Col lg={3}>
              <Card className="mini-stats-wid">
                <CardBody>
                <Link to={'/recruitment/low'}>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <p className="text-muted fw-medium">{"Low"}</p>
                      <h4 className="mb-0">{lowCount}</h4>
                    </div>
                  </div>
                  </Link>
                </CardBody>
              </Card>
            </Col>
           
          </Row>
        
          <Row>
            <Col md={12}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <DataGrid
                  autoHeight
                  sx={{ "--DataGrid-overlayHeight": "300px" }}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={positionData}
                  loading={
                    positionData.length == 0 && tableLoader == true
                      ? true
                      : false
                  }
                  columns={columnsFor}
                />
              </Box>
            </Col>
          </Row>
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                <Row>
            <Col md={12}>
              <Box sx={{ width: "100%", height: "100%" }}>
                <DataGrid
                  autoHeight
                  sx={{ "--DataGrid-overlayHeight": "300px" }}
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  rows={positionData}
                  loading={
                    positionData.length == 0 && tableLoader == true
                      ? true
                      : false
                  }
                  columns={columnsFor}
                />
              </Box>
            </Col>
          </Row>
                </CustomTabPanel>
               
                </Box>

          </Col>
          </Row>
         
        </Container>
      </div>
      <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas}>
        <OffcanvasHeader toggle={toggleRightCanvas}>
          Add Position
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
              if (e.target.elements.designation_for.value == "") {
                toastr.error("Designation is required")
                return false
              } else if (e.target.elements.department_for.value == "") {
                toastr.error("Department is required")
                return false
              } else if (e.target.elements.no_of_vacancies.value == "") {
                toastr.error("No of vancancy is required")
                return false
              } else if (e.target.elements.pirority.value == "") {
                toastr.error("Pirority is required")
                return false
              } else if (e.target.elements.last_date.value == "") {
                toastr.error("Last Date is required")
                return false
              } else {
                setSpinner(true)
                let json = JSON.parse(localStorage.getItem("authUser")).map(
                  e => e.org_id
                )

                let form = new FormData()

                form.append("method", "insert_position")
                form.append("org_id", json)
                form.append(
                  "designation_for",
                  e.target.elements.designation_for.value
                )
                form.append(
                  "department_for",
                  e.target.elements.department_for.value
                )
                form.append(
                  "no_of_vancancies",
                  e.target.elements.no_of_vacancies.value
                )
                form.append("priority", e.target.elements.pirority.value)
                form.append("last_date", e.target.elements.last_date.value)
                axios
                  .post(
                    process.env.REACT_APP_LEAVE_API_URL + "/positions.php",
                    form
                  )
                  .then(res => {
                    setSpinner(false)

                    console.log(res.data.length)
                    if (res.data == " success") {
                      fetchAllPositions()
                      Swal.fire({
                        title: "Success",
                        icon: "success",
                        text: "You successfully posted the position",
                        timer: 2000,
                        timerProgressBar: true,
                      })
                      e.target.elements.designation_for.value = ""
                      e.target.elements.department_for.value = ""
                      e.target.elements.no_of_vacancies.value = ""
                      e.target.elements.pirority.value = ""
                      e.target.elements.last_date.value = ""
                      setIsRight(!isRight)
                    } else {
                      Swal.fire({
                        title: "Error",
                        icon: "error",
                        text: "Please check your input fields and try again",
                      })
                    }
                  })
              }
            }}
          >
            <Row>
              <Col md={12} className="mb-3">
                <Label>Designation</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="designation_for"
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>Department</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="department_for"
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>No of Vacancies</Label>
                <Input
                  type="number"
                  min={1}
                  className="form-control"
                  name="no_of_vacancies"
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>Pirority</Label>
                <select name="pirority" className="form-control">
                  <option value={"High"}>High</option>
                  <option value={"Medium"}>Medium</option>
                  <option value={"Low"}>Low</option>
                </select>
              </Col>
              <Col md={12} className="mb-3">
                <Label>Apply Last Date</Label>
                <Input
                  type="date"
                  min={getCurrentDate()}
                  className="form-control"
                  name="last_date"
                />
              </Col>
              <Col md={12} className="mt-3">
                {spinner == true ? (
                  <Spinner color="primary" size={"sm"} />
                ) : (
                  <button className="btn btn-primary" type="submit">
                    Add Position
                  </button>
                )}
                <span
                  style={{ marginLeft: 10 }}
                  className="btn btn-danger"
                  onClick={() => setIsRight(!isRight)}
                >
                  Cancel{" "}
                </span>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas isOpen={isEditRight} direction="end" toggle={toggleRightEditCanvas}>
        <OffcanvasHeader toggle={toggleRightEditCanvas}>
          Edit Position
        </OffcanvasHeader>
        <OffcanvasBody>
          <Form
            onSubmit={e => {
              e.preventDefault()
             
                setEditspinner(true)
                
                let form = new FormData()

                form.append("method", "edit_position")
                form.append("edit_id", singlePosition.id)
                form.append(
                  "designation_for",
                  singlePosition.designation
                )
                form.append(
                  "department_for",
                  singlePosition.department
                )
                form.append(
                  "no_of_vancancies",
                  singlePosition.vacancies
                )
                form.append("priority",  singlePosition.priority)
                form.append("last_date",  singlePosition.last_date)
                axios
                  .post(
                    process.env.REACT_APP_LEAVE_API_URL + "/positions.php",
                    form
                  )
                  .then(res => {
                    setEditspinner(false)
                    if (res.data == " success") {
                      fetchAllPositions()
                      Swal.fire({
                        title: "Success",
                        icon: "success",
                        text: "You successfully edit the position",
                        timer: 2000,
                        timerProgressBar: true,
                      })
              
                      setIsEditRight(!isEditRight)
                    } else {
                      Swal.fire({
                        title: "Error",
                        icon: "error",
                        text: "Please check your input fields and try again",
                      })
                    }
                  })
              
            }}
          >
            <Row>
              <Col md={12} className="mb-3">
                <Label>Designation</Label>
                
                <Input
                  type="text"
                  className="form-control"
                  name="designation_for"
                  value={singlePosition.designation}
                  onChange={(e)=>setSinglePosition((pev)=>({
                    ...pev,
                    designation:e.target.value
                  }))}
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>Department</Label>
                <Input
                  type="text"
                  className="form-control"
                  name="department_for"
                  value={singlePosition.department}
                  onChange={(e)=>setSinglePosition((pev)=>({
                    ...pev,
                    department:e.target.value
                  }))}
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>No of Vacancies</Label>
                <Input
                  type="number"
                  min={1}
                  className="form-control"
                  name="no_of_vacancies"
                  value={singlePosition.vacancies}
                  onChange={(e)=>setSinglePosition((pev)=>({
                    ...pev,
                    vacancies:e.target.value
                  }))}
                />
              </Col>
              <Col md={12} className="mb-3">
                <Label>Pirority</Label>
                <select
                    value={singlePosition.priority}
                    onChange={(e)=>setSinglePosition((pev)=>({
                      ...pev,
                      priority:e.target.value
                    }))}
                
                name="pirority" className="form-control">
                  <option value={"High"}>High</option>
                  <option value={"Medium"}>Medium</option>
                  <option value={"Low"}>Low</option>
                </select>
              </Col>
              <Col md={12} className="mb-3">
                <Label>Apply Last Date</Label>
                <Input
                  type="date"
                  min={getCurrentDate()}
                  className="form-control"
                  name="last_date"
                  value={singlePosition.last_date}
                  onChange={(e)=>setSinglePosition((pev)=>({
                    ...pev,
                    last_date:e.target.value
                  }))}
                />
              </Col>
              <Col md={12} className="mt-3">
                {Editspinner == true ? (
                  <Spinner color="primary" size={"sm"} />
                ) : (
                  <button className="btn btn-primary" type="submit">
                    Edit Position
                  </button>
                )}
                <span
                  style={{ marginLeft: 10 }}
                  className="btn btn-danger"
                  onClick={() => setIsRight(!isRight)}
                >
                  Cancel{" "}
                </span>
              </Col>
            </Row>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
      <ChatWidget/>
    </React.Fragment>
  )
}


HomeHR.propTypes = {
    invoices: PropTypes.array,
    onGetInvoices: PropTypes.func,
}
  

export default withRouter(HomeHR)
