import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Map,
  InfoWindow,
  Marker,
  GoogleApiWrapper,
  Circle,
} from "google-maps-react"
import { connect } from "react-redux"
// import LightData from "./LightData"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import axios from "axios"
import { Skeleton } from "@mui/material"
import { Link } from "react-router-dom"
import ChatWidget from "components/Common/ChatWidget"
const LoadingContainer = () => <div>Loading...</div>
const SkeletonRow = () => (
  <Row>
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={150} style={{ marginBottom: 10 }} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
              <Skeleton height={20} width={100} />
              <Skeleton height={20} width={80} />
            </Col>
            <Col md={6}>
              <Skeleton height={500} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  </Row>
)
const LocationScreen = props => {
  //meta title
  document.title = `Location | ${process.env.REACT_APP_NAME}`
  const [alllocation, setAlllocation] = useState(false)
  const [mockData2, setMockData] = useState([])
  const [showPlaceHolder, setShowPlaceHolder] = useState(false)

  const fetchCompaines = async () => {
    setShowPlaceHolder(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let role = localArray.map(e => e.role)
    var formdata = new FormData()
    formdata.append("method", "fetch_company")
    formdata.append("role", role)
    formdata.append("emp_id", emp)
    formdata.append("org_id", org)

    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/company.php", formdata)
      .then(result => {
        setShowPlaceHolder(false)
        if (result.data.length !== 0) {
          setMockData(result.data.reverse())
        } else {
          setMockData([])
        }
      })
      .catch(error => console.log("error", error))
  }
  useEffect(() => {
    fetchCompaines()
  }, [])
  function getCurrentDate() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0")
    const day = currentDate.getDate().toString().padStart(2, "0")
    const formattedDate = year + "-" + month + "-" + day

    return formattedDate
  }
  const currentDate = getCurrentDate()
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs breadcrumbItem="Locations" />
          <Link
            to="/addlocation"
            className="btn btn-primary"
            style={{ marginBottom: 10 }}
          >
            Add New
          </Link>
          {showPlaceHolder == true ? <SkeletonRow /> : null}
          {mockData2.map((e, index) => {
            let array = e.location_map.split(",")
            return (
              <Row key={index}>
                <Col md={12}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={6}>
                          <p style={{ fontSize: 16, fontWeight: "500" }}>
                            Company Name
                          </p>
                          <p>{e.title}</p>
                          <p style={{ fontSize: 16, fontWeight: "500" }}>
                            Location
                          </p>
                          <p>{e.location}</p>
                          <Link
                            to={"../attendance/total"}
                            state={{
                              location: e.location,
                              filter_date: currentDate,
                            }}
                          >
                            <p style={{ fontSize: 16, fontWeight: "500" }}>
                              No of Employee
                            </p>
                            <p>{e.counter}</p>
                          </Link>
                        </Col>
                        <Col md={6} style={{ textAlign: "end" }}>
                          <div
                            id="gmaps-markers"
                            className="gmaps"
                            style={{ position: "relative" }}
                          >
                            <Map
                              initialCenter={{
                                lat: parseFloat(array[0]),
                                lng: parseFloat(array[1]),
                              }}
                              google={props.google}
                              style={{ width: "100%", height: "100%" }}
                              zoom={13}
                            >
                              <Circle
                                center={{
                                  lat: parseFloat(array[0]),
                                  lng: parseFloat(array[1]),
                                }} // Set center of the circle
                                radius={parseFloat(e.geofence)} // Set radius of the circle in meters
                                strokeColor="red" // Set stroke color
                                strokeOpacity={0.8} // Set stroke opacity
                                strokeWeight={2} // Set stroke weight
                                fillColor="red" // Set fill color
                                fillOpacity={0.35} // Set fill opacity
                              />
                              <Marker
                                title={`${e.title}`}
                                name={`${e.location}`}
                                position={{
                                  lat: parseFloat(array[0]),
                                  lng: parseFloat(array[1]),
                                }}
                              />

                              {/* <Marker name={"Dolores park"} /> */}
                              <InfoWindow>
                                <div>
                                  <h1>{e.location}</h1>
                                </div>
                              </InfoWindow>
                            </Map>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )
          })}
        </div>
      </div>
      <ChatWidget />
    </React.Fragment>
  )
}

LocationScreen.propTypes = {
  google: PropTypes.object,
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: "AIzaSyBcmiuSTdNiLIfKu4gfuJTh9GmZacB37eU",
    LoadingContainer: LoadingContainer,
    v: "3",
  })(LocationScreen)
)
