import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// users
import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("Admin");
  const [profile, setprofile] = useState(null);

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      // Check if the local storage was updated
      if (event.key === 'authUser') {
        // Update the state of your website with the new data from local storage
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.map((e) => e.username));
        setprofile(obj.map((e) => e.profile_image));
      }
    });
    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.map((e)=>e.username));
        setprofile(obj.map((e)=>e.profile_image))
    }
  }, []);

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={ profile || user1 }
            style={{objectFit:'cover'}}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-2 me-1" style={{fontWeight:'bold', fontSize:18}}>{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
         
         <DropdownItem>
           <Link to="/profile" >
            <i className="bx bx-user font-size-16 align-middle me-1 text-secondary" />
            <span style={{color:'#212529',paddingTop:4}}>{props.t("Profile")}</span>
          </Link>
          </DropdownItem>
          {/*  <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
         
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("SignOut")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
