import React, { Component, useMemo, useState } from "react"
import propTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  Spinner,
} from "reactstrap"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import classnames from "classnames"
import {
  Actions,
  Coin,
  Pdate,
  Status,
  Tracked,
  Type,
} from "pages/Timesheet/SheetData"
import TableContainer from "components/Common/TableContainer"
import Select from "react-select"
import PopularPost from "pages/Dashboard-Blog/PopularPost"
import LatestProjects from "./LatestProjects"
import avatar4 from "../../assets/images/users/avatar-4.jpg"
import avatar5 from "../../assets/images/users/avatar-5.jpg"
import avatar1 from "../../assets/images/users/avatar-1.jpg"
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import avatar6 from "../../assets/images/users/avatar-6.jpg"
import avatar3 from "../../assets/images/users/avatar-3.jpg"
import avatar8 from "../../assets/images/users/avatar-8.jpg"
import avatar7 from "../../assets/images/users/avatar-7.jpg"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import ReactApexChart from "react-apexcharts"
import { styled } from "@mui/material/styles"

import PieChartCustom from "pages/AllCharts/apex/PieChartCustom"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"
import { ArrowDropDownCircleOutlined } from "@mui/icons-material"
import ChatWidget from "components/Common/ChatWidget"
import {  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef, } from "@mui/x-data-grid"
import Swal from "sweetalert2"
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}))

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        style={{ flexShrink: 0 }}
        width="240"
        height="200"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Activity Found..</Box>
    </StyledGridOverlay>
  )
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: String(JSON.parse(localStorage.getItem('authUser')).map((e)=>e.role == 'user' || e.role == 'supervisor' ? '2'  :  localStorage.getItem('currentActiveTabOnThisScreen') == null ? "1" : localStorage.getItem('currentActiveTabOnThisScreen'))) ,
      dataFetch: [],
      filterFetch: [],
      activityColor: null,
      assignTo: [],
      mockData2: [],
      DataToBeSet: [],
      createActivity: false,
      DataToBeSetTasks: [],
      optionforTask: [],
      activeProject: 0,
      completedProject: 0,
      newProjects: 0,
      activeTabValue: 0,
      completedTasks: [],
      activeTasks: [],
      overdueTasks: [],
      totalTasks: [],
      editActivity : false,
      tableLoader : false,
      editActivityLoader: false,
      editActivityData : {
        id : '',
        activity_color:'',
        activity_name:'',
        activity_description:'',
        assignedTo : []
      }
    }
  }
  
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      localStorage.setItem('currentActiveTabOnThisScreen', tab)
      this.setState({ activeTab: tab })
    }
  }
  
  fetchEmployeeList = () => {
    
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        
        this.setState({ mockData2: result })
      })
      .catch(er => {
        console.log(er)
      })
  }
  getAllActivities = async () => {
   this.setState({tableLoader : true})
    var formdata = new FormData()
    formdata.append("method", "get-activities-all")
    formdata.append("role", "admin")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        // console.log(result)
        // this.setState({tableLoader : false})
        this.setState({ dataFetch: result, filterFetch: result })
        setTimeout(() => {
          if (this.state.dataFetch.length == 0) {
            this.setState({tableLoader :false});
          }
        }, 1000);
      })
      .catch(error => console.log("error", error))
  }

  handleEdit = (rowData) => {
    console.log("Editing row:", rowData);
    // this.setState({ editActivity: true, selectedActivity: rowData });
  };
  getAllProjectFromDB = () => {
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    if (role == "admin") {
      var formdata = new FormData()
      formdata.append("org_id", orgId)
      formdata.append("method", "fetch-projects-for-dashboard")

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_LEAVE_API_URL + "/projects.php",
        requestOptions
      )
        .then(response => response.json())

        .then(result => {
          this.setState({
            DataToBeSet: result.data,
            activeProject: result.active,
            completedProject: result.completed,
            newProjects: result.new,
          })
        })
        .catch(error => console.log("error", error))
    } else {
      var formdata = new FormData()
      formdata.append("method", "get-tasks-assigned-all")
      formdata.append("id", user_id)
      formdata.append("role", role)
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
      fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
        .then(response => response.json())
        .then(result => this.setState({ DataToBeSet: result }))
        .catch(error => console.log("error", error))
    }
  }
  getAllTasks = () => {
    // this.setState({isLoading : true})
    this.setState({ DataToBeSetTasks: [] })
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    this.setState({ CurrentUserID: user_id, currentRole: role })
    var formdata = new FormData()
    formdata.append("method", "get-tasks-assigned-all-by-status")
    formdata.append("id", user_id)
    formdata.append("role", role)
    formdata.append("status", "null")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.json())

      .then(result => {
        this.setState({ DataToBeSetTasks: result })
        // result.data.forEach(item => {

        // totalTasks = result.data.length;
        const total = result.filter(item => {
          return item
        })
        const active = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)
          if (item.task.status == "active" && deadlineDate > currentDate) {
            return item
          }
        })
        const overdue = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)

          if (deadlineDate < currentDate && item.task.status == "active") {
            return item
          }
        })
        const completed = result.filter(item => {
          if (item.task.status == "completed") {
            return item
          }
        })
        this.setState({ totalTasks: total })
        this.setState({ overdueTasks: overdue })
        this.setState({ activeTasks: active })
        this.setState({ completedTasks: completed })
        let option = {
          completed: completed.length || 0,
          active: active.length || 0,
          overdue: overdue.length || 0,
        }
        this.setState({ optionforTask: option })
       
      })
      .catch(error => console.log("error", error))

  }

 
  componentDidMount() {
    this.getAllActivities()
    this.getAllProjectFromDB()
    this.fetchEmployeeList()
    this.getAllTasks()
    
  }
  render() {
    document.title = `Activities | ${process.env.REACT_APP_NAME}`
    const {
      mockData2,
      
      DataToBeSet,
      newProjects,
      DataToBeSetTasks,
      activeTabValue,
      activeTasks,
      completedTasks,
      totalTasks,
      overdueTasks,
      editActivity,
      tableLoader,
      editActivityData,
      editActivityLoader
    } = this.state
    const singleActivity = async (id)=>{
      const formData = new FormData();
      formData.append('method', 'get-activities-by-id');
      formData.append('activity_id' , id)
      const requestOptions = {
        method:'POST', 
        body: formData
      }
      const response = await fetch(process.env.REACT_APP_LEAVE_API_URL + '/activity.php', requestOptions);
      const res = await response.json();
      const {activity, assigned_to} = res;
      if(activity){
       const array =  assigned_to.map((e)=>({
          label:e.id+'-'+e.first_name + ' ' +  e.last_name,
          value: e.id
        }))
        this.setState({
          editActivityData:{
              id: activity.id, 
              activity_name: activity.activity_name,
              activity_description: activity.activity_description,
              activity_color: activity.activity_color,
              assigned_to : array,
          }
        })

      }
    }
    const onPressUpdateActivity = async(e) =>{
      e.preventDefault();
      this.setState({editActivityLoader  : true})
      const form = new FormData();
      let assignedArray = [];
      if(this.state.editActivityData?.assignedTo?.length !==  undefined){
         assignedArray = this.state.editActivityData.assignedTo.map((e)=>e.value);
      }
      form.append('method' , 'update-activity-by-admin');
      form.append('name', this.state.editActivityData.activity_name);
      form.append('desc', this.state.editActivityData.activity_description);
      form.append('color', this.state.editActivityData.activity_color);
      form.append('assigned_to', assignedArray.toString());
      form.append('activity_id', this.state.editActivityData.id);
      const requestOptions = {
        method : 'POST', 
        body: form
      }
      const callApi = await  fetch(process.env.REACT_APP_LEAVE_API_URL + '/activity.php',requestOptions);
      const response = await callApi.text();
      if(response === 'success'){
          toastr.success('You successfully updated the activity information');
          this.setState({editActivity: false})
          this.getAllActivities();
          this.setState({editActivityLoader  : false})
      }else{
        toastr.error('Server error ! Please try again');
        this.setState({editActivityLoader  : false})
      }
      
    }
    const columnsFor: GridColDef[] = [
      {
        field: "activity_color",
        headerName: "Activity Color",
        width: 250,
        renderCell: param => (
          <p style={{backgroundColor : param.value,color:'transparent' ,width:80}}>{param.value}</p>
        ),
      },
      {
        field: "activity_name",
        headerName: "Activity Name",
        width: 250,
        renderCell: param =><span>{param.value}</span>,
      },
    
     
      {
        field: "status",
        headerName: "Status",
        width: 250,
        renderCell: param => <p>{param.value}</p>,
      },
      {
        field: "",
        headerName: "Actions",
        width: 250,
        renderCell: param => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <span
                  className="btn btn-primary"
                  onClick={()=>{
                    singleActivity(param.row.id)
                    this.setState({editActivity: true})}}
                >
                  Edit
                </span>
              </li>
              <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
                <span
                  className="btn btn-danger"
                  onClick={()=>{
                   
                      Swal.fire({
                        title: "Delete Task",
                        html: "<p>Are you sure to delete this Activity</p> ",
                        showCancelButton: true,
                        confirmButtonText: "Yes",
                        cancelButtonText: "No",
                        cancelButtonColor: "red",
                        confirmButtonColor: "green",
                      }).then(result => {
                        if (result.isConfirmed) {
                          // Handle action for Button 1
                          let form = new FormData()
                          form.append("method", "delete-activity-by-admin")
                          form.append("activity_id", param.row.id)
                          var requestOptions = {
                            method: "POST",
                            body: form,
                          }
                          fetch(
                            process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
                            requestOptions
                          )
                            .then(res => res.text())
                            .then(result => {
                              if (result == "success") {
                                Swal.fire("Deleted", "Activity is successfully deleted")
                                this.getAllActivities();
                              } else {
                                Swal.fire(
                                  "Server Error",
                                  "Error while deleting the Activity. Try again"
                                )
                              }
                            })
                        } else if (result.dismiss === "cancel") {
                          Swal.close()
                        }
                      })
                  }}
                
                >
                  Delete
                </span>
              </li>
            </ul>
          )
        },
      },
    ]
    
    const renderRadioButton = (color, value) => (
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 8,
        }}
      >
        <div
          style={{
            backgroundColor: color,
            borderRadius: 50,
            width: 150,
            marginBottom: 8,
          }}
        >
          <input
            type="radio"
            name="radiobutton"
            className="form-check-input"
            value={this.state.activityColor}
            // checked={this.state.activityColor === value ? true : false}
            onChange={() => this.setState({ activityColor: value })}
            color={"white"}
          />
        </div>
      </div>
    )
    const renderRadioButtonEdit = (color, value) => (
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 8,
        }}
      >
        <div
          style={{
            backgroundColor: color,
            borderRadius: 50,
            width: 150,
            marginBottom: 8,
          }}
        >
          <input
            type="radio"
            name="radiobutton"
            className="form-check-input"
            value={this.state.editActivityData.activity_color}
            onChange={(e) => this.setState({ editActivityData :{
              ...editActivityData , 
              activity_color : value
            }})}
            color={"white"}
          />
        </div>
      </div>
    )

   
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="ms-auto">
              <Row>
                <Col md={12}>
                  <Box sx={{ width: "100%" }}>
                  <Card
                        className="overflow-hidden"
                        style={{
                          minHeight: "200px",
                          //   backgroundImage: `url(${profileImg})`,
                          backgroundSize: "50% auto", // Adjust as needed
                          backgroundPosition: "bottom right",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <CardBody className="pt-3">
                          <Row>
                            <Col sm="12" className="mb-4">
                              <Row>
                                <Col md={6}>
                                  <h4 className="text-primary">Activities</h4>
                                </Col>
                                <Col md={6} style={{ textAlign: "end" }}>
                                  <button
                                    onClick={() =>
                                      this.setState({ createActivity: true })
                                    }
                                    className="btn btn-primary"
                                  >
                                    Add Activity
                                  </button>
                                </Col>
                              </Row>
                            </Col>
                            <Col md="12">
                            <Box sx={{ width: "100%" }}>
                              <DataGrid
                                autoHeight
                                sx={{ "--DataGrid-overlayHeight": "300px" }}
                                slots={{
                                  toolbar: GridToolbar,
                                  noRowsOverlay: CustomNoRowsOverlay
                                }}
                                
                                rows={this.state.filterFetch}
                                isCellEditable={false}
                                isRowSelectable={false}
                                loading={
                                  this.state.filterFetch.length == 0  && tableLoader === true 
                                    ? true
                                    : false
                                }
                                columns={columnsFor}
                                getRowId={row => row.id}
                              />
                            </Box>
                            </Col>
                            
                          </Row>
                        </CardBody>
                      </Card>
                  </Box>
                </Col>
              </Row>
            </div>
          </Container>
          <Offcanvas
            isOpen={this.state.createActivity}
            direction="end"
            style={{ width: 500 }}
            toggle={() => this.setState({ createActivity: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ createActivity: false })}
            >
              Add New Activity
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={e => {
                  e.preventDefault()
                  if (e.target.elements.activity_name.value === "") {
                    toastr.error("Activity name is required")
                    this.setState({ loading: false, disable: false })
                    return false
                  } else if (
                    e.target.elements.activity_description.value == ""
                  ) {
                    toastr.error(
                      "Please add some description related to activity"
                    )
                    this.setState({ loading: false, disable: false })
                    return false
                  } else if (e.target.elements.radiobutton.value == null) {
                    toastr.error("Select an activity color")
                    return false
                  } else {
                    var formdata = new FormData()
                    formdata.append("method", "create-activity-by-admin")
                    formdata.append(
                      "name",
                      e.target.elements.activity_name.value
                    )
                    formdata.append(
                      "desc",
                      e.target.elements.activity_description.value
                    )
                    formdata.append(
                      "color",
                      e.target.elements.radiobutton.value
                    )
                    formdata.append(
                      "assigned_to",
                      this.state.assignTo.toString()
                    )
                    var requestOptions = {
                      method: "POST",
                      body: formdata,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
                      requestOptions
                    )
                      .then(response => response.text())
                      .then(result => {
                        this.setState({ createActivity: false })
                        if (result === "success") {
                          toastr.success("Activity is added")
                          this.getAllActivities()
                          this.setState({ createActivity: false })
                        } else {
                          Alert.alert("Error while adding values")
                          this.setState({ loading: false, disable: false })
                        }
                      })
                      .catch(error => console.log("error", error))
                  }
                }}
              >
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="activity_name"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="activity_description"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Color</label>
                      {renderRadioButton("red", "red")}
                      {renderRadioButton("green", "green")}
                      {renderRadioButton("blue", "blue")}
                      {renderRadioButton("yellow", "yellow")}
                      {renderRadioButton("orange", "orange")}
                      {renderRadioButton("pink", "pink")}
                      {renderRadioButton("black", "black")}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Assign Member</label>
                      <Select
                        isMulti={true}
                        onChange={item => {
                          this.setState({ assignTo: item.map(e => e.value) })
                          
                          // this.setState({assignTo: item.value});
                        }}
                        options={mockData2}
                        className="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md={12} style={{ textAlign: "end" }}>
                    <button className="btn btn-primary" type="submit">
                      Add Activity
                    </button>
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
          <Offcanvas
            isOpen={editActivity}
            direction="end"
            style={{ width: 500 }}
            toggle={() => this.setState({ editActivity: false })}
          >
            <OffcanvasHeader
              toggle={() => this.setState({ editActivity: false })}
            >
              Edit Activity
            </OffcanvasHeader>
            <OffcanvasBody>
              <Form
                onSubmit={onPressUpdateActivity}
              >
                <Row>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editActivityData.activity_name}
                        onChange={(e)=>this.setState({
                          editActivityData : {
                            ...editActivityData,
                            activity_name : e.target.value
                          }
                        })}
                        name="activity_name"
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Description</label>
                      <input
                        type="text"
                        className="form-control"
                        name="activity_description"
                        value={editActivityData.activity_description}
                        onChange={(e)=>this.setState({
                          editActivityData : {
                            ...editActivityData,
                            activity_description : e.target.value
                          }
                        })}
                      />
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Activity Color</label>
                      {renderRadioButtonEdit("red", "red")}
                      {renderRadioButtonEdit("green", "green")}
                      {renderRadioButtonEdit("blue", "blue")}
                      {renderRadioButtonEdit("yellow", "yellow")}
                      {renderRadioButtonEdit("orange", "orange")}
                      {renderRadioButtonEdit("pink", "pink")}
                      {renderRadioButtonEdit("black", "black")}
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="mb-3">
                      <label>Assign Member</label>
                      <Select
                        isMulti
                        value={editActivityData.assignedTo}
                        onChange={item => {
                          this.setState({
                            editActivityData:{
                              ...editActivityData,
                              assignedTo: item
                            }
                          })
                        }}
                       
                        options={mockData2}
                        className="select2-selection"
                      />
                    </div>
                  </Col>
                  <Col md={12} style={{ textAlign: "end" }}>
                    {
                      editActivityLoader === true ? 
                      <Spinner/> : 
                    <button className="btn btn-primary" type="submit">
                      Edit Activity
                    </button>
                    }
                  </Col>
                </Row>
              </Form>
            </OffcanvasBody>
          </Offcanvas>
        </div>
        <ChatWidget/>
      </React.Fragment>
    )
  }
}
Home.propTypes = {
  orders: propTypes.array,
  onGetOrders: propTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Home))
