import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"

import { connect } from "react-redux"
import {
  Row,
  Col,
  Label,
  Input,
  TabPane,
  TabContent,
  Form,
  FormGroup,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
} from "reactstrap"
import { Link, useLocation } from "react-router-dom"
import {
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap"

import classnames from "classnames"

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import "../../assets/style/style.css"
import logo from "../../assets/images/Icon.png"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  logoutUserSuccess,
} from "../../store/actions"
import TableContainer from "components/Common/TableContainer"
import { useMediaQuery } from "react-responsive"
import axios from "axios"
import Dialpad from "components/Dialpad/Dialpad"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { getDatabase, ref, onValue, off, push, update } from "firebase/database"
import { TaskSharp } from "@mui/icons-material"
const Header = props => {
  const WebLocation = useLocation()
  const MySwal = withReactContent(Swal)
  const isMobile = useMediaQuery({ maxWidth: 768 })
  const db = getDatabase()
  const [counterMessage, setCounterMessage] = useState(0)
  const [modal, setmodal] = useState(false)
  const [activeTab, setActiveTab] = useState("1")
  const [alllocation, setAlllocation] = useState(false)
  const [startsession, setStartsession] = useState(false)
  const [timer, setTimer] = useState(0)
  const [isRunning, setIsRunning] = useState(false)
  const [lastout, setLastout] = useState("")
  const [changeActivity, setChangeActivity] = useState(false)
  const reference = useRef()
  const [buttonTxt, setButtonTxt] = React.useState("Check In Now")
  const [orgName, setOrgName] = React.useState(null)
  const [orgID, setOrgID] = React.useState(null)
  const [Ownerstatus, setOwnerStatus] = React.useState(null)
  const [punchInLocation, setPunchInLocation] = React.useState(null)
  const [punchInTime, setPunchInTime] = React.useState(null)
  const [secondsRemaining, setSecondsRemaining] = React.useState(
    localStorage.getItem("countdownDuration") === null
      ? 0
      : Number(localStorage.getItem("countdownDuration"))
  )
  const [timerStarted, setTimerStarted] = React.useState(false)
  const [emp_id, setEmpID] = React.useState("")
  const [currentUserRole, setCurrentUserRole] = React.useState("")
  const [currentUsername, setCurrentUsername] = React.useState("")
  const [isLoading, setIsLoading] = React.useState(false)
  const [breakButtonText, setBreakButtonText] = React.useState("Break Time")
  const [breakButtonIcon, setBreakButtonIcon] = React.useState("coffee-outline")
  const [activityName, setActivityName] = React.useState(null)
  const [activityBackground, setActivitybackground] = React.useState(null)
  const [activityID, setActivityID] = React.useState(null)
  const [loader, setLoader] = React.useState(false)
  const [filterFetch, setfilterFetch] = React.useState([])
  const [putStatus, setPutStatus] = React.useState(null)
  const [visible, setVisible] = React.useState(false)
  const [currentStatusForClockIn, setCurrentStatusForClockIn] =
    React.useState(null)
  const [acceptedPin, setacceptedPin] = React.useState(null)
  const [LastActivity, setLastActivity] = React.useState(null)
  const [activeBottomTab, setactiveBottomTab] = React.useState("Tasks")
  const [clockInPin, setclockInPin] = React.useState("")
  const [shownSuccessScreen, setshownSuccessScreen] = React.useState(false)
  const [profileImage, setProfileImage] = React.useState("")
  const [isRight, setisRight] = React.useState(false)
  const [errorForLocation, seterrorForLocation] = React.useState(false)
  const [currentLat, setCurrentLat] = React.useState(null)
  const [currentLong, setCurrentLong] = React.useState(null)
  const [notificationList, setnotificationList] = React.useState([])
  const [onLeave, setOnLeave] = React.useState(false)
  const fetchLeaveStatus = () => {
    let val = JSON.parse(localStorage.getItem("authUser"))
    let emp = val.map(e => e.user_id)
    // runnerOther(emp)
    let formdata = new FormData()
    const current = getCurrentDate()
    formdata.append("method", "check-leave-status-for-today")
    formdata.append("emp_id", emp)
    formdata.append("post_id", current)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch("https://api.frontforcecrm.com/leave.php", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.message == "done") {
          setOnLeave(true)
        } else {
          setOnLeave(false)
        }
      })
      .catch(error => console.error(error))
  }
  function getCurrentDate() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0")
    const day = currentDate.getDate().toString().padStart(2, "0")
    const formattedDate = day + "-" + month + "-" + year

    return formattedDate
  }
  const getAllActivities = async (id, role) => {
    var formdata = new FormData()
    formdata.append("method", "get-activities-all")
    formdata.append("id", emp_id)
    formdata.append("role", currentUserRole)
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    axios
      .post("https://api.frontforcecrm.com/activity.php", formdata)

      .then(result => setfilterFetch(result.data))
      .catch(error => console.log("error", error))
  }
  const getAllTasks = async (emp_id, currentUserRole, org_id) => {
    var formdata = new FormData()
    formdata.append("method", "get-tasks-assigned-all")
    formdata.append("id", emp_id)
    formdata.append("org_id", org_id)
    formdata.append("role", currentUserRole)
    console.warn(formdata)
    axios
      .post("https://api.frontforcecrm.com/tasks.php", formdata)
      .then(result => setfilterFetch(result.data))
      .catch(error => console.log("error", error))
  }
  const changeActiveBottomTab = val => {
    setactiveBottomTab(val)
    if (val === "Activities") {
      getAllActivities()
    } else {
      getAllTasks(emp_id, currentUserRole, orgID)
    }
  }
  const revData = async () => {
    try {
      setIsLoading(true)
      const value = localStorage.getItem("authUser")
      const activity = localStorage.getItem("activityName")
      const activityBack = localStorage.getItem("activityBackground")
      setActivitybackground(activityBack)
      setActivityName(activity)

      if (value !== null) {
        let jsonFormat = JSON.parse(value)

        jsonFormat.map(e => {
          setEmpID(e.user_id)
          setOrgID(e.org_id)
          fetchCurrentClockIn(e.user_id)
          setCurrentUserRole(e.role)
          setCurrentUsername(e.username)
          fetchUserTodayAttendance(e.user_id, e.org_id)
          // getAllActivities(e.user_id, e.role)
          getAllTasks(e.user_id, e.role, e.org_id)
          setOwnerStatus(e.owner_status)
          setacceptedPin(e.pin)
          setProfileImage(e.profile_image)
          fetchCurrentCompanyLatLong(e.user_id)
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
  React.useEffect(() => {
    revData()
    fetchLeaveStatus()
    let intervalId
    if (timerStarted) {
      intervalId = setInterval(() => {
        setSecondsRemaining(prevSeconds => prevSeconds + 1)
        console.log(secondsRemaining)
        SaveTimer()
      }, 1000)
    }

    return () => clearInterval(intervalId)
  }, [timerStarted])
  useEffect(() => {
    // setMessageList([])
    const fetchLastMessages = async () => {
      const userId = JSON.parse(localStorage.getItem("authUser")).map(
        e => e.user_id
      )

      const chatIdsRef = ref(db, "chats")
      onValue(chatIdsRef, async snapshot => {
        const chatIds = snapshot.val()

        let array = []
        if (!chatIds) return
        Object.keys(chatIds).map(async chatId => {
          // Use userId here to filter chatIds based on participants
          const chatIdParts = chatId.split("_")

          if (chatIdParts.includes(userId[0]) == true) {
            const lastMessageRef = ref(db, `chats/${chatId}/messages`)

            onValue(lastMessageRef, async messageSnapshot => {
              const mess = messageSnapshot.val()
              const filter = Object.values(mess).filter(
                e => e.receiver._id == userId[0] && e.status == "un-read"
              )

              setCounterMessage(filter.length)
            })
          }
        })
      })

      return () => {
        // Detach the listener
        off(chatIdsRef)
      }
    }
    fetchLastMessages()
  }, [db])
  const formattedTime = timeString => {
    const time = new Date(`1970-01-01T${timeString}Z`)
    return time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    })
  }
  const SaveTimer = async () => {
    // console.log('save')
    // console.log(secondsRemaining)
    localStorage.setItem("countdownDuration", secondsRemaining.toString())
    localStorage.setItem("timerStarted", timerStarted.toString())
  }
  const formatTimes = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
      2,
      "0"
    )}m:${String(remainingSeconds).padStart(2, "0")}`
  }
  function formatTimeToAmPm(timeString) {
    // Parse the time string and create a Date object
    const parsedTime = new Date(`2000-01-01T${timeString}`)

    // Use Intl.DateTimeFormat to format the time in 12-hour format with "am" or "pm"
    const formattedTime = new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(parsedTime)

    return formattedTime
  }
  function getStatusOfDate(dateTimeString) {
    const targetDate = new Date(dateTimeString)
    const currentDate = new Date()
    const timeDifference = currentDate - targetDate
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24))
    if (daysDifference === 0) {
      return "Today"
    } else if (daysDifference === 1) {
      return "Yesterday"
    } else {
      return `${daysDifference} days ago`
    }
  }
  function checkAdjustedTime(shiftStartTime, minutesToAdd) {
    let start_time = JSON.parse(localStorage.getItem("authUser")).map(e =>
      e.schedule_info == undefined
        ? null
        : e.schedule_info.start_time == undefined
        ? null
        : e.schedule_info.start_time
    )
    let trady_margin = JSON.parse(localStorage.getItem("authUser")).map(e =>
      e.schedule_info == undefined
        ? null
        : e.schedule_info.trady_margin == undefined
        ? null
        : e.schedule_info.trady_margin
    )
    let currentTime = new Date()

    // Parse the shift start time
    let shiftTime = start_time[0].split(" ")
    let shiftHourMinute = shiftTime[0].split(":")
    let shiftHour = parseInt(shiftHourMinute[0])
    let shiftMinute = parseInt(shiftHourMinute[1])
    let shiftAmPm = shiftTime[1]
    if (shiftAmPm.toUpperCase() === "PM" && shiftHour !== 12) {
      shiftHour += 12
    } else if (shiftAmPm.toUpperCase() === "AM" && shiftHour === 12) {
      shiftHour = 0
    }
    let adjustedTime = new Date(
      currentTime.getFullYear(),
      currentTime.getMonth(),
      currentTime.getDate(),
      shiftHour,
      shiftMinute
    )
    adjustedTime.setMinutes(
      adjustedTime.getMinutes() + parseFloat(trady_margin[0])
    )

    // Check if adjusted time is equal to or greater than current time

    return currentTime >= adjustedTime
  }
  const fetchUserTodayAttendance = async (id, org_id) => {
    var formdata = new FormData()
    formdata.append("method", "fetch_attendance_before_api_call_from_mobile")
    formdata.append("emp_id", id)
    formdata.append("org_id", org_id)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/attendance.php", formdata)

      .then(async result => {
        setIsLoading(false)
        if (result.data.message == "no schedule") {
          localStorage.setItem("whatGoing", "Schedule OFF")
          return false
        }
        if (
          result.data.message === "not found" ||
          result.data.current_data.punch_out_location !== null
        ) {
          setButtonTxt("Check In")
          setTimerStarted(false)
          setSecondsRemaining(0)
          let stat, stata, valueof
          // if(checkAdjustedTime()== true){
          //   setPutStatus(``)
          // }else if(checkAdjustedTime()== false) {
          //   let start_time =  JSON.parse(localStorage.getItem("authUser")).map(
          //     e =>  e.schedule_info == undefined  ?  null : e.schedule_info.start_time == undefined ?  null : e.schedule_info.start_time
          //   )
          //   setPutStatus(`Shift Start At ${start_time[0]}`)
          // }
          if (result.data.punch_out_location) {
            setPunchInLocation(result.data.punch_out_location)
            stat = getStatusOfDate(result.data.current_data.punch_time_out)
            stata = result.data.current_data.punch_time_out.split(" ")
            valueof = formatTimeToAmPm(stata[1])
            setPutStatus(`${valueof} - ${stat}`)
          }
          localStorage.removeItem("timerStarted")
          localStorage.removeItem("countdownDuration")
          localStorage.removeItem("activityName")
          setActivityName(null)
          localStorage.removeItem("activityID")
          localStorage.removeItem("whatGoing")
          localStorage.removeItem("activityType")
          localStorage.removeItem("timerStarted")
        } else {
          if (result.data.activity_data !== null) {
            setLastActivity(result.data.activity_data.activity_type)
            const what = localStorage.getItem("whatGoing")
            if (what === null) {
              localStorage.setItem(
                "whatGoing",
                result.data.activity_data.activity_type == "Working"
                  ? "Working"
                  : "Break Time"
              )
            } else {
              localStorage.setItem("whatGoing", what)
            }
          } else {
            const dateStringWithSpace = result.data.current_data.punch_time_in
            const dateStringWithT = dateStringWithSpace.replace(" ", "T")

            const differenceInSeconds =
              getTimeDifferenceInSeconds(dateStringWithT)
            setSecondsRemaining(Math.round(differenceInSeconds))
            localStorage.setItem(
              "countdownDuration",
              String(Math.round(differenceInSeconds))
            )
          }

          localStorage.setItem(
            "setAttendanceID",
            String(result.data.attendance_id)
          )
          setPunchInLocation(result.data.punch_in_location)
          let value = result.data.current_data.punch_time_in.split(" ")
          setPunchInTime(formattedTime(value[1]))
          const status = localStorage.getItem("timerStarted")
          const checkr = localStorage.getItem("whatGoing")
          setPutStatus(null)
          if (checkr == "Break Time") {
            setBreakButtonIcon("coffee-off-outline")
            setBreakButtonText("End Break")
          }
          if (checkr == "Working") {
            setBreakButtonIcon("coffee-outline")
            setBreakButtonText("Break Time")
          }

          if (status == "false") {
            setTimerStarted(!timerStarted)
          } else {
            const values = localStorage.getItem("countdownDuration")
            if (values === null) {
              setSecondsRemaining(0)
            } else {
              if (result.data.activity_data == null) {
                localStorage.setItem("whatGoing", "Working")
                const dateStringWithSpace =
                  result.data.current_data.punch_time_in
                const dateStringWithT = dateStringWithSpace.replace(" ", "T")
                const differenceInSeconds =
                  getTimeDifferenceInSeconds(dateStringWithT)
                setSecondsRemaining(Math.round(differenceInSeconds))
                localStorage.setItem(
                  "countdownDuration",
                  String(Math.round(differenceInSeconds))
                )
              }
              if (result.data.activity_data !== null) {
                const dateStringWithSpace =
                  result.data.activity_data.activity_time
                const differenceInSecondss =
                  getTimeDifferenceInSecondsForm(dateStringWithSpace)
                setSecondsRemaining(Math.abs(differenceInSecondss))
                localStorage.setItem(
                  "countdownDuration",
                  String(Math.abs(differenceInSecondss))
                )
              } else {
                setSecondsRemaining(parseInt(values))
                localStorage.setItem("countdownDuration", values)
              }
            }
            setTimerStarted(true)
          }
          setButtonTxt("Check Out")
        }
      })
      .catch(error => console.log("error", error))
  }

  function isWithinTimeRange(givenTime) {
    // Get current time
    var currentTime = new Date()

    // Parse given time
    var givenTimeObj = new Date()
    var givenTimeParts = givenTime.split(":")
    givenTimeObj.setHours(parseInt(givenTimeParts[0], 10))
    givenTimeObj.setMinutes(parseInt(givenTimeParts[1], 10))

    // Add 10 minutes to given time
    givenTimeObj.setMinutes(givenTimeObj.getMinutes() + 10)

    // Compare current time with adjusted given time
    return currentTime <= givenTimeObj
  }

  const actionPerformAfter = async activity => {
    // const noofSeconds = await localStorage.getItem('countdownDuration');
    let date = new Date()
    let currentTimer =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    var formdata = new FormData()
    formdata.append("method", "create-activity")
    formdata.append("noofseconds", secondsRemaining)
    formdata.append("attendance_id", localStorage.getItem("setAttendanceID"))
    formdata.append("activity_type", activity)
    formdata.append("org_id", orgID)
    formdata.append("activity_time", currentTimer)
    formdata.append("current_mode", activity === "Working" ? "Break" : "In")
    const vale = localStorage.getItem("activityID")
    const activity_type = localStorage.getItem("activityType")
    if (vale !== null) {
      formdata.append("activity_id", vale)
      formdata.append("other_type", activity_type)
    } else {
      formdata.append("activity_id", null)
    }
    formdata.append("emp_id", emp_id)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/activity.php", formdata)

      .then(async result => {
        setLoader(false)
        if (activity === "Working" && result.data == "success") {
          localStorage.setItem("whatGoing", "Break Time")
          setBreakButtonText("End Break")
          setBreakButtonIcon("coffee-off-outline")
          let val2 = true
          try {
            localStorage.setItem("countdownDuration", "0")
            localStorage.setItem("timerStarted", val2.toString())
            setIsRunning(true)
          } catch (error) {
            alert(error)
          }
          setSecondsRemaining(0)
        } else if (activity === "Break Time" && result.data === "success") {
          localStorage.setItem("whatGoing", "Working")
          localStorage.setItem("countdownDuration", "0")
          setBreakButtonText("Break Time")
          setBreakButtonIcon("coffee-outline")
          setSecondsRemaining(0)
          localStorage.setItem("countdownDuration", "0")
          localStorage.setItem("timerStarted", "true")
          // window.location.reload()
          setIsRunning(true)
        }
        if (result.data !== "success") {
          alert("Error")
        }
      })
      .catch(error => console.log("error", error))
  }
  const changeMyActivity = val => {
    let array = val.split("_")
    if (activityName === array[1]) {
      alert("You are already working on this activity")
    } else {
      setActivityName(array[1])
      setActivitybackground(array[2])
      setActivityID(array[0])
    }
    // ref.current.close()
  }
  const changeMyActivityContinue = val => {
    let array = val.split("_")

    setActivityName(array[1])
    setActivitybackground(array[2])
    setActivityID(array[0])
  }

  const actionPerformAfterSwitchActivity = async () => {
    // const noofSeconds = await localStorage.getItem('countdownDuration');
    let date = new Date()
    let currentTimer =
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    var formdata = new FormData()
    formdata.append("method", "create-activity")
    formdata.append("noofseconds", secondsRemaining)
    formdata.append("attendance_id", localStorage.getItem("setAttendanceID"))
    formdata.append("activity_type", "Working")
    formdata.append("org_id", orgID)
    formdata.append("activity_time", currentTimer)
    formdata.append("current_mode", "In")
    const vale = localStorage.getItem("activityID")
    const activity_type = localStorage.getItem("activityType")

    if (vale !== null) {
      formdata.append("activity_id", vale)
      formdata.append("other_type", activity_type)
    } else {
      formdata.append("activity_id", null)
    }
    formdata.append("emp_id", emp_id)
    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/activity.php", formdata)

      .then(async result => {
        setLoader(false)
        if (result.data === "success") {
          localStorage.setItem("whatGoing", "Working")
          setSecondsRemaining(0)
          setIsRunning(true)
          localStorage.setItem("timerStarted", "true")
          localStorage.setItem("countdownDuration", "0")
          localStorage.setItem("activityName", `${activityName}`)
          localStorage.setItem("activityID", `${activityID}`)
          localStorage.setItem("activityBackground", `${activityBackground}`)
          localStorage.setItem("activityType", activeBottomTab)
          setChangeActivity(false)
        }
        if (result.data !== "success") {
          alert("Error")
        }
      })
      .catch(error => console.log("error", error))
  }

  const stopActivity = async () => {
    localStorage.removeItem("activityName")
    localStorage.removeItem("activityID")
    localStorage.removeItem("activityBackground")
    localStorage.setItem("countdownDuration", "0")
    actionPerformAfter("Working")
    setSecondsRemaining(0)
  }
  const hideModal = () => setVisible(false)
  const fetchCurrentClockIn = async id => {
    var formdata = new FormData()
    formdata.append("method", "fetch-current-method-for-login")
    formdata.append("emp_id", id)

    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/login.php", formdata)

      .then(result => {
        if (
          result.data.default_login === null ||
          result.data.default_login === "null"
        ) {
          setCurrentStatusForClockIn("By Face")
        } else {
          setCurrentStatusForClockIn(result.data.default_login)
        }
      })
      .catch(error => console.log("error", error))
  }
  const getCurrentDateTime = () => {
    const options = {
      weekday: "short",
      day: "numeric",
      month: "long",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }

    const currentDate = new Date().toLocaleDateString("en-US", options)
    let val = currentDate.split("at")
    return currentDate
  }
  function getTimeDifferenceInSeconds(targetDateString) {
    // Convert the target date string to a JavaScript Date object
    const targetDateTime = new Date(targetDateString)
    const currentDateTime = new Date()
    const differenceInMilliseconds = currentDateTime - targetDateTime
    // Convert the difference to seconds
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000)

    return differenceInSeconds
  }
  function getTimeDifferenceInSecondsForm(targetTimeString) {
    // Get the current time
    const currentTime = new Date()

    // Extract hours, minutes, and seconds from the targetTimeString
    const [targetHours, targetMinutes, targetSeconds] = targetTimeString
      .split(":")
      .map(Number)

    // Set the target time using the current date and the extracted time components
    const targetTime = new Date()
    targetTime.setHours(targetHours)
    targetTime.setMinutes(targetMinutes)
    targetTime.setSeconds(targetSeconds)

    // Calculate the difference in milliseconds
    const differenceInMilliseconds = targetTime - currentTime

    // Convert the difference to seconds
    const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000)

    return differenceInSeconds
  }
  useEffect(() => {
    let intervalId
    if (isRunning) {
      intervalId = setInterval(() => {
        const value = setTimer(prevTimer => prevTimer + 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [isRunning])

  const toggleRightCanvas = () => {
    setisRight(!isRight)
  }
  const formatTime = time => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor((time % 3600) / 60)
    const seconds = time % 60
    const formatDigit = digit => (digit < 10 ? `0${digit}` : digit)
    return `${formatDigit(hours)}h:${formatDigit(minutes)}m:${formatDigit(
      seconds
    )}`
  }

  const toggleModal = () => {
    setisRight(!isRight)
  }
  const toggleActivityCanvas = () => {
    setChangeActivity(!changeActivity)
  }
  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const ResumeTimeRecord = () => {
    const currentDateTime = new Date()
    const options = {
      weekday: "long",
      hour: "numeric",
      minute: "numeric",
    }
    const formattedDateTime = currentDateTime.toLocaleString(undefined, options)
    setLastout(formattedDateTime)
    localStorage.setItem("lasttime2", formattedDateTime)
    setIsRunning(!isRunning)
    setStartsession(!startsession)
    setmodal(false)
  }
  const getLocation = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        position => {
          const location = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
          resolve(location)
        },
        error => {
          reject(error)
        }
      )
    })
  }
  const processAfterCaptureImage = async () => {
    let whichType = buttonTxt === "Check In" ? "Check In" : "Check Out"
    // let jsonFormat = JSON.parse(localStorage.getItem('authUser')).map((e)=>e.schedule_info.)
    let startTime = JSON.parse(localStorage.getItem("authUser")).map(e =>
      e.schedule_info == undefined
        ? null
        : e.schedule_info.start_time == undefined
        ? null
        : e.schedule_info.start_time
    )
    let endTime = JSON.parse(localStorage.getItem("authUser")).map(e =>
      e.schedule_info == undefined
        ? null
        : e.schedule_info.end_time == undefined
        ? null
        : e.schedule_info.end_time
    )

    getLocation()
      .then(location => {
        let formdata = new FormData()
        formdata.append("emp_id", emp_id)
        formdata.append("method", "FaceVerification")
        formdata.append("attendanceType", "PIN")
        formdata.append("org_id", orgID)
        formdata.append(
          "location",
          location.latitude + "," + location.longitude
        )
        formdata.append("checkOther", whichType)
        formdata.append("device_token", "By User")

        if (whichType == "Check Out") {
          formdata.append(
            "attendance_id",
            localStorage.getItem("setAttendanceID")
          )
          const secondsValue = localStorage.getItem("countdownDuration")
          const activity_id = localStorage.getItem("activityID")
          const activity_type = localStorage.getItem("activityType")
          formdata.append("noOfWorkingSeconds", secondsValue)
          formdata.append("acitivity_Id", activity_id)
          formdata.append("activity_type", activity_type)
          formdata.append("end_of_day", "true")
        }
        axios
          .post(
            process.env.REACT_APP_LEAVE_API_URL + "/attendance.php",
            formdata
          )
          .then(async result => {
            setisRight(!isRight)
            if (result.data.message == "success") {
              if (whichType === "Check In") {
                Swal.fire({
                  title: "Check In successfully",
                  html: `<img class="rounded-circle avatar-md" src="${result.data.profile_image}" /> <br> <p>${result.data.emp_name} attendance is marked</p>`,
                })
                setTimerStarted(true)
                localStorage.setItem("countdownDuration", "0")
                localStorage.setItem("timerStarted", "true")
                setIsRunning(true)
                if (activityName !== null) {
                  localStorage.setItem("activityName", `${activityName}`)
                  localStorage.setItem("activityID", `${activityID}`)
                  localStorage.setItem(
                    "activityBackground",
                    `${activityBackground}`
                  )
                  localStorage.setItem("activityType", `${activeBottomTab}`)
                  localStorage.setItem(
                    "setAttendanceID",
                    String(result.data.last_id)
                  )
                  localStorage.setItem("whatGoing", "Working")
                  // window.location.reload()
                } else {
                  localStorage.removeItem("activityName")
                  localStorage.removeItem("activityID")
                  localStorage.removeItem("activityType")
                  // window.location.reload()
                }
              } else {
                setIsRunning(false)
                setSecondsRemaining(0)
                setTimerStarted(false)
                localStorage.removeItem("countdownDuration")
                localStorage.removeItem("timerStarted")
                localStorage.removeItem("setAttendanceID")
                localStorage.setItem("whatGoing", "Log out")
                Swal.fire({
                  title: "Check Out successfully",
                  html: `<img class="rounded-circle avatar-md" src="${result.data.profile_image}" /> <br> <p>${result.data.emp_name} is logged out</p>`,
                })
                // Swal.fire(``)
                // window.location.reload()
              }
            } else {
              alert(`${result.data.message}`)
            }
          })
          .catch(error => console.log("error", error))
      })
      .catch(error => {
        console.error("Error getting location:", error.message)
      })
  }

  const handleKeyUp = (currentRef, nextRef, e) => {
    nextRef.current.focus()
    if (e.key === "Backspace") {
      currentRef.current.value = ""
    } else if (e.key.length === 1) {
      // If a character is typed, move focus to the next input
      if (currentRef.current.value !== "") {
        if (nextRef.current) nextRef.current.focus()
      }
    } else {
      nextRef.current.focus()
    }
  }
  const handleDigitPress = digit => {
    if (clockInPin.length < 4) {
      setclockInPin(clockInPin + digit)
      // this.setState({clockInPin: clockInPin + digit});
    }
    var val = clockInPin + digit
    if (val.length === 4) {
      functionRun(clockInPin + digit)
    }
  }
  const functionRun = val => {
    if (val === acceptedPin) {
      setshownSuccessScreen(true)
      return false
    } else {
      Swal.fire({
        title: "Invalid PIN",
        text: "Please check your PIN input and try again",
        icon: "error",
        timer: 2000,
        timerProgressBar: true,
      })
      setclockInPin("")
      return false
    }
  }
  const onBreakActions = breakType => {
    if (breakType == "Take Break") {
      actionPerformAfter("Working")
    } else {
      actionPerformAfter("Break Time")
    }
  }
  const handleDeletePress = () => {
    if (clockInPin.length > 0) {
      setclockInPin(clockInPin.slice(0, -1))
    }
  }
  function fetchCurrentCompanyLatLong(passid) {
    var formdata = new FormData()
    formdata.append("method", "get-company-by-emp-id")
    formdata.append("emp_id", passid)

    axios
      .post(process.env.REACT_APP_LEAVE_API_URL + "/company.php", formdata)

      .then(async result => {
        if (result.data !== null) {
          if (result.data.location_map) {
            let val = result.data.location_map.split(",")
            let distance
            await getLocation().then(location => {
              distance = calculateDistance(
                location.latitude,
                location.longitude,
                val[0],
                val[1]
              )
            })

            if (result.data.geofence !== "null") {
              if (distance <= result.data.geofence) {
                seterrorForLocation(false)
              } else {
                seterrorForLocation(true)
              }
            } else {
              seterrorForLocation(false)
            }
          }
        }
      })
      .catch(error => console.log("error", error))
  }
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371 // Radius of the Earth in kilometers
    const dLat = (lat2 - lat1) * (Math.PI / 180)
    const dLon = (lon2 - lon1) * (Math.PI / 180)
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) *
        Math.cos(lat2 * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c * 1000 // Distance in meters
    return distance
  }
  const [product, setProduct] = useState("")
  useEffect(() => {
    setInterval(() => {
      document.title.split("|").map((e, index) => {
        if (index == 0) {
          setProduct(e)
        }
      })
    }, 1000)
    setInterval(() => {
      document.title.split("|").map((e, index) => {
        if (index == 0) {
          setProduct(e)
        }
      })
    }, 1000)
  }, [document.title])
  const what = localStorage.getItem("whatGoing")

  return (
    <React.Fragment>
      <header id="page-topbar">
        <Row>
          <div className="navbar-header">
            <Col xl={3}>
              <div className="d-flex">
                <div className="navbar-brand-box d-lg-none d-md-block">
                  <Link to="/" className="logo logo-dark">
                    <span className="logo-sm">
                      <img src={logo} alt="" height="22" />
                    </span>
                  </Link>
                  <Link to="/" className="logo logo-light">
                    <span className="logo-sm">
                      <img src={logo} alt="" height="22" />
                    </span>
                  </Link>
                </div>

                <button
                  type="button"
                  onClick={() => {
                    tToggle()
                  }}
                  className="btn btn-sm px-3 font-size-16 header-item "
                  id="vertical-menu-btn"
                >
                  <i className="fa fa-fw fa-bars" />
                </button>
                {WebLocation.pathname !== "/dashboard" &&
                  WebLocation.pathname !== "/timesheet" &&
                  WebLocation.pathname !== "/track-location" &&
                  WebLocation.pathname !== "/review/timeoff" &&
                  WebLocation.pathname !== "/people/members" &&
                  WebLocation.pathname !== "/projects" &&
                  WebLocation.pathname !== "/tasks" &&
                  WebLocation.pathname !== "/activities" &&
                  WebLocation.pathname !== "/payroll" &&
                  WebLocation.pathname !== "/requests" &&
                  WebLocation.pathname !== "/invoices" &&
                  WebLocation.pathname !== "/recruitment" &&
                  WebLocation.pathname !== "/schedules" &&
                  WebLocation.pathname !== "/locations" &&
                  WebLocation.pathname !== "/performance" &&
                  WebLocation.pathname !== "/announcements" &&
                  WebLocation.pathname !== "/organization/profile" && (
                    <button
                      onClick={() => window.history.back()}
                      style={{
                        marginRight: "10px",
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                      }}
                    >
                      <i
                        className="bx bx-arrow-back"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </button>
                  )}

                <p
                  style={{
                    paddingTop: 20,
                    fontSize: 16,
                    fontWeight: "600",
                    fontFamily: "Nunito Sans",
                  }}
                >
                  {product}
                  &nbsp;&nbsp;
                  {what !== null && Ownerstatus == "false" ? (
                    <span
                      style={{
                        backgroundColor:
                          breakButtonText == "Break Time" ? "green" : "#f1b34c",
                        color: "white",
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 5,
                      }}
                    >
                      {what == "Break Time" ? "Break" : what}
                    </span>
                  ) : null}
                  {onLeave ? (
                    <span
                      style={{
                        backgroundColor: "#f1b34c",
                        color: "white",
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: 5,
                      }}
                    >
                      {"On Leave"}
                    </span>
                  ) : null}
                </p>
              </div>
            </Col>
            <Col className="offset-xl-1 float-end" xl={4}>
              {onLeave == true ? null : (
                <div
                  className="d-flex flex-wrap gap-2"
                  style={{
                    paddingTop: buttonTxt == "Check Out" ? 14 : 0,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ paddingTop: 5, textAlign: "center" }}>
                    {putStatus ? putStatus : null}
                    {timerStarted == true ? (
                      <p style={{ fontWeight: "600", marginBottom: 0 }}>
                        {formatTime(secondsRemaining)}
                      </p>
                    ) : null}
                    {activityName ? (
                      <span
                        style={{
                          backgroundColor: activityBackground,
                          padding: 5,
                          borderRadius: 10,
                          marginLeft: 5,
                          color: "white",
                          fontSize: 10,
                        }}
                      >
                        {activityName}
                      </span>
                    ) : null}
                    <br />
                    {/* {punchInLocation ? punchInLocation : "Detect Location"} */}
                  </div>

                  {buttonTxt == "Check Out" ? (
                    <div
                      className="my_class_iwant"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        style={{
                          borderRadius: 100,
                          paddingTop: 8,
                          paddingBottom: 5,
                          backgroundColor:
                            breakButtonText == "Break Time"
                              ? "transparent"
                              : "#f1b44c",
                        }}
                        className={`btn-sm btn ${
                          breakButtonText == "Break Time"
                            ? "btn-warning"
                            : "btn-outline-warning"
                        }`}
                        title={breakButtonText}
                        onClick={() => {
                          if (breakButtonText === "Break Time") {
                            onBreakActions("Take Break")
                          } else {
                            onBreakActions("Other")
                          }
                        }}
                      >
                        <i
                          style={{
                            color:
                              breakButtonText == "Break Time"
                                ? "#f1b44c"
                                : "white",
                          }}
                          className="bx bx-restaurant font-size-16"
                        ></i>{" "}
                      </Button>
                      <p style={{ fontSize: 9, fontWeight: "600" }}>
                        {breakButtonText == "Break Time"
                          ? "Start Break"
                          : "End Break"}
                      </p>
                    </div>
                  ) : Ownerstatus == "true" ? null : (
                    <div className="my_class_iwant">
                      <Button
                        tag="button"
                        disabled={putStatus == null ? false : true}
                        style={{
                          borderRadius: 100,
                          paddingTop: 8,
                          paddingBottom: 5,
                        }}
                        className="btn-sm btn btn-info"
                        onClick={toggleModal}
                      >
                        <i className="bx bx-play font-size-16"></i>{" "}
                      </Button>
                      <p style={{ fontWeight: "600", fontSize: 9 }}>Clock In</p>
                    </div>
                  )}
                  {buttonTxt == "Check Out" && activityName !== "" ? (
                    <div
                      className="my_class_iwant"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        style={{
                          borderRadius: 100,
                          paddingTop: 8,
                          paddingBottom: 5,
                        }}
                        tag="button"
                        className="btn-sm btn btn-info"
                        onClick={() => setChangeActivity(true)}
                        disabled={breakButtonText == "End Break" ? true : false}
                      >
                        <TaskSharp fontSize="12" />
                      </Button>
                      <p style={{ fontSize: 9, fontWeight: "600" }}>
                        {"Change Activity"}
                      </p>
                    </div>
                  ) : null}
                  {buttonTxt == "Check Out" ? (
                    <div
                      className="my_class_iwant"
                      style={{ textAlign: "center" }}
                    >
                      <Button
                        style={{
                          borderRadius: 100,
                          paddingTop: 8,
                          paddingBottom: 5,
                        }}
                        tag="button"
                        className="btn-sm btn btn-danger"
                        onClick={toggleModal}
                        disabled={breakButtonText == "End Break" ? true : false}
                      >
                        <i className="bx bx-stop font-size-16"></i>{" "}
                      </Button>
                      <p style={{ fontSize: 9, fontWeight: "600" }}>
                        {"Clock out"}
                      </p>
                    </div>
                  ) : null}
                </div>
              )}
            </Col>

            <Col xl={4}>
              <div className="float-end">
                <NotificationDropdown />
                <ProfileMenu />
              </div>
            </Col>
          </div>
        </Row>
      </header>

      <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas}>
        <OffcanvasHeader toggle={toggleRightCanvas}>
          {buttonTxt}
        </OffcanvasHeader>
        <OffcanvasBody>
          {shownSuccessScreen === false ? (
            errorForLocation === true ? (
              <div style={{ textAlign: "center", paddingTop: 50 }}>
                <i style={{ fontSize: 24 }} className="bx bx-map"></i>
                <p>
                  {
                    "You are outside the office location. Please check your location and try again"
                  }
                </p>
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  {/* <Form onSubmit={(e)=>{
               e.preventDefault();
               const first = e.target.elements.first_one.value;
               const second = e.target.elements.second_one.value;
               const third = e.target.elements.third_one.value;
               const fourth = e.target.elements.fourth_one.value;
               const proper = first + second + third + fourth  
               if(proper.length <= 3){
                toastr.error('PIN has four digits', 'PIN ERROR', 2000);
                return false;
               }else{

                 functionRun(proper )
               }
              }}>
                            <Row>
                              <Col className="col-3">
                                <div className="mb-3">
                                  <Label htmlFor="digit1_input" className="visually-hidden">Dight 1</Label>
                                  <Input
                                   type="tel"
                                    className="form-control form-control-lg text-center two-step"
                                    maxLength="1"
                                    data-value="1"
                                    name="first_one"
                                    id="digit1_input"
                                    onKeyUp={(e) => handleKeyUp(firstInputRef, secondInputRef, e)}
                                    ref={firstInputRef}
                                    />
                                </div>
                              </Col>

                              <Col className="col-3">
                                <div className="mb-3">
                                  <Label htmlFor="digit2-input" className="visually-hidden">Dight 2</Label>
                                  <Input type="text"
                                    className="form-control form-control-lg text-center two-step"
                                    maxLength="1"
                                    data-value="2"
                                    name="second_one"
                                    id="digit2-input"
                                    onKeyUp={(e) => handleKeyUp(secondInputRef, thirdInputRef, e)}
                                    ref={secondInputRef}
                                    />
                                </div>
                              </Col>

                              <Col className="col-3">
                                <div className="mb-3">
                                  <Label htmlFor="digit3-input" className="visually-hidden">Dight 3</Label>
                                  <Input type="text"
                                    className="form-control form-control-lg text-center two-step"
                                    maxLength="1"
                                    name="third_one"
                                    data-value="3"
                                    id="digit3-input"
                                    onKeyUp={(e) => handleKeyUp(thirdInputRef, fourthInputRef, e)}
                                    ref={thirdInputRef}
                                    />
                                </div>
                              </Col>

                              <Col className="col-3">
                                <div className="mb-3">
                                  <Label htmlFor="digit4-input" className="visually-hidden">Dight 4</Label>
                                  <Input type="text"
                                    className="form-control form-control-lg text-center two-step"
                                    maxLength="1"
                                    data-value="4"
                                    name="fourth_one"
                                    id="digit4-input"
                                    onKeyUp={(e) => handleKeyUp(fourthInputRef, fourthInputRef, e)}
                                    ref={fourthInputRef}
                                    />
                                </div>
                              </Col>
                              <Col md={12}>
                                 <div className="mt-4">
                                      <button
                                      type="submit"
                                        className="btn btn-success w-md"
                                      >
                                        Confirm
                                      </button>
                                    </div>
                              </Col>
                            </Row>
                          </Form> */}

                  <Dialpad
                    pin={clockInPin}
                    onDeletePress={handleDeletePress}
                    onDigitPress={handleDigitPress}
                  />
                </Col>
              </Row>
            )
          ) : null}
          {shownSuccessScreen == true ? (
            <div>
              <Row>
                <Col xl={3}>
                  <img
                    src={profileImage || "https://via.placeholder.com/80"}
                    alt=""
                    style={{ objectFit: "cover" }}
                    className="avatar-sm rounded-circle"
                  />
                </Col>
                <Col xl={6}>
                  <p className="mb-2" style={{ fontWeight: "bold" }}>
                    {currentUsername}
                  </p>

                  <p style={{ fontWeight: "500", margin: 0 }}>Date & Time</p>
                  <p className="mb-4">{getCurrentDateTime()}</p>
                </Col>
              </Row>
              {buttonTxt === "Check In" ? (
                <div>
                  <Nav tabs className="nav-tabs-custom mb-3" role="tablist">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "1",
                        })}
                        onClick={() => {
                          toggleTab("1")
                        }}
                      >
                        {buttonTxt}
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "2",
                        })}
                        onClick={() => {
                          toggleTab("2")
                        }}
                      >
                        Add hours
                      </NavLink>
                    </NavItem> */}
                  </Nav>

                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col className="col-12">
                          <div className="mb-3">
                            <FormGroup check inline>
                              <Input
                                onClick={() => changeActiveBottomTab("Tasks")}
                                type="radio"
                                id="radioButton"
                                checked={
                                  activeBottomTab === "Tasks" ? true : false
                                }
                              />
                              <Label
                                htmlFor="radioButton2"
                                check
                                onClick={() => changeActiveBottomTab("Tasks")}
                              >
                                Task
                              </Label>
                            </FormGroup>
                            <FormGroup check inline>
                              <Input
                                onClick={() =>
                                  changeActiveBottomTab("Activities")
                                }
                                type="radio"
                                id="radioButton"
                                checked={
                                  activeBottomTab === "Activities"
                                    ? true
                                    : false
                                }
                              />
                              <Label
                                check
                                htmlFor="radioButton"
                                onClick={() =>
                                  changeActiveBottomTab("Activities")
                                }
                              >
                                Activity
                              </Label>
                            </FormGroup>
                          </div>
                          {activeBottomTab == "Activities" ? (
                            <div className="mb-3">
                              <Input
                                className="form-control"
                                type="select"
                                placeholder="Select an activity"
                                onChange={e => changeMyActivity(e.target.value)}
                              >
                                <option>Select an activity</option>
                                {filterFetch.map((e, i) => (
                                  <option
                                    key={i}
                                    value={
                                      e.id +
                                      "_" +
                                      e.activity_name +
                                      "_" +
                                      e.activity_color
                                    }
                                  >
                                    {e.activity_name}
                                  </option>
                                ))}
                              </Input>
                            </div>
                          ) : (
                            <div className="mb-3">
                              <Input
                                className="form-control"
                                type="select"
                                placeholder="Select a task"
                                onChange={e => changeMyActivity(e.target.value)}
                              >
                                <option value={null}>Select a task </option>
                                {filterFetch.map((e, i) => {
                                  return (
                                    <option
                                      key={i}
                                      value={
                                        e.task
                                          ? e.task.id +
                                            "_" +
                                            e.task.task_title +
                                            "_" +
                                            "#094eab"
                                          : null
                                      }
                                    >
                                      {" "}
                                      {e.task ? e.task.task_title : null}
                                    </option>
                                  )
                                })}
                              </Input>
                            </div>
                          )}
                          {/* <div className="mb-3">
                      <Input className="form-control" type="select">
                        <option>Select a project</option>
                      </Input>
                    </div> */}

                          <div className="custom-horizontal-line"></div>
                        </Col>
                        {/* <Col className="col-4">
                    <Button color="light" className="btn-sm btn-rounded ">
                      <span>
                        <i className="bx bx-alarm" />
                        Add reminder
                      </span>
                    </Button>
                  </Col>
                  <Col className="col-4">
                    <Button color="light" className="btn-sm btn-rounded ">
                      <span>
                        <i className="bx bx-alarm-off" />
                        Disable
                      </span>
                    </Button>
                  </Col> */}
                      </Row>
                    </TabPane>
                  </TabContent>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="2">
                      <Row>
                        <Col className="col-6">
                          <div className="mb-3">
                            <Input className="form-control" type="time" />
                          </div>
                        </Col>
                        <Col className="col-6">
                          <div className="mb-3">
                            <Input className="form-control" type="date" />
                          </div>
                        </Col>
                        <Col className="col-12">
                          <div className="mb-3">
                            <Input
                              className="form-control"
                              type="select"
                              placeholder="Select an activity"
                              onChange={e => changeMyActivity(e.target.value)}
                            >
                              <option>Select an activity</option>
                              {filterFetch.map((e, i) => (
                                <option
                                  key={i}
                                  value={
                                    e.id +
                                    "_" +
                                    e.activity_name +
                                    "_" +
                                    e.activity_color
                                  }
                                >
                                  {e.activity_name}
                                </option>
                              ))}
                            </Input>
                          </div>

                          <div className="mb-3">
                            <Input
                              className="form-control"
                              type="textarea"
                              placeholder="Add a note"
                            />
                          </div>
                          <div className="custom-horizontal-line"></div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </div>
              ) : (
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                  <FormGroup check inline>
                    <Input type="radio" id="radioMe" checked={true} />
                    <Label htmlFor="radioMe">End of the day</Label>
                  </FormGroup>
                </div>
              )}
              <Row style={{ columnGap: 20 }}>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setisRight(!isRight)
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{ marginTop: 10 }}
                  type="button"
                  color="primary"
                  onClick={() => processAfterCaptureImage()}
                >
                  Save
                </Button>
              </Row>
            </div>
          ) : null}
        </OffcanvasBody>
      </Offcanvas>
      <Offcanvas
        isOpen={changeActivity}
        direction="end"
        toggle={toggleActivityCanvas}
      >
        <OffcanvasHeader toggle={toggleActivityCanvas}>
          {"Change Activity"}
        </OffcanvasHeader>
        <OffcanvasBody>
          {shownSuccessScreen === false ? (
            errorForLocation === true ? (
              <div style={{ textAlign: "center", paddingTop: 50 }}>
                <i style={{ fontSize: 24 }} className="bx bx-map"></i>
                <p>
                  {
                    "You are outside the office location. Please check your location and try again"
                  }
                </p>
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  <Dialpad
                    pin={clockInPin}
                    onDeletePress={handleDeletePress}
                    onDigitPress={handleDigitPress}
                  />
                </Col>
              </Row>
            )
          ) : null}
          {shownSuccessScreen == true ? (
            <div>
              <Row>
                <Col xl={3}>
                  <img
                    src={profileImage || "https://via.placeholder.com/80"}
                    alt=""
                    style={{ objectFit: "cover" }}
                    className="avatar-sm rounded-circle"
                  />
                </Col>
                <Col xl={6}>
                  <p className="mb-2" style={{ fontWeight: "bold" }}>
                    {currentUsername}
                  </p>

                  <p style={{ fontWeight: "500", margin: 0 }}>Date & Time</p>
                  <p className="mb-4">{getCurrentDateTime()}</p>
                </Col>
              </Row>
              <div>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col className="col-12">
                        <div className="mb-3">
                          <FormGroup check inline>
                            <Input
                              onClick={() => changeActiveBottomTab("Tasks")}
                              type="radio"
                              id="radioButton"
                              checked={
                                activeBottomTab === "Tasks" ? true : false
                              }
                            />
                            <Label
                              htmlFor="radioButton2"
                              check
                              onClick={() => changeActiveBottomTab("Tasks")}
                            >
                              Task
                            </Label>
                          </FormGroup>
                          <FormGroup check inline>
                            <Input
                              onClick={() =>
                                changeActiveBottomTab("Activities")
                              }
                              type="radio"
                              id="radioButton"
                              checked={
                                activeBottomTab === "Activities" ? true : false
                              }
                            />
                            <Label
                              check
                              htmlFor="radioButton"
                              onClick={() =>
                                changeActiveBottomTab("Activities")
                              }
                            >
                              Activity
                            </Label>
                          </FormGroup>
                        </div>
                        {activeBottomTab == "Activities" ? (
                          <div className="mb-3">
                            <Input
                              className="form-control"
                              type="select"
                              placeholder="Select an activity"
                              onChange={e => changeMyActivity(e.target.value)}
                            >
                              <option>Select an activity</option>
                              {filterFetch.map((e, i) => (
                                <option
                                  key={i}
                                  value={
                                    e.id +
                                    "_" +
                                    e.activity_name +
                                    "_" +
                                    e.activity_color
                                  }
                                >
                                  {e.activity_name}
                                </option>
                              ))}
                            </Input>
                          </div>
                        ) : (
                          <div className="mb-3">
                            <Input
                              className="form-control"
                              type="select"
                              placeholder="Select a task"
                              onChange={e =>
                                changeMyActivityContinue(e.target.value)
                              }
                            >
                              <option value={null}>Select a task </option>
                              {filterFetch.map((e, i) => {
                                return (
                                  <option
                                    key={i}
                                    value={
                                      e.task
                                        ? e.task.id +
                                          "_" +
                                          e.task.task_title +
                                          "_" +
                                          "#094eab"
                                        : null
                                    }
                                  >
                                    {" "}
                                    {e.task ? e.task.task_title : null}
                                  </option>
                                )
                              })}
                            </Input>
                          </div>
                        )}
                        {/* <div className="mb-3">
                      <Input className="form-control" type="select">
                        <option>Select a project</option>
                      </Input>
                    </div> */}

                        <div className="custom-horizontal-line"></div>
                      </Col>
                      {/* <Col className="col-4">
                    <Button color="light" className="btn-sm btn-rounded ">
                      <span>
                        <i className="bx bx-alarm" />
                        Add reminder
                      </span>
                    </Button>
                  </Col>
                  <Col className="col-4">
                    <Button color="light" className="btn-sm btn-rounded ">
                      <span>
                        <i className="bx bx-alarm-off" />
                        Disable
                      </span>
                    </Button>
                  </Col> */}
                    </Row>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="2">
                    <Row>
                      <Col className="col-6">
                        <div className="mb-3">
                          <Input className="form-control" type="time" />
                        </div>
                      </Col>
                      <Col className="col-6">
                        <div className="mb-3">
                          <Input className="form-control" type="date" />
                        </div>
                      </Col>
                      <Col className="col-12">
                        <div className="mb-3">
                          <Input
                            className="form-control"
                            type="select"
                            placeholder="Select an activity"
                            onChange={e =>
                              changeMyActivityContinue(e.target.value)
                            }
                          >
                            <option>Select an activity</option>
                            {filterFetch.map((e, i) => (
                              <option
                                key={i}
                                value={
                                  e.id +
                                  "_" +
                                  e.activity_name +
                                  "_" +
                                  e.activity_color
                                }
                              >
                                {e.activity_name}
                              </option>
                            ))}
                          </Input>
                        </div>

                        <div className="mb-3">
                          <Input
                            className="form-control"
                            type="textarea"
                            placeholder="Add a note"
                          />
                        </div>
                        <div className="custom-horizontal-line"></div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>

              <Row style={{ columnGap: 20 }}>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    setChangeActivity(!changeActivity)
                  }}
                >
                  Close
                </Button>
                <Button
                  style={{ marginTop: 10 }}
                  type="button"
                  color="primary"
                  onClick={() => actionPerformAfterSwitchActivity()}
                >
                  Change Activity
                </Button>
              </Row>
            </div>
          ) : null}
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
}

const mapStatetoProps = state => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
