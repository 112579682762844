import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
import logo from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import ChatWidget from "components/Common/ChatWidget";

const InvoiceDetail = props => {

  let  state = useLocation() ;
  
  document.title = `Invoice Detail | ${process.env.REACT_APP_NAME}`


 

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };


  function formatDate(dateString) {
    // Split the date string by '/'
    const dateParts = dateString.split('/');
    const year = parseInt(dateParts[2]);
    // Extract the month (subtract 1 because months are zero-based)
    const month = parseInt(dateParts[0]) - 1;
    const day = parseInt(dateParts[1]);

    // Create a Date object using the extracted parts
    const date = new Date(year, month, day);

    // Format the date as "Month Day, Year"
    const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

    return formattedDate;
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" />
          {!isEmpty(state.state.obj) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        InvoiceNo # {state.state.obj.id}
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo-dark" className="logo-dark-element" height="20" />
                        <img src={logoLight} alt="logo-light" className="logo-light-element" height="20" />
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col sm="6">
                        <address>
                          <strong>Billed To:</strong>
                          <br />
                           
                              <React.Fragment >
                                <span>{state.state.obj.billing_name}</span>
                                <br />
                                <span>{state.state.obj.billing_address}</span>
                                <br />
                                <span>{state.state.obj.billing_email}</span>
                                <br />
                              </React.Fragment>
                         
                        </address>
                      </Col>
                      <Col sm="6" className="text-sm-end">
                        <address>
                          <strong>Organization Name:</strong>
                          <br />
                   
                              <React.Fragment >
                                <span>{JSON.parse(localStorage.getItem('authUser')).map((e)=>e.org_title)}</span>
                                <br />
                              </React.Fragment>
                        
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="mt-3">
                        <address>
                        
                        </address>
                      </Col>
                      <Col sm="6" className="mt-3 text-sm-end">
                        <address>
                          <strong>Invoice Date:</strong>
                          <br />
                          {formatDate(state.state.obj.created_date)}
                          <br />
                          <br />
                        </address>
                      </Col>
                    </Row>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 fw-bold">Order summary</h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th style={{ width: "70px" }}>No.</th>
                            <th >Item</th>
                            <th  style={{ width: "570px" }} className="text-end">Quantity</th>
                            <th className="text-end">Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(
                            state.state.obj.itemList,
                            (item, key) => (
                              <tr key={key}>
                                <td>{item.id}</td>
                                <td>{item.itemName}-{item.itemDescription}</td>
                                <td className="text-end">{item.itemQuantity}</td>
                                <td className="text-end">{state.state.obj.currency}{parseFloat(item.itemPrices).toFixed(2)}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan="3" className="text-end">
                              Sub Total
                            </td>
                            <td className="text-end">
                            {state.state.obj.currency}{parseFloat(state.state.obj.subtotal).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="border-0 text-end">
                              <strong>Discount</strong>
                            </td>
                            <td className="border-0 text-end">
                            {state.state.obj.currency}{parseFloat(state.state.obj.discount_value !== null ? state.state.obj.discount_value: 0 ).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="border-0 text-end">
                              <strong>Tax</strong>
                            </td>
                            <td className="border-0 text-end">
                            {state.state.obj.currency}{parseFloat(state.state.obj.tax_value).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3" className="border-0 text-end">
                              <strong>Total</strong>
                            </td>
                            <td className="border-0 text-end">
                              <h4 className="m-0">
                                {state.state.obj.currency}{parseFloat(state.state.obj.total).toFixed(2)}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success  me-2"
                        >
                          <i className="fa fa-print" />
                        </Link>
                        <Link to="#" className="btn btn-primary w-md ">
                          Send
                        </Link>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <ChatWidget/>
    </React.Fragment>
  );
};

InvoiceDetail.propTypes = {
  match: PropTypes.any,
};

export default withRouter(InvoiceDetail);
