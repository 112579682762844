import React, { useEffect, useState, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  Tooltip,
  UncontrolledTooltip,
  Badge,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

import {
  Pdate,
  Type,
  Value,
  ValueinUSD,
  Status,
  Coin,
  Tracked,
  Actions,
} from "./SheetData"

import TableContainer from "../../components/Common/TableContainer"

//Date Picker
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import "assets/scss/datatables.scss"

// import { cryptoOrderData } from "../../../common/data";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getCryptoOrders } from "store/crypto/actions"

import img3 from "../../assets/images/approve-hour.f7e7a87f.svg"
import { Link } from "react-router-dom"
import {
  DataGrid,
  GridRowsProp,
  GridToolbar,
  GridColDef,
} from "@mui/x-data-grid"
import { styled } from "@mui/material/styles"
import { Box, Skeleton, Tab, Tabs, Typography } from "@mui/material"

import MonthlyShown from "./MonthlyShown"
import ChatWidget from "components/Common/ChatWidget"
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}
const TimeSheet = props => {
  document.title = `TimeSheet | ${process.env.REACT_APP_NAME}`

  const { orders, onGetOrders } = props
  const [selectedDates, setSelectedDates] = useState([])
  const [TimeSheetData, SetTimeSheetData] = useState([])
  const [currentRole, setCurrentRole] = useState(
    JSON.parse(localStorage.getItem("authUser")).map(e => e.role)
  )
  const [pick_date, setPickDate] = useState("")
  const [selectedMonth, setselectedMonth] = useState(new Date().getMonth() + 1)
  function getCurrentDate() {
    const today = new Date()
    const dd = String(today.getDate()).padStart(2, "0")
    const mm = String(today.getMonth() + 1).padStart(2, "0") // January is 0!
    const yyyy = today.getFullYear()

    return `${yyyy}-${mm}-${dd}`
  }
  const FirstDate = getCurrentDate()

  const [activeTab, setActiveTab] = useState(0)
  const [present, setPresent] = useState(0)
  const [leave, setLeave] = useState(0)
  const [absent, setAbsent] = useState(0)
  const [startDate, setStartDate] = useState(new Date(FirstDate))
  const [selectCoin, setselectCoin] = useState(null)
  const [selectType, setselectType] = useState("Buy")
  const [selectStatus, setselectStatus] = useState("Completed")
  const [productData, setSetProductData] = useState([orders])
  const [weekStartDate, setWeekStartDate] = useState(null)
  const [weekStartDateSend, setWeekStartDateSend] = useState(null)
  const [weekEndDate, setWeekEndDate] = useState(null)
  const [weekEndDateSend, setWeekEndDateSend] = useState(null)
  const [columnShow, setColumnShow] = useState([])
  const [dataShownWeekly, setdataShownWeekly] = useState([])
  const [changeWeek, setChangeWeek] = useState("")
  const [DepartmentsArray, setDepartmentsArray] = useState([])
  const [selectDept, setSelectDept] = useState("All")
  const [showPlaceholder, setshowPlaceholder] = useState(false)
  const [tableLoader, setTableLoader] = useState(true)
  const fetchEmployeeForWeekly = async () => {
    setshowPlaceholder(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "attendance-for-timesheet-weekly")
    formdata.append("emp_id", emp)
    formdata.append("start_date", formatDateValues(weekStartDateSend))
    formdata.append("end_date", formatDateValues(weekEndDateSend))
    formdata.append("org_id", org)
    formdata.append("dept", selectDept)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/attendance.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setshowPlaceholder(false)
        setdataShownWeekly(result.data)
        // this.setState({filteredData: result.data, data: result.data});
        // this.setState({isLoading: false});
      })
      .catch(error => console.log("error", error))
  }

  const fetchEmployeeForMonthly = async () => {
    setshowPlaceholder(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "attendance-for-timesheet-monthly")
    formdata.append("emp_id", emp)
    formdata.append("current_month", selectedMonth)
    formdata.append("dept", selectDept)
    formdata.append("org_id", org)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/attendance.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setshowPlaceholder(false)

        setdataShownWeekly(result.data)
        JSON.stringify(result.data)
        // this.setState({filteredData: result.data, data: result.data});
        // this.setState({isLoading: false});
      })
      .catch(error => console.log("error", error))
  }
  const fetchEmployee = async () => {
    // setshowPlaceholder(true)
    setTableLoader(true)
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "attendance-for-timesheet-for-desktop")
    formdata.append("emp_id", emp)
    formdata.append("dept", selectDept)
    const dd = String(startDate.getDate()).padStart(2, "0")
    const mm = String(startDate.getMonth() + 1).padStart(2, "0") // January is 0!
    const yyyy = startDate.getFullYear()

    const dateIwant = `${yyyy}-${mm}-${dd}`
    console.log(dateIwant)
    formdata.append("filter_date", dateIwant)
    formdata.append("org_id", org)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/attendance.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setshowPlaceholder(false)
        let present = 0
        let leave = 0
        let absent = 0
        result.data.map(e => {
          if (e.status == "present") {
            present = present + 1
          } else if (e.status == "leave") {
            leave = leave + 1
          } else {
            absent = absent + 1
          }
        })
        setLeave(leave)
        setPresent(present)
        setAbsent(absent)
        const dataFor = result.data.sort((a, b) => {
          // Convert the 'time_punch_in' strings to Date objects for comparison
          const timeA = new Date(a.time_punch_in);
          const timeB = new Date(b.time_punch_in);
        
          // Compare the dates
          return timeB - timeA;
        });
        SetTimeSheetData(dataFor)
        // SetTimeSheetData(result.data)
        if (result.data.length == 0) {
          setTableLoader(false)
        }
      })
      .catch(error => {
        console.log(error)
        // window.location.href='/no-internet'
      })
  }
  // useEffect(() => {
  //   onGetOrders()
  //   setSetProductData(orders)
  // }, [onGetOrders])
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }
  const handleFilter = () => {
    var allProduct = orders.filter(order => {
      return (
        order.type === selectType &&
        order.coin === selectCoin &&
        order.status === selectStatus
      )
    })
    setSetProductData(allProduct)
  }

  useEffect(() => {
    getAllActivities()
    setdataShownWeekly([])
    setColumnShow([])
    localStorage.setItem("activeTabsForPage", String(activeTab))
    if (activeTab == 0) {
      fetchEmployee()
    } else if (activeTab == 1) {
      fetchEmployeeForWeekly()
      let currentDate = new Date()
      getCurrentWeekDates(changeWeek ? changeWeek : currentDate)
    } else if (activeTab == 2) {
      fetchEmployeeForMonthly()
      getMonthDatesWithArray(
        selectedMonth ? selectedMonth : new Date().getMonth() + 1
      )
    }
  }, [activeTab, startDate, weekStartDate, selectedMonth, selectDept])

  const onChangevalueoFMonth = val => {
    setselectedMonth(val)
  }

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }))

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    )
  }
  // const convertTime = inputTime => {
  //   if (inputTime !== 0) {
  //     const [hours, minutes, seconds] = inputTime.split(":")
  //     const dateObj = new Date()
  //     dateObj.setHours(hours)
  //     dateObj.setMinutes(minutes)
  //     dateObj.setSeconds(seconds)
  //     const formattedTime = dateObj.toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       hour12: true,
  //     })

  //     return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
  //   } else {
  //     return "N/a"
  //   }
  // }
  const columnsFor: GridColDef[] = [
    {
      field: "da",
      headerName: "Image",
      width: 100,
      renderCell: param => (
        <img
          src={param.row.data.profile_image}
          className="avatar-xs rounded-circle"
          style={{objectFit:'cover'}}
        />
      ),
    },
    {
      field: "emp_id",
      headerName: "Emp ID",
      width: 80,
      renderCell: param => <Link to={`../../people/members/view/${param.value}`}>{param.value}</Link>,
    },
    {
      field: "d",
      headerName: "Full Name",
      width: 150,
      renderCell: param => (
        <p>
          {param.row.data.first_name} {param.row.data.last_name}
        </p>
      ),
    },
    {
      field: "time_punch_in",
      headerName: "Check In",
      width: 150,
      renderCell: param => {
        let valuez = []
        if (param.value !== null) {
          valuez = param.value.split(" ")
        }

        return <p>{convertTime(valuez.length !== 0 ? valuez[1] : 0)}</p>
      },
    },
    {
      field: "time_punch_out",
      headerName: "Check Out",
      width: 150,
      renderCell: param => {
        let valuez = []
        if (param.value !== null) {
          valuez = param.value.split(" ")
        }

        return <p>{convertTime(valuez.length !== 0 ? valuez[1] : 0)}</p>
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: param => <p>{param.value}</p>,
    },
    {
      field: "total_work_time",
      headerName: "Working Hours",
      width: 150,
      renderCell: param => (
        <p>{param.value ? formatTime(param.value) : "00h:00m:00"}</p>
      ),
    },

    {
      field: "",
      headerName: "Actions",
      width: 150,
      renderCell: param => {
        return (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li data-bs-toggle="tooltip" data-bs-placement="top" title="View">
              <Link
                className="btn btn-primary"
                to={"/timesheet/view"}
                state={{ obj: param.row, created_at: param.value }}
              >
                DETAILS
              </Link>
            </li>
          </ul>
        )
      },
    },
  ]

  function getDatesArray(startDate, endDate) {
    const datesArray = [];
    const options = { weekday: "long", day: "2-digit", month: "short" };
  
    const currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const dayName = currentDate.toLocaleDateString("en-US", { weekday: "long" });
      const formattedDate = currentDate.toLocaleDateString("en-US", options);
      datesArray.push({ dayName, date: formattedDate });
      currentDate.setDate(currentDate.getDate() + 1);
    }
  
    return datesArray;
  }
  
  function getAllDatesInMonth(currentMonth) {
    const currentDate = new Date()
    currentDate.setMonth(currentMonth - 1)
    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const allDates = [] 
    for (let day = 1; day <= new Date(year, month, 0).getDate(); day++) {
      const dateObj = new Date(year, month - 1, day)
      const dayName = dateObj.toLocaleDateString("en-US", { weekday: "long" })
      const date = dateObj.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "short", 
      })

      // if (!excludedDays.includes(dayName)) {
        allDates.push({ dayName, date })
      // }
    }

    return allDates
  }
  const getMonthDatesWithArray = month => {
    const Welcome = getAllDatesInMonth(month)
    setColumnShow(Welcome)
  }
  function getCurrentWeekDates(timestamp) {
    const currentDate = new Date(timestamp);
    const currentDay = currentDate.getDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
    const diff = currentDay === 0 ? 0 : 7 - currentDay; // Calculate the difference in days to Sunday (start of the week)
    const startDate = new Date(currentDate);
    startDate.setDate(startDate.getDate() - diff); // Set startDate to Sunday of the current week

    // Adjust end date to include Saturday
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6); // Set endDate to Saturday of the current week

    // Format dates to 'DD Mon' format
    const options = { day: "2-digit", month: "short" };
    const formattedStartDate = startDate.toLocaleDateString("en-US", options);
    const formattedEndDate = endDate.toLocaleDateString("en-US", options);
    
    const datesArray = getDatesArray(startDate, endDate);

    setWeekStartDateSend(startDate);
    setWeekEndDateSend(endDate);
    setWeekStartDate(formattedStartDate);
    setWeekEndDate(formattedEndDate);
    setColumnShow(datesArray);
    console.log()
    setSelectedDates(datesArray);
}

  const handleDateChange = selectedDates => {
    // setSelectedDates(selectedDates);
    setChangeWeek(selectedDates)
    getCurrentWeekDates(selectedDates)
    console.log(selectedDates)
  }
  function getDatesArrays(startDate, endDate) {
    const datesArray = []
    const currentDate = new Date(startDate)

    while (currentDate <= endDate) {
      datesArray.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return datesArray
  }
  function formatDateValues(dateString) {
    // Create a new Date object from the input string
    const date = new Date(dateString)

    // Extract year, month, and day components
    const year = date.getFullYear()
    // Add 1 to month since January is 0-indexed
    const month = (date.getMonth() + 1).toString().padStart(2, "0")
    const day = date.getDate().toString().padStart(2, "0")

    // Construct the formatted date string in YYYY-MM-DD format
    const formattedDate = `${year}-${month}-${day}`

    return formattedDate
  }

  function getDateRange(datesArray) {
    if (!Array.isArray(datesArray) || datesArray.length === 0) {
      return ""
    }

    const firstDate = new Date(datesArray[0])
    const lastDate = new Date(datesArray[datesArray.length - 1])

    const formattedFirstDate = formatDate(firstDate)
    const formattedLastDate = formatDate(lastDate)

    return `${formattedFirstDate} - ${formattedLastDate}`
  }
  function formatDate(date) {
    const day = date.getDate()
    const month = date.toLocaleString("default", { month: "short" })
    return `${day} ${month}`
  }
  const formatTime = seconds => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60
    return `${String(hours).padStart(2, "0")}h:${String(minutes).padStart(
      2,
      "0"
    )}m:${String(remainingSeconds).padStart(2, "0")}`
  }
  function isDatePassed(inputDate) {
    // Convert input date string to Date object
    const inputDateObj = new Date(inputDate)
    // Get current date
    const currentDateObj = new Date()
    inputDateObj.setHours(0, 0, 0, 0)
    currentDateObj.setHours(0, 0, 0, 0)

    const retun = inputDateObj <= currentDateObj

    return retun 
  }
  const getAllActivities = async () => {
    // const value = await JSON.parse(AsyncStorage.getItem('@loginSession')).map((e)=>e.org_id);
    // alert(value)
    let local = localStorage.getItem("authUser")
    let mapsing = JSON.parse(local)
    let org = mapsing.map(e => e.org_id)
    var formdata = new FormData()
    formdata.append("method", "get-department-by-admin")
    formdata.append("org_id", org)

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/departments.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
        setDepartmentsArray(result)

        // setTimeout(() => {
        //   if (this.state.filterFetch.length == 0) {
        //     this.setState({isLoading :false});
        //   }
        // }, 1000);
      })
      .catch(error => console.log("error", error))
  }
  const tooltipTargetRef = useRef(null)
  const convertTime = inputTime => {
    if (inputTime !== 0) {
      const [hours, minutes, seconds] = inputTime.split(":")
      const dateObj = new Date()
      dateObj.setHours(hours)
      dateObj.setMinutes(minutes)
      dateObj.setSeconds(seconds)
      const formattedTime = dateObj.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })

      return formattedTime.toLowerCase() // Convert to lowercase for consistency with 'pm'
    } else {
      return "N/a"
    }
  }
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [tooltipContent, setTooltipContent] = useState("")

  const handleMouseEnter = (event, dayData) => {
    let value = []
    if (dayData.time_punch_in !== null) {
      value = dayData.time_punch_in.split(" ")
    }
    let valuez = []
    if (dayData.time_punch_out !== null) {
      valuez = dayData.time_punch_out.split(" ")
    }
    setTooltipContent(`
    <div style="padding: 10px; border-radius: 8px; box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); color: white;">
      <div style="font-weight: bold; margin-bottom: 10px;">Today's Status: ${
        dayData.status &&   isDatePassed(String(dayData.date))  ? dayData.status.toUpperCase() :( dayData.shift_start ==  'N/a'  ? "OFF DAY" : !isDatePassed(String(dayData.date)) ?  "Upcoming Day" : "NULL") 
      }</div>
      <div style="display: flex; justify-content: space-between; margin-bottom: 5px;">
        <div style="flex: 1;">
          <div>Check-in:</div>
          <div>Check-out:</div>
          <div>Shift Start:</div>
          <div>Shift End:</div>
          <div>Total Break:</div>
          <div>Total Working:</div>
        </div>
        <div style="flex: 1; text-align: right;">
          <div>${dayData.time_punch_in ? convertTime(value[1]) : "N/A"}</div>
          <div>${dayData.time_punch_out ? convertTime(valuez[1]) : "N/A"}</div>
          <div>${dayData.shift_start == 'N/a' ? 'N/a' :dayData.shift_start}</div>
          <div>${dayData.shift_end == 'N/a' ? 'N/a' : dayData.shift_end}</div>
          <div>${formatTime(dayData.total_break_time)}</div>
          <div>${formatTime(dayData.total_work_time)}</div>
        </div>
      </div>
    
    </div>
  `)
    setTooltipOpen(true)
  }

  const handleMouseLeave = () => {
    setTooltipOpen(false)
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }
  function formatTimeShiftTiming(time) {
    // Check if the time contains "am" or "pm"
   return time; 
}

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          {/* <Breadcrumbs title="Crypto" breadcrumbItem="Orders" /> */}
          <Row>
            <Col md={12}>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={activeTab} onChange={handleChange}>
                    <Tab label="Daily" {...a11yProps(0)} />
                    <Tab label="Weekly" {...a11yProps(1)} />
                    <Tab label="Monthly" {...a11yProps(2)} />
                  </Tabs>
                  {JSON.parse(localStorage.getItem("authUser")).map(
                    (e, index) => {
                      if (e.role !== "user") {
                        return (
                          <Row key={index} style={{ paddingTop: 20 }}>
                            <Col md={12}>
                              <Label>Select the department</Label>
                            </Col>
                            <Col lg={2} style={{ cursor: "pointer" }}>
                              <Card
                                onClick={() => setSelectDept("All")}
                                className={`blog-stats-wid ${
                                  selectDept == "All" ? "bg-info" : null
                                } `}
                              >
                                <CardBody style={{ padding: 12 }}>
                                  <div className="d-flex flex-wrap">
                                    <div className="me-3">
                                      <p
                                        style={{
                                          color:
                                            selectDept == "All"
                                              ? "white"
                                              : "black",
                                          fontWeight:
                                            selectDept == "All"
                                              ? "bold"
                                              : "normal",
                                        }}
                                        className="text-muted mb-2"
                                      >
                                        {"All"}
                                      </p>
                                      {/* <h5 className="mb-0">{presentEmployee}</h5> */}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            {DepartmentsArray.map((e, index) => (
                              <Col
                                lg={2}
                                style={{ cursor: "pointer" }}
                                key={index}
                                onClick={() => setSelectDept(e.name)}
                              >
                                <Card
                                  className={`blog-stats-wid ${
                                    selectDept == e.name ? "bg-info" : null
                                  } `}
                                >
                                  <CardBody style={{ padding: 12 }}>
                                    <div className="d-flex flex-wrap">
                                      <div
                                        className="me-3"
                                        style={{
                                          color:
                                            selectDept == e.name
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color:
                                              selectDept === e.name
                                                ? "white"
                                                : "black",
                                            whiteSpace: "nowrap",
                                            fontWeight:
                                              selectDept == e.name
                                                ? "bold"
                                                : "normal",
                                          }}
                                          className="text-muted mb-2"
                                        >
                                          {e.name}
                                        </p>

                                        {/* <h5 className="mb-0">{presentEmployee}</h5> */}
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                          </Row>
                        )
                      }
                    }
                  )}
                </Box>
                <CustomTabPanel value={activeTab} index={0}>
                  <Form>
                    <Row className="mb-4">
                      <Col sm={4}>
                        <FormGroup className="mb-3">
                          <Label className="form-label">Date :</Label>
                          <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            className="form-control"
                            dateFormat="d-MM-yyyy"
                            placeholderText="Select date"
                          />
                        </FormGroup>
                      </Col>
                      {currentRole == "admin" ? (
                        <Col md={12}>
                          <Row>
                            <Col lg={3}>
                              <Card className="mini-stats-wid">
                                <CardBody>
                                  <Link to={"#"}>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">Total</p>
                                        <h5 className="mb-0">
                                          {present + leave + absent}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div
                                          style={{ background: "transparent" }}
                                          className="avatar-title text-primary font-size-20"
                                        >
                                          <img
                                            src={require("../../assets/images/icons/Employees.png")}
                                            style={{ width: 40, height: 40 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </CardBody>
                              </Card>
                            </Col>

                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <Link to={"#"}>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Present
                                        </p>
                                        <h5 className="mb-0">{present}</h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div
                                          style={{ background: "transparent" }}
                                          className="avatar-title  text-primary font-size-20"
                                        >
                                          {/* <i className="bx bxs-note"></i> */}
                                          <img
                                            src={require("../../assets/images/icons/Presents.png")}
                                            style={{ width: 40, height: 40 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <Link to={"#"}>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Absent
                                        </p>
                                        <h5 className="mb-0">{absent}</h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div
                                          style={{ background: "transparent" }}
                                          className="avatar-title  text-primary font-size-20"
                                        >
                                          <img
                                            src={require("../../assets/images/icons/Absents.png")}
                                            style={{ width: 40, height: 40 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col lg={3}>
                              <Card className="blog-stats-wid">
                                <CardBody>
                                  <Link to={"/#"}>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Leaves
                                        </p>
                                        <h5 className="mb-0">{leave}</h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div
                                          style={{ background: "transparent" }}
                                          className="avatar-title  text-primary font-size-20"
                                        >
                                          <img
                                            src={require("../../assets/images/icons/Leaves.png")}
                                            style={{ width: 40, height: 40 }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </Col>
                      ) : null}
                    </Row>
                  </Form>
                  {TimeSheetData.length == 0 && showPlaceholder == true ? (
                    <div>
                      <Row style={{ columnGap: 0 }}>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                        <Col md={2}>
                          <Skeleton width={"100%"} height={70} />
                        </Col>
                      </Row>
                      <Skeleton
                        style={{
                          borderWidth: 1,
                          borderColor: "black",
                          marginTop: 5,
                        }}
                        width={"100%"}
                        height={70}
                      />
                      <Skeleton
                        style={{ marginTop: 5 }}
                        width={"100%"}
                        height={70}
                      />
                      <Skeleton
                        style={{ marginTop: 5 }}
                        width={"100%"}
                        height={70}
                      />
                      <Skeleton
                        style={{ marginTop: 5 }}
                        width={"100%"}
                        height={70}
                      />
                      <Skeleton
                        style={{ marginTop: 5 }}
                        width={"100%"}
                        height={70}
                      />
                    </div>
                  ) : (
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        autoHeight
                        sx={{ "--DataGrid-overlayHeight": "300px" }}
                        slots={{
                          toolbar: GridToolbar,
                          noRowsOverlay: CustomNoRowsOverlay
                        }}
                        
                        rows={TimeSheetData}
                        isCellEditable={false}
                        isRowSelectable={false}
                        loading={
                          TimeSheetData.length == 0 && tableLoader == true
                            ? true
                            : false
                        }
                        columns={columnsFor}
                        getRowId={row => row.emp_id}
                      />
                    </Box>
                  )}
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={1}>
                  <Row>
                    <Col md={12}>
                      <h3>Weely</h3>
                      <Row>
                        <Col md={4}>
                          <div className="docs-datepicker">
                            {/* <InputGroup> */}
                            <label>{weekStartDate + "-" + weekEndDate}</label>

                            <Flatpickr
                              value={selectedDates} // Set the selected dates
                              onChange={handleDateChange}
                              className="form-control d-block"
                              options={{
                                altInput: true,
                                dateFormat: "Y-m-d",
                              }}
                            />

                            {/* </InputGroup> */}
                            <div className="docs-datepicker-container" />
                          </div>
                        </Col>
                      </Row>
                      {/* &nbsp; <i className="fa fa-1x fa-calendar"></i> */}
                    </Col>
                    <Col md={12}>
                      <Row style={{ paddingTop: 20 }}>
                        <Col md={2}>
                          <table
                            className="table"
                            style={{
                              background: "transparent",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  Employee <br></br>Name{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataShownWeekly.length == 0 &&
                              showPlaceholder == true ? (
                                <tr>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                </tr>
                              ) : null}
                              {dataShownWeekly.map((employee, index) => (
                                <tr
                                  key={index}
                                  style={{ whiteSpace: "nowrap" }}
                                >
                                  <td>
                                    {currentRole == "admin" ||
                                    currentRole == "supervisor" ? (
                                      <Link
                                        to={`../../people/members/view/${employee.emp_data.emp_id}`}
                                      >
                                       {employee.emp_data.emp_id}
                                        {"-"}
                                        {employee.emp_data.first_name +
                                          employee.emp_data.last_name}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`../../people/members/view/${employee.emp_data.id}`}
                                      >
                                        {employee.emp_data.id}
                                        {"-"}
                                        {employee.emp_data.first_name +
                                          employee.emp_data.last_name}
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                        <Col md={10}>
                          <table
                            className="table"
                            style={{ background: "transparent" }}
                          >
                            <thead>
                              <tr>
                                {columnShow.map((e, index) => (
                                  <th key={index}>
                                    {e.dayName.substring(0, 3)} <br></br>
                                    {e.date.split(",")[1]}
                                  </th>
                                ))}
                                <th>Working Hours</th>
                              </tr>
                            </thead>

                            <tbody>
                              {dataShownWeekly.length == 0 &&
                              showPlaceholder == true ? (
                                <tr>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                </tr>
                              ) : null}

                              {dataShownWeekly.map((employee, index) => (
                                <tr key={index}>
                                  {employee.data_list.map(
                                    (dayData, dayIndex) => {
                                      const obj = {
                                        status: dayData.status,
                                        data: employee.emp_data,
                                        id: dayData.emp_id,
                                        attendance_id : dayData.attendance_id,
                                        created_at: dayData.created_at,
                                        time_punch_in: dayData.time_punch_in,
                                        time_punch_out: dayData.time_punch_out,
                                        total_work_time:
                                          dayData.total_work_time,
                                        total_break_time:
                                          dayData.total_break_time,
                                      }
                                      return (
                                        <td key={dayIndex}>
                                          {isDatePassed(String(dayData.date)) ==
                                          true   && dayData.shift_start !== 'N/a' ? (
                                            <div>
                                              <Link
                                                to={
                                                  isDatePassed(
                                                    String(dayData.date)
                                                  ) == true
                                                    ? "/timesheet/view"
                                                    : "/#"
                                                }
                                                state={{
                                                  obj: obj,
                                                  created_at: dayData.date,
                                                }}
                                              >
                                                {dayData.status == "leave"  ? (
                                                  <span
                                                    style={{ color: "orange",fontSize:12 }}
                                                    id={`tooltip_${dayIndex}`}
                                                    onMouseEnter={e =>
                                                      handleMouseEnter(
                                                        e,
                                                        dayData
                                                      )
                                                    }
                                                    onMouseLeave={
                                                      handleMouseLeave
                                                    }
                                                  >
                                                    L
                                                  </span>
                                                ) : dayData.status ==
                                                  "absent" ? (
                                                  <span
                                                    style={{ color: "red",fontSize:12 }}
                                                    id={`tooltip_${dayIndex}`}
                                                    onMouseEnter={e =>
                                                      handleMouseEnter(
                                                        e,
                                                        dayData
                                                      )
                                                    }
                                                    onMouseLeave={
                                                      handleMouseLeave
                                                    }
                                                  >
                                                    A
                                                  </span>
                                                ) : (
                                                 
                                                  <span
                                                  
                                                    id={`tooltip_${dayIndex}`}
                                                    onMouseEnter={e =>
                                                      handleMouseEnter(
                                                        e,
                                                        dayData
                                                      )
                                                    }
                                                    onMouseLeave={
                                                      handleMouseLeave
                                                    }
                                                    style={{ color: "green" ,fontSize:12 }}
                                                  >
                                                    {dayData.total_work_time ===
                                                    null
                                                      ? "--"
                                                      : formatTime(
                                                          dayData.total_work_time
                                                        )}
                                                  </span>
                                                )}
                                              </Link>
                                              <UncontrolledTooltip
                                                style={{ width: 500 }}
                                                target={`tooltip_${dayIndex}`}
                                                placement="top"
                                                // isOpen={tooltipOpen}
                                                // toggle={() => setTooltipOpen(!tooltipOpen)}
                                              >
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html: tooltipContent,
                                                  }}
                                                ></p>
                                              </UncontrolledTooltip>
                                            </div>
                                          ) : (
                                            <span style={{fontSize:12}}>{dayData.shift_start ==  'N/a' ? 'Schedule OFF' : formatTimeShiftTiming(dayData.shift_start)} {dayData.shift_end == 'N/a' ? null : '- ' +formatTimeShiftTiming(dayData.shift_end)}</span>
                                          )}
                                        </td>
                                      )
                                    }
                                  )}
                                  <td><span style={{fontSize:12}}>{formatTime(employee.total_hours)}</span></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                          {/* {JSON.stringify(dataShownWeekly)} */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CustomTabPanel>
                <CustomTabPanel value={activeTab} index={2}>
                  <Row>
                    <Col md={12}>
                      <h3>Monthly</h3>
                      <div className="row">
                        <p>
                          <span>
                            <span
                              className="badge bg-success"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Present
                          </span>
                          &nbsp;&nbsp;
                          <span>
                            {" "}
                            <span
                              className="badge bg-trady"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Trady
                          </span>
                          &nbsp;&nbsp;
                          <span>
                            {" "}
                            <span
                              className="badge bg-half-day"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Half Day
                            
                          </span>
                          &nbsp;&nbsp;
                          <span>
                            {" "}
                            <span
                              className="badge bg-danger"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Absent
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span>
                            {" "}
                            <span
                              className="badge bg-warning"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Leave
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span>
                            {" "}
                            <span
                              className="badge bg-red"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Schedule OFF
                          </span>
                          &nbsp;&nbsp;{" "}
                          <span>
                            {" "}
                            <span
                              className="badge bg-green"
                              style={{ color: "transparent" }}
                            >
                              --
                            </span>
                            &nbsp;Upcoming Days 
                          </span>
                         
                        </p>
                      </div>
                      <Row>
                        <Col md={4}>
                          <select
                            className="form-control"
                            value={selectedMonth}
                            onChange={e => onChangevalueoFMonth(e.target.value)}
                          >
                            <option value={1}>Jan</option>
                            <option value={2}>Feb</option>
                            <option value={3}>Mar</option>
                            <option value={4}>Apr</option>
                            <option value={5}>May</option>
                            <option value={6}>Jun</option>
                            <option value={7}>July</option>
                            <option value={8}>Aug</option>
                            <option value={9}>Sep</option>
                            <option value={10}>Oct</option>
                            <option value={11}>Nov</option>
                            <option value={12}>Dec</option>
                          </select>
                        </Col>
                      </Row>
                      {/* &nbsp; <i className="fa fa-1x fa-calendar"></i> */}
                    </Col>
                    <Col md={12}>
                      <Row style={{ paddingTop: 20 }}>
                        <Col md={2}>
                          <table
                            className="table"
                            style={{
                              background: "transparent",
                            }}
                          >
                            <thead>
                              <tr>
                                <th>
                                  Employee <br></br>Name{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {dataShownWeekly.length == 0 &&
                              showPlaceholder == true ? (
                                <tr>
                                  <td>
                                    <Skeleton width={100} height={20} />
                                  </td>
                                </tr>
                              ) : null}
                              {dataShownWeekly.map((employee, index) => (
                                <tr key={index}>
                                  <td style={{ whiteSpace: "nowrap" }}>
                                    {currentRole == "admin" ||
                                    currentRole == "supervisor" ? (
                                      <Link
                                        to={`../../people/members/view/${employee.emp_data.emp_id}`}
                                      >
                                        {employee.emp_data.emp_id}
                                        {"-"}
                                        {employee.emp_data.first_name +
                                          employee.emp_data.last_name}
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`../../people/members/view/${employee.emp_data.id}`}
                                      >
                                        {employee.emp_data.id}
                                        {"-"}
                                        {employee.emp_data.first_name +
                                          employee.emp_data.last_name}
                                      </Link>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Col>
                        <Col md={10}>
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                              <thead className="thead-dark">
                                <tr>
                                  {columnShow.map((e, index) => (
                                    <th key={index}>
                                      <div className="text-center">
                                        {e.dayName.substring(0, 1)}
                                        <br />
                                        {e.date.split(" ")[1]}
                                      </div>
                                    </th>
                                  ))}
                                  <th>Working Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                {dataShownWeekly.length == 0 &&
                                showPlaceholder == true ? (
                                  <tr>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                    <td>
                                      <Skeleton width={30} height={20} />
                                    </td>
                                  </tr>
                                ) : null}
                                {dataShownWeekly.map((employee, index) => (
                                  <tr key={index}>
                                    {employee.data_list.map(
                                      (dayData, dayIndex) => {
                                        const obj = {
                                          status: dayData.status,
                                          data: employee.emp_data,
                                          id: dayData.emp_id,
                                          attendance_id : dayData.attendance_id, 
                                          created_at: dayData.created_at,
                                          time_punch_in: dayData.time_punch_in,
                                          time_punch_out:
                                            dayData.time_punch_out,
                                          total_work_time:
                                            dayData.total_work_time,
                                          total_break_time:
                                            dayData.total_break_time,
                                        }
                                        return (
                                          <td key={dayIndex}>
                                            <Link
                                              to={
                                                isDatePassed(
                                                  String(dayData.date)
                                                ) == true
                                                  ? "/timesheet/view"
                                                  : "#"
                                              }
                                              state={{
                                                obj: obj,
                                                created_at: dayData.date,
                                              }}
                                              className="d-block text-center"
                                              style={{
                                                textDecoration: "none",
                                              }}
                                            >
                                              <div
                                                id={`tooltip_${dayIndex}`}
                                                onMouseEnter={e =>
                                                  handleMouseEnter(e, dayData)
                                                }
                                                onMouseLeave={handleMouseLeave}
                                                style={{
                                                  color: "transparent",
                                                }}
                                                className={`badge ${
                                                  dayData.time_punch_in ==
                                                    null &&
                                                  !isDatePassed(
                                                    String(dayData.date)
                                                  )  && dayData.shift_start !== 'N/a'
                                                    ? "bg-green"
                                                    : dayData.status === 
                                                      "absent" && dayData.shift_start !== 'N/a' 
                                                    ? "bg-danger"
                                                    : dayData.status ===
                                                      "present" && dayData.shift_start !== 'N/a'
                                                    ? "bg-success"
                                                    : dayData.status === "leave"
                                                    && dayData.shift_start !== 'N/a'
                                                    ? "bg-warning"
                                                    : dayData.shift_start == 'N/a' ?
                                                    "bg-red" : ""
                                                }`}
                                              >
                                                {dayData.time_punch_in == null
                                                  ? "-0"
                                                  : "-0"}
                                              </div>
                                            </Link>
                                            {/* {tooltipOpen && ( */}
                                            <UncontrolledTooltip
                                              style={{ width: 500 }}
                                              target={`tooltip_${dayIndex}`}
                                              placement="top"
                                              // isOpen={tooltipOpen}
                                              // toggle={() => setTooltipOpen(!tooltipOpen)}
                                            >
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: tooltipContent,
                                                }}
                                              ></p>
                                            </UncontrolledTooltip>

                                            {/* <UncontrolledTooltip
                                                style={{width:500}}
                                                title="Here"
                                                placement="top"
                                                target={`tooltip_${dayIndex}`}
                                                trigger="hover"
                                              >
                                                
                                              </UncontrolledTooltip> */}
                                            {/* )} */}
                                          </td>
                                        )
                                      }
                                    )}
                                    <td>{formatTime(employee.total_hours)}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CustomTabPanel>
              </Box>
            </Col>
          </Row>

        <ChatWidget/>
        </Container>
      </div>
    </React.Fragment>
  )
}

TimeSheet.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeSheet))
