import React, { Component, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { NavLink, Link } from "react-router-dom"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  CardTitle,
  Button,
  Label,
  Input,
  Spinner
  
} from "reactstrap"
import toastr from "toastr"
import Switch from 'react-switch'

import "toastr/build/toastr.min.css"
import classnames from "classnames"
import {
  Actions,
  Coin,
  Pdate,
  Status,
  Tracked,
  Type,
} from "pages/Timesheet/SheetData"

import Select from "react-select"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Box,
  Tab,
  Tabs,
  Typography,
} from "@mui/material"

import { ArrowDropDownCircleOutlined } from "@mui/icons-material"
import ChatWidget from "components/Common/ChatWidget"
import axios from "axios"
import LatestProjects from "./LatestProjects"
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

class ProjectHomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeTab: String(JSON.parse(localStorage.getItem('authUser')).map((e)=>e.role == 'user' || e.role == 'supervisor' ? '2'  :  localStorage.getItem('currentActiveTabOnThisScreen') == null ? "1" : localStorage.getItem('currentActiveTabOnThisScreen'))) ,
      dataFetch: [],
      filterFetch: [],
      activityColor: null,
      isAttachmentSwitch:false,
      loadingSpinner:false,
      assignTo: [],
      mockData2: [],
      createTaskShow:false,
      clientType:'',
      DataToBeSet: [],
      createActivity: false,
      DataToBeSetTasks: [],
      optionforTask: [],
      activeProject: 0,
      completedProject: 0,
      newProjects: 0,
      activeTabValue: 0,
      completedTasks: [],
      clientDataList: [],
      activeTasks: [],
      overdueTasks: [],
      totalTasks: [],
      setassignTo:[],
      startDate:new Date(),
      projectDescription: '',
    }
  }
  
  toggleTab = tab => {
    if (this.state.activeTab !== tab) {
      localStorage.setItem('currentActiveTabOnThisScreen', tab)
      this.setState({ activeTab: tab })
    }
  }
  
  fetchEmployeeList = () => {
    
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    let form = new FormData()
    form.append("method", "fetch-user-for-select")
    form.append("emp_id", emp)
    form.append("right", "true")
    form.append("org_id", org)
    var requestOptions = {
      method: "POST",
      body: form,
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/login.php", requestOptions)
      .then(res => res.json())
      .then(result => {
        this.setState({ mockData2: result })
      })
      .catch(er => {
        console.log(er)
      })
  }
  getAllActivities = async () => {
   
    var formdata = new FormData()
    formdata.append("method", "get-activities-all")
    formdata.append("role", "admin")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    await fetch(
      process.env.REACT_APP_LEAVE_API_URL + "/activity.php",
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        
        this.setState({ dataFetch: result, filterFetch: result })
     
      })
      .catch(error => console.log("error", error))
  }
  generateColumns = () => {
    return [
      {
        Header: "Activity Color",
        accessor: "activity_color",
        filterable: true,
        Cell: cellProps => {
          return (
            <span style={{ background: cellProps.value, color: "transparent" }}>
              --
            </span>
          )
        },
      },
      {
        Header: "Activity Name",
        accessor: "activity_name",
        filterable: true,
        Cell: cellProps => <Type {...cellProps} />,
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: cellProps => {
          if (cellProps.value == "active") {
            return <Badge className="bg-success font-size-10">active</Badge>
          } else {
            return <Badge className="bg-danger font-size-10">in-active</Badge>
          }
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Cell: cellProps => <Coin {...cellProps} />,
      },
      {
        Header: "Actions",
        accessor: "emp_id",
        filterable: true,
        Cell: cellProps => {
          return (
            <div>
              <button className="btn btn-info">Edit</button>&nbsp;&nbsp;
              <button className="btn btn-danger">Delete</button>
            </div>
          )
        },
      },
    ]
  }
  fetchClientList = () => {
    let localVar = localStorage.getItem("authUser")
    let localArray = JSON.parse(localVar)
    let emp = localArray.map(e => e.user_id)
    let org = localArray.map(e => e.org_id)
    
        let form  = new FormData();
        form.append('method','fetch-clients');
        
        form.append('org_id',org);
        var requestOptions = {
          method:'POST',
          body:form
        }
        fetch(process.env.REACT_APP_LEAVE_API_URL+'/clients.php',requestOptions).then((res)=>res.json()).then((result)=>{
        
        this.setState({clientDataList: result});
        
        }).catch((er)=>{
          console.log(er)
        })
      
  }
  getAllProjectFromDB = () => {
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    if (role == "admin") {
      var formdata = new FormData()
      formdata.append("org_id", orgId)
      formdata.append("method", "fetch-projects-for-dashboard")

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }

      fetch(
        process.env.REACT_APP_LEAVE_API_URL + "/projects.php",
        requestOptions
      )
        .then(response => response.json())

        .then(result => {
          this.setState({
            DataToBeSet: result.data,
            activeProject: result.active,
            completedProject: result.completed,
            newProjects: result.new,
          })
        })
        .catch(error => console.log("error", error))
    } else {
      var formdata = new FormData()
      formdata.append("method", "get-tasks-assigned-all")
      formdata.append("id", user_id)
      formdata.append("role", role)
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      }
      fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
        .then(response => response.json())
        .then(result => this.setState({ DataToBeSet: result }))
        .catch(error => console.log("error", error))
    }
  }
  getAllTasks = () => {
    // this.setState({isLoading : true})
    this.setState({ DataToBeSetTasks: [] })
    let valueFrom = localStorage.getItem("authUser")
    let convertedValue = JSON.parse(valueFrom)
    let orgId = convertedValue.map(e => e.org_id)
    let user_id = convertedValue.map(e => e.user_id)
    let role = convertedValue.map(e => e.role)
    this.setState({ CurrentUserID: user_id, currentRole: role })
    var formdata = new FormData()
    formdata.append("method", "get-tasks-assigned-all-by-status")
    formdata.append("id", user_id)
    formdata.append("role", role)
    formdata.append("status", "null")
    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    }

    fetch(process.env.REACT_APP_LEAVE_API_URL + "/tasks.php", requestOptions)
      .then(response => response.json())

      .then(result => {
        this.setState({ DataToBeSetTasks: result })
    
        const total = result.filter(item => {
          return item
        })
        const active = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)
          if (item.task.status == "active" && deadlineDate > currentDate) {
            return item
          }
        })
        const overdue = result.filter(item => {
          let currentDate = new Date()
          let deadlineDate = new Date(item.task.deadline_date)

          if (deadlineDate < currentDate && item.task.status == "active") {
            return item
          }
        })
        const completed = result.filter(item => {
          if (item.task.status == "completed") {
            return item
          }
        })
        this.setState({ totalTasks: total })
        this.setState({ overdueTasks: overdue })
        this.setState({ activeTasks: active })
        this.setState({ completedTasks: completed })
        let option = {
          completed: completed.length || 0,
          active: active.length || 0,
          overdue: overdue.length || 0,
        }
        this.setState({ optionforTask: option })
      
      })
      .catch(error => console.log("error", error))

 
  }

 
  componentDidMount() {
    this.getAllActivities()
    this.getAllProjectFromDB()
    this.fetchEmployeeList()
    this.getAllTasks()
    this.fetchClientList()
    // this.getTasksCounter()
  }
  render() {
    document.title = `Projects | ${process.env.REACT_APP_NAME}`
    const {
      projectDescription,
     clientType,
      mockData2,
      DataToBeSet,
      newProjects,
      DataToBeSetTasks,
      activeTabValue,
      activeTasks,
      completedTasks,
      totalTasks,
      overdueTasks,
      isAttachmentSwitch,
      loadingSpinner,
      setassignTo,
      clientDataList,
      startDate
    } = this.state
    const columns = this.generateColumns()
    const renderRadioButton = (color, value) => (
      <div
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginVertical: 8,
        }}
      >
        <div
          style={{
            backgroundColor: color,
            borderRadius: 50,
            width: 150,
            marginBottom: 8,
          }}
        >
          <input
            type="radio"
            name="radiobutton"
            className="form-check-input"
            value={this.state.activityColor}
            // checked={this.state.activityColor === value ? true : false}
            onChange={() => this.setState({ activityColor: value })}
            color={"white"}
          />
        </div>
      </div>
    )
    const convertDateFormat = inputDate => {
      const parts = inputDate.split("/")
      const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`
      return formattedDate
    }
    const formatDate = inputDate => {
      const date = new Date(inputDate)
      const options = { day: "2-digit", month: "short", year: "numeric" }
      return new Intl.DateTimeFormat("en-UK", options).format(date)
    }
    const calculateDaysDifference = deadlineDate => {
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const currentDate = new Date()
      const deadline = new Date(deadlineDate)

      // Calculate the difference in days
      const differenceInDays = Math.round((deadline - currentDate) / oneDay)

      // Determine status message based on the difference
      let statusMessage
      if (differenceInDays === 1) {
        statusMessage = "Tomorrow"
      } else if (differenceInDays === 0) {
        statusMessage = "Today"
      } else if (differenceInDays < 0) {
        statusMessage = `${Math.abs(differenceInDays)} days overdue`
      } else {
        statusMessage = `${differenceInDays} days remaining`
      }

      return statusMessage
    }

    const series = [
      {
        name: "Complete Tasks",
        type: "column",
        data: [23, 11, 22, 27, 13, 22, 52, 21, 44, 22, 30],
      },
      {
        name: "All Tasks",
        type: "line",
        data: [23, 11, 34, 27, 17, 22, 62, 32, 44, 22, 39],
      },
      {
        name: "Overdue Tasks",
        type: "line",
        data: [9, 1, 2, 3, 7, 8, 10, 12, 18, 0, 9],
      },
    ]

    const options = {
      chart: { height: 280, type: "line", stacked: !1, toolbar: { show: !1 } },
      stroke: { width: [0, 1, 2], curve: "smooth" },
      plotOptions: { bar: { columnWidth: "50%", endingShape: "rounded" } },
      colors: ["#556ee6", "#34c38f", "red"],
      fill: {
        gradient: {
          inverseColors: !1,
          shade: "light",
          type: "vertical",
          opacityFrom: 0.85,
          opacityTo: 0.55,
          stops: [0, 100, 100, 100],
        },
      },
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
      ],
      markers: { size: 0 },
      yaxis: { min: 0 },
    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      this.setState({ activeTabValue: newValue })
    }
    const Offsymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            No
          </div>
        );
      };
      const OnSymbol = () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              fontSize: 12,
              color: "#fff",
              paddingRight: 2
            }}
          >
            {" "}
            Yes
          </div>
        );
      };
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <div className="ms-auto">
              <Row>
                <Col md={12}>
                  <Box sx={{ width: "100%" }}>
                  <Row style={{ paddingTop: 20 }}>
                            <Col md={6}>
                              <h5>Project Information</h5>
                            </Col>
                            <Col md={6} className="text-end mb-3">
                              <Button onClick={()=>this.setState({createTaskShow: true})} >Add Project</Button>
                            </Col>
                            <Col lg={3}>
                              <Link to={"../projects/total"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">Total</p>
                                        <h5 className="mb-0">
                                          {DataToBeSet.length}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col lg={3}>
                              <Link to={"../projects/new"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">New</p>
                                        <h5 className="mb-0">{newProjects}</h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                            <Col lg={3}>
                              <Link to={"../projects/active"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Active{" "}
                                        </p>
                                        <h5 className="mb-0">
                                          {this.state.activeProject}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>

                            <Col lg={3}>
                              <Link to={"../projects/completed"}>
                                <Card className="mini-stats-wid">
                                  <CardBody>
                                    <div className="d-flex flex-wrap">
                                      <div className="me-3">
                                        <p className="text-muted mb-2">
                                          Completed
                                        </p>
                                        <h5 className="mb-0">
                                          {this.state.completedProject}
                                        </h5>
                                      </div>

                                      <div className="avatar-sm ms-auto">
                                        <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                          <i className="bx bxs-book-bookmark"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Link>
                            </Col>
                          </Row>

                          <Row>
                            <Col lg={12}>
                              <LatestProjects parsedData={DataToBeSet} />
                            </Col>
                          </Row>
                  </Box>
                </Col>
              </Row>
            </div>
          </Container>
          <Offcanvas
          isOpen={this.state.createTaskShow}
          direction="bottom"
        style={{height:800}}
          toggle={()=>this.setState({createTaskShow: false})}
        >
          <OffcanvasHeader
          toggle={()=>this.setState({createTaskShow: false})}
          >
            Create Project
          </OffcanvasHeader>
          <OffcanvasBody>
            <Form
            onSubmit={(e)=>{
                e.preventDefault();
                
                if(clientType == ""){
                    toastr.error('Select the client type');
                    return false;
                }else if(e.target.elements.project_name.value == ""){
                    toastr.error('Project name is required');
                    return false
                }else if(projectDescription == ""){
                    toastr.error('Project description is required');
                    return false
                }else{
                    let local = JSON.parse(localStorage.getItem('authUser'));
                    let org = local.map((e)=>e.org_id)
                    let user_id = local.map((e)=>e.user_id)
                    this.setState({loadingSpinner: true})
                    let form = new FormData();
                    const fileInput = document.querySelector('input[type="file"]')
                    const file = isAttachmentSwitch === true ? fileInput.files[0] : null
                    form.append('method', 'create-project');
                    form.append('org_id', org );
                    form.append('client_type' , this.state.clientType);
                    if(this.state.clientType == 'new client'){
                        form.append('client_name',e.target.elements.client_name.value)
                        form.append('client_email',e.target.elements.client_email.value)
                        form.append('client_phone',e.target.elements.client_phone.value)
                        form.append('client_address',e.target.elements.client_address.value)
                    }else{
                        form.append('client_id',this.state.clientType == 'existing client' ? e.target.elements.client_id.value : null)
                    }
                    const dd = String(startDate.getDate()).padStart(2, "0")
                    const mm = String(startDate.getMonth() + 1).padStart(2, "0") // January is 0!
                    const yyyy = startDate.getFullYear()
                    let datetosend = `${mm}/${dd}/${yyyy}`
                    form.append('project_name',e.target.elements.project_name.value)
                    form.append('project_description',projectDescription)
                    form.append('deadline_date',datetosend)
                    form.append('created_by',user_id)
                    if (isAttachmentSwitch == true) {
                      form.append('file_upload', file);
                    }
                   
                    axios.post(
                      process.env.REACT_APP_LEAVE_API_URL+'/projects.php',form,{headers:{
                          "Content-Type": "multipart/form-data",
                        }}
                       ).then(result => {
                          this.setState({loadingSpinner: false})
                          if(result.data === 'success'){
                              toastr.success('You successfully added the project');
                              this.getAllProjectFromDB();
                              this.setState({createTaskShow:false});
                          }else{
                              toastr.error('Error while adding the project');
                          }
                      })
                      .catch(e => console.log(e));
                }  
            }}
            >
            
              <Row>
                <Col md={12} className="mb-3">
                

                  <label>{'Select Client'}</label>
                    <div className="row">
                    <div className="col-md-2">

                        <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor3"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>this.setState({clientType: e.target.value})}
                              value={'existing client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor3"
                            >
                              Existing Client
                            </label>
                          </div>
                    </div>
                    <div className="col-md-2">
                           <div className="form-check form-radio-primary mb-3">
                            <input
                              type="radio"
                              id="customRadiocolor2"
                              name="customRadiocolor1"
                              className="form-check-input"
                              onChange={(e)=>this.setState({clientType: e.target.value})}
                              value={'new client'}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customRadiocolor2"
                            >
                              New Client
                            </label>
                          </div>
                    </div>
                    </div>
                  
                  
                </Col>
                
                {

                  clientType == "" ?  null:
                  ( clientType == 'existing client' ? 
                    <Col md={12} className="mb-3">
                  <div className="mb-3">
                    <label>Select Client</label>
                    <Select
                      
                      onChange={item => 
                        this.setState({setassignTo: item.value})
                      }
                      options={clientDataList}
                      name="client_id"
                      className="select2-selection"
                    />
                  </div>
                </Col>: <Col md={12}>
                    <Label>Client Information</Label>
                    <Row>
                        <Col md={4} className="mb-3">
                            <Input
                            type="text"
                            className="form-control"
                            name="client_name"
                            placeholder="Client Name"
                            
                            />
                        </Col>
                        <Col md={4} className="mb-3">
                            <Input
                            type="email"
                            className="form-control"
                            name="client_email"
                            placeholder="Client Email"

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} className="mb-3">
                            <Input
                            type="tel"
                            className="form-control"
                            name="client_phone"
                            placeholder="Client Phone"
                            
                            />
                        </Col>
                        <Col md={12} className="mb-3">
                            <Input
                            type="textarea"
                            className="form-control"
                            name="client_address"
                            placeholder="Client Address"

                            />
                        </Col>
                    </Row>
                </Col>)
                }
                <Col md={6} className="mb-3">
                  <Label>Project Name</Label>
                 <Input
                    name="project_name"
                    type="text"
                    className="form-control"
                    placeholder="..."
                 />
                </Col>
                <Col md={6} className="mb-3">
                  <Label>Deadline Date</Label>
                  <DatePicker
                      selected={startDate}
                      onChange={date => this.setState({startDate:date})}
                      className="form-control"
                      dateFormat="d-MM-yyyy"
                      name="deadline_date"
                      placeholderText="Select date"
                    />
                </Col>
                <Col md={12} className="mb-3">
                  <Label>Project Description</Label>
                   <CKEditor
                      editor={ClassicEditor}
                      data=""
                      
                      onReady={editor => {
                        console.log('Editor is ready to use!', editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        this.setState({projectDescription : data})

                      }}
                      
                    />
                  
                </Col>
               
                <Col md={12}>
                <div
                    style={{
                      display:'flex',
                      flexDirection: 'row',
                      columnGap:40,
                      // justifyContent: 'space-between',
                      paddingVertical: 10,
                    }}>
                    <div style={{justifyContent: 'center'}}>
                        <p style={{fontSize: 18}}>Any Attachments</p>
                    </div>
                    <Switch
                          uncheckedIcon={<Offsymbol />}
                          checkedIcon={<OnSymbol />}
                          className="me-1 mb-sm-8 mb-2"
                          onColor="#626ed4"
                          checked={isAttachmentSwitch}
                          onChange={() => {
                            this.setState({isAttachmentSwitch: !isAttachmentSwitch});
                          }}
                    />
                    </div>
                </Col>
                {
                    isAttachmentSwitch == true ? 
                    <Col md={12}>
                        <div style={{paddingBottom:10}}>Note File Size is less than 5 MB</div>
                        <div className="mb-3">
                            <input
                            name="input_file"
                            type="file"
                            className="form-control"
                            />
                        </div>
                    </Col> : null
                }
                <Col md={12} style={{textAlign:'start'}}>
                    <button type="button"  onClick={()=>this.setState({createTaskShow :false})} className="btn btn-danger">Cancel</button>
                    &nbsp;&nbsp;
                    {
                        loadingSpinner == false ? 
                        <button type="submit" className="btn btn-primary">Add Project</button>
                        :<Spinner style={{paddingTop:8}}/>
                    }
                </Col>
              </Row>
            </Form>
          </OffcanvasBody>
        </Offcanvas>
        </div>
        <ChatWidget/>
      </React.Fragment>
    )
  }
}
ProjectHomePage.PropTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
}

const mapStateToProps = ({ crypto }) => ({
  orders: crypto.orders,
})

const mapDispatchToProps = dispatch => ({
  onGetOrders: () => dispatch(getCryptoOrders()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProjectHomePage))
